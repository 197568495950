import './styles.css';
import { ErrorComponent } from 'adp-panel/components/Error/ErrorComponent';
import { GOALS_PATIENT } from 'adp-panel/constants/featureToggles';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import { useFeatureToggleIsEnabled } from 'adp-panel/hooks/useFeatureToggleIsEnabled';
import { useQuery } from 'adp-panel/hooks/useQuery';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import GoalUsageTab from 'adp-panel/pages/Goals/GoalsUsageTab';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';
import { useTypedParams } from 'hooks/useTypedParams';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  CLINICIANS,
  COMPANY,
  CONFIGURATOR_DEVICE,
  DEVICE,
  GOALS_ADD,
  PATIENTS,
  PATIENT_EDIT
} from '../../../constants/routes';
import useUserData from '../../../hooks/useUserData';
import {
  DeviceEntry,
  DeviceExtendOptions,
  DevicesQueryParams
} from '../../api/devices/device.types';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import {
  CLINICIANS_PERMISSIONS,
  COMPANIES_PERMISSIONS,
  GOALS_PERMISSIONS,
  PATIENTS_PERMISSIONS
} from '../../constants/rolesPermissions';
import { useDevicesList, useDeviceUpdate } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import { useUser, useUserDelete } from '../../hooks/api/useUsers';
import { userCanVisit, userHasPermissions } from '../../utils/permissionUtils';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import { stringAvatar, stringToColor } from 'adp-panel/components/PatientCard/PatientCard';
import CustomButton from 'components/Button/CustomButton';
import { Avatar, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as SendSvg } from 'assets/send.svg';
import { ReactComponent as HandSvg } from 'assets/hand-device-x.svg';
import { ReactComponent as HandAddSvg } from 'assets/hand-device-add.svg';
import Card from 'adp-panel/components/Card/Card';
import CustomTable from 'adp-panel/components/Table/CustomTable';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import { CustomTabPanel } from 'components/TabPanel/CustomTabPanel';
import DotsBackground from 'assets/dots.svg';
import AssignDevicePatientDetailsModal from 'adp-panel/components/Modals/AssignDevicePatientDetailsModal';
import LoaderWrapper from 'components/Loader/Loader';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useSessionStart } from 'hooks/useSessionStart';

const TABS = {
  devices: 0,
  deviceUsage: 1,
  goals: 2
};

const mapDeviceRows = (apiData: DeviceEntry[]) =>
  apiData.map((device) => ({
    id: device.id,
    serial: device.serial,
    model: device.model?.name,
    mobileStatus: true,
    lastDumUpdate: device.last_activity_at
  }));

const PatientDetails = () => {
  const goalsEnabled = useFeatureToggleIsEnabled(GOALS_PATIENT);
  const query = useQuery();
  const goalId = query.get('goalId');
  const { isLoading: isLoadingCanAccessGoals, canAccess: canAccessGoals } = useCanAccess({
    resource: 'goals'
  });
  const [selected, setSelected] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { patientId } = useTypedParams(['patientId']);
  const { data: userData, me, rolesByName } = useUserData();
  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.clinician]
  };
  const { handleOpenRemoteSession, handleOpenLocalSession, sessionStartingState } =
    useSessionStart();

  useEffect(() => {
    if (goalId) {
      setSelected(TABS.goals);
    }
  }, [goalId]);

  const queryParamsDevices: DevicesQueryParams = {
    amputee: patientId,
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };
  const {
    result: patient,
    isLoading: isLoadingPatient,
    isError: isErrorPatientInfo
  } = useUser(parseInt(patientId, 10), queryParams);

  const {
    result: devices,
    total: totalDevices,
    isLoading: isLoadingDevices
  } = useDevicesList(queryParamsDevices);

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();
  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const isLoadingPage = isLoadingCanAccessGoals || isLoadingPatient;

  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const {
    isOpen: isUnassignDeviceModalOpen,
    handleOpen: handleUnassignDeviceModalOpen,
    handleClose: handleUnassignDeviceModalClose
  } = useModal();

  const handleDelete = async () => {
    await deleteUser(patientId);
    navigate(PATIENTS);
  };

  const handleUnassignDevice = async () => {
    await updateDevice({ data: { amputee_id: null }, deviceId: selectedDevice });
    handleUnassignDeviceModalClose();
  };

  const handleEdit = () => {
    const parsedPatient = {
      ...patient,
      company: patient?.location?.company?.name,
      companyId: patient?.location?.company?.id,
      location: patient?.location?.name,
      locationId: patient?.location_id,
      clinician: patient?.clinician
    };
    navigate(PATIENT_EDIT, { state: { detail: parsedPatient } });
  };

  const handleAddGoal = () => {
    navigate(GOALS_ADD, { state: { userId: patientId } });
  };

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const hasGoalAccess = () => {
    if (userHasPermissions([RoleEnum.clinicianSupport], rolesByName)) {
      return canAccessGoals;
    }

    return userHasPermissions(GOALS_PERMISSIONS.CREATE, rolesByName);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    isOpen: isAssignDeviceModalOpen,
    handleOpen: handleAssignDeviceModalOpen,
    handleClose: handleAssignDeviceModalClose
  } = useModal();

  const deviceExists = devices?.[0]?.serial;

  if (isErrorPatientInfo)
    return (
      <ErrorComponent
        message="The patient no longer exists or you don't have access to it."
        allowRedirect
      />
    );

  return (
    <DefaultLayout>
      {isLoadingPage ? (
        <LoaderWrapper full>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: deviceExists ? '4fr 3fr' : '1fr',
            gap: '24px'
          }}>
          <ConfirmDeleteModal
            isModalOpen={isModalOpen}
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={patient?.name}
                id={parseInt(patientId, 10)}
                text={'Do you want to delete patient'}
              />
            }
          />
          {isUnassignDeviceModalOpen && (
            <ConfirmDeleteModal
              isModalOpen={isUnassignDeviceModalOpen}
              handleClose={handleUnassignDeviceModalClose}
              handleAccept={handleUnassignDevice}
              isLoading={isLoadingUpdate}
              message={'Do you want to unassign device from the patient?'}
              acceptMessage='Yes, unassign'
              title='Unassign device'
            />
          )}
          <AssignDevicePatientDetailsModal
            isModalOpen={isAssignDeviceModalOpen}
            handleModalClose={handleAssignDeviceModalClose}
            patientId={patientId}
          />
          <Card>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                {...stringAvatar(patient?.name)}
                sx={{
                  width: 84,
                  height: 84,
                  bgcolor: stringToColor(patient?.name),
                  fontSize: 30,
                  textTransform: 'uppercase',
                  marginRight: '16px'
                }}
              />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Typography variant='h6' sx={{ fontWeight: '600' }}>
                  {patient?.name}
                </Typography>
                <div>
                  <Typography variant='body2'>E-mail</Typography>
                  <Typography variant='h6' sx={{ fontWeight: '600' }}>
                    {patient?.email}
                  </Typography>
                </div>
                {devices?.[0]?.serial && (
                  <div>
                    <Typography variant='body2'>Device ID</Typography>
                    <Typography variant='h6' sx={{ fontWeight: '600' }}>
                      {devices?.[0]?.serial}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
              <CustomButton
                Icon={CogSvg}
                onClick={(e) =>
                  handleOpenLocalSession(
                    devices?.[0]?.serial,
                    devices?.[0]?.bluetooth_id,
                    devices?.[0]?.id
                  )
                }
                disabled={!deviceExists}>
                Configure Zeus
              </CustomButton>
              <CustomButton
                Icon={ComputerSvg}
                color='light'
                loading={sessionStartingState === FETCHING_STATES.loading}
                onClick={() => handleOpenRemoteSession(Number(patientId))}
                disabled={!deviceExists}>
                Start Remote Session
              </CustomButton>
              <CustomButton Icon={SendSvg} color='light' onClick={() => false}>
                Invite Medical Professional
              </CustomButton>
            </div>
            <Tabs value={value} onChange={handleChange} sx={{ marginTop: '32px' }}>
              <Tab label='Devices' />
              <Tab label='Goals' disabled={!deviceExists} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              {deviceExists ? (
                <CustomTable
                  tableData={devices}
                  totalItems={totalDevices}
                  isLoading={isLoadingDevices}
                  columns={[
                    {
                      field: 'serial',
                      headerName: 'Serial Number',
                      flex: 1
                    },
                    { field: 'model', headerName: 'Model', flex: 1, sortable: false },
                    {
                      field: 'mobileStatus',
                      headerName: 'Mobile App Status',
                      flex: 1,
                      sortable: false
                    },
                    {
                      field: 'lastDumUpdate',
                      headerName: 'Date of last update of device usage data',
                      flex: 1,
                      sortable: false
                    },
                    {
                      field: 'actions',
                      type: 'actions',
                      flex: 1,
                      headerName: 'Actions',
                      getActions: (params) => {
                        const { serial, bluetooth_id, id } = params.row;
                        return [
                          <CustomButton
                            Icon={TrashSvg}
                            color='light'
                            data-testid='button-delete'
                            onClick={() => {
                              setSelectedDevice(id);
                              handleUnassignDeviceModalOpen();
                            }}
                          />
                        ];
                      }
                    }
                  ]}
                  columnVisibilityModel={{
                    active_code: userHasPermissions([RoleEnum.superAdmin], rolesByName)
                  }}
                  mapTableData={mapDeviceRows}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                />
              ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
                  <div
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: '20px',
                      padding: '40px',
                      backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
                    }}>
                    <Card style={{ display: 'flex', alignItems: 'center' }}>
                      <HandSvg />
                    </Card>
                    <Typography sx={{ fontWeight: 600 }}>No devices</Typography>
                    <Typography variant='caption' sx={{ width: '250px' }}>
                      Click the button below to add first device to the patient.
                    </Typography>
                    <div style={{ display: 'flex', gap: '16px' }}>
                      <CustomButton Icon={HandAddSvg} onClick={handleAssignDeviceModalOpen}>
                        Assign device
                      </CustomButton>
                    </div>
                  </div>
                </Card>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              Goals
            </CustomTabPanel>
          </Card>
          {deviceExists && <Card>Hello</Card>}
        </div>
      )}
    </DefaultLayout>
  );
};

export default PatientDetails;
