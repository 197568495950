import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormInput from '../FormInput/FormInput';
import { requiredValidator } from '../FormInput/validators';
import * as Sentry from '@sentry/react';
import LoaderButton from '../LoaderButton/LoaderButton';
import { AddDeviceModalContext } from 'adp-panel/hoc/ModalProvider';
import { Loader } from '@progress/kendo-react-indicators';
import CustomVerificationCode from 'adp-panel/components/CustomVerificationCode/CustomVerificationCode';
import { Link } from 'adp-panel/pages/Auth/SetUpPass/styled';
import { useAddDeviceWithCode } from 'adp-panel/hooks/api/useDeviceCodes';
import { useDevicesListInfinite } from 'adp-panel/hooks/api/useDevices';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import { NotificationFactory } from 'lib/NotificationFactory';

const InnerWrapper = styled.div`
  max-width: 760px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const CodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const AddDeviceModal = () => {
  const { hideAddDeviceModal } = useContext(AddDeviceModalContext);
  const { mutateAsync: addDeviceWithCode, isLoading: isLoadingAddDeviceWithCode } =
    useAddDeviceWithCode();
  const [verificationCode, setVerificationCode] = useState<undefined | string>(undefined);
  const {
    result: devices,
    isLoading: isLoadingDevices,
    refetch: refetchDevices,
    isSuccess: isSuccessDevices
  } = useDevicesListInfinite({ perPage: '200' });

  const handleVerificationCodeChange = (e: string) => {
    setVerificationCode(e.toUpperCase());
  };

  const handleSubmit = async (e: any) => {
    const { serial } = e;

    if (devices) {
      const hasAccessToDevice = devices.find((device: DeviceEntry) => device.serial === serial);

      if (hasAccessToDevice) {
        NotificationFactory.errorNotification(
          'This Device has already been added to your Company. You can find it on the Devices page.'
        );
        return;
      }
    }

    if (!verificationCode) return;

    try {
      await addDeviceWithCode({ code: verificationCode, serial });
      hideAddDeviceModal();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <Dialog title={'Add new device'} onClose={hideAddDeviceModal}>
      <InnerWrapper>
        {isLoadingDevices ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader />
          </div>
        ) : (
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <p style={{ marginBottom: '36px' }}>
                  If you just received new device you can add it by pasting activation code. You can
                  find code in the package with device. If you can’t find it, please write to:{' '}
                  <Link href='mailto:support@aetherbiomedical.com'>
                    support@aetherbiomedical.com
                  </Link>
                </p>
                <div style={{ display: 'grid', justifyItems: 'center' }}>
                  <CodeWrapper>
                    <p style={{ marginBottom: '12px' }}>
                      Please enter the activation code included with the product.
                    </p>
                    <CustomVerificationCode
                      onChange={handleVerificationCodeChange}
                      value={verificationCode}
                    />
                  </CodeWrapper>
                  <Field
                    id={'serial'}
                    name={'serial'}
                    label={
                      <div style={{ textAlign: 'center', width: '100%' }}>Device serial number</div>
                    }
                    component={FormInput}
                    validator={requiredValidator}
                    style={{ width: '200px', justifyContent: 'center' }}
                  />
                </div>
                <ButtonsWrapper className='k-form-buttons'>
                  <Button onClick={hideAddDeviceModal} type='button'>
                    Cancel
                  </Button>
                  <Button
                    themeColor={'primary'}
                    type='submit'
                    disabled={verificationCode?.length !== 6 || isLoadingAddDeviceWithCode}>
                    Add
                    {isLoadingAddDeviceWithCode && <LoaderButton />}
                  </Button>
                </ButtonsWrapper>
              </FormElement>
            )}></Form>
        )}
      </InnerWrapper>
    </Dialog>
  );
};

export default AddDeviceModal;
