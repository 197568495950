import styled, { css } from 'styled-components';

export const HistoryConfiguratorContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
  margin-right: 20px;
  > div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

export const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TableStyle = css`
  display: grid;
  grid-template-columns: 40% repeat(auto-fit, minmax(100px, 1fr));
  justify-items: start;
  align-items: center;
`;

export const TableBody = styled.div`
  ${TableStyle};
`;

export const TableBodyConfig = styled.div`
  ${TableStyle};
  &:first-child {
    padding: 0 0 20px 0;
  }
  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

export const TableHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;
