import React from 'react';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { Modal } from '@mui/material';
import { ModalStyle } from './styles';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

type ConfirmDeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  message: React.ReactNode;
  isLoading: boolean;
  isModalOpen: boolean;
  acceptMessage?: string;
  title?: string;
};

export const ModalMessageDelete = ({
  name,
  id,
  text
}: {
  name?: string;
  id?: number;
  text: string;
}) => (
  <>
    {text} <strong>{name}</strong> (ID: {id})?
  </>
);

export const ModalMessageDevice = ({ id }: { id: number }) => (
  <>Do you want to delete device (ID: {id})?</>
);

export const ModalMessageMode = ({ id }: { id: number }) => (
  <>Do you want to delete mode (ID: {id})?</>
);

export const ModalMessageReleaseNote = ({ id }: { id: number }) => (
  <>Do you want to delete mapping (ID: {id})?</>
);

const ConfirmDeleteModal = ({
  handleClose,
  isModalOpen,
  message,
  handleAccept,
  isLoading,
  acceptMessage = 'Yes, delete',
  title = 'Confirm delete'
}: ConfirmDeleteModalProps) => (
  <Modal open={isModalOpen} sx={{ width: '480px', ...ModalStyle }}>
    <FormContentWrapper title={title}>
      <p>{message}</p>
      <FormButtonsWrapper>
        <CustomButton color='light' onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton onClick={handleAccept} loading={isLoading}>
          {acceptMessage}
        </CustomButton>
      </FormButtonsWrapper>
    </FormContentWrapper>
  </Modal>
);

export default ConfirmDeleteModal;
