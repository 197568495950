import Card from 'adp-panel/components/Card/Card';
import { Typography } from '@mui/material';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as ProfileSvg } from 'assets/profile.svg';
import CustomButton from 'components/Button/CustomButton';
import Avatar from '@mui/material/Avatar';
import { myTheme } from 'theme/theme';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string) {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')?.[1]?.[0]}`
  };
}

const PatientCard = ({
  name,
  serial,
  handleConfigure,
  handleProfile,
  handleSession,
  loadingSession
}: {
  name: string;
  serial?: string;
  handleConfigure: () => void;
  handleProfile: () => void;
  handleSession: () => void;
  loadingSession: boolean;
}) => {
  return (
    <Card
      style={{
        backgroundColor: myTheme.palette.background
      }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          {...stringAvatar(name)}
          sx={{
            width: 84,
            height: 84,
            bgcolor: stringToColor(name),
            fontSize: 30,
            textTransform: 'uppercase',
            marginRight: '16px'
          }}
        />
        <div>
          <Typography variant='h6' sx={{ fontWeight: '600' }}>
            {name}
          </Typography>
          {serial && <Typography variant='body2'>Device ID: {serial}</Typography>}
        </div>
      </div>
      <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
        <CustomButton Icon={CogSvg} onClick={handleConfigure} disabled={!serial}>
          Configure Zeus
        </CustomButton>
        <CustomButton Icon={ProfileSvg} color='light' onClick={handleProfile}>
          Profile
        </CustomButton>
        <CustomButton
          Icon={ComputerSvg}
          color='light'
          onClick={handleSession}
          disabled={!serial}
          loading={loadingSession}>
          Start Remote Session
        </CustomButton>
      </div>
    </Card>
  );
};

export default PatientCard;
