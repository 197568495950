import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import ReactSlider from 'react-slider';
import './sliderStyles.css';

type SliderProps = {
  data: {};
  handleChange: Function;
  handleOnAfterChange?: Function;
  handleOnBeforeChange?: Function;
  orientationTrack?: string;
  invert?: boolean;
  value?: number | number[];
  min?: number;
  max?: number;
  disable?: boolean;
  trackClass?: string;
};

const SliderStyle = styled.div<{ disabled?: any }>`
  height: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`;

const Slider = memo(
  ({
    data,
    handleChange,
    handleOnAfterChange = () => true,
    handleOnBeforeChange = () => true,
    orientationTrack = 'horizontal',
    invert = false,
    value = 0,
    min = 0,
    max = 100,
    disable = false,
    trackClass = 'track-default',
    ...props
  }: SliderProps) => (
    <SliderStyle disabled={disable}>
      <ReactSlider
        {...props}
        onChange={(valueChanged, index) => handleChange(valueChanged, index, data)}
        className={orientationTrack === 'vertical' ? 'vertical-slider' : 'horizontal-slider'}
        thumbClassName={invert ? 'thumb-vertical' : 'thumb'}
        trackClassName={
          orientationTrack === 'vertical' ? `track-vertical ${trackClass}` : `track ${trackClass}`
        }
        onAfterChange={(valueChanged, index) => handleOnAfterChange(valueChanged, index, data)}
        onBeforeChange={(valueChanged, index) => handleOnBeforeChange(valueChanged, index, data)}
        orientation={orientationTrack}
        invert={invert}
        value={value}
        min={min}
        max={max}
        disabled={disable}
      />
    </SliderStyle>
  )
);

export default Slider;
