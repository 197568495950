import React from 'react';
import {
  StyledCalendarIcon,
  StyledCheck,
  StyledTableIcon
} from 'configurator/components/atoms/Icons/Icons';
import { ToggleViewButton, ToggleViewButtonsWrapper, ToggleViewWrapper } from './styled';

interface ToggleHistoryViewProps {
  status?: boolean;
  handler?: any;
}

export const ToggleHistoryView = ({
  status = false,
  handler = () => false
}: ToggleHistoryViewProps) => (
  <ToggleViewWrapper>
    <span>Choose view: </span>
    <ToggleViewButtonsWrapper>
      <ToggleViewButton type='button' active={status} onClick={handler}>
        <StyledCalendarIcon />
      </ToggleViewButton>
      <ToggleViewButton type='button' active={!status} onClick={handler}>
        <StyledTableIcon />
      </ToggleViewButton>
    </ToggleViewButtonsWrapper>
  </ToggleViewWrapper>
);

export const CheckComponent = () => (
  <span>
    <StyledCheck style={{ marginRight: '9px' }} />
    Yes
  </span>
);
