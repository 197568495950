import styled, { css } from 'styled-components';
import { freezeModeEmgPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import {
  FREEZE_MODE_ON_DUAL,
  FREEZE_MODE_ON_SINGLE
} from 'configurator/consts/deviceConfig/freezeMode';
import { configSettingsNamesMap, gripsGroupsOptionsMap } from 'configurator/utils/definesLocal';
import {
  ConfigToNameFunctionMapping,
  ConfigToValueFunctionMapping
} from 'configurator/views/DeviceConfig/ValueMappings';
import Accordion from 'configurator/components/atoms/Accordion/Accordion';
import { TableStyle } from 'configurator/views/DeviceConfig/DeviceConfig/styled';
import { actionMapping } from 'configurator/views/DeviceConfig/DeviceConfig/DeviceConfigComponent';
import { HistoryConfiguratorContainer } from 'configurator/views/DeviceConfig/SessionHistory/styled';

enum CONFIG_PROPERTY_SUPPORT {
  active,
  inactive,
  movedToCommon,
  movedToMode
}

interface ConfigHistoryItemProps {
  name: string;
  configName: keyof DeviceConfigTemplate;
  after: any;
  now: any;
  before?: any;
  showLoadChanges?: boolean;
  supported?: CONFIG_PROPERTY_SUPPORT;
}

const TableBodyConfig = styled.div`
  ${TableStyle};

  &:first-child {
    padding: 0 0 20px 0;
  }

  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

const ConfiguratorLimitWrapper = (initial: number[], limit: number[]) => (
  <HistoryConfiguratorContainer>
    <div>
      <span>Initial</span>
      <span>Limit</span>
    </div>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {initial &&
      initial.map((item, index) => (
        <div>
          <ValueWrapper>{item}</ValueWrapper>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <ValueWrapper>{limit[index]}</ValueWrapper>
        </div>
      ))}
  </HistoryConfiguratorContainer>
);

const gripName = (grip: any) =>
  `${configSettingsNamesMap.get('gripsPositions')} (${gripsGroupsOptionsMap.get(Number(grip))})`;

const GripHeaderWrapper = ({ grip }: any) => <div>{gripsGroupsOptionsMap.get(Number(grip))}</div>;

const GripsPositionsWrapper = (value: any, grip: any) =>
  ConfiguratorLimitWrapper(value[grip].initial, value[grip].limit);

const NameWrapper = (configName: keyof DeviceConfigTemplate) => {
  if (configName.includes('gripsPositions')) {
    const names = configName.split('.');
    return `${configSettingsNamesMap.get(
      names[0] as keyof DeviceConfigTemplate
    )} (${gripsGroupsOptionsMap.get(Number(names[1]))}, ${names[2]})`;
  }
  return configSettingsNamesMap.get(configName) ?? 'Setting name';
};

const HeaderItemWrapper = (type: string, value: any) => {
  if (type.includes('gripsPositions')) {
    const names = type.split('.');
    return (value || value === 0) && names[0] !== undefined ? (
      <div>{ConfigToNameFunctionMapping[`${names[0]}`](value)}</div>
    ) : null;
  }

  return value || value === 0 ? <div>{ConfigToNameFunctionMapping[`${type}`](value)}</div> : null;
};

export const ConfigHistoryItem = ({
  name,
  configName,
  after,
  now,
  before,
  showLoadChanges = false,
  supported = CONFIG_PROPERTY_SUPPORT.active
}: ConfigHistoryItemProps) => {
  const HistoricItemWrapper = (type: string, value: any, index: any, currentConfig = false) => {
    if (type.includes('gripsPositions')) {
      const names = name.split('.');

      if (currentConfig) value = now.gripsPositions[`${names[1]}`][`${names[2]}`][index];

      return value || value === 0 ? (
        <ValueWrapper>{ConfigToValueFunctionMapping[`${names[0]}`](value, index)}</ValueWrapper>
      ) : (
        <ValueWrapper />
      );
    }

    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
    if (before) before = [before?.[1]];
  }

  if (name === 'gripsPositions') {
    const grips = Object.keys(after);

    const GripDifference = ({ grip }: any) => (
      <Accordion header={gripName(grip)}>
        <TableBodyConfig>
          <GripHeaderWrapper grip={grip} />
          {before && GripsPositionsWrapper(before, grip)}
          {GripsPositionsWrapper(after, grip)}
          {GripsPositionsWrapper(now[`${name}`], grip)}
        </TableBodyConfig>
      </Accordion>
    );

    return (
      <>
        {grips.map((grip) => (
          <GripDifference grip={grip} />
        ))}
      </>
    );
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {!showLoadChanges && before && HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {!showLoadChanges && before && HistoricItemWrapper(name, before, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  if (name === 'freezeModeEmg') {
    const freezeModeDeactivatorBefore = before?.[freezeModeEmgPositions.detectorActivatingType];
    const freezeModeDeactivatorAfter = after?.[freezeModeEmgPositions.detectorActivatingType];

    if (
      (freezeModeDeactivatorBefore === FREEZE_MODE_ON_DUAL[0] ||
        freezeModeDeactivatorBefore === FREEZE_MODE_ON_SINGLE[0]) &&
      (freezeModeDeactivatorAfter === FREEZE_MODE_ON_DUAL[0] ||
        freezeModeDeactivatorAfter === FREEZE_MODE_ON_SINGLE[0])
    )
      return null;
  }

  if (!after) return null;

  return (
    <Accordion
      header={NameWrapper(configName)}
      actions={actionMapping[supported]}
      style={{ marginTop: '24px' }}>
      {after.map((value: any, index: any) => {
        const mappingExists =
          value || value === 0 ? ConfigToValueFunctionMapping[`${name}`](value, index) : null;

        if (!mappingExists) return;

        return (
          <TableBodyConfig>
            {HeaderItemWrapper(name, index)}
            {!showLoadChanges &&
              before &&
              HistoricItemWrapper(name, before ? before[index] : null, index)}
            {HistoricItemWrapper(name, value, index)}
            {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
          </TableBodyConfig>
        );
      })}
    </Accordion>
  );
};
