import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { DisableFunctionality } from 'configurator/utils/disableFunctionalityModifiier';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useSettingsStore } from 'configurator/reducers/settingsStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';

const SettingsContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  max-width: 800px;

  > * {
    padding-top: 20px;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const DisableControl = styled.div<{ disable?: any }>`
  ${({ disable }) => disable && DisableFunctionality};
`;

const ApplicationSettings = () => {
  const { getInitialConfig } = useConfigStore((state: any) => ({
    setItemConfigStore: state.setItemConfigStore,
    getInitialConfig: state.getInitialConfig
  }));
  const deviceConnected = useDeviceInfoStore((state: any) => state.connected);
  const { demoMode, setItemSettingsStore } = useSettingsStore((state: any) => ({
    demoMode: state.demoMode,
    setItemSettingsStore: state.setItemSettingsStore
  }));
  const { isUnsaved } = useUnsaved();
  const { openModal, blockScreen, unblockScreen } = useUiStore((state) => ({
    openModal: state.openModal,
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen
  }));

  const handleDemoModeChange = async (id: any, value: any) => {
    setItemSettingsStore('demoMode', value === 'On');
    if (value === 'On' && isUnsaved) openModal(MODALS.immediateSending);

    if (value === 'On' && !isUnsaved) {
      try {
        blockScreen(BLOCK_MODALS.DEMO_CHANGE);
        setItemSettingsStore('demoMode', true);
        await getInitialConfig();
        unblockScreen(BLOCK_MODALS.DEMO_CHANGE);
      } catch (e) {
        console.log(e);
        unblockScreen(BLOCK_MODALS.DEMO_CHANGE);
      }
    }
  };

  return (
    <>
      <SettingsContainer>
        <DisableControl disable={!deviceConnected}>
          <InputOptions
            header='Send changes immediately'
            options={['On', 'Off']}
            id='demoMode'
            onChange={handleDemoModeChange}
            value={demoMode ? 'On' : 'Off'}
          />
        </DisableControl>
        <div>
          <div>
            <ParagraphBold>
              Zeus Configurator ADP v.{process.env.REACT_APP_SOFTWARE_VERSION}
            </ParagraphBold>
            <Divider margin='20px' />
            <p>Copyright (C) {dayjs().format('YYYY')} Aether Biomedical Sp. z o.o.</p>
            <Divider margin='20px' />
            <p>
              The program is provided AS IS with NO WARRANTY OF ANY KIND, INCLUDING THE WARRANTY OF
              DESIGN, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </p>
          </div>
        </div>
      </SettingsContainer>
    </>
  );
};

export default ApplicationSettings;
