/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const FreezeModeEMGThresholds = ({
  addHistoryProsthesis,
  freezeModeEmg,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const [freezeModeEmgLocal, setFreezeModeEmgLocal] = useState<any>([
    // @ts-ignore
    initialState.config.freezeModeEmg[0],
    // @ts-ignore
    initialState.config.freezeModeEmg[1]
  ]);

  const handleFreezeEmgSlider = (value: any, sliderNumber: any) => {
    const isOpening = sliderNumber === 1;
    addHistoryProsthesis(
      // @ts-ignore
      setConfigProperty('freezeModeEmg', [
        freezeModeEmg[2],
        !isOpening ? value : freezeModeEmg[0],
        isOpening ? value : freezeModeEmg[1]
      ])
    );
  };

  useEffect(() => {
    if (freezeModeEmg) {
      setFreezeModeEmgLocal([freezeModeEmg?.[0], freezeModeEmg?.[1]]);
    }
  }, [freezeModeEmg]);

  const FreezeModeEMGThresholds = useMemo(
    () => (
      <MultiSlider
        header='EMG freeze mode relaxation'
        sliderValues={[freezeModeEmgLocal?.[0], freezeModeEmgLocal?.[1]]}
        disabled={replayIsEnabled}
        limits={[
          { min: 1, max: 100 },
          { min: 1, max: 100 }
        ]}
        handleChange={(...args: any) =>
          setFreezeModeEmgLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>Relaxation closing: {freezeModeEmgLocal?.[0]}</p>,
          <p>Relaxation opening: {freezeModeEmgLocal?.[1]}</p>
        ]}
        handleOnAfterChange={(...args: any) => handleFreezeEmgSlider(args[1], args[2])}
        description='Sets thresholds of minimum opening/closing signals of freeze mode switch'
      />
    ),
    [freezeModeEmg, freezeModeEmgLocal]
  );

  return FreezeModeEMGThresholds;
};

export default FreezeModeEMGThresholds;
