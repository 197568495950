import React from 'react';
import { Link } from '@mui/material';
import { TEXTS } from 'constants/texts';

interface ApiErrorsInterface {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        code: string;
        message: string | React.JSX.Element;
      };
    };
  };
}

export const API_ERRORS = {
  DEVICE: {
    UPDATE: {
      ACTIVE_CODE_EXISTS: {
        code: 'DEVICE:UPDATE:ACTIVE_CODE_EXISTS',
        message:
          'You can’t assign this device to any Company because it has an Activation code. To assign to Company, please contact ADP Team.'
      }
    },
    ADD_WITH_CODE: {
      NO_ACTIVE_CODES: {
        code: 'DEVICE:ADD_WITH_CODE:NO_ACTIVE_CODES',
        message: (
          <p>
            The code you provided is no longer active, please contact us at
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link> to resolve
            the issue.
          </p>
        )
      },
      ASSIGNED_TO_COMPANY: {
        code: 'DEVICE:ADD_WITH_CODE:ASSIGNED_TO_COMPANY',
        message: (
          <p>
            Activation code is no longer valid. Please contact Aether Biomedical at{' '}
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>
          </p>
        )
      },
      INCORRECT_CODE: {
        code: 'DEVICE:ADD_WITH_CODE:INCORRECT_CODE',
        message: (
          <p>
            Activation code is incorrect. Please check it and enter it again or contact us at
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>
          </p>
        )
      }
    }
  },
  AUTH: {
    REGISTER: {
      DEVICE_ASSIGNED: {
        code: 'AUTH:REGISTER:DEVICE_ASSIGNED',
        message: (
          <p>
            Activation code is no longer valid. Please contact Aether Biomedical at{' '}
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>
          </p>
        )
      },
      CODE_INCORRECT: {
        code: 'AUTH:REGISTER:CODE_INCORRECT',
        message: (
          <p>
            Activation code is incorrect. Please check it and enter it again or contact us at
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link>
          </p>
        )
      },
      NO_ACTIVE_CODES: {
        code: 'AUTH:REGISTER:NO_ACTIVE_CODES',
        message: (
          <p>
            The code you provided is no longer active, please contact us at
            <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link> to resolve
            the issue.
          </p>
        )
      }
    }
  }
};
