import { DefaultTheme } from 'styled-components';

type DevicesSize = {
  xsmall: number;
  small: number;
  tablet: number;
  medium: number;
  large: number;
  xlarge: number;
  desktop: number;
  xxlarge: number;
};

const deviceSize = {
  xsmall: 450,
  small: 576,
  tablet: 720,
  medium: 768,
  large: 992,
  xlarge: 1200,
  desktop: 1360,
  xxlarge: 1440
};

type Devices = {
  xsmall: string;
  small: string;
  tablet: string;
  medium: string;
  large: string;
  xlarge: string;
  desktop: string;
  xxlarge: string;
};

const devices = {
  xsmall: `(min-width: ${deviceSize.xsmall}px)`,
  small: `(min-width: ${deviceSize.small}px)`,
  tablet: `(min-width: ${deviceSize.tablet}px)`,
  medium: `(min-width: ${deviceSize.medium}px)`,
  large: `(min-width: ${deviceSize.large}px)`,
  xlarge: `(min-width: ${deviceSize.xlarge}px)`,
  desktop: `(min-width: ${deviceSize.desktop}px)`,
  xxlarge: `(min-width: ${deviceSize.xxlarge}px)`
};

type Dimensions = {
  contentMaxWidthBig: number;
  contentMaxWidthMedium: number;
  contentMaxWidthSmall: number;
  spacing: number;
  formWidth: number;
  spacingHeader: number;
};

const dimensions = {
  contentMaxWidthBig: 1080,
  contentMaxWidthMedium: 912,
  contentMaxWidthSmall: 768,
  spacing: 16,
  formWidth: 400,
  spacingHeader: 12
};

type FontSize = {
  labelSmall: string;
  label: string;
  labelBig: string;
  copy: string;
  base: string;
  titleMobile: string;
  title: string;
  pageTitleMobile: string;
  pageTitle: string;
  headerTitle: string;
  authTitle: string;
};

const fontSize = {
  labelSmall: '10px',
  label: '12px',
  labelBig: '14px',
  copy: '15px',
  base: '16px',
  titleMobile: '18px',
  title: '19px',
  pageTitleMobile: '24px',
  headerTitle: '28px',
  pageTitle: '40px',
  authTitle: '36px'
};

type FontWeight = {
  light: number;
  medium: number;
  heavy: number;
};

const fontWeight = {
  light: 300,
  medium: 500,
  heavy: 700
};

type Palette = {
  primary: string;
  primaryLight: string;
  secondary: string;
  background: string;
  headerTitle: string;
  headerTitle2: string;
  subtitle: string;
  neutral: string;
  border: string;
  error: string;
  error2: string;
  success: string;
  success2: string;
  link: string;
  link2: string;
  subtleText: string;
  linkClicked: string;
  separator: string;
  orange: string;
  green: string;
  red: string;
  configFill: string;
  info: string;
  infoText: string;
};

const palette = {
  primary: '#33499C',
  primaryLight: '#fff',
  secondary: '#aaa',
  background: '#f9fafb',
  headerTitle: '#1d2b4f',
  headerTitle2: '#101828',
  subtitle: '#475467',
  neutral: '#6c757d',
  gray: '#EAECF0',
  border: '#ced4da',
  error: '#d04a57',
  error2: '#dc3534',
  success: '#20c997',
  success2: '#28a745',
  subtleText: '#626c84',
  configFill: '#e9ecef',
  link: '#626c84',
  link2: '#0e63f4',
  linkClicked: '#6F42C1',
  separator: '#dee2e6',
  orange: '#eea537',
  green: '#54bd8d',
  red: '#eea537',
  info: '#e8f0fc',
  infoText: '#2f538e'
};

export interface ThemeInterface {
  deviceSize: DevicesSize;
  devices: Devices;
  fontWeight: FontWeight;
  palette: Palette;
  fontSize: FontSize;
  dimensions: Dimensions;
}

export interface DefaultTheme {
  deviceSize: DevicesSize;
  devices: Devices;
  fontWeight: FontWeight;
  palette: Palette;
  fontSize: FontSize;
  dimensions: Dimensions;
  colorPrimary: string;
  colorDanger: string;
  colorPrimaryShades: string[];
  colorSecondary: string;
  colorSupporting: string;
  colorFill: string;
  fontFamilyPrimary: string;
  colorError: string;
  colorErrorShades: string[];
  separator: string;
  breakpoints: any;
  typography: any;
}

const myTheme = {
  deviceSize,
  devices,
  fontWeight,
  palette,
  fontSize,
  dimensions,
  colorPrimary: 'hsl(201, 53%, 46%)',
  colorDanger: 'hsl(0, 71%, 53%)',
  colorPrimaryShades: [
    'hsl(201, 53%, 32%)',
    'hsl(201, 55%, 40%)',
    'hsl(201, 57%, 48%)',
    'hsl(201, 59%, 56%)',
    'hsl(201, 61%, 64%)',
    'hsl(201, 63%, 72%)',
    'hsl(201, 65%, 80%)'
  ],
  colorSecondary: 'hsl(201, 100%, 65%)',
  colorSupporting: '#FFFFFF',
  colorFill: '#F2F2F2',
  fontFamilyPrimary: "'Open Sans', sans-serif",
  colorError: 'hsl(0, 71%, 53%)',
  colorErrorShades: ['hsl(0, 71%, 45%%)', 'hsl(0, 71%, 61%)'],
  separator: '#dee2e6',
  breakpoints: {
    hdWidth: '1450px',
    hdWidthNum: 1450,
    large: '1450px',
    largeNum: 1450,
    hdWidthSmall: '1124px',
    hdWidthSmallNum: 1124,
    hdHeight: '800px',
    hdHeightNum: 800,
    medium: '1124px',
    mediumNum: 1124,
    small: '924px',
    smallNum: 924,
    xsmall: '724px',
    xsmallNum: 724,
    mediumHeight: '730px',
    mediumHeightNum: 730,
    smallHeight: '520px',
    smallHeightNum: 520
  },
  typography: {
    fonts: {
      OpenSans: "'Open Sans', sans-serif"
    },
    fontSizes: {
      xxs: '10px',
      xs: '14px',
      s: '18px',
      m: '24px',
      l: '28px',
      xl: '36px'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700
    },
    colors: {
      primary: '#FFFFFF',
      secondary: '#393939',
      tetiary: '#4FC1FF',
      green: '#378805',
      red: 'hsl(1, 100%, 50%)',
      subtitle: 'hsl(0, 0%, 50%)',
      text1: '#101828'
    }
  }
};

export { myTheme };
