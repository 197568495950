import api from '../utils/apiClient';
import qs from 'qs';
import {
  DocumentStatusParams,
  DocumentAcceptancePayload
} from 'adp-panel/api/documents/documents.types';

export const GET_DOCUMENTS_STATUS = 'api/documents/status';
export const POST_DOCUMENTS_ACCEPT = 'api/documents/accept';

const withCredentials = {
  withCredentials: true
};

export const getDocumentsStatus = (params: DocumentStatusParams): Promise<any> => {
  return api
    .get(`${GET_DOCUMENTS_STATUS}?${qs.stringify(params)}`, withCredentials)
    .then((res: any) => res.data);
};

export const acceptDocuments = (payload: DocumentAcceptancePayload): Promise<any> => {
  return api.post(POST_DOCUMENTS_ACCEPT, payload, withCredentials).then((res: any) => res.data);
};
