import Card from 'adp-panel/components/Card/Card';

const LoaderWrapper = ({ children, full }: { children: React.ReactNode; full?: boolean }) => (
  <Card>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(full && { height: '80vh' })
      }}>
      {children}
    </div>
  </Card>
);

export default LoaderWrapper;
