import { useQuery } from '@tanstack/react-query';
import { fetchUserData } from 'adp-panel/api/authentication/authentication';

export const useCheckUserAuthorization = () => {
  return useQuery(['auto-logout'], fetchUserData, {
    refetchOnWindowFocus: false,
    staleTime: 0,
    retry: 0
  });
};

export default useCheckUserAuthorization;
