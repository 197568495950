import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useUnsaved from 'configurator/hooks/useUnsaved';
import ModalBase from './ModalBase';
import { pauseSoundElement, playSoundElement } from 'App';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';

const ParagraphBold = styled.p`
  ${TextS};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: Function;
  handleAccept: Function;
};

const DisruptiveDisconnectModal = ({ handleClose, handleAccept }: ModalProps) => {
  const { isUnsaved } = useUnsaved();
  const { bluetoothConnect } = useBluetooth();
  const { deviceConnected, amputeeId } = useDeviceInfoStore((state: any) => ({
    deviceConnected: state.connected,
    amputeeId: state.amputeeId
  }));
  const getInitialConfigAPI = useConfigStore((state: any) => state.getInitialConfigAPI);
  const { initialConfigState } = useUiStore((state) => ({
    initialConfigState: state.initialConfigState
  }));

  useEffect(() => {
    playSoundElement();
  }, []);

  const accepted = async () => {
    pauseSoundElement();
    handleAccept();
    handleClose();
  };

  const denied = async () => {
    pauseSoundElement();
    await getInitialConfigAPI();
    handleClose();
  };

  const reconnect = () => {
    pauseSoundElement();
    bluetoothConnect();
    handleClose();
  };

  const canSaveSettings =
    amputeeId && isUnsaved && initialConfigState === FETCHING_STATES.successful;

  return (
    <ModalBase
      handleClick={() => {
        pauseSoundElement();
        handleClose();
      }}
      header='Device was disconnected unexpectedly'
      allowBackDropClose={false}>
      <DialogContent>
        <ParagraphBold>
          {canSaveSettings
            ? 'Changes were not saved, do you want to save them?'
            : 'Device has been disconnected, do you want to reconnect?'}
        </ParagraphBold>
        <ButtonsWrapper>
          {canSaveSettings && (
            <Button
              sx={{ width: 200 }}
              onClick={denied}
              variant={amputeeId ? 'outlined' : 'contained'}>
              {`No, don't save`}
            </Button>
          )}
          {!deviceConnected && (
            <Button sx={{ width: 200 }} onClick={reconnect} variant='outlined'>
              Reconnect
            </Button>
          )}
          {canSaveSettings && (
            <Button sx={{ width: 200 }} onClick={accepted}>
              Yes, save
            </Button>
          )}
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default DisruptiveDisconnectModal;
