import {
  SIMPLY_LOCALIZE_API_KEY,
  SIMPLY_LOCALIZE_API_TOKEN,
  SIMPLY_LOCALIZE_API_URL,
  SIMPLY_LOCALIZE_CDN_ENVIRONMENT,
  SIMPLY_LOCALIZE_CDN_URL
} from 'adp-panel/constants/config';
import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';

const isProductionCode = process.env.NODE_ENV === 'production';
const fallbackLanguage = 'en';

const projectToken = SIMPLY_LOCALIZE_API_TOKEN; // YOUR PROJECT TOKEN
const apiKey = SIMPLY_LOCALIZE_API_KEY; // YOUR API KEY

const apiBaseUrl = SIMPLY_LOCALIZE_API_URL;
const cdnBaseUrl = SIMPLY_LOCALIZE_CDN_URL;
const environment = SIMPLY_LOCALIZE_CDN_ENVIRONMENT; // or "_production"
const loadPath = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/translation`;
const loadPathWithNamespaces = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}`;

console.log({ apiBaseUrl, cdnBaseUrl, environment, loadPath, loadPathWithNamespaces });
const configuration = {
  headers: {
    'X-SimpleLocalize-Token': apiKey
  }
};

const createTranslationKeys = async (requestBody: any) =>
  axios.post(`${apiBaseUrl}/v1/translation-keys/bulk`, requestBody, configuration);
const updateTranslations = async (requestBody: any) =>
  axios.patch(`${apiBaseUrl}/v2/translations/bulk`, requestBody, configuration);

type MissingTranslation = {
  translationKey: string;
  namespace: string;
  language: string;
  fallbackValue: string;
};

const missing: MissingTranslation[] = [];
const saveMissing = async () => {
  if (missing.length === 0 || isProductionCode) {
    return;
  }
  console.info(`Saving ${missing.length} missing translation keys`);

  const translationKeys = missing.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace
  }));

  // await createTranslationKeys({ translationKeys }).catch((error) =>
  //   console.error(`Error during creating translation keys: ${error}`)
  // );

  const translations = missing.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace,
    language: element.language,
    text: element.fallbackValue
  }));
  // await updateTranslations({ translations }).catch((error) =>
  //   console.error(`Error during updating translations: ${error}`)
  // );
  // missing.length = 0;
};

// You can control how often a missing translation keys are sent to SimpleLocalize.
// Lowering this value may lead to hitting rate-limiter, 30 seconds is a sweet spot.
const SYNC_INTERVAL = 30 * 1000; // 30 seconds
setInterval(async () => {
  console.log('missing', missing);
  await saveMissing();
}, SYNC_INTERVAL);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: fallbackLanguage,
    // defaultNS: false,
    interpolation: {
      escapeValue: false
    },
    initImmediate: false,
    react: {
      useSuspense: false
    },
    backend: {
      loadPath: loadPathWithNamespaces // or loadPathWithNamespaces if you use namespaces
    },

    saveMissing: !isProductionCode, // save missing keys only in development mode
    debug: true,
    missingKeyHandler: async (languages, namespace, translationKey, fallbackValue) => {
      console.debug(`[${namespace}][${translationKey}] not available in Translation Hosting`);
      missing.push({
        translationKey: translationKey,
        namespace: namespace ?? '',
        language: languages[0] ?? fallbackLanguage,
        fallbackValue: fallbackValue ?? ''
      });
    }
  });

i18n.loadNamespaces('account');
export default i18n;
