import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addUser,
  changePasswordForAnotherUser,
  deleteUser,
  getUser,
  getUsers,
  updateUser,
  updateUserPhone
} from '../../api/users/users';
import { UserEntry, UserQueryParams, UsersQueryParams } from '../../api/users/users.types';
import {
  changePassword,
  changePasswordWithToken,
  fetchUserData,
  resetPassword,
  verifyPasswordChangeToken
} from 'adp-panel/api/authentication/authentication';
import { AxiosError } from 'axios';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useEffect } from 'react';
import { useAuthStore } from 'reducers/authStore';
import { mapErrorMessage } from 'utils/notifications';

export const ME_QUERY_KEY = 'me';
export const USER_QUERY_KEY_INFO = 'user_info';
export const USER_QUERY_KEY = 'user';
export const USERS_QUERY_KEY = 'users';

export const useUsers = (params: UsersQueryParams = {}) => {
  const { data, isLoading, isError, refetch } = useQuery([USERS_QUERY_KEY, params], () =>
    getUsers(params)
  );

  return {
    result: data?.items || null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch
  };
};

export const useUsersInfinite = (
  queryParams?: UsersQueryParams,
  dependency: any = true,
  queryOptions?: any
) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    error,
    fetchNextPage
  } = useInfiniteQuery(
    [USERS_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getUsers({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency,
      ...queryOptions
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useUser = (userId: number | string, params: UserQueryParams = {}) => {
  const { data, isLoading, isError } = useQuery(
    [USER_QUERY_KEY, userId, params],
    () => getUser(userId, { params }),
    {
      enabled: !!userId
    }
  );

  return {
    result: (data as UserEntry) || null,
    isLoading,
    isError
  };
};

export const useUserCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(addUser, {
    onSuccess() {
      NotificationFactory.successNotification('User created');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateUser, {
    onSuccess() {
      queryClient.invalidateQueries([USER_QUERY_KEY]);
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserDelete = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteUser, {
    onSuccess() {
      NotificationFactory.successNotification('User deleted');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useChangePassword = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(changePassword, {
    onSuccess() {
      NotificationFactory.successNotification('Password changed');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data ? data : null,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useResetPasswordRequest = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(resetPassword, {
    onSuccess() {
      NotificationFactory.successNotification('Reset password link successfully sent');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data ? data : null,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useForceChangePasswordForAnotherUser = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(changePasswordForAnotherUser, {
    onSuccess() {
      NotificationFactory.successNotification('Password changed');
    },
    onError(error: AxiosError) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data ? data : null,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useChangePasswordWithToken = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(changePasswordWithToken, {
    onSuccess() {
      NotificationFactory.successNotification('Password changed');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data ? data : null,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useVerifyPasswordChangeToken = () => {
  return useMutation(verifyPasswordChangeToken);
};

export const useUserMe = (enabled = true) => {
  const mfaCodeRequire = useAuthStore((state) => state.mfaCodeRequire);
  const {
    data,
    isLoading,
    isInitialLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isFetched,
    isSuccess
  } = useQuery([ME_QUERY_KEY], fetchUserData, {
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 60 * 1000, // 1 minute,
    onError(error: any) {
      if (error.isAxiosError && error?.response?.status === 401) {
        mfaCodeRequire({
          require: true,
          channel: error.response.data.method
        });
      }
    }
  });

  return {
    result: data ? data : null,
    isLoading,
    isInitialLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isFetched,
    isSuccess
  };
};

export const useUserInfo = (userId: number, params?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_QUERY_KEY],
    () => getUser(userId, params),
    {
      enabled: !!userId,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useChangeUserPhone = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateUserPhone, {
    onSuccess() {
      NotificationFactory.successNotification('Phone changed');
    },
    onError(error: AxiosError) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data ? data : null,
    mutateAsync,
    isLoading,
    isError
  };
};
