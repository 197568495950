import { ComponentPropsWithoutRef } from 'react';
import { Chip, ChipOwnProps } from '@mui/material';

interface CustomChip extends ChipOwnProps {
  sx?: any;
}

const CustomChip = ({ sx, ...props }: CustomChip & ComponentPropsWithoutRef<'div'>) => {
  const style = {
    ...(props.color === 'success' && {
      border: '1px solid #ABEFC6',
      backgroundColor: '#ECFDF3',
      color: '#067647'
    }),
    ...(props.color === 'warning' && {
      border: '1px solid #F9DBAF',
      backgroundColor: '#FEF6EE',
      color: '#B93815'
    }),
    ...(props.color === 'error' && {
      border: '1px solid #FECDCA',
      backgroundColor: '#FEF3F2',
      color: '#B42318'
    }),
    ...sx
  };

  return <Chip sx={{ ...style, ...sx }} {...props} />;
};

export default CustomChip;
