import { fetchUserData } from '../api/authentication/authentication';
import { getUser } from '../api/users/users';
import { UserEntry, UserExtendOptions } from '../api/users/users.types';

let permissionExpiresAt = 0;
let insideCall = 0;
const fetchPermissions = async () => {
  fetchUserData().then((result: any) => {
    getUser(result.id, {
      params: { extend: [UserExtendOptions.permissions, UserExtendOptions.roles] }
    }).then((response: UserEntry) => {
      localStorage.setItem('roles', JSON.stringify(response.roles));
      localStorage.setItem('permissions', JSON.stringify(response.permissions));
      permissionExpiresAt = Date.now() + 1000 * 60 * 5; // 5 minutes
      insideCall = 0;
    });
  });
};

const authProvider = {
  getPermissions: async () => {
    if (Date.now() > permissionExpiresAt && insideCall === 0) {
      insideCall += 1;
      await fetchPermissions();
    }
    const permissions = localStorage.getItem('permissions');
    const roles = localStorage.getItem('roles');

    return {
      permissions: permissions ? JSON.parse(permissions) : [],
      roles: roles ? JSON.parse(roles) : []
    };
  },
  getAcl: async () => fetchPermissions()
};

export default authProvider;
