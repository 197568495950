import * as Sentry from '@sentry/react';
import Notification, { NotificationVariant } from '../components/Notifications/Notification';
import { Toast, toast, ToastOptions } from 'react-hot-toast';
import React from 'react';
import parseApiError from './parseApiError';
import ApiErrorNotification from '../components/Notifications/ApiErrorNotification';
import { API_ERRORS } from 'adp-panel/api/errors';

function renderNotification(variant: NotificationVariant) {
  return (
    title: string | React.JSX.Element,
    description: string,
    options: ToastOptions,
    children: React.ReactNode
  ) => {
    return toast.custom(
      (t: Toast) => (
        <Notification toast={t} title={title} description={description} variant={variant}>
          {children}
        </Notification>
      ),
      options
    );
  };
}

export const handleStatusErrors = (error: any) => {
  const code = error?.request?.status;
  const defaultErrorMessage = `Something went wrong. Please refresh the page or try again later.`;
  let message = defaultErrorMessage;

  if (code >= 500 && code <= 599) {
    Sentry.captureException(error);

    switch (code) {
      case 503:
        message =
          "Connection cannot be established. This may be due to a firewall block on your computer. Please contact your organization's IT department.";
        break;
    }
  }

  return message;
};

export function errorNotification(
  title: string | React.JSX.Element = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('error')(title, description, options, children);
}

export function errorApiNotification(error: any, fallbackErrorMessage: string | null = null) {
  if (!error.isAxiosError && fallbackErrorMessage) {
    errorNotification(fallbackErrorMessage);
  }

  const parsedError = parseApiError(error);
  if (parsedError.accumulatedErrors) {
    return errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
  } else {
    return errorNotification(parsedError.message, '', { id: parsedError.message });
  }
}

export function successNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('success')(title, description, options, children);
}

export function warningNotification(
  title = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification('warning')(title, description, options, children);
}

export const mapErrorMessage = (error: any, overrideDefaultMessage?: string) => {
  if (overrideDefaultMessage) {
    errorNotification(overrideDefaultMessage);
    return;
  }

  const code = parseApiError(error)?.code;

  if (code) {
    const [group, action, errorType] = code.split(':');
    const customMessage = API_ERRORS[group]?.[action]?.[errorType];

    if (customMessage) {
      errorNotification(customMessage);
      return;
    }
  }

  errorApiNotification(error);
};
