/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent, TextField } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 20px;
`;

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

type ImmediateSendingModeChangeModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleAccept: Function;
  handleSaveWithoutChanges: () => void;
  isLoading: boolean;
};

type Inputs = {
  note: string;
};

const ImmediateSendingModeChangeModal = ({
  handleClose,
  handleAccept,
  handleSaveWithoutChanges,
  isLoading
}: ImmediateSendingModeChangeModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleAccept(data);
  };

  return (
    <ModalBase
      handleClick={handleClose}
      header='Immediate sending mode change'
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '650px'
          }
        }
      }}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ParagraphBold>
            Before changing this setting, we recommend saving the current settings, otherwise they
            may be lost.
          </ParagraphBold>
          <TextField
            fullWidth
            id='description'
            label='Description'
            multiline
            required
            placeholder='Describe the change'
            {...register('note', { required: 'This field is required.' })}
            error={Boolean(errors?.note)}
            helperText={errors?.note?.message}
            rows={6}
          />
          <ButtonsWrapper>
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSaveWithoutChanges}
              loading={isLoading}
              variant='outlined'>
              <span>Continue without saving changes</span>
            </LoadingButton>
            <LoadingButton type='submit' loading={isLoading}>
              <span>Save and change</span>
            </LoadingButton>
          </ButtonsWrapper>
        </form>
      </DialogContent>
    </ModalBase>
  );
};

export default ImmediateSendingModeChangeModal;
