import { FunctionComponent, ReactNode } from 'react';
import { InnerWrapper } from './styled';
import Card from 'adp-panel/components/Card/Card';
import CardHeader from 'adp-panel/components/Card/CardHeader';

export interface FormContentWrapperProps {
  children: ReactNode;
  title?: string;
  small?: boolean;
}

const FormContentWrapper: FunctionComponent<FormContentWrapperProps> = ({
  children,
  title,
  small
}) => {
  return (
    <InnerWrapper small={small}>
      <Card>
        <CardHeader title={title ?? ''} />
        {children}
      </Card>
    </InnerWrapper>
  );
};

export default FormContentWrapper;
