import { crc32Table } from './CRCTable';
import { Buffer } from 'buffer';

export const consumeBytesToValueLSB = (buffer, it) => {
  let convertedValue = 0;
  const kConvertedBytesAmount = 4;
  const kByteLength = 8;

  for (let i = 0; i < kConvertedBytesAmount; i++) {
    const temp = buffer[it];
    convertedValue += (temp << (kByteLength * i)) >>> 0;
    it++;
  }

  return [convertedValue, it];
};

export const convertValueToUInt8Bytes = (value, size) => {
  const kConvertedBytesAmount = size;
  let resultBytes = [];
  for (let i = kConvertedBytesAmount - 1; i >= 0; i--) {
    resultBytes.push((value >> (i * 8)) & 0xff);
  }

  return resultBytes;
};

export const countCRC32ForDataBytes = (dataBytes) => {
  var crcTable = crc32Table;
  var crc = 0 ^ -1;

  for (var i = 0; i < dataBytes.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ dataBytes[i]) & 0xff];
  }

  return (crc ^ -1) >>> 0;
};

export const stringToUint8 = (s, offset) => Buffer.from(s).readUInt8(offset);

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
