import React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DocumentEntry } from 'adp-panel/api/documents/documents.types';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import FormCheckbox from 'adp-panel/components/FormInput/FormCheckbox';
import LegendParagraph from 'adp-panel/components/LegendParagraph/LegendParagraph';
import LoaderButton from 'adp-panel/components/LoaderButton/LoaderButton';
import { requiredValidator } from 'adp-panel/components/FormInput/validators';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 14px;
`;

const TextWrapper = styled.div`
  margin-bottom: 30px;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

type DocumentsModalProps = {
  title: string;
  description: string;
  checkboxText: string;
  acceptAction: (e: any, files: number[]) => void;
  logoutAction: () => void;
  files: DocumentEntry[];
  isLoading: boolean;
};

const FileToRead = ({ name, url }: { name: string; url: string }) => (
  <FileWrapper>
    <div>{name}</div>
    <a href={url} target='_blank' rel='noreferrer'>
      <Button type='button'>Read</Button>
    </a>
  </FileWrapper>
);

const DocumentsModal = ({
  title,
  description,
  checkboxText,
  acceptAction,
  logoutAction,
  files,
  isLoading = false
}: DocumentsModalProps) => {
  return (
    <Dialog title={title} closeIcon={false} width={600}>
      {<TextWrapper>{description}</TextWrapper>}
      <LegendParagraph headerText='FILES'>
        {files.map((file) => (
          <FileToRead name={file.document.name} url={file.file} key={file.document_id} />
        ))}
      </LegendParagraph>
      <Form
        onSubmit={(e) =>
          acceptAction(
            e,
            files.map((file) => file.id)
          )
        }
        render={() => (
          <FormElement>
            <fieldset className={'k-form-fieldset'}>
              <Field
                id={'accept'}
                name={'accept'}
                label={<span style={{ fontSize: '14px', fontWeight: '500' }}>{checkboxText}</span>}
                component={FormCheckbox}
                validator={requiredValidator}
              />
              <ButtonsWrapper>
                <Button type='button' onClick={logoutAction}>
                  Log out
                </Button>
                <Button type='submit' themeColor={'primary'}>
                  Confirm {isLoading && <LoaderButton />}
                </Button>
              </ButtonsWrapper>
            </fieldset>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default DocumentsModal;
