import api from '../utils/apiClient';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '../../../types';
import { LogEntry, LogsQueryParams } from './logs.types';
import { encodeQueryData } from '../utils/encodeQuery';

export const LOGS_LISTING = 'api/logs';

const withCredentials = {
  withCredentials: true
};

export const fetchLogs = (params: LogsQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<LogEntry>>(`${LOGS_LISTING}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};
