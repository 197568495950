import {
  emgThresholdsEntry,
  DeviceConfigTemplate,
  freezeModeEmgEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import {
  ControlModes,
  GripSwitchingModes,
  InputDevices,
  InputSites,
  SingleElectrodeMode
} from 'bluetooth/Bluetooth/Control';
import { aetherBatteryVoltageFunctions } from 'configurator/utils/definesLocal';
import toast from 'react-hot-toast';
import { ConfigStoreState } from 'configurator/reducers/configStore';
import { EMG_SPIKE_WARNING } from 'configurator/consts/notifications';
import {
  FREEZE_MODE_OFF,
  FREEZE_MODE_ON_DUAL,
  FREEZE_MODE_ON_SINGLE
} from 'configurator/consts/deviceConfig/freezeMode';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { getCurrentConfigSelector, getFwVersionSelector, getModeBySlotSelector } from './selectors';
import { checkBatteryBeepEmergency, checkEmgValidity } from './modifierFunctions';

export const freezeModeEmgModifierControlConfig = ({
  newInputSite,
  versions,
  firmware,
  oldFreezeModeEmg
}) => {
  let newFreezeModeEmg = oldFreezeModeEmg;
  const canApplyFreezeModeFix =
    Number(versions?.current?.[1]) >= 2 || Number(firmware?.name[0]) >= 2;

  if (
    canApplyFreezeModeFix &&
    newInputSite === InputSites.kSingleElectrode &&
    oldFreezeModeEmg[2] === 1
  ) {
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 3, 3];
  }

  if (newInputSite !== InputSites.kSingleElectrode && oldFreezeModeEmg[2] === 3)
    newFreezeModeEmg = [oldFreezeModeEmg[0], oldFreezeModeEmg[1], 1, 2];

  return newFreezeModeEmg;
};

export type freezeModeValue = [0 | 1, number, number];

export const freezeModeEmgModifier = (
  freezeModeEmgValue: freezeModeValue,
  currentConfig: DeviceConfigTemplate,
  firmware,
  versions,
  newConfig
) => {
  let value: freezeModeValue | freezeModeEmgEntry = freezeModeEmgValue;
  let triggered = false;
  const inputSite = currentConfig.inputSite?.[0];
  const inputDevice = currentConfig.inputDevice?.[0];
  const emgThresholds = currentConfig.emgThresholds;
  const changeSignalOpen = emgThresholds[2];
  const changeSignalClose = emgThresholds[5];
  const [status, closing, opening] = value;
  value = [
    closing,
    opening,
    status ? FREEZE_MODE_ON_DUAL[0] : FREEZE_MODE_OFF[0],
    status ? FREEZE_MODE_ON_DUAL[1] : FREEZE_MODE_OFF[1]
  ] as freezeModeEmgEntry;

  const canApplyFreezeModeFix =
    (Number(versions?.current?.[1]) >= 2 || Number(firmware?.name[0]) >= 2) &&
    inputSite === InputSites.kSingleElectrode;

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  if (canApplyFreezeModeFix) {
    value = [
      closing,
      opening,
      status ? FREEZE_MODE_ON_SINGLE[0] : FREEZE_MODE_OFF[0],
      status ? FREEZE_MODE_ON_SINGLE[1] : FREEZE_MODE_OFF[1]
    ] as freezeModeEmgEntry;
  }

  // Send FREEZE_MODE_ON_DUAL value to hand with COAPT
  if (inputDevice && inputDevice === InputDevices.kInputOptionPatRec) {
    value = [
      closing,
      opening,
      status ? FREEZE_MODE_ON_DUAL[0] : FREEZE_MODE_OFF[0],
      status ? FREEZE_MODE_ON_DUAL[1] : FREEZE_MODE_OFF[0]
    ] as freezeModeEmgEntry;
  }

  if (closing >= changeSignalClose) {
    triggered = true;
    value = [changeSignalClose - 1, value[1], value[2], value[3]] as freezeModeEmgEntry;
  }

  if (opening >= changeSignalOpen) {
    triggered = true;
    value = [value[0], changeSignalOpen - 1, value[2], value[3]] as freezeModeEmgEntry;
  }

  return { adjustedConfig: { ...newConfig, freezeModeEmg: value }, triggered };
};

export const singleElectrodeModeModifier = (singleElectrodeMode, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const adjustedEmg = checkEmgValidity(
    { ...currentConfig, singleElectrodeMode },
    currentConfig.emgThresholds
  );

  adjustedConfig = {
    ...newConfig,
    singleElectrodeMode,
    emgThresholds: adjustedEmg
  };

  return adjustedConfig;
};

export const singleElectrodeModeSettingsModifier = (
  singleElectrodeModeSettingsValue,
  currentEmgThresholds,
  newConfig
) => {
  let [startPointSignalThresholds] = singleElectrodeModeSettingsValue as any;
  const csOpening = currentEmgThresholds[0];
  const csClosing = currentEmgThresholds[1];
  const thresholdOpening = currentEmgThresholds[2];
  const thresholdClosing = currentEmgThresholds[5];
  let adjustedConfig = {};
  let triggered = false;

  adjustedConfig = {
    ...newConfig,
    singleElectrodeModeSettings: singleElectrodeModeSettingsValue
  };

  const thresholdsMinValue = Math.min(thresholdOpening, thresholdClosing, csOpening, csClosing);

  if (startPointSignalThresholds >= thresholdsMinValue) {
    startPointSignalThresholds = thresholdsMinValue - 1;
    adjustedConfig = {
      ...newConfig,
      singleElectrodeModeSettings: [
        startPointSignalThresholds,
        singleElectrodeModeSettingsValue[1],
        singleElectrodeModeSettingsValue[2],
        singleElectrodeModeSettingsValue[3]
      ]
    };
    triggered = true;
  }

  return { adjustedConfig, triggered };
};

export const speedControlStrategyModifier = (speedControlStrategy, currentConfig, newConfig) => {
  let adjustedConfig = {};
  const adjustedEmg = checkEmgValidity(
    { ...currentConfig, speedControlStrategy },
    currentConfig.emgThresholds
  );

  adjustedConfig = {
    ...newConfig,
    speedControlStrategy,
    emgThresholds: adjustedEmg
  };

  return adjustedConfig;
};

export const controlConfigModifier = ({
  newInputSite,
  newGripSwitchingMode,
  oldInputSite,
  prevState,
  versions,
  firmware,
  set,
  get
}) => {
  const deviceInfoState = useDeviceInfoStore.getState();
  let applyGlobally = false;
  if (newInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kSingleGripSwitching;
    applyGlobally = true;
  } else if (oldInputSite === InputSites.kSingleElectrode) {
    newGripSwitchingMode = GripSwitchingModes.kCoContraction;
    applyGlobally = true;
  }
  if (newGripSwitchingMode === GripSwitchingModes.kSingleGripSwitching) {
    newInputSite = InputSites.kSingleElectrode;
    applyGlobally = true;
  }
  // Set modes configs

  let modesApplied;
  const globalInputSite = getFwVersionSelector(deviceInfoState) < 220;

  if (!globalInputSite) applyGlobally = false;

  if (applyGlobally) {
    modesApplied = prevState.config.modes;
  } else {
    modesApplied = [getModeBySlotSelector(prevState, prevState.slotSelected)];
  }

  // Dont touch or hands below 2.0 will crash, as Szymon stated, 'na mokro'
  const oldFreezeModeEmg = prevState.config.modes[0].config?.freezeModeEmg;

  // Set common config
  const newCommon = {
    ...prevState.config.common,
    config: {
      ...prevState.config.common.config,
      ...(globalInputSite && { inputSite: [newInputSite] })
    }
  };

  const newModes = prevState.config.modes.map((mode) => {
    if (modesApplied.find((_mode) => _mode.slot === mode.slot)) {
      return {
        ...mode,
        config: {
          ...mode.config,
          gripSwitchingMode: [newGripSwitchingMode],
          ...(!globalInputSite && { inputSite: [newInputSite] }),
          ...(oldFreezeModeEmg && {
            freezeModeEmg: freezeModeEmgModifierControlConfig({
              newInputSite,
              versions,
              firmware,
              oldFreezeModeEmg: mode.config.freezeModeEmg
            })
          })
        }
      };
    }
    return mode;
  });

  const newState = { config: { common: newCommon, modes: newModes } } as ConfigStoreState;

  // Apply all modifiers for new state
  const currentConfig = getCurrentConfigSelector(newState);
  const { newConfigModesSettings, triggered } = inputSiteModifier(
    currentConfig as DeviceConfigTemplate,
    newState,
    globalInputSite ? null : [newState.slotSelected]
  );

  if (triggered.find((element) => element.configName === 'emgSpike')) {
    toast.error(EMG_SPIKE_WARNING.message, EMG_SPIKE_WARNING.options);
  }

  set(
    {
      config: { common: newCommon, modes: newConfigModesSettings }
    },
    false,
    {
      type: 'setControlConfig',
      common: newCommon,
      modes: newModes
    }
  );
};

export const applyModifierModes = (
  modifierFunctions: Array<{
    configName: keyof DeviceConfigTemplate;
    modifierFunction: (...args: any) => { adjustedSetting: any; triggered: boolean };
  }>,
  prevState,
  type,
  modesApplied: [number] | null = null
) => {
  const conflict: Array<{ configName: keyof DeviceConfigTemplate; mode: number }> = [];

  const newConfigModesSettings = prevState.config.modes.map((mode) => {
    if (modesApplied && !modesApplied.includes(mode.slot)) return mode;
    const currentConfig = getCurrentConfigSelector(prevState, mode.slot);
    const configObject = {};
    modifierFunctions.forEach((element) => {
      const { adjustedSetting, triggered } = element.modifierFunction(
        currentConfig as DeviceConfigTemplate
      );
      configObject[`${element.configName}`] = adjustedSetting;
      if (triggered) conflict.push({ configName: element.configName, mode: mode.slot });
    });
    return {
      ...mode,
      config: {
        ...mode.config,
        ...configObject
      }
    };
  });

  return { newConfigModesSettings, triggered: conflict };
};

export const inputDeviceAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'gripSwitchingMode',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return {
              adjustedSetting:
                currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode
                  ? [GripSwitchingModes.kSingleGripSwitching]
                  : [GripSwitchingModes.kCoContraction],
              triggered: false
            };
          return { adjustedSetting: [GripSwitchingModes.kCoapt], triggered: false };
        }
      },
      {
        configName: 'controlMode',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes)
            return { adjustedSetting: [ControlModes.kGripPairs], triggered: false };

          if (currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionPatRec)
            return { adjustedSetting: [ControlModes.kCoapt], triggered: false };

          return { adjustedSetting: currentSlotConfig.controlMode, triggered: false };
        }
      },
      {
        configName: 'freezeModeEmg',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionPatRec &&
            currentSlotConfig.freezeModeEmg[3] !== FREEZE_MODE_OFF[0]
          )
            return {
              adjustedSetting: [
                currentSlotConfig.freezeModeEmg[0],
                currentSlotConfig.freezeModeEmg[1],
                FREEZE_MODE_ON_DUAL[0],
                FREEZE_MODE_ON_DUAL[1]
              ],
              triggered: false
            };

          if (
            currentSlotConfig.inputDevice?.[0] === InputDevices.kInputOptionElectrodes &&
            currentSlotConfig.freezeModeEmg[3] !== FREEZE_MODE_OFF[0]
          ) {
            if (currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode) {
              return {
                adjustedSetting: [
                  currentSlotConfig.freezeModeEmg[0],
                  currentSlotConfig.freezeModeEmg[1],
                  FREEZE_MODE_ON_SINGLE[0],
                  FREEZE_MODE_ON_SINGLE[1]
                ],
                triggered: false
              };
            }
            return {
              adjustedSetting: [
                currentSlotConfig.freezeModeEmg[0],
                currentSlotConfig.freezeModeEmg[1],
                FREEZE_MODE_ON_DUAL[0],
                FREEZE_MODE_ON_DUAL[1]
              ],
              triggered: false
            };
          }
          return {
            adjustedSetting: [
              currentSlotConfig.freezeModeEmg[0],
              currentSlotConfig.freezeModeEmg[1],
              currentSlotConfig.freezeModeEmg[2],
              currentSlotConfig.freezeModeEmg[3]
            ],
            triggered: false
          };
        }
      }
    ],
    newState,
    'inputDeviceAdjustment',
    currentMode
  );

export const singleElectrodeModeAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'emgSpike',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
            currentSlotConfig.emgSpike?.[0] === 1
          )
            return {
              adjustedSetting: [0, currentSlotConfig.emgSpike[1]],
              triggered: true
            };
          return { adjustedSetting: currentSlotConfig.emgSpike, triggered: false };
        }
      }
    ],
    newState,
    'singleElectrodeModeAdjustments',
    currentMode
  );

export const inputSiteModifier = (
  currentConfig: DeviceConfigTemplate,
  newState: ConfigStoreState,
  currentMode: [number] | null
) => {
  const { newConfigModesSettings, triggered } = applyModifierModes(
    [
      {
        configName: 'emgSpike',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.inputSite?.[0] === InputSites.kSingleElectrode &&
            currentSlotConfig.singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
            currentSlotConfig.emgSpike?.[0] === 1
          )
            return {
              adjustedSetting: [0, currentSlotConfig.emgSpike[1]],
              triggered: true
            };
          return { adjustedSetting: currentSlotConfig.emgSpike, triggered: false };
        }
      },
      {
        configName: 'emgThresholds',
        modifierFunction: (currentSlotConfig) => {
          const newEmg = checkEmgValidity(
            currentSlotConfig as DeviceConfigTemplate,
            currentSlotConfig.emgThresholds as emgThresholdsEntry
          );
          return { adjustedSetting: newEmg, triggered: false };
        }
      }
    ],
    newState,
    'inputSiteAdjustments',
    currentMode
  );
  return { newConfigModesSettings, triggered };
};

export const emergencyBatterySettingsAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState
) =>
  applyModifierModes(
    [
      {
        configName: 'batteryBeep',
        modifierFunction: (currentSlotConfig) => {
          if (currentSlotConfig.batteryBeep && currentSlotConfig.batteryBeep?.[1] !== 0) {
            return checkBatteryBeepEmergency(
              currentSlotConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            );
          }
          return { adjustedSetting: currentSlotConfig.batteryBeep, triggered: false };
        }
      }
    ],
    prevState,
    'checkBatteryBeepValidity'
  );

export const batteryBeepAfterModifier = (
  currentConfig: DeviceConfigTemplate,
  prevState: ConfigStoreState,
  currentMode: [number] | null
) => {
  // Dont make adjustments when battery beep is not in mode
  if (!currentMode) return { newConfigModesSettings: null, triggered: [] };

  return applyModifierModes(
    [
      {
        configName: 'batteryBeep',
        modifierFunction: (currentSlotConfig) => {
          if (
            currentSlotConfig.emergencyBatterySettings &&
            currentSlotConfig.emergencyBatterySettings?.[0] !== 0
          ) {
            return checkBatteryBeepEmergency(
              currentSlotConfig,
              6,
              aetherBatteryVoltageFunctions.voltageToPercent,
              aetherBatteryVoltageFunctions.percentToVoltage
            );
          }
          return { adjustedSetting: currentSlotConfig.batteryBeep, triggered: false };
        }
      }
    ],
    prevState,
    'checkBatteryBeepValidity',
    currentMode
  );
};

export const singleElectrodeSettingsAlternatingAfterModifier = (
  newState: ConfigStoreState,
  currentMode: [number] | null
) =>
  applyModifierModes(
    [
      {
        configName: 'singleElectrodeSettingsAlternating',
        modifierFunction: (currentSlotConfig: DeviceConfigTemplate) => {
          const minimumTimeBetweenPulses =
            currentSlotConfig.singleElectrodeSettingsAlternating?.[
              singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
            ];
          const stateSwitchDelay =
            currentSlotConfig.singleElectrodeSettingsAlternating?.[
              singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
            ];

          if (minimumTimeBetweenPulses < stateSwitchDelay) {
            const adjustedSingleElectrodeSettingsAlternating =
              currentSlotConfig.singleElectrodeSettingsAlternating;

            adjustedSingleElectrodeSettingsAlternating![
              singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
            ] = stateSwitchDelay;

            const adjustedMinimumTimeBetweenPulses =
              adjustedSingleElectrodeSettingsAlternating![
                singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
              ];
            const maximumTimeBetweenPulses =
              adjustedSingleElectrodeSettingsAlternating![
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              ];

            if (adjustedMinimumTimeBetweenPulses > maximumTimeBetweenPulses) {
              adjustedSingleElectrodeSettingsAlternating![
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              ] = adjustedMinimumTimeBetweenPulses;
            }

            return {
              adjustedSetting: adjustedSingleElectrodeSettingsAlternating,
              triggered: true
            };
          }

          return {
            adjustedSetting: currentSlotConfig.singleElectrodeSettingsAlternating,
            triggered: false
          };
        }
      }
    ],
    newState,
    'singleElectrodeSettingsAlternatingAdjustmends',
    currentMode
  );
