import { ChatRoomEntry } from 'adp-panel/features/Chat/api/chat.types';
import { forceReconfigureChatClient } from 'adp-panel/api/utils/ablyClient';
import { UserEntry } from 'adp-panel/api/users/users.types';
import { v4 as uuidv4 } from 'uuid';
import cryptoUtils from 'adp-panel/features/Chat/hooks/cryptoUtils';
import { useMessageChannelWithDefaultCallback } from 'adp-panel/features/Chat/hooks/channels/useMessageChannel';
import { authAblyClient } from 'adp-panel/api/authentication/authentication';

const useRoomSendMessage = (currentRoom: ChatRoomEntry, userData: UserEntry) => {
  const { encrypt } = cryptoUtils(currentRoom.encryption_key);
  const channel = useMessageChannelWithDefaultCallback();

  const sendMessageAndNotifyMembers = async (message: string) => {
    const messageId = uuidv4();
    const presenceData = currentRoom.participants?.map((item: UserEntry) => {
      if (Number(item.id) === Number(userData.id))
        return generateMessageReadStructure(messageId, String(item.id));

      return generateMessageUnreadStructure(messageId, String(item.id));
    });

    // Additional safeguard in case Ably still holds an outdated clientId.
    // @ts-ignore
    if (Number(channel.realtime.auth.clientId) !== Number(userData.id)) {
      const ably = forceReconfigureChatClient({
        authCallback: async (tokenParams, callback) => {
          try {
            const tokenRequest = await authAblyClient();
            callback(null, tokenRequest);
          } catch (error: any) {
            callback(error, null);
          }
        }
      });
      await ably.connection.once('connected');
      ably.channels.get(currentRoom.name).publish('message', {
        encryptedMessage: encrypt(message),
        user: {
          id: userData['id'],
          name: userData['name'],
          email: userData['email'],
          image: userData['image'],
          permissions: userData['permissions'],
          roles: userData['roles']
        },
        msgId: messageId,
        recipients: presenceData
      });
      return;
    }

    channel.publish('message', {
      encryptedMessage: encrypt(message),
      user: {
        id: userData['id'],
        name: userData['name'],
        email: userData['email'],
        image: userData['image'],
        permissions: userData['permissions'],
        roles: userData['roles']
      },
      msgId: messageId,
      recipients: presenceData
    });
  };

  const generateMessageUnreadStructure = (
    originalMessageId: string,
    memberId: string = String(userData.id)
  ) => {
    return {
      delivered: true,
      msgId: originalMessageId,
      seen: false,
      clientId: memberId
    };
  };

  const generateMessageReadStructure = (
    originalMessageId: string,
    memberId: string = String(userData.id)
  ) => {
    return {
      delivered: true,
      msgId: originalMessageId,
      seen: true,
      clientId: memberId
    };
  };

  return {
    sendMessage: sendMessageAndNotifyMembers
  };
};

export default useRoomSendMessage;
