import React from 'react';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { Toaster as HotToaster } from 'react-hot-toast';
import { canBeShownBasedOnNotAllowedRoutes } from 'utils/filterRoutes';
import { CONFIGURATION, REGISTER } from 'constants/routes';

const REGISTER_ROUTES = [REGISTER, CONFIGURATION];

const registerToastOptions = {
  className: '',
  style: {
    marginTop: '30px'
  },
  error: {
    style: {
      display: 'none'
    }
  },
  success: {
    style: {
      display: 'none'
    }
  },
  loading: {
    style: {
      display: 'none'
    }
  }
};

const defaultToastOptions = {
  duration: 10000,
  style: {
    borderRadius: '0.25rem',
    padding: '0.8rem 0.8rem',
    fontSize: '0.875rem',
    lineHeight: 1.5
  },
  success: {
    icon: <SvgIcon icon={svgIcons['checkOutlineIcon']} />,
    style: {
      background: '#bfddcf',
      color: '#0d462c'
    }
  },
  error: {
    icon: <SvgIcon icon={svgIcons['xIcon']} />,
    style: {
      background: '#f5c6cb',
      color: '#721c24'
    }
  }
};

const Toaster = () => {
  const determineToastOptions = () => {
    const registerRoute = !canBeShownBasedOnNotAllowedRoutes(
      window.location.pathname,
      REGISTER_ROUTES
    );

    if (registerRoute) return registerToastOptions;

    return defaultToastOptions;
  };

  return (
    <div>
      <HotToaster
        gutter={12}
        position='bottom-right'
        reverseOrder={false}
        containerStyle={{ zIndex: 10010 }}
        toastOptions={determineToastOptions()}
      />
    </div>
  );
};

export default Toaster;
