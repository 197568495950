import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
  Avatar,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';
import { ReactComponent as PLFlag } from 'assets/languages/pl.svg';
import { ReactComponent as GBFlag } from 'assets/languages/gb.svg';

const codeIconMap = new Map();
// codeIconMap.set('US', <USFlag />);
codeIconMap.set('English', <GBFlag />);
codeIconMap.set('Polish', <PLFlag />);

const StyledFlag = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: 20px;
  width: 20px;

  img,
  svg {
    border-radius: 50%;
  }
`;

export interface LanguageType {
  code: string;
  label: string;
  icon: ReactElement;
}

export const languages: readonly LanguageType[] = [
  {
    label: 'English',
    code: 'en',
    icon: codeIconMap.get('English')
  },
  {
    label: 'Polish',
    code: 'pl',
    icon: codeIconMap.get('Polish')
  }
];
const CustomLanguageSelect = ({
  id,
  label,
  control,
  optional,
  showLabel,
  loading
}: {
  id: string;
  label: string;
  control: any;
  optional: boolean;
  showLabel: boolean;
  loading: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <div>
          {showLabel && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <InputLabel
                shrink={false}
                htmlFor={id}
                sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
                <LabelWrapper>
                  <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                  {optional && <Typography>(optional)</Typography>}
                </LabelWrapper>
              </InputLabel>
              {loading && <CircularProgress size={20} />}
            </div>
          )}
          <Autocomplete
            {...field}
            id={id}
            options={languages}
            autoHighlight
            onChange={(e, data) => {
              field.onChange(data);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box key={key} component='li' {...optionProps}>
                  <StyledFlag>{option.icon}</StyledFlag>
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <div style={{ position: 'relative' }}>
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  id={id}
                  name={id}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: codeIconMap.get(params.inputProps.value) ? (
                      <InputAdornment position='start'>
                        <StyledFlag style={{ marginRight: 0 }}>
                          {codeIconMap.get(params.inputProps.value)}
                        </StyledFlag>
                      </InputAdornment>
                    ) : null
                  }}
                />
              </div>
            )}
          />
        </div>
      )}
    />
  );
};

export default CustomLanguageSelect;
