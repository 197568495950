import styled from 'styled-components';

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 16px;
`;

export default Card;
