import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { userCanVisit } from '../utils/permissionUtils';
import { DASHBOARD } from '../../constants/routes';
import { NotificationFactory } from 'lib/NotificationFactory';

// const SentryRoute = Sentry.withSentryReactRouterV6Routing(Route);

export const AuthRoute = ({ children, roles = null, user = {} }: any) => {
  const location = useLocation();
  const { rolesByName = null, isLoading } = user;

  if (!userCanVisit(roles, rolesByName) && !isLoading) {
    NotificationFactory.errorNotification('You do not have permission to access this page');
    return <Navigate to={DASHBOARD} state={{ from: location }} />;
  }

  return children;
};
