import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import * as Sentry from '@sentry/react';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../../../constants/routes';
import FormInput from '../../../components/FormInput/FormInput';
import { requiredValidator } from '../../../components/FormInput/validators';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';
import { useResetPasswordRequest } from '../../../hooks/api/useUsers';
import { RegisterBackgroundWrapper, RegisterWrapper } from '../Login/styled';
import { ButtonWrapper } from '../SetUpPass/styled';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';

const ResetPassword = ({
  redirection = LOGIN,
  customCallback = null,
  backCallback
}: {
  redirection?: any;
  customCallback?: Function | null;
  backCallback?: any;
}) => {
  const { mutateAsync: resetPassword, isLoading } = useResetPasswordRequest();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    try {
      await resetPassword(data.email);
      if (customCallback) {
        customCallback();
        return;
      }
      navigate(redirection);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error');
    }
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
      <div>
        <AuthLayout lightVariant showUdi>
          <AuthWrapperBox>
            <AuthTitle>
              {t('auth:component:reset_password.title', 'Sign In With Your Existing Account')}
            </AuthTitle>
            <AuthParagraph variant='body2'>
              {t(
                'auth:component:reset_password.text',
                'Already registered with us? Sign in with your email to connect to the configurator or explore the Aether Digital Platform (ADP) for additional functionalities.'
              )}
            </AuthParagraph>
            <Form
              onSubmit={onSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form-fieldset'}>
                    <Field
                      data-testid='email'
                      id={'emailAddress'}
                      name={'email'}
                      type={'email'}
                      label={t('auth:component:reset_password.form.email', 'Email address')}
                      validator={requiredValidator}
                      component={FormInput}
                    />
                  </fieldset>
                  <div className='k-form-buttons'>
                    <ButtonWrapper>
                      {backCallback && (
                        <Button
                          data-testid='reset-password-back'
                          themeColor={'secondary'}
                          type={'button'}
                          onClick={backCallback}>
                          {t('auth:component:reset_password.button.back', 'Back')}
                        </Button>
                      )}
                      <Button
                        data-testid='reset-password'
                        themeColor={'primary'}
                        type={'submit'}
                        disabled={!formRenderProps.allowSubmit || isLoading}>
                        {t('auth:component:reset_password.button.reset_password', 'Reset password')}
                        {isLoading && <LoaderButton />}
                      </Button>
                    </ButtonWrapper>
                  </div>
                </FormElement>
              )}
            />
          </AuthWrapperBox>
        </AuthLayout>
      </div>
      <RegisterWrapper>
        <RegisterBackgroundWrapper>
          <div style={{ maxWidth: '1100px', padding: '0 60px 0 60px' }}></div>
        </RegisterBackgroundWrapper>
      </RegisterWrapper>
    </div>
  );
};

export default ResetPassword;
