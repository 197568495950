import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Caret } from 'assets/caret-up-solid.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCaretRight = styled(Caret)`
  width: 12px;
  transform: rotate(90deg);
`;

const StyledCaretLeft = styled(Caret)`
  width: 12px;
  transform: rotate(-90deg);
`;

const PaginationButton = styled.button<{ active?: any; inactive?: any }>`
  width: 38px;
  height: 38px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colorFill};
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #3788b3;
      background-color: #e9ecef;
    `};

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
      z-index: -1;
      cursor: auto;
      pointer-events: none;
    `}
`;

const Pagination = ({ setCurrentPage, currentPage, lastPage = 1 }) => (
  <Wrapper>
    <PaginationButton
      inactive={currentPage === 1}
      onClick={() => setCurrentPage((prev) => prev - 1)}
      type='button'>
      <StyledCaretLeft />
    </PaginationButton>
    {Array.from(Array(lastPage).keys()).map((page, index) => (
      <PaginationButton
        active={currentPage === index + 1}
        onClick={() => setCurrentPage(index + 1)}
        type='button'>
        {index + 1}
      </PaginationButton>
    ))}
    <PaginationButton
      inactive={currentPage === lastPage}
      onClick={() => setCurrentPage((prev) => prev + 1)}
      type='button'>
      <StyledCaretRight />
    </PaginationButton>
  </Wrapper>
);

export default Pagination;
