import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logoutAPI } from 'adp-panel/api/authentication/authentication';
import { setApiAuthToken } from 'adp-panel/api/utils/apiClient';
import { LOGIN } from 'constants/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'reducers/authStore';

export default function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const broadcast = new BroadcastChannel('logouts');
  const { logoutSuccess, startLogout } = useAuthStore((state) => ({
    logoutSuccess: state.logoutSuccess,
    startLogout: state.startLogout
  }));

  const { isLoading, mutate: logout } = useMutation(logoutAPI, {
    onSuccess() {
      localStorage.removeItem('redirectUrl');
      sessionStorage.removeItem('lastActivity');
      logoutSuccess();
      broadcast.postMessage({ typ: 'LOG_OUT' });
      setTimeout(() => {
        queryClient.clear();
      }, 200);
      navigate(LOGIN);
    },
    onSettled() {
      window.location.reload();
    }
  });

  const beforeLogout = (dispatchEvent: boolean = false) => {
    startLogout();
    setApiAuthToken(null);
    queryClient.cancelQueries();
    logout();

    if (dispatchEvent) {
      const event = new CustomEvent('logoutEvent', { detail: {}, bubbles: true });
      document.dispatchEvent(event);
    }
  };

  const { isLoading: mfaLogoutIsLoading, mutate: mfaLogout } = useMutation(logoutAPI, {
    onSuccess() {
      window.location.replace(LOGIN);
    }
  });

  const offlineLogout = () => {
    setApiAuthToken(null);
    logoutSuccess();
    queryClient.cancelQueries();
    setTimeout(() => {
      queryClient.resetQueries();
    }, 200);
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      offlineLogout();
    };
  }, []);

  return {
    logout: (dispatchEvent: boolean = false) => beforeLogout(dispatchEvent),
    mfaLogout,
    isLoading: isLoading || mfaLogoutIsLoading,
    offlineLogout
  };
}
