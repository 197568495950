import Ably, { Types } from 'ably';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { authAblyClient } from '../../api/authentication/authentication';
import { UserEntry } from '../../api/users/users.types';

type EventEmitter = Types.RealtimeCallbacks | undefined;

const autoLogoutChannel = (userId: string) => `auto-logout-${userId}`;

const useSynchronizeAutoLogoutTimer = (
  user: UserEntry,
  onResetTimeAction: (message: any) => void
) => {
  const eventEmitter = useRef<EventEmitter>(undefined);
  const [initialized, setInitialized] = useState(false);

  const assertEventEmitterIsInitialized = (
    eventEmitterToAssert: MutableRefObject<EventEmitter>
  ): Types.RealtimeCallbacks => {
    if (eventEmitterToAssert.current === undefined) {
      throw Error(`EventEmitter is not initialized`);
    }
    return eventEmitterToAssert.current;
  };

  const connectToClient = async () => {
    const ably = new Ably.Realtime.Callbacks({
      authCallback: async (tokenParams, callback) => {
        try {
          const tokenRequest = await authAblyClient();
          callback(null, tokenRequest);
        } catch (error: any) {
          callback(error, null);
        }
      }
    });
    await ably.connection.once('connected');

    return ably;
  };

  const emitActivityEvent = (errorCallback?: () => void) => {
    try {
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(autoLogoutChannel(String(user.id)));
      sessionStorage.setItem('lastActivity', new Date().toISOString());
      channel.publish('reset', { application: 'CONFIGURATOR' });
    } catch (error) {
      if (errorCallback) {
        errorCallback();
      }
      console.warn(error);
    }
  };

  const emitSoftLogoutEvent = (onReceiptReturnEvent: () => void) => {
    try {
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(autoLogoutChannel(String(user.id)));

      channel.publish('softLogout', { application: 'ADP' });
      channel.subscribe('forceLogout', onReceiptReturnEvent);
    } catch (error) {
      console.warn(error);
    }
  };

  const emitEvent = useCallback(
    (eventName: string, errorCallback?: () => void) => {
      try {
        const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
        eventEmitterIsDefined.channels
          .get(autoLogoutChannel(String(user.id)))
          .publish(eventName, {});
      } catch (error) {
        if (errorCallback) {
          errorCallback();
        }
        console.warn(error);
      }
    },
    [eventEmitter, user?.id]
  );

  const on = (event: any, callback: any) => {
    if (!initialized) return;
    try {
      const channelName = autoLogoutChannel(String(user.id));
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(channelName);
      channel.subscribe(event, callback);
    } catch (error) {
      console.warn(error);
    }
  };

  const off = (event: any, callback: any) => {
    if (!initialized) return;
    try {
      const channelName = autoLogoutChannel(String(user.id));
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(channelName);
      channel.unsubscribe(event, callback);
    } catch (error) {
      console.warn(error);
    }
  };

  const emitRemainingTimeEvent = useCallback(
    (remainingSeconds: number) => {
      try {
        const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
        eventEmitterIsDefined.channels
          .get(autoLogoutChannel(String(user.id)))
          .publish('remaining', { remainingTime: remainingSeconds, application: 'ADP' });
      } catch (error) {
        console.warn(error);
      }
    },
    [eventEmitter, user?.id]
  );

  useEffect(() => {
    if (!initialized) return;
    try {
      const channelName = autoLogoutChannel(String(user.id));
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(channelName);

      const onEvent = (message: any) => {
        sessionStorage.setItem('lastActivity', new Date().toISOString());
        onResetTimeAction(message);
      };

      const onMount = async () => {
        await channel.subscribe('reset', onEvent);
      };
      const onUnmount = async () => {
        await channel.unsubscribe('reset', onEvent);

        setTimeout(async () => {
          if (channel.listeners.length === 0) {
            await channel.detach();
          }
        }, 2500);
      };

      onMount();

      return () => {
        onUnmount();
      };
    } catch (error) {
      console.log(error);
    }
  }, [initialized]);

  useEffect(() => {
    try {
      if (user === undefined) return;
      let didUserConnectInterrupt = false;
      const connectionPromise = connectToClient().then((client) => {
        if (!didUserConnectInterrupt) {
          eventEmitter.current = client;
          setInitialized(true);
        }
      });

      return () => {
        didUserConnectInterrupt = true;
        const emitter = eventEmitter.current;
        if (emitter === undefined) return;

        connectionPromise
          .then(() => emitter.connection.close())
          .then(() => {
            console.log('connection closed');
          });
        eventEmitter.current = undefined;
        setInitialized(false);
      };
    } catch (error) {
      console.warn(error);
    }
  }, []);

  return {
    initialized,
    emitRemainingTimeEvent,
    emitEvent,
    emitSoftLogoutEvent,
    emitActivityEvent,
    on,
    off
  };
};

export default useSynchronizeAutoLogoutTimer;
