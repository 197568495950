export enum DocumentsEnum {
  web = 'web',
  mobile = 'mobile'
}

export interface DocumentEntry {
  id: number;
  document_id: number;
  index: number;
  file: string;
  created_at: string;
  updated_at: string;
  document: {
    id: number;
    name: string;
    type: DocumentsEnum;
    created_at: string;
    updated_at: string;
  };
}

export interface DocumentStatusParams {
  type: DocumentsEnum;
}

export interface DocumentAcceptancePayload {
  documents: number[];
}
