import { HandSide, ThumbLength } from 'bluetooth/Bluetooth/Defines';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import {
  ControlModes,
  GripSwitchingModes,
  InputOptions,
  InputSites,
  SpeedControlStrategies,
  StartOnStartup,
  SingleElectrodeMode,
  InputDevices
} from '../../bluetooth/Bluetooth/Control';
import { Grips } from 'bluetooth/Bluetooth/Grips';

export enum HandMovementStates {
  idle,
  opening,
  closing
}

const handMovementStatesTuples: [HandMovementStates, string][] = [
  [HandMovementStates.idle, 'No movement'],
  [HandMovementStates.opening, 'Opening'],
  [HandMovementStates.closing, 'Closing']
];

const gripsOptionsTuples: any = [
  [Grips.kGripPower, 'Power'],
  [Grips.kGripTripodOpen, 'Tripod Open'],
  [Grips.kGripTripodClosed, 'Tripod Closed'],
  [Grips.kGripTrigger, 'Trigger'],
  [Grips.kGripPrecisionOpen, 'Precision Open'],
  [Grips.kGripPrecisionClosed, 'Precision Closed'],
  [Grips.kGripCamera, 'Camera'],
  [Grips.kGripHook, 'Hook'],
  [Grips.kGripKey, 'Key'],
  [Grips.kGripFingerPoint, 'Finger Point'],
  [Grips.kGripMouse, 'Mouse'],
  [Grips.kGripRestOpp, 'Rest opp'],
  [Grips.kGripRestNopp, 'Open Palm'],
  [Grips.kGripFingerPointOpen, 'Active Index'],
  [Grips.kGripCounting, 'Counting'],
  [Grips.kGripTypeUnknown, 'Disabled']
];

const inputOptionTuples: any = [
  [InputOptions.kEMG, 'EMG'],
  [InputOptions.kLinearTransducer, 'Linear Tranducer']
];

const inputDeviceTuples: any = [
  [InputDevices.kInputOptionElectrodes, 'EMG'],
  [InputDevices.kInputOptionPatRec, 'Coapt']
];

const inputSiteTuples: any = [
  [InputSites.kSingleElectrode, 'Single'],
  [InputSites.kDualDirectElectrodes, 'Dual direct'],
  [InputSites.kDualInvertedElectrodes, 'Dual Inverted']
];

const speedControlTuples: any = [
  [SpeedControlStrategies.kThreshold, 'One speed'],
  [SpeedControlStrategies.kProportional, 'Proportional']
];

const singleElectrodeModeTuples: any = [
  [SingleElectrodeMode.kFastRising, 'Slope'],
  [SingleElectrodeMode.kAlternating, 'Alternating']
];

const gripSwitchingTuples: any = [
  [GripSwitchingModes.kCoContraction, 'Co-contraction'],
  [GripSwitchingModes.kOpenOpen, 'Open-open'],
  [GripSwitchingModes.kSingleGripSwitching, 'Single electrode'],
  [GripSwitchingModes.kHoldOpen, 'Hold-open'],
  [GripSwitchingModes.kCoapt, 'Coapt']
];

const controlModeTuples: any = [
  [ControlModes.kGripPairs, 'Pairing mode'],
  [ControlModes.kGripSequence, 'Sequential mode'],
  [ControlModes.kCoapt, 'Coapt']
];

const thumbLengthTuples: any = [
  [ThumbLength.kThumbLong, 'Thumb long'],
  [ThumbLength.kThumbNotSet, 'Thumb unknown'],
  [ThumbLength.kThumbShort, 'Thumb short']
];

const handSideTuples: any = [
  [HandSide.kHandLeft, 'Hand left'],
  [HandSide.kHandRight, 'Hand right']
];

const genericSwitchingTuples: any = [
  [0, 'Off'],
  [1, 'On']
];

const freezeModeTuples: [0 | 1 | 3, string][] = [
  [0, 'Disabled'],
  [1, 'Enabled'],
  [3, 'Enabled']
];

const startOnStartupTuples: any = [
  [StartOnStartup.kNotWaitForFirstCoContraction, 'Off'],
  [StartOnStartup.kWaitForFirstCoContraction, 'On']
];

const useFeedbackTypeTuples: [0, string][] = [[0, 'Finger buzzer']];

const userFeedbackTypePcbV4Tuples: [1 | 2, string][] = [
  [1, 'External buzzer'],
  [2, 'Vibration']
];

const allUserFeedbackTypeTuples = [...useFeedbackTypeTuples, ...userFeedbackTypePcbV4Tuples];

export const Fingers = {
  Thumb: 0,
  Index: 1,
  Middle: 2,
  Ring: 3,
  Pinky: 4
};

const SettingsNameTuples: any = [
  ['chooseGripsHistory', 'Choose Grips'],
  ['emgSettingsHistory', 'EMG settings'],
  ['emgThresholdsHistory', 'EMG settings'],
  ['gripPairsConfigHistory', 'Grips configuration'],
  ['gripsConfigurationHistory', 'Grips configuration'],
  ['gripSequentialConfigHistory', 'Grips configuration'],
  ['prosthesisSettingsHistory', 'Prosthesis Settings']
];

export const ConfigSettingsNamesTuples: [keyof DeviceConfigTemplate, string][] = [
  ['gripPairsConfig', 'Grips pairs'],
  ['inputSite', 'Electrode input configuration'],
  ['inputDevice', 'Input device'],
  ['controlMode', 'Control mode'],
  ['speedControlStrategy', 'Speed control strategy'],
  ['gripSwitchingMode', 'Grip switching mode'],
  ['gripSequentialConfig', 'Grips sequential'],
  ['emgThresholds', 'EMG thresholds %'],
  ['fingerStrength', 'Finger strength'],
  ['autoGrasp', 'Autograsp'],
  ['emgSpike', 'Ignore EMG spikes'],
  ['holdOpen', 'Hold open'],
  ['softGrip', 'Soft grip'],
  ['emgGains', 'EMG gains'],
  ['pulseTimings', 'Pulse timings'],
  ['coContractionTimings', 'Co-contraction timings'],
  ['gripsPositions', 'Grip positions'],
  ['batteryBeep', 'Low battery beep'],
  ['singleElectrodeMode', 'Single electrode direction control'],
  ['singleElectrodeModeSettings', 'Electrode settings'],
  ['freezeModeEmg', 'Freeze mode EMG'],
  ['generalHandSettings', 'General hand settings'],
  ['followingGrip', 'Following grip'],
  ['freezeModeEmgSettings', 'Freeze mode emg settings'],
  ['buzzingVolumeSettings', 'Buzzing volume'],
  ['userFeedbackType', 'Feedback type'],
  ['emergencyBatterySettings', 'Emergency mode'],
  ['oneSpeed', 'One speed settings'],
  ['singleElectrodeSettingsAlternating', 'Single electrode alternating settings']
];

export const aetherBatteryVoltageFunctions = {
  voltageToPercent: (voltage: any) => {
    if (voltage >= 6500 && voltage < 6900) return Math.round(0.0225 * voltage - 145.25);
    if (voltage >= 6900 && voltage < 8400) return Math.round(0.06 * voltage - 404);
    if (voltage < 6500) return 1;
    return 100;
  },
  percentToVoltage: (percent: any) => {
    if (percent >= 1 && percent < 10) return Math.round(44.444 * percent + 6455.56);
    if (percent >= 10 && percent < 100) return Math.round(16.667 * percent + 6733.3);
    if (percent < 1) return 6500;
    return 8400;
  }
};

// eslint-disable-next-line no-unused-vars
export const inputOptionsMap: any = new Map(inputOptionTuples);
export const inputSiteMap: any = new Map(inputSiteTuples);
export const inputDeviceMap: any = new Map(inputDeviceTuples);
export const speedControlMap: any = new Map(speedControlTuples);
export const singleElectrodeModeMap: any = new Map(singleElectrodeModeTuples);
export const gripSwitchingMap: any = new Map<GripSwitchingModes, string>(gripSwitchingTuples);
export const controlModeMap: any = new Map(controlModeTuples);
export const genericSwitchingMap: any = new Map(genericSwitchingTuples);
export const configSettingsNamesMap: Map<keyof DeviceConfigTemplate, string> = new Map(
  ConfigSettingsNamesTuples
);
export const startOnStartupMap: any = new Map(startOnStartupTuples);
export const thumbLengthMap: any = new Map(thumbLengthTuples);
export const handSideMap: any = new Map(handSideTuples);
export const userFeedbackTypeMap: Map<number, string> = new Map(useFeedbackTypeTuples);
export const userFeedbackTypePcbV4Map: Map<number, string> = new Map(userFeedbackTypePcbV4Tuples);
export const allUserFeedbackTypeMap: Map<number, string> = new Map(allUserFeedbackTypeTuples);
export const freezeModeMap: Map<number, string> = new Map(freezeModeTuples);
export const handMovementStatesMap: Map<HandMovementStates, string> = new Map(
  handMovementStatesTuples
);

export const inputOptionsReversedMap = new Map(
  Array.from(inputOptionsMap, (a: any) => a.reverse())
);
export const inputSiteReversedMap = new Map(Array.from(inputSiteMap, (a: any) => a.reverse()));
export const inputDeviceReversedMap = new Map(Array.from(inputDeviceMap, (a: any) => a.reverse()));
export const speedControlReversedMap = new Map(
  Array.from(speedControlMap, (a: any) => a.reverse())
);
export const singleElectrodeModeReverseMap = new Map(
  Array.from(singleElectrodeModeMap, (a: any) => a.reverse())
);
export const gripSwitchingReversedMap = new Map(
  Array.from(gripSwitchingMap, (a: any) => a.reverse())
);
export const controlModeReversedMap = new Map(Array.from(controlModeMap, (a: any) => a.reverse()));
export const genericSwitchingReversedMap = new Map(
  Array.from(genericSwitchingMap, (a: any) => a.reverse())
);
export const userFeedbackTypeReversedMap = new Map(
  Array.from(userFeedbackTypeMap, (a: any) => a.reverse())
);
export const userFeedbackTypePcbV4ReversedMap = new Map(
  Array.from(userFeedbackTypePcbV4Map, (a: any) => a.reverse())
);
export const allUserFeedbackTypeReversedMap = new Map(
  Array.from(allUserFeedbackTypeMap, (a: any) => a.reverse())
);
export const gripsGroupsOptionsMap: any = new Map(gripsOptionsTuples);
export const gripsGroupsOptionsReversedMap = new Map(
  Array.from(gripsGroupsOptionsMap, (a: any) => a.reverse())
);
export const historySettingsNameMap = new Map(SettingsNameTuples);
export const historySettingsNameReversedMap = new Map(
  Array.from(historySettingsNameMap, (a: any) => a.reverse())
);
export const startOnStartupReversedMap = new Map(
  Array.from(startOnStartupMap, (a: any) => a.reverse())
);
export const thumbLengthReversedMap = new Map(Array.from(thumbLengthMap, (a: any) => a.reverse()));
export const handSideReversedMap = new Map(Array.from(handSideTuples, (a: any) => a.reverse()));
export const freezeModeReversesMap = new Map(Array.from(freezeModeTuples, (a: any) => a.reverse()));
export const handMovementStatesReversedMap = new Map(
  Array.from(handMovementStatesTuples, (a: any) => a.reverse())
);
