import React from 'react';
import styled, { css } from 'styled-components';
import { LogEntry } from 'adp-panel/api/logs/logs.types';
import { TableLink } from 'adp-panel/components/Links/Links';
import { CLINICIANS, COMPANY, DEVICE, LOCATION, PATIENTS } from 'constants/routes';
import { rolesByName, userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum, Role } from 'adp-panel/api/users/users.types';
import dayjs from 'dayjs';
import { Badge } from 'adp-panel/components/Badge/Badge';
import { rolesNamesMap } from 'adp-panel/utils/definesLocal';

const badgesColors = {
  patients: '#FFC107',
  clinicians: '#DC3534',
  ticket: '#54bd8d',
  devices: '#60C0D3'
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 38px;
  margin-bottom: 24px;
`;

const Header = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.headerTitle};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface CustomBadgeProps {
  patients?: boolean;
  devices?: boolean;
  clinicians?: boolean;
  ticket?: boolean;
}

const CustomBadge = styled.div<CustomBadgeProps>`
  ${Badge};
  white-space: nowrap;

  ${({ patients }) =>
    patients &&
    css`
      background-color: ${badgesColors.patients};
    `};

  ${({ devices }) =>
    devices &&
    css`
      background-color: ${badgesColors.devices};
    `};

  ${({ clinicians }) =>
    clinicians &&
    css`
      background-color: ${badgesColors.clinicians};
    `};

  ${({ ticket }) =>
    ticket &&
    css`
      background-color: ${badgesColors.ticket};
    `};
`;

const LogText = styled.p`
  :first-letter {
    text-transform: uppercase;
  }
`;

interface TableRow {
  name: string;
  date: string;
  idAddress: string;
  userName: string;
}

const acceptedEvents = [
  'company_create',
  'company_delete',
  'company_update',
  'company_location_create',
  'company_location_delete',
  'company_location_update',
  'device_create',
  'device_config',
  'device_config_add',
  'device_config_update',
  'device_update',
  'device_delete',
  'device_attached',
  'device_detached',
  'p2p_session_close',
  'p2p_session_create',
  'p2p_session_update',
  'user_create',
  'user_update',
  'user_delete',
  'config_mode_create',
  'config_mode_update',
  'config_send',
  'config_update',
  'config_demo_update',
  'password_change_admin',
  'password_change_token',
  'support_ticket_create',
  'support_ticket_close',
  'support_ticket_reopen'
];

interface UserBadgesProps {
  userRoles: Role[] | undefined;
  logItem: LogEntry;
}

interface UserLinkProps {
  userRoles: Role[] | undefined;
  logItem: LogEntry;
  comment: any;
}

type ElementMapping = Record<string, (item: LogEntry, comment: any) => React.ReactNode | null>;
type FilterMapping = Record<string, (item: LogEntry, meId: number) => boolean>;

const UserBadges = ({ userRoles, logItem }: UserBadgesProps) => {
  const rolesNames = rolesByName(userRoles);

  return userRoles ? (
    <>
      {userRoles.map((role, index) => (
        <CustomBadge
          key={`${logItem?.id}${index}`}
          clinicians={userHasPermissions([RoleEnum.clinician], rolesNames)}
          patients={userHasPermissions([RoleEnum.amputee], rolesNames)}>
          {rolesNamesMap.get(role.name)}
        </CustomBadge>
      ))}
    </>
  ) : null;
};

const UserLink = ({ userRoles, logItem, comment }: UserLinkProps) => {
  const rolesNames = rolesByName(userRoles);
  let link = <strong>{comment?.name || logItem?.element?.name}</strong>;

  if (userHasPermissions([RoleEnum.amputee], rolesNames)) {
    link = (
      <TableLink to={`${PATIENTS}/${logItem?.element_id}`}>
        <strong>{comment?.name || logItem?.element?.name}</strong>
      </TableLink>
    );
  }
  if (userHasPermissions([RoleEnum.clinician], rolesNames)) {
    link = (
      <TableLink to={`${CLINICIANS}/${logItem?.element_id}`}>
        <strong>{comment?.name || logItem?.element?.name}</strong>
      </TableLink>
    );
  }
  return userRoles ? link : null;
};

// @ts-ignore
const logsMapping: ElementMapping = {
  company_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Companies</CustomBadge>
      <p>
        Added new company{' '}
        <TableLink to={`${COMPANY}/${item?.element_id}`}>
          <strong>{comment?.name}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  company_delete: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Companies</CustomBadge>
      <p>
        Deleted company <strong>{comment?.name}</strong>
      </p>
    </ActivityWrapper>
  ),
  company_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Companies</CustomBadge>
      <p>
        Updated company{' '}
        <TableLink to={`${COMPANY}/${item?.element_id}`}>
          <strong>{comment?.name}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  company_location_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Locations</CustomBadge>
      <p>
        Added new location{' '}
        <TableLink to={`${LOCATION}/${item?.element_id}`}>
          <strong>{comment?.name}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  company_location_delete: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Locations</CustomBadge>
      <p>
        Deleted location <strong>{comment?.name}</strong>
      </p>
    </ActivityWrapper>
  ),
  company_location_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Locations</CustomBadge>
      <p>
        Updated location{' '}
        <TableLink to={`${LOCATION}/${item?.element_id}`}>
          <strong>{comment?.name}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  device_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Added new device{' '}
        <TableLink to={`${DEVICE}/${item?.element_id}`}>
          <strong>{item?.element?.serial}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  device_config: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device config changed <strong>{item?.element?.serial}</strong>
      </p>
    </ActivityWrapper>
  ),
  device_config_add: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device config added <strong>{item?.element?.serial}</strong>
      </p>
    </ActivityWrapper>
  ),
  device_config_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device config updated <strong>{item?.element?.serial}</strong>
      </p>
    </ActivityWrapper>
  ),
  device_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Updated device{' '}
        <TableLink to={`${DEVICE}/${item?.element_id}`}>
          <strong>{item?.element?.serial}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  device_delete: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Deleted device <strong>{comment?.serial}</strong>
      </p>
    </ActivityWrapper>
  ),
  device_attached: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device <strong>{item.element?.serial}</strong> has been linked to account{' '}
        <strong>{comment?.attached_to?.name}</strong>
      </p>
    </ActivityWrapper>
  ),
  device_detached: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device <strong>{item.element?.serial}</strong> has been disconnected from account{' '}
        <strong>{comment?.detached_from?.name}</strong>
      </p>
    </ActivityWrapper>
  ),
  p2p_session_close: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Devices</CustomBadge>
      <p>
        Session closed <strong>{item?.element?.token}</strong>
      </p>
    </ActivityWrapper>
  ),
  p2p_session_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Devices</CustomBadge>
      <p>
        Session open <strong>{item?.element?.token}</strong>
      </p>
    </ActivityWrapper>
  ),
  p2p_session_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge>Devices</CustomBadge>
      <p>
        Updated session <strong>{item?.element?.token}</strong>
      </p>
    </ActivityWrapper>
  ),
  user_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <UserBadges userRoles={item?.element?.roles} logItem={item} />
      <p>
        Created user <UserLink userRoles={item?.element?.roles} logItem={item} comment={comment} />
      </p>
    </ActivityWrapper>
  ),
  user_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <UserBadges userRoles={item?.element?.roles} logItem={item} />
      <p>
        Updated user <UserLink userRoles={item?.element?.roles} logItem={item} comment={comment} />
      </p>
    </ActivityWrapper>
  ),
  user_delete: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <UserBadges userRoles={comment?.roles} logItem={item} />
      <p>
        Deleted user <strong>{comment?.name}</strong>
      </p>
    </ActivityWrapper>
  ),
  config_mode_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Mode <strong>{item?.element?.name}</strong> created for device{' '}
        <TableLink to={`${DEVICE}/${item?.element?.device_id}`}>
          <strong>{item?.element?.device_id}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  config_mode_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Mode <strong>{item?.element?.name}</strong> updated for device{' '}
        <TableLink to={`${DEVICE}/${item?.element?.device_id}`}>
          <strong>{item?.element?.device_id}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  config_send: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>Config sent for device using ticket</p>
    </ActivityWrapper>
  ),
  config_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Config updated for device{' '}
        <TableLink to={`${DEVICE}/${item?.element?.id}`}>
          <strong>{item.element?.serial}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  config_demo_update: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge devices>Devices</CustomBadge>
      <p>
        Device demo config updated{' '}
        <TableLink to={`${DEVICE}/${item?.element?.device_id}`}>
          <strong>{item?.element?.device_id}</strong>
        </TableLink>
      </p>
    </ActivityWrapper>
  ),
  password_change_admin: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <UserBadges userRoles={item?.element?.roles} logItem={item} />
      <p>
        Password changed for user{' '}
        <UserLink userRoles={item?.element?.roles} logItem={item} comment={comment} />
      </p>
    </ActivityWrapper>
  ),
  password_change_token: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <UserBadges userRoles={item?.element?.roles} logItem={item} />
      <p>
        Change password by token for user{' '}
        <UserLink userRoles={item?.element?.roles} logItem={item} comment={comment} />
      </p>
    </ActivityWrapper>
  ),
  support_ticket_create: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge ticket>Support Ticket</CustomBadge>
      <p>
        Support ticket created <strong>{item?.element?.id}</strong>
      </p>
    </ActivityWrapper>
  ),
  support_ticket_close: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge ticket>Support Ticket</CustomBadge>
      <p>
        Support ticket closed <strong>{item?.element?.id}</strong>
      </p>
    </ActivityWrapper>
  ),
  support_ticket_reopen: (item: LogEntry, comment: any) => (
    <ActivityWrapper>
      <CustomBadge ticket>Support Ticket</CustomBadge>
      <p>
        Support ticket reopen <strong>{item?.element?.id}</strong>
      </p>
    </ActivityWrapper>
  ),
  default: (item: LogEntry, comment: any) => {
    const typeToPropMap = {
      patients: { patients: true },
      devices: { devices: true },
      clinicians: { clinicians: true },
      default: { patients: false, devices: false, clinicians: false }
    };
    return (
      <ActivityWrapper>
        <CustomBadge
          {...(typeToPropMap[item.type as keyof typeof typeToPropMap] || typeToPropMap.default)}>
          {item.type
            .replace(/_/g, ' ')
            .replace(/([A-Z])/g, ' $1')
            .trim()}
        </CustomBadge>
        <LogText>{item.event_name.replaceAll('_', ' ')}</LogText>
      </ActivityWrapper>
    );
  }
};

const elementMapping: ElementMapping = {
  company_create: (item: LogEntry, comment: any) => comment?.name,
  company_update: (item: LogEntry, comment: any) => comment?.name,
  company_delete: (item: LogEntry, comment: any) => comment?.name,
  company_location_create: (item: LogEntry, comment: any) => comment?.name,
  company_location_update: (item: LogEntry, comment: any) => comment?.name,
  device_create: (item: LogEntry, comment: any) => item?.element?.serial,
  device_config: (item: LogEntry, comment: any) => item?.element?.serial,
  device_config_add: (item: LogEntry, comment: any) => item?.element?.serial,
  device_config_update: (item: LogEntry, comment: any) => item?.element?.serial,
  device_update: (item: LogEntry, comment: any) => item?.element?.serial,
  device_delete: (item: LogEntry, comment: any) => item?.element?.serial,
  device_attached: (item: LogEntry, comment: any) => item?.element?.serial,
  device_detached: (item: LogEntry, comment: any) => item?.element?.serial,
  company_location_delete: (item: LogEntry, comment: any) => '',
  p2p_session_create: (item: LogEntry, comment: any) => '',
  p2p_session_close: (item: LogEntry, comment: any) => '',
  user_create: (item: LogEntry, comment: any) => item?.element?.name,
  user_update: (item: LogEntry, comment: any) => item?.element?.name,
  user_delete: (item: LogEntry, comment: any) => item?.element?.name,
  config_mode_create: (item: LogEntry, comment: any) => item?.element?.name,
  config_mode_update: (item: LogEntry, comment: any) => item?.element?.name,
  config_send: (item: LogEntry, comment: any) => item?.element?.name,
  config_update: (item: LogEntry, comment: any) => item?.element?.name,
  config_demo_update: (item: LogEntry, comment: any) => item?.element?.name,
  password_change_admin: (item: LogEntry, comment: any) => item?.element?.name,
  password_change_token: (item: LogEntry, comment: any) => item?.element?.name,
  support_ticket_create: (item: LogEntry, comment: any) => item?.element?.name,
  support_ticket_close: (item: LogEntry, comment: any) => item?.element?.name,
  support_ticket_reopen: (item: LogEntry, comment: any) => item?.element?.name,
  default: (item: LogEntry, comment: any) => item?.element?.name
};

const filters: FilterMapping = {
  // Filter own login events
  user_login: (item: LogEntry, meId: number) => {
    if (item.user_id === meId) return false;
    return true;
  },
  user_logout: (item: LogEntry, meId: number) => {
    if (item.user_id === meId) return false;
    return true;
  }
};

const parseLogTitle = (item: LogEntry, comment: any = null) => {
  return logsMapping[item.event_name] !== undefined
    ? logsMapping[item.event_name](item, comment)
    : logsMapping.default(item, comment);
};

const parseElement = (item: LogEntry, comment: any = null) => {
  return elementMapping[item.event_name] !== undefined
    ? elementMapping[item.event_name](item, comment)
    : elementMapping.default(item, comment);
};

const filterElements = (item: LogEntry, meId: number) => {
  return filters[item.event_name] !== undefined ? filters[item.event_name](item, meId) : true;
};

export const mapActivitiesData = (logs: { items: LogEntry[] }, meId: number) => {
  const formatDate = (date: any) => dayjs(date).format('MM/DD/YYYY, HH:mm');
  return (
    logs?.items &&
    logs.items
      .filter((item) => filterElements(item, meId))
      .map((item: LogEntry) => {
        return {
          name: parseLogTitle(item, item.comments && JSON.parse(item.comments)),
          element: parseElement(item, item.comments && JSON.parse(item.comments)),
          date: formatDate(item.created_at ?? ''),
          idAddress: item.ip_address,
          userName: item.user?.name || item.user?.email
        } as TableRow;
      })
  );
};
