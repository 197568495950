import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY,
  CHAT_ROOMS_QUERY_KEY
} from 'adp-panel/features/Chat/hooks/api/useChatRooms';
import { useForceChangePasswordForAnotherUser, useUserCreate } from 'adp-panel/hooks/api/useUsers';
import { DASHBOARD } from 'constants/routes';
import { NotificationFactory } from 'lib/NotificationFactory';
import { isNil, omitBy } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRoles } from 'types';
import { UserCreatePayload } from 'adp-panel/api/users/users.types';

export const useAddPatient = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: createUser } = useUserCreate();
  const { mutateAsync: changePasswordRequest } = useForceChangePasswordForAnotherUser();

  const handleSubmit = async ({
    firstName = undefined,
    lastName = undefined,
    email,
    password = undefined,
    clinician,
    redirect = DASHBOARD,
    cancelRedirect = false
  }: {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string;
    password: string | undefined;
    clinician: any;
    redirect: string;
    cancelRedirect: boolean;
  }) => {
    try {
      setIsLoading(true);
      const user = await createUser(
        omitBy(
          {
            name: firstName + ' ' + lastName,
            email,
            role: UserRoles.amputee,
            mfa_enabled: 0,
            ...(clinician?.id && { clinician: clinician.id })
          },
          isNil
        ) as UserCreatePayload
      );

      if (password && password.length > 0) {
        await changePasswordRequest({ userId: user?.id, password });
      }
      await queryClient.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      await queryClient.invalidateQueries([CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY]);
      if (redirect && !cancelRedirect) navigate(redirect);
      return user;
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to create patient');
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading };
};
