/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'assets/aether_logo_white.png';
import Navbar from 'configurator/components/molecules/Navbar/Navbar';
import { ReactComponent as Background } from 'assets/Vector1.svg';
import { Button } from '@mui/material';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';
import { CHOOSE_GRIPS } from 'constants/routes';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #33499c;
  width: 275px;

  a {
    z-index: 1;
    text-decoration: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;

const ButtonWrapper = styled.div`
  padding: 0 15px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 20px;
    padding: 0 30px;
  }
`;

const BackgroundWrapper = styled(Background)`
  display: none;
  position: absolute;
  top: 0;
  left: -5px;
  height: 93%;
  width: 109%;
`;

const Sidebar = memo(() => {
  const { handleLogout } = useLeaveConfigurator();

  return (
    <Wrapper>
      <InnerWrapper>
        <NavLink to={CHOOSE_GRIPS} style={{ marginBottom: '36px' }}>
          <img src={Logo} />
        </NavLink>
        <Navbar />
        <ButtonWrapper>
          <Button onClick={handleLogout}>Logout</Button>
        </ButtonWrapper>
      </InnerWrapper>
      <BackgroundWrapper />
    </Wrapper>
  );
});

export default Sidebar;
