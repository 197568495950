import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createConfigMode,
  createConfigModeFromTemplate,
  getMode,
  getModes,
  getModesByDevice,
  getModesByUser,
  updateMode
} from 'adp-panel/api/modes/modes';
import { ModesEntry, ModesQueryParams } from 'adp-panel/api/modes/modes.types';
import { errorApiNotification, mapErrorMessage } from 'utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';

export const MODES_LIST_KEY = 'modes_list';
export const MODES_USER_KEY = 'modes_user';
export const MODE_USER_KEY = 'mode_user';

export const useModes = (queryParams: ModesQueryParams, enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [MODES_LIST_KEY, queryParams],
    () => getModes(queryParams),
    {
      enabled,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useUserModes = (userId: number, enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [MODES_USER_KEY, userId],
    () => getModesByUser(userId),
    {
      enabled,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useDeviceModes = (
  deviceId: number,
  enabled: boolean,
  queryParams: ModesQueryParams
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [MODES_USER_KEY, deviceId],
    () => getModesByDevice(deviceId, queryParams),
    {
      enabled,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useMode = (deviceId: number, modeId: number, enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [MODES_LIST_KEY, [deviceId, modeId]],
    () => getMode(deviceId, modeId),
    {
      enabled,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: (data ?? null) as ModesEntry,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useCreateModeConfig = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createConfigMode, {
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

const useAction = (request: any, success: string, fail: string) => {
  const { data, mutateAsync, isLoading, isError } = useMutation(request, {
    onSuccess() {
      NotificationFactory.successNotification(success);
    },
    onError(error) {
      errorApiNotification(error, fail);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useModeCreateFromTemplate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createConfigModeFromTemplate, {
    onSuccess() {
      NotificationFactory.successNotification('Mode from template created');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUpdateMode = () => {
  return useAction(updateMode, 'Mode edited', 'Failed to edit mode');
};
