import React from 'react';
import {
  TicketPreviewContent,
  TicketPreviewContentText,
  TicketPreviewContentTitle,
  TicketPreviewHeader,
  TicketPreviewHeaderCreator,
  TicketPreviewHeaderStatuses,
  TicketPreviewTile,
  TicketPreviewTime
} from 'adp-panel/features/Chat/components/Extensions/TicketPreview.styled';
import { TicketStatusBadge } from 'adp-panel/features/Chat/components/ChatBadges';
import { Avatar } from '@progress/kendo-react-layout';
import CompanyImg from 'assets/company_img.jpg';
import { Button } from '@progress/kendo-react-buttons';
import { SupportTicketEntry, SupportTicketStatusEnum } from 'adp-panel/api/tickets/tickets.types';
import { ChatMessage, TicketMessage } from 'adp-panel/features/Chat/types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import dayjs from 'dayjs';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';

const formatTittle = (title: string) =>
  title?.length > 250 ? title.substring(0, 250) + '...' : title;

const ticketStatusMap = new Map<string, string>([
  [SupportTicketStatusEnum.new, 'New'],
  [SupportTicketStatusEnum.inProgress, 'In progress'],
  [SupportTicketStatusEnum.closed, 'Closed'],
  [SupportTicketStatusEnum.reopened, 'Re-opened']
]);

const TicketPreview = ({ message }: { message: ChatMessage & TicketMessage }) => {
  const ticket = message.data as SupportTicketEntry;
  const getTicketTitle = (ticket: SupportTicketEntry) => {
    const titleFromMessageContent = ticket?.messages[0]?.content
      ? formatTittle(ticket?.messages[0].content)
      : '';

    return ticket?.messages[0] && ticket?.messages[0].title && ticket?.messages[0].title.length > 0
      ? formatTittle(ticket.messages[0].title)
      : titleFromMessageContent;
  };
  const messageTime = message.created_at.toLocaleDateString();
  const messageTimeFull = dayjs(message.created_at).format('MM/DD/YYYY, HH:mm');
  return (
    <TicketPreviewTile key={message.id}>
      <Tooltip anchorElement='target' position='top' style={{ zIndex: 10005 }}>
        <TicketPreviewTime title={messageTimeFull}>{messageTime}</TicketPreviewTime>
      </Tooltip>
      <TicketPreviewHeader>
        <TicketPreviewHeaderStatuses>
          <TicketStatusBadge status={ticket.status}>
            {ticketStatusMap.get(ticket.status)}
          </TicketStatusBadge>
          <TicketStatusBadge status={ticket.status}>
            {message.data?.messages?.length || 0} responses
          </TicketStatusBadge>
        </TicketPreviewHeaderStatuses>
        <TicketPreviewHeaderCreator>
          <span>Created by</span>
          <Avatar
            style={{ marginLeft: 10, width: '30px', height: '30px', marginRight: '10px' }}
            type='image'>
            {ticket?.sender?.image ? (
              <img src={ticket?.sender?.image ?? CompanyImg} alt='Avatar image' />
            ) : (
              <DefaultAvatar />
            )}
          </Avatar>
          <span>{ticket.sender.name}</span>
        </TicketPreviewHeaderCreator>
      </TicketPreviewHeader>
      <TicketPreviewContent>
        <TicketPreviewContentTitle>Ticket #{ticket.id}</TicketPreviewContentTitle>
        <TicketPreviewContentText>{getTicketTitle(ticket)}</TicketPreviewContentText>
        <Button
          onClick={() => window.location.replace(`/ticket?ticketId=${ticket.id}`)}
          themeColor={'primary'}>
          Go to the ticket
        </Button>
      </TicketPreviewContent>
    </TicketPreviewTile>
  );
};

export default TicketPreview;
