import * as React from 'react';
import { AvatarOverlay, CustomAvatar } from 'adp-panel/features/Chat/components/styled';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { UserEntry } from 'adp-panel/api/users/users.types';
import { rolesNamesMap } from 'adp-panel/utils/definesLocal';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';
import * as svgIcons from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';

type Props = {
  title: string;
  item: UserEntry;
  handleDelete: (item: UserEntry) => void;
  showDeleteButton: boolean;
  image: string | undefined;
  style: React.CSSProperties;
};

const TooltipContentTemplate = ({ item }: { item: UserEntry }) => {
  const role = item?.roles?.[0];
  return (
    <span>
      {item.name} <br /> {rolesNamesMap.get(role?.name)}
    </span>
  );
};

export const ChatAvatar = ({
  title,
  item,
  style,
  image,
  handleDelete,
  showDeleteButton
}: Props) => {
  return (
    <CustomAvatar style={style} title={title}>
      <Tooltip
        content={() => <TooltipContentTemplate item={item} />}
        parentTitle
        anchorElement='target'
        position='top'
        style={{ zIndex: 10005 }}>
        {image ? <img src={image} alt='Patient Avatar' /> : <DefaultAvatar />}
        {showDeleteButton && (
          <AvatarOverlay onClick={() => handleDelete(item)}>
            <SvgIcon icon={svgIcons['xIcon']} size='small' />
          </AvatarOverlay>
        )}
      </Tooltip>
    </CustomAvatar>
  );
};
