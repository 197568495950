import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox from 'components/FormFields/FormComboBox';
import { useForm } from 'react-hook-form';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalStyle } from './styles';
import { useDevicesListInfinite, useDeviceUpdate } from 'adp-panel/hooks/api/useDevices';
import {
  DeviceEntry,
  DeviceExtendOptions,
  DevicesQueryParams
} from '../../api/devices/device.types';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import * as Sentry from '@sentry/react';

const deviceAssignSchema = yup.object().shape({
  device: yup.mixed().test('is-object', VALIDATOR_TEXT.REQUIRED, (value) => {
    return value !== null && typeof value === 'object';
  })
});

const AssignDevicePatientDetailsModal = ({ isModalOpen, handleModalClose, patientId }) => {
  const defaultValues = {
    device: null
  };

  const commonQueryParams: DevicesQueryParams = {
    perpage: 1000
  };

  const queryParamsDevices: DevicesQueryParams = {
    extend: [DeviceExtendOptions.amputee],
    ...commonQueryParams
  };

  const { result: devicesData, isLoading: isLoadingDevices } =
    useDevicesListInfinite(queryParamsDevices);

  const {
    control,
    handleSubmit: handleSubmitDevice,
    reset
  } = useForm<{
    device: any;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(deviceAssignSchema),
    mode: 'onChange',
    shouldUnregister: false
  });

  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();

  const handleSubmit = async (e: any) => {
    const { device = undefined } = e;

    const deviceData = {
      amputee_id: patientId
    };

    try {
      await updateDevice({ data: deviceData, deviceId: device.id });
      reset();
      handleModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <Modal open={isModalOpen} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper title={`Assign Device`}>
        <form onSubmit={handleSubmitDevice(handleSubmit)}>
          <FormWrapper>
            <FormComboBox
              label='Device'
              id={'device'}
              control={control}
              options={devicesData}
              optionLabel={'serial'}
              placeholder='Choose from list'
            />
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-medical-form'
                type='button'
                color='light'
                onClick={handleModalClose}>
                Cancel
              </CustomButton>
              <LoadingButton
                data-testid='submit-patient-form'
                type='submit'
                loading={isLoadingUpdate}>
                <span>Assign device to patient</span>
              </LoadingButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      </FormContentWrapper>
    </Modal>
  );
};

export default AssignDevicePatientDetailsModal;
