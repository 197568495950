import { Paginable } from 'types';

import { UserEntry } from 'adp-panel/api/users/users.types';

export enum AvailableExtendOptions {
  permissions = 'permissions',
  roles = 'roles'
}

export interface AvailableParticipantsQueryParams extends Paginable {
  extend?: AvailableExtendOptions[];
}

export interface AvailableParticipantsEntry
  extends Omit<UserEntry, 'location' | 'devices' | 'creator'> {}
