import { useQuery } from '../../../hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { MfaOptions, MfaRequire } from '../../../api/authentication/authentication.types';
import { LOGIN } from '../../../../constants/routes';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { useAuthStore } from 'reducers/authStore';

const MfaAuth = () => {
  const query = useQuery();
  const code = query.get('code');
  const channel = query.get('channel');
  const navigate = useNavigate();
  const mfaCodeRequire = useAuthStore((state) => state.mfaCodeRequire);

  if (code && channel) {
    const data: MfaRequire = {
      require: true,
      channel: channel as MfaOptions,
      code
    };
    mfaCodeRequire(data);
    navigate(LOGIN, { state: { code: code } });
  }
  return <ConfirmationLoader fullScreen />;
};

export default MfaAuth;
