import * as Sentry from '@sentry/react';
import Login from 'adp-panel/pages/Auth/Login';
import MfaAuth from 'adp-panel/pages/Auth/MfaAuth';
import ResetPassword from 'adp-panel/pages/Auth/ResetPassword';
import SetUpPass from 'adp-panel/pages/Auth/SetUpPass';
import Register from 'configurator/views/Register/Register/Register';
import * as routes from 'constants/routes';
import { Navigate, Route, Routes } from 'react-router-dom';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

type NotProtectedAppProps = {
  loggedIn: boolean;
};

const NotProtectedApp = ({ loggedIn }: NotProtectedAppProps) => {
  return (
    <SentryRoutes>
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path={routes.FORGOTTEN_PASSWORD} element={<ResetPassword />} />
      <Route path={routes.REGISTER} element={<Register />} />
      <Route path={routes.SETUP_PASS} element={<SetUpPass />} />
      {loggedIn && <Route path={routes.CONFIRM_MFA} element={<MfaAuth />} />}
      <Route path='/' element={<Navigate to={routes.LOGIN} replace />} />
      <Route path='*' element={<Navigate to={routes.LOGIN} replace />} />
    </SentryRoutes>
  );
};

export default NotProtectedApp;
