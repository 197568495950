import React, { useMemo } from 'react';
import { Alert } from '@mui/material';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import {
  genericSwitchingMap,
  genericSwitchingReversedMap,
  configSettingsNamesMap,
  gripSwitchingMap
} from 'configurator/utils/definesLocal';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';

const FollowingGrip = ({
  addHistoryProsthesis,
  followingGrip,
  replayIsEnabled,
  setConfigProperty,
  gripSwitchingMode
}: any) => {
  const handleFollowingGrip = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('followingGrip', [genericSwitchingReversedMap.get(value)])
    );
  };
  const warningFollowingGrip = useMemo(() => {
    if (followingGrip[0] === 1 && gripSwitchingMode[0] === GripSwitchingModes.kHoldOpen) {
      return (
        <Alert severity='error' style={{ marginTop: '10px' }}>
          {`Enabling this function might influence behavior of current ${configSettingsNamesMap.get(
            'gripSwitchingMode'
          )}: ${gripSwitchingMap.get(GripSwitchingModes.kHoldOpen)}.
          Switching it off is preferable.`}
        </Alert>
      );
    }
    return null;
  }, [followingGrip, gripSwitchingMode]);

  const FollowingGrip = useMemo(
    () => (
      <InputOptions
        header={configSettingsNamesMap.get('followingGrip')!}
        options={['On', 'Off']}
        id='following-grip'
        onChange={handleFollowingGrip}
        disable={replayIsEnabled}
        // @ts-ignore
        value={genericSwitchingMap.get(followingGrip[0])}
        description='Enable or disable following grip'
        warning={warningFollowingGrip}
      />
    ),
    [followingGrip, gripSwitchingMode]
  );

  return FollowingGrip;
};

export default FollowingGrip;
