import React from 'react';
import IconsSidebar from 'configurator/components/organisms/IconsSidebar.js/IconsSidebar';
import DefaultLayout from 'configurator/layouts/DefaultLayout';
import { Header1 } from 'configurator/views/Servicing/styled';

const LayoutSwitcher = ({
  component: Component,
  header = null,
  handleUndo = null,
  isUnsaved = false,
  headerPadding = '0 0 0 0'
}: any) => {
  return (
    <DefaultLayout>
      {handleUndo && <IconsSidebar undoFunc={handleUndo} unsaved={isUnsaved} />}
      {header && <Header1 style={{ padding: '0 0 20px 0' }}>{header}</Header1>}
      <Component />
    </DefaultLayout>
  );
};

export default LayoutSwitcher;
