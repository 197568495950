import { deleteChatMessage } from 'adp-panel/features/Chat/api/chat';
import { useMutation } from '@tanstack/react-query';
import { useConfirmation } from 'adp-panel/features/Chat/components/Modals/ConfirmModalUsingContext';
import { NotificationFactory } from 'lib/NotificationFactory';

export const useChatMessageDelete = () => {
  const { openConfirmation } = useConfirmation();

  const { data, mutateAsync, isLoading, isError } = useMutation(deleteChatMessage, {
    onSuccess() {
      NotificationFactory.successNotification('Chat message deleted');
    },
    onError(error: any) {
      NotificationFactory.errorNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
