import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';

const InnerWrapper = styled.div`
  width: 550px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;

const TextWrapper = styled.div`
  margin-bottom: 30px;
`;

const LogoutInProgress = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
`;

type AutoLogoutModalProps = {
  resetAction: () => void;
  logoutAction: () => void;
  isLoading: boolean;
  remainingTimeInSeconds: number;
};

let remainingTimeInterval: ReturnType<typeof setInterval> | null = null;

const AutoLogoutModal = ({
  resetAction,
  logoutAction,
  isLoading,
  remainingTimeInSeconds = 10
}: AutoLogoutModalProps) => {
  const [autoLogoutRemainingTime, setAutoLogoutRemainingTime] = useState(remainingTimeInSeconds);

  useEffect(() => {
    if (autoLogoutRemainingTime <= 0) {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
      logoutAction();
    }
  }, [autoLogoutRemainingTime]);

  useEffect(() => {
    remainingTimeInterval = setInterval(() => {
      setAutoLogoutRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
    };
  }, []);

  return (
    <Dialog title={'Are you there?'} onClose={resetAction}>
      <InnerWrapper>
        {isLoading ? (
          <>
            <ConfirmationLoader inline={true} />
            <LogoutInProgress>Logout in progress...</LogoutInProgress>
          </>
        ) : (
          <>
            <TextWrapper>{`Are you still active? We will log you out in ${autoLogoutRemainingTime} seconds due to inactivity.`}</TextWrapper>
            <ButtonsWrapper>
              <Button type='button' onClick={resetAction} themeColor={'primary'}>
                Yes, I&apos;m here
              </Button>
              <Button type='button' onClick={logoutAction} themeColor={'error'}>
                Log me out
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </InnerWrapper>
    </Dialog>
  );
};

export default AutoLogoutModal;
