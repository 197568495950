import * as Sentry from '@sentry/react';
import React from 'react';
import { AxiosError } from 'axios';
import { map } from 'lodash';
import { ToastOptions } from 'react-hot-toast';
import {
  renderNotification,
  ApiErrorNotification
} from 'configurator/components/atoms/ErrorNotification/ApiErrorNotification';
import { API_ERRORS } from 'configurator/api/errors';

type ErrorKey = {
  [prop: string]: string[];
};

interface ApiError {
  message: string;
  errors: ErrorKey[];
  accumulatedErrors: string[];
  code: string;
}

export const handleStatusErrors = (error: any) => {
  const code = error?.request?.status;
  const defaultErrorMessage = `Something went wrong. Please refresh the page or try again later.`;
  let message = defaultErrorMessage;

  if (code >= 500 && code <= 599) {
    Sentry.captureException(error);

    // eslint-disable-next-line default-case
    switch (code) {
      case 503:
        message =
          "Connection cannot be established. This may be due to a firewall block on your computer. Please contact your organization's IT department.";
        break;
    }
  }

  return message;
};

export const parseApiError = (error: AxiosError): ApiError => {
  const getAllMessagesFromError = (): string[] => {
    const errors: string[] = [];
    map(error.response?.data?.errors || {}, (value: string[]) => {
      Array.from(value).forEach((item: string) => {
        errors.push(item);
      });
    });
    return errors;
  };
  return {
    message: error.response?.data?.message || handleStatusErrors(error),
    errors: error.response?.data,
    accumulatedErrors: getAllMessagesFromError(),
    code: error.response?.data?.code
  };
};
export function errorNotification(
  title: string | React.JSX.Element = '',
  description = '',
  options: ToastOptions = {},
  children: React.ReactNode = null
) {
  return renderNotification()(title, description, options, children);
}
export function errorApiNotification(error: any, fallbackErrorMessage: string | null = null) {
  if (!error?.isAxiosError && fallbackErrorMessage) {
    errorNotification(fallbackErrorMessage);
  }
  const parsedError = parseApiError(error);
  if (parsedError.accumulatedErrors) {
    errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
    return errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
  }
  return errorNotification(parsedError.message, '', { id: parsedError.message });
}

export function errorApiNotificationParse(error: any) {
  if (!error) return { message: 'Something went wrong', errors: null };

  let errorMessage: string | null | React.JSX.Element = null;
  const { message, code, accumulatedErrors } = parseApiError(error);
  errorMessage = message;

  if (code) {
    const customMessage = findCustomMessage(code);
    if (customMessage) errorMessage = customMessage;
  }

  return { message: errorMessage, errors: accumulatedErrors, code };
}

export const isNetworkError = (error: any) =>
  error.request?.status && (error?.request?.status < 200 || error?.request?.status >= 300);

export const findCustomMessage = (code) => {
  const [group, action, errorType] = code.split(':');
  const customMessage = API_ERRORS[group]?.[action]?.[errorType]?.message;
  return customMessage;
};

export const mapErrorMessage = (error: any, overrideDefaultMessage?: string) => {
  if (overrideDefaultMessage) {
    errorNotification(overrideDefaultMessage);
    return;
  }

  const code = parseApiError(error)?.code;

  if (code) {
    const customMessage = findCustomMessage(code);

    if (customMessage) {
      errorNotification(customMessage);
      return;
    }
  }

  errorApiNotification(error);
};
