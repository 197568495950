import PatientForm from './PatientForm';
import { RoleEnum } from '../../../api/users/users.types';
import useUserData from '../../../../hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useAddPatient } from '../Hooks/useAddPatient';

const AddPatient = () => {
  const { rolesByName } = useUserData();

  const { handleSubmit, isLoading } = useAddPatient();

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Add patient'} small>
        <PatientForm
          handleSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.clinicAdmin], rolesByName)}
          isLoading={isLoading}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default AddPatient;
