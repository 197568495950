import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Error, Hint } from '@progress/kendo-react-labels';

const FormCheckbox = (fieldRenderProps: any) => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    value,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const fullLabel = (
    <span>
      {label}
      {optional ? <span className={'k-label-optional'}>(Optional)</span> : ''}
    </span>
  );
  const hindId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper style={{ marginTop: '0' }}>
      <Checkbox
        ariaDescribedBy={`${hindId} ${errorId}`}
        label={fullLabel}
        valid={valid}
        value={value}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export default FormCheckbox;
