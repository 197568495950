import VerificationInput from 'react-verification-input';
import './style.css';

const CustomVerificationCodeForm = (fieldRenderProps: any) => {
  const { label, value, onChange, valueGetter } = fieldRenderProps;

  return (
    <VerificationInput
      value={value}
      length={6}
      validChars='A-Z0-9a-z'
      onChange={(e) => onChange({ target: { value: e } })}
      placeholder='-'
      classNames={{
        character: 'character',
        characterInactive: 'character-inactive',
        container: 'container'
      }}
    />
  );
};

export default CustomVerificationCodeForm;
