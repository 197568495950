/* eslint-disable react/jsx-key */
/* eslint-disable no-unreachable */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button, Checkbox, Chip, ListItemText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { ButtonsWrapper, StyledForm } from './styled';
import Card from '../../components/atoms/Card/Card';
import { useUser } from '../../hooks/api/useUsers';
import { useDevice } from '../../hooks/api/useDevice';
import { useServicingParts, useServicingRepair } from '../../hooks/api/useServicing';
import FileInput from '../../components/atoms/Input/FileInput';

const ServicingForm = () => {
  const [files, setFiles] = useState([]);
  const { amputeeId, deviceId } = useDeviceInfoStore((state: any) => ({
    amputeeId: state.amputeeId,
    deviceId: state.deviceId
  }));
  const { result: amputee } = useUser(Number(amputeeId));
  const { result: device } = useDevice(Number(deviceId));
  const [selectedParts, setSelectedParts] = useState<string[]>([]);
  const [selectedReason, setSelectedReason] = useState<any>();
  const { mutateAsync: createServicingRepair, isLoading: isLoadingServicingRepair } =
    useServicingRepair();
  const { result: servicingParts } = useServicingParts({
    perpage: 1000
  });
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append('device_id', String(device?.id));
      formData.append('user_id', String(amputee?.id));

      if (files) {
        files.forEach((file) => {
          formData.append('files[]', file);
        });
      }

      if (selectedParts) {
        servicingParts
          .filter((item: any) => selectedParts.includes(item))
          .map((item: any) => ({ part_id: item.id, reason: e[`reason_${item.id}`] ?? null }))
          .forEach((item: any, index: any) => {
            formData.append(`parts[${index}][part_id]`, item.part_id);
            formData.append(
              `parts[${index}][reason]`,
              item.reason ? item.reason : 'Reason not provided'
            );
          });
      }

      await createServicingRepair(formData);
      navigate('/');
    } catch (error: any) {
      console.log('error', error);
    }
  };

  const handleClose = () => {
    navigate('/');
  };

  const transformReasonForReplacement = () =>
    servicingParts &&
    servicingParts
      .filter((item: any) => selectedParts.includes(item))
      .map((item: any) => ({
        id: item.id,
        name: `for ${item.name}`
      }));

  return (
    <Card style={{ width: 700, marginTop: 30 }}>
      <StyledForm onSubmit={handleSubmit((data) => onSubmit(data))}>
        <TextField
          id='selected-parts'
          select
          label='Parts'
          required
          {...register('parts', { required: 'Select at least one part' })}
          error={Boolean(errors?.parts)}
          helperText={errors?.parts?.message}
          SelectProps={{
            multiple: true,
            onChange: (e: any) => setSelectedParts(e.target.value),
            renderValue: (selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </Box>
            ),
            value: selectedParts,
            MenuProps: {
              sx: { maxHeight: 250 }
            }
          }}>
          {(servicingParts ?? []).map((part: any) => (
            <MenuItem value={part} key={part.id}>
              <Checkbox checked={selectedParts.indexOf(part) > -1} />
              <ListItemText primary={part.name} />
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='selected-reason'
          select
          label='Reason for the replacement'
          {...register('reason')}
          SelectProps={{
            onChange: (e) => setSelectedReason(e.target.value),
            value: selectedReason,
            MenuProps: {
              sx: { maxHeight: 250 }
            }
          }}>
          {(transformReasonForReplacement() ?? []).map((reason: any) => (
            <MenuItem value={reason.id}>{reason.name}</MenuItem>
          ))}
        </TextField>
        {selectedReason && selectedParts?.length > 0 && (
          <TextField
            id={`reason_desc__${selectedReason}`}
            label='Describe the change'
            multiline
            {...register(`reason_${selectedReason}`)}
            rows={6}
          />
        )}
        <span>Upload an attachment</span>
        <FileInput
          register={register}
          setValue={setValue}
          onChange={(files: any) => setFiles(files)}
          unregister={unregister}
          name='attachments'
          mode='update'
          limit={20}
          accept={{ 'image/png': ['.png', '.jpg', '.gif'], 'video/mp4': ['.mp4', '.mov', '.avi'] }}
        />
        <ButtonsWrapper>
          <Button onClick={handleClose} variant='outlined' type='button'>
            Cancel
          </Button>
          <LoadingButton
            type='submit'
            disabled={!selectedParts?.length}
            loading={isLoadingServicingRepair}>
            <span>Send request</span>
          </LoadingButton>
        </ButtonsWrapper>
      </StyledForm>
    </Card>
  );
};

export default ServicingForm;
