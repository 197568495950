import api, { withCredentials, withCredentialsAndMultipartForm } from '../utils/apiClient';
import { CompatibilitiesQueryParams, CompatibilityEntry, VersionEntry } from './version.types';
import { PaginatedResponse } from '../../../types';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { convertToFormData } from '../utils/apiUtils';

export const GET_SOFTWARE_VERSION = 'api/versions/software';
export const GET_FIRMWARE_VERSION = 'api/versions/firmware';
export const CREATE_FIRMWARE_VERSION = 'api/versions/firmware';
export const GET_PCB_VERSION = 'api/versions/pcb';
export const CREATE_PCB_VERSION = 'api/versions/pcb';
export const DELETE_SOFTWARE_VERSION = 'api/versions/software/{id}';
export const GET_COMPATIBILITIES = 'api/versions/compatibility';
export const CREATE_COMPATIBILITY = 'api/versions/compatibility';
export const UPDATE_COMPATIBILITY = 'api/versions/compatibility/{id}';
export const DELETE_COMPATIBILITY = 'api/versions/compatibility/{id}';

export const getSoftwareVersion = (): Promise<any> => {
  return api.get<VersionEntry>(GET_SOFTWARE_VERSION, withCredentials).then((res: any) => res.data);
};

export const createSoftwareVersion = (name: string): Promise<any> => {
  return api
    .post<VersionEntry>(GET_SOFTWARE_VERSION, { name }, withCredentials)
    .then((res: any) => res.data);
};

export const deleteSoftwareVersion = (entryId: number): Promise<any> => {
  return api
    .delete<VersionEntry>(DELETE_SOFTWARE_VERSION.replace('{id}', String(entryId)), withCredentials)
    .then((res: any) => res.data);
};

export const getFirmwareVersion = (): Promise<any> => {
  return api
    .get<VersionEntry>(`${GET_FIRMWARE_VERSION}`, withCredentials)
    .then((res: any) => res.data);
};

export const createFirmwareVersion = (payload: any): Promise<any> => {
  return api
    .post<VersionEntry>(
      CREATE_FIRMWARE_VERSION,
      convertToFormData(payload),
      withCredentialsAndMultipartForm
    )
    .then((res: any) => res.data);
};

export const getPCBVersion = (): Promise<any> => {
  return api.get<VersionEntry>(`${GET_PCB_VERSION}`, withCredentials).then((res: any) => res.data);
};

export const createPCBVersion = (name: string): Promise<any> => {
  return api
    .post<VersionEntry>(CREATE_PCB_VERSION, { name }, withCredentials)
    .then((res: any) => res.data);
};

export const getCompatibilities = ({
  params = {}
}: {
  params?: CompatibilitiesQueryParams;
}): Promise<any> =>
  api
    .get<PaginatedResponse<CompatibilityEntry>>(
      `${GET_COMPATIBILITIES}?${qs.stringify({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);

export const createCompatibility = (payload: any): Promise<any> => {
  return api
    .post<VersionEntry>(CREATE_COMPATIBILITY, payload, withCredentials)
    .then((res: any) => res.data);
};

export const updateCompatibility = ({ compatibilityId, payload }: any): Promise<any> => {
  return api
    .put<VersionEntry>(
      UPDATE_COMPATIBILITY.replace('{id}', String(compatibilityId)),
      payload,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const deleteCompatibility = (entryId: number): Promise<any> => {
  return api
    .delete<VersionEntry>(DELETE_COMPATIBILITY.replace('{id}', String(entryId)), withCredentials)
    .then((res: any) => res.data);
};
