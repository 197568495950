/* eslint-disable no-unused-vars */
import { useContext, useRef, useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import { CONFIGURATOR_DEVICE, DEVICE_EDIT, PATIENTS } from '../../../constants/routes';
import { useDeviceDelete } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import Card from 'adp-panel/components/Card/Card';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { AddDeviceModalContext } from 'adp-panel/hoc/ModalProvider';
import CustomTable from 'adp-panel/components/Table/CustomTable';
import { useUsers } from 'adp-panel/hooks/api/useUsers';
import { UsersQueryParams, UserExtendOptions, UserEntry } from 'adp-panel/api/users/users.types';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';
import { ReactComponent as SendSvg } from 'assets/send.svg';
import { ReactComponent as SlidersSvg } from 'assets/sliders.svg';
import CustomButton from 'components/Button/CustomButton';
import InviteMedicalProfessionalModal from 'adp-panel/components/Modals/InviteMedicalProfessionalModal';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { TableLink } from 'adp-panel/components/Links/Links';
import PatientsModal from 'adp-panel/components/Modals/PatientsModal';
import { Chip } from '@mui/material';
import CustomChip from 'components/CustomChip/CustomChip';

const dummyPatients = [
  { id: 2, name: 'Patient 1' },
  { id: 3, name: 'Patient 3' },
  { id: 4, name: 'Patient 4' }
];

const mapDeviceRows = (apiData: UserEntry[]) =>
  apiData.map((medicalProfessional) => ({
    id: medicalProfessional.id,
    name: medicalProfessional.name,
    email: medicalProfessional.email,
    role: medicalProfessional.role_name,
    amputees: dummyPatients,
    status: true
  }));

const MedicalProfessionalsList = () => {
  const { data: userData, rolesByName } = useUserData();
  const [selectedDevice, setSelectedDevice] = useState<number>();
  const navigate = useNavigate();
  const tableRef = useRef(null) as any;
  const { showAddDeviceModal } = useContext(AddDeviceModalContext);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const queryParams: UsersQueryParams = {
    perpage: 10,
    page: paginationModel.page + 1,
    extend: [UserExtendOptions.roles, UserExtendOptions.permissions],
    roles: [RoleEnum.clinicAdmin, RoleEnum.clinicianSupport]
  };

  const {
    result: medicalProfessionalsData,
    total: totalMedicalProfessionals,
    isLoading: isLoadingMedicalProfessionals
  } = useUsers(queryParams);

  console.log(medicalProfessionalsData);

  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const deleteDev = async () => {
    if (selectedDevice) {
      await deleteDevice(selectedDevice);
      handleModalClose();
      tableRef.current.refresh();
    }
  };

  const handleConfigure = (serial: string, bluetooth_id: string, deviceId: string) => {
    navigate(CONFIGURATOR_DEVICE, { state: { serial, bluetoothId: bluetooth_id, deviceId } });
  };

  return (
    <DefaultLayout>
      <Card>
        <div>
          <PatientsModal
            handleClose={handleModalClose}
            isModalOpen={isModalOpen}
            patients={dummyPatients}
          />
          <CustomTable
            actions={
              <CustomButton
                Icon={AddUserSvg}
                color='light'
                onClick={() => openModal(MODALS.inviteMedicalProfessional)}>
                Invite new Medical Professional
              </CustomButton>
            }
            tableData={medicalProfessionalsData}
            tableHeader='List of Medical Professionals'
            totalItems={totalMedicalProfessionals}
            isLoading={isLoadingMedicalProfessionals}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1
              },
              { field: 'email', headerName: 'E-mail', flex: 1, sortable: false },
              { field: 'role', headerName: 'Role', flex: 1, sortable: false },
              {
                field: 'amputees',
                headerName: 'Patients',
                flex: 1,
                renderCell: (params) => {
                  const { amputees } = params.row;
                  const amputeesArray = amputees?.slice(0, 2);
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      {amputeesArray?.map((amputee, index) => {
                        const notLast = index + 1 !== amputeesArray.length;
                        const patientName = notLast ? `${amputee?.name}, ` : amputee?.name;
                        return (
                          <TableLink to={`${PATIENTS}/${amputee?.id}`}>{patientName}</TableLink>
                        );
                      })}
                      {amputees.length > 2 && (
                        <span>
                          <CustomButton
                            color='light'
                            sx={{ minWidth: 0, width: '36px', height: '36px', marginLeft: '8px' }}
                            onClick={handleModalOpen}>
                            +{amputees.length - 2}
                          </CustomButton>
                        </span>
                      )}
                    </div>
                  );
                }
              },
              {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params) => <CustomChip label='Active' color='success' size='small' />
              },
              {
                field: 'actions',
                type: 'actions',
                flex: 1,
                headerName: 'Actions',
                getActions: (params) => {
                  const { serial, bluetooth_id, role, id } = params.row;
                  const sendAgain = false;

                  let actionsArray = [
                    <CustomButton
                      Icon={AddUserSvg}
                      color='light'
                      onClick={() => navigate(DEVICE_EDIT, { state: { detail: id } })}
                      data-testid='button-edit'
                    />
                  ];

                  if (sendAgain)
                    actionsArray = [
                      <CustomButton Icon={SendSvg} color='light'>
                        Send again
                      </CustomButton>,
                      ...actionsArray
                    ];

                  if (role === 'ClinicianSupport')
                    actionsArray = [
                      <CustomButton Icon={SlidersSvg} color='light'>
                        Edit permissions
                      </CustomButton>,
                      ...actionsArray
                    ];

                  return actionsArray;
                }
              }
            ]}
            mapTableData={mapDeviceRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default MedicalProfessionalsList;
