/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, MenuItem, TextField, DialogContent, Tooltip, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import { GripSwitchingModes, InputSites } from 'bluetooth/Bluetooth/Control';
import {
  DeviceConfigTemplate,
  CommonConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { getCurrentConfigApiSelector } from 'configurator/reducers/helpers/selectors';
import {
  configSettingsNamesMap,
  gripSwitchingMap,
  inputSiteMap
} from 'configurator/utils/definesLocal';
import { useConfigStore } from 'configurator/reducers/configStore';
import { transformCompared } from 'configurator/utils/Config/transformConfig';
import ModalBase from './ModalBase';
import { ChangesWrapper } from './RestoreConfigHistoryModal';
import { TableBody, TableHeader } from 'configurator/views/DeviceConfig/DeviceConfig/styled';
import { ConfigHistoryItem } from 'configurator/components/organisms/Config/ConfigHistoryItem';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 24px;
`;

type ConfirmAbortModeProps = {
  handleAccept: Function;
  handleClose: any;
  header: string;
  entry: DeviceConfigTemplate;
  isLoading: boolean;
};

const isPossibleConfigIssueAfterImport = (
  currentConfig: CommonConfigTemplate,
  importedConfig: DeviceConfigTemplate
) => {
  const importedInputSite = importedConfig?.inputSite;

  if (importedInputSite) return;

  const currentInputSiteValue = currentConfig?.inputSite?.[0];
  // @ts-ignore
  const importedGripSwitchingModeValue = importedConfig?.gripSwitchingMode[0];

  const wrongGripSwitchingMode =
    currentInputSiteValue === InputSites.kSingleElectrode &&
    importedGripSwitchingModeValue !== GripSwitchingModes.kSingleGripSwitching;

  const wrongInputSite =
    currentInputSiteValue !== InputSites.kSingleElectrode &&
    importedGripSwitchingModeValue === GripSwitchingModes.kSingleGripSwitching;

  return wrongGripSwitchingMode || wrongInputSite;
};

const ImportTemplateModal = ({
  handleAccept,
  handleClose,
  header,
  entry,
  isLoading
}: ConfirmAbortModeProps) => {
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const configStoreState = useConfigStore();
  const { common, modes } = configStoreState.config;
  const selectedModeConfig = getCurrentConfigApiSelector(configStoreState, selectedModeSlot);

  const configNow = {
    ...common.configAPI,
    ...modes.find((mode: any) => mode.slot === selectedModeSlot)?.configAPI
  } as DeviceConfigTemplate;
  const compared = transformCompared(configNow, entry);

  return (
    <ModalPortal>
      <ModalBase handleClick={handleClose} header={header}>
        <DialogContent>
          {modes && (
            <TextField
              fullWidth
              id='selected-mode'
              label='Selected mode'
              select
              SelectProps={{
                value: selectedModeSlot,
                onChange: (e: any) => setSelectedModeSlot(e.target.value)
              }}>
              {modes.map((mode: any) => (
                <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                  {mode.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <ParagraphBold>
            Do you want to import this template, and send it to patient?
          </ParagraphBold>
          {isPossibleConfigIssueAfterImport(configNow, entry) && (
            <Alert severity='warning' style={{ marginBottom: '24px' }}>
              {`You are trying to import ${configSettingsNamesMap.get(
                'gripSwitchingMode'
              )}: ${gripSwitchingMap.get(
                // @ts-ignore
                entry.gripSwitchingMode[0]
              )} that conflicts with current ${configSettingsNamesMap.get(
                'inputSite'
              )}: ${inputSiteMap.get(selectedModeConfig?.inputSite?.[0])}`}
            </Alert>
          )}
          {compared?.length > 0 && (
            <ChangesWrapper>
              <TableBody sticky>
                <TableHeader>Configuration</TableHeader>
                <TableHeader>Being sent</TableHeader>
                <TableHeader>Current</TableHeader>
              </TableBody>
              {compared.map((entry: any) => (
                <ConfigHistoryItem
                  name={entry.key}
                  configName={entry.key}
                  after={entry.value}
                  now={configNow}
                />
              ))}
            </ChangesWrapper>
          )}
          <ButtonsWrapper>
            <Button onClick={handleClose} type='button' variant='outlined'>
              Cancel
            </Button>
            <Tooltip title={compared?.length !== 0 ? null : 'No changes to import'}>
              <span>
                <LoadingButton
                  type='button'
                  onClick={() =>
                    handleAccept(modes.find((mode: any) => mode.slot === selectedModeSlot)?.id)
                  }
                  loading={isLoading}
                  disabled={compared?.length === 0}>
                  <span>Yes, send</span>
                </LoadingButton>
              </span>
            </Tooltip>
          </ButtonsWrapper>
        </DialogContent>
      </ModalBase>
    </ModalPortal>
  );
};

export default ImportTemplateModal;
