import React from 'react';
import { Button, DialogContent } from '@mui/material';
import styled from 'styled-components';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { PCB_REPLACEMENT_VIDEO } from 'configurator/consts/consts';

const Wrapper = styled.div`
  display: grid;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ListWrapper = styled.ol`
  li {
    margin-bottom: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 16px;
`;

const StyledVideo = styled.video`
  width: 100%;
  padding-right: 16px;
`;

const ParagraphBold = styled.p`
  ${HeaderM};
  margin-bottom: 8px;
`;

type PcbReplacementInstructionProps = {
  handleNext: any;
  handlePrevious: any;
};

const PcbReplacementInstruction = ({
  handleNext,
  handlePrevious
}: PcbReplacementInstructionProps) => (
  <DialogContent>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Wrapper>
        <ParagraphBold>How to replace a PCB?</ParagraphBold>
        <StyledVideo controls>
          <source src={PCB_REPLACEMENT_VIDEO} type='video/mp4' />
        </StyledVideo>
        <ListWrapper style={{ listStylePosition: 'inside', paddingLeft: '0' }}>
          <li>
            Turn off the battery and disconnect the device from the socket. Lay the device on the
            table inner palm facing down.
          </li>
          <li>
            Unscrew 6 torx screws from the wrist, pull the wrist out of the device and disconnect
            the coaxial socket cable.
          </li>
          <li>
            Unscrew 4 torx screws from the outer palm. Open the device by picking up the outer palm.
          </li>
          <li>
            Disconnect 2 pin and/or 3 pin connectors if they are present. Disconnect the motor
            cables from the PCB.
          </li>
          <li>Unscrew 3|4 screws that are fixing the PCB in place. Pull out the PCB.</li>
          <li>
            Place the new PCB inside the device. Mind the cable placements, do not pinch any cable
            with PCB. Screw 3|4 PCB screws back.
          </li>
          <li>
            Connect the motor cables back to the PCB. In PCB 3v4 the thumb motor socket is the one
            in the middle, in PCB 4v1 it is located in the centre on the bottom side of the PCB.
            Other motor sockets are directly below the corresponding motors. Lay the cables flat.
            Connect 2 pin and/or 3 pin connectors if they are present.
          </li>
          <li>
            Place the outer palm back on the device. It should fit perfectly in its place. If the
            outer palm sticks out, check if the wires are placed correctly. Screw 4 torx screws in
            the outer palm.
          </li>
          <li>
            Connect coaxial socket cable, fit the wrist to the device. Screw 6 torx screws back to
            the wrist.
          </li>
        </ListWrapper>
      </Wrapper>
      <ButtonsWrapper>
        <Button type='button' onClick={handlePrevious} variant='outlined'>
          Previous step
        </Button>
        <Button type='button' onClick={handleNext}>
          Next step
        </Button>
      </ButtonsWrapper>
    </div>
  </DialogContent>
);

export default PcbReplacementInstruction;
