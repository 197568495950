import styled from 'styled-components';

export const commonFormStyles = {
  labelMarginBottom: '5px'
};

export const LabelStyle = {
  color: '#344054',
  fontWeight: '500'
};

export const LabelWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const FormWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

export const FormColumnWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 170px auto;
`;

export const FormModalCheckboxesList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
