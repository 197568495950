import { Paginable, Sortable } from '../../../types';

export enum RawFilesSortOptions {
  order = 'order'
}

export enum PeriodEnum {
  day = 'day',
  week = 'week',
  month = 'month'
}

export enum EmgPeakPeriodEnum {
  day = 'day',
  month = 'month',
  all = 'all'
}

export enum VelocityLevelGroupEnum {
  day = 'day',
  month = 'month',
  hourly = 'hourly',
  none = 'none'
}

export enum GroupEnum {
  hourly = 'hourly',
  day = 'day',
  week = 'week',
  month = 'month',
  grip = 'grip'
}

export enum TimeSpentGroupEnum {
  none = 'none',
  day = 'day'
}

export interface UsageMonitoryQueryParams {
  deviceId?: number;
  period?: PeriodEnum;
  date_from?: string;
  date_to?: string;
  group?: GroupEnum;
  grips?: number[];
}

export interface EmgPeakChartQueryParams {
  deviceId?: number;
  period?: EmgPeakPeriodEnum;
  date_from: string;
  date_to?: string;
}

export interface TimeSpentChartQueryParams {
  deviceId?: number;
  date_from: string;
  date_to?: string;
  group?: TimeSpentGroupEnum;
}

export interface VelocityLevelChartQueryParams {
  deviceId?: number;
  group?: VelocityLevelGroupEnum;
  date_from: string;
  date_to?: string;
}

export interface VelocityLevelChartRecord {
  group_by?: string | number;
  device_id: number;
  grip: number;
  velocity_open_1: string;
  velocity_open_2: string;
  velocity_open_3: string;
  velocity_close_1: string;
  velocity_close_2: string;
  velocity_close_3: string;
}

export interface RawFilesQueryParams extends Paginable, Sortable<RawFilesSortOptions> {}
