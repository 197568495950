import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createLocation,
  createLocationOpeningHours,
  deleteLocation,
  deleteLocationOpeningHours,
  getLocation,
  getLocationOpeningHours,
  getLocations,
  updateLocation
} from '../../api/locations/locations';
import { mapErrorMessage } from '../../utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';

export const LOCATIONS_QUERY_KEY = 'locations';
const LOCATION_QUERY_KEY = 'location';
const LOCATION_OPENING_HOURS_QUERY_KEY = 'location_opening_hours';

export const useLocations = (queryParams?: any, dependency: any = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [LOCATIONS_QUERY_KEY, queryParams],
    () => getLocations(queryParams),
    {
      enabled: dependency,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useLocationsInfinite = (queryParams?: any, dependency: any = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [LOCATIONS_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getLocations({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCompanyLocation = (locationId: number, queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [LOCATION_QUERY_KEY],
    () => getLocation(locationId, queryParams),
    {
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useLocationOpeningHours = (locationId: number | null, format: string) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [LOCATION_OPENING_HOURS_QUERY_KEY, [locationId, format]],
    () => getLocationOpeningHours(locationId, format),
    {
      enabled: Boolean(locationId),
      onError() {
        NotificationFactory.errorNotification('Failed to fetch location opening hours');
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useLocationCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createLocation, {
    onSuccess() {
      NotificationFactory.successNotification('Location created');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useLocationOpeningHoursCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createLocationOpeningHours, {
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useLocationUpdate = (successMessage: string = 'Location edited') => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateLocation, {
    onSuccess() {
      NotificationFactory.successNotification(successMessage);
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useLocationArchive = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateLocation, {
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useLocationDelete = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteLocation, {
    onSuccess() {
      NotificationFactory.successNotification('Location deleted');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useLocationOpeningEntryDelete = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteLocationOpeningHours, {
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
