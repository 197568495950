import React from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
  Avatar
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';
import {
  AutocompleteRenderOptionState,
  AutocompleteOwnerState,
  TextFieldProps
} from '@mui/material';
import { stringAvatar, stringToColor } from 'adp-panel/components/PatientCard/PatientCard';

interface FormComboBoxProps {
  id: string;
  label: string;
  control?: any;
  options: any;
  optionLabel: string;
  onChange?: any;
  disabled?: boolean;
  loading?: boolean;
  isOptionEqualToValue?: any;
  optional?: boolean;
  value?: any;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<any, false, false, false, 'div'>
  ) => React.ReactNode;
  multiple?: any;
  nameProperty?: string;
  showLabel?: boolean;
  placeholder?: string;
}

export const clinicianOption = (props, option, nameFullProperty, nameProperty) => {
  const { ...optionProps } = props;

  if (!option) return;

  return (
    <li
      style={{ display: 'flex', alignItems: 'center' }}
      {...optionProps}
      key={option[nameFullProperty]}>
      <Avatar
        {...stringAvatar(option[nameProperty])}
        sx={{
          bgcolor: stringToColor(option[nameProperty]),
          fontSize: 12,
          width: 28,
          height: 28
        }}
      />
      <div style={{ marginLeft: '8px' }}>{option[nameFullProperty]}</div>
    </li>
  );
};

const UserComboBoxAvatar = ({ name }: { name: string }) => (
  <Avatar
    {...stringAvatar(name)}
    sx={{
      bgcolor: stringToColor(name),
      fontSize: 12,
      width: 28,
      height: 28,
      position: 'absolute',
      transform: 'translateY(5px)',
      marginLeft: '16px'
    }}
  />
);

const FormComboBox = ({
  id,
  label,
  control,
  options,
  optionLabel,
  onChange,
  disabled = false,
  loading = false,
  isOptionEqualToValue,
  optional = false,
  value = undefined,
  multiple,
  nameProperty,
  renderOption,
  showLabel = true,
  placeholder = ''
}: FormComboBoxProps) => {
  const ComboBoxLabel = () => (
    <div style={{ display: 'flex', gap: '8px' }}>
      <InputLabel
        shrink={false}
        htmlFor={id}
        sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
        <LabelWrapper>
          <Typography sx={{ ...LabelStyle }}>{label}</Typography>
          {optional && <Typography>(optional)</Typography>}
        </LabelWrapper>
      </InputLabel>
    </div>
  );

  const AutocompleteCommonProps = {
    multiple,
    disablePortal: true,
    id,
    options: options ? options : [],
    disabled,
    getOptionLabel: (option) => option[optionLabel],
    renderOption,
    isOptionEqualToValue,
    loading
  };

  const TextFieldCommonProps: TextFieldProps = {
    InputLabelProps: {
      shrink: true
    },
    fullWidth: true,
    size: 'small',
    variant: 'outlined',
    id,
    name: id,
    placeholder
  };

  return control ? (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => {
        const selectedValue = value !== undefined ? value : field.value;
        return (
          <div>
            {showLabel && <ComboBoxLabel />}
            <Autocomplete
              {...field}
              onChange={(e, data) => {
                field.onChange(data);
                if (onChange) onChange(data);
              }}
              value={selectedValue || undefined}
              {...AutocompleteCommonProps}
              renderInput={(params) => {
                return (
                  <div style={{ position: 'relative' }}>
                    {nameProperty && (field?.value?.[nameProperty] || value?.[nameProperty]) && (
                      <UserComboBoxAvatar
                        name={field?.value?.[nameProperty] || value?.[nameProperty]}
                      />
                    )}
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                      inputProps={{
                        ...params.inputProps,
                        ...(nameProperty &&
                          (field?.value?.[nameProperty] || value?.[nameProperty]) && {
                            style: { paddingLeft: '44px' }
                          })
                      }}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      {...TextFieldCommonProps}
                    />
                  </div>
                );
              }}
            />
          </div>
        );
      }}
    />
  ) : (
    <div>
      {showLabel && <ComboBoxLabel />}
      <Autocomplete
        onChange={(e, data) => {
          if (onChange) onChange(data);
        }}
        value={value}
        {...AutocompleteCommonProps}
        renderInput={(params) => {
          return (
            <div style={{ position: 'relative' }}>
              {nameProperty && value?.[nameProperty] && (
                <UserComboBoxAvatar name={value?.[nameProperty]} />
              )}
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
                inputProps={{
                  ...params.inputProps,
                  ...(nameProperty &&
                    value?.[nameProperty] && {
                      style: { paddingLeft: '44px' }
                    })
                }}
                {...TextFieldCommonProps}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default FormComboBox;
