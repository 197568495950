import { useState } from 'react';
import { PageState } from '../../types';
import { GridPageChangeEvent } from '@progress/kendo-react-grid';

export const useTablePages = (initialSkip: number = 0, perPage: number = 12) => {
  const [page, setPage] = useState<PageState>({ skip: initialSkip, take: perPage });

  const pageChange = async (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  return {
    page,
    pageSize: perPage,
    perPage: page?.take,
    currentPage: (page?.skip + page?.take) / page?.take,
    pageChange,
    setPage
  };
};
