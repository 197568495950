import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { startOnStartupMap, startOnStartupReversedMap } from 'configurator/utils/definesLocal';
import { generalHandSettingsEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';

const CsBeforeStartup = ({
  addHistoryProsthesis,
  generalHandSettings,
  startupSignalPermission,
  setConfigProperty,
  disable
}: {
  addHistoryProsthesis: any;
  generalHandSettings: generalHandSettingsEntry;
  startupSignalPermission: any;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const handleStartupSignal = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('generalHandSettings', [
        generalHandSettings[0],
        generalHandSettings[1],
        // @ts-ignore
        startOnStartupReversedMap.get(value)
      ])
    );
  };

  const CsBeforeStartup = useMemo(
    () => (
      <InputOptions
        header='Wait for CS before startup'
        data-tour='startup-signal'
        options={['On', 'Off']}
        id='startup-signal'
        onChange={handleStartupSignal}
        disable={disable}
        // @ts-ignore
        value={startOnStartupMap.get(generalHandSettings[2])}
        description='If selected, device will await a CS signal before startup'
      />
    ),
    [generalHandSettings, startupSignalPermission, disable]
  );

  return CsBeforeStartup;
};

export default CsBeforeStartup;
