import {
  ACCOUNT,
  DASHBOARD,
  DEVICE,
  FEATURE_TOGGLES,
  MEDICAL_PROFESSIONALS,
  PATIENTS,
  RELEASES
} from '../../constants/routes';
import { RoleEnum } from '../api/users/users.types';
import { DEVICES_PERMISSIONS } from './rolesPermissions';
import { ReactComponent as DeviceSvg } from 'assets/device.svg';
import { ReactComponent as HomeSvg } from 'assets/dashboard.svg';
import { ReactComponent as MedicalSvg } from 'assets/medical.svg';
import { ReactComponent as CogSvg } from 'assets/cog_menu.svg';
import { ReactComponent as LogoutSvg } from 'assets/logout.svg';

export interface MenuItem {
  name: string;
  url?: string;
  disabled?: boolean;
  roles?: RoleEnum[];
  items?: MenuItem[];
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  action?: any;
}

export const MENU_ACTIONS = {
  logout: 'logout',
  inviteNewMedicalProfessional: 'inviteNewMedicalProfessional'
};

export const MENU_CONFIG: MenuItem[] = [
  {
    name: 'Dashboard',
    url: DASHBOARD,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: HomeSvg
  },
  {
    name: 'Devices',
    url: DEVICE,
    roles: DEVICES_PERMISSIONS.LIST,
    icon: DeviceSvg
  },
  {
    name: 'Patients',
    url: PATIENTS,
    roles: [RoleEnum.superAdmin],
    icon: DeviceSvg
  },
  {
    name: 'Medical Professionals',
    roles: [RoleEnum.superAdmin],
    icon: MedicalSvg,
    url: MEDICAL_PROFESSIONALS
  },
  {
    name: 'Medical Professionals',
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    icon: MedicalSvg,
    items: [
      {
        name: 'List of medical professionals',
        url: MEDICAL_PROFESSIONALS,
        roles: [RoleEnum.clinician, RoleEnum.clinicAdmin]
      },
      {
        name: 'Invite new',
        action: MENU_ACTIONS.inviteNewMedicalProfessional,
        roles: [RoleEnum.clinician, RoleEnum.clinicAdmin]
      }
    ]
  }
  // {
  //   name: 'Product',
  //   url: RELEASES,
  //   roles: [RoleEnum.superAdmin],
  //   items: [
  //     {
  //       name: 'Version mapping',
  //       url: RELEASES,
  //       roles: [RoleEnum.superAdmin],
  //       icon: DeviceSvg
  //     },
  //     {
  //       name: 'Feature toggles',
  //       url: FEATURE_TOGGLES,
  //       roles: [RoleEnum.superAdmin],
  //       icon: DeviceSvg
  //     }
  //   ],
  //   icon: DeviceSvg
  // }
];

export const MENU_BOTTOM_CONFIG: MenuItem[] = [
  {
    name: 'Account settings',
    url: ACCOUNT,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: CogSvg
  },
  {
    name: 'Logout',
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: LogoutSvg,
    action: MENU_ACTIONS.logout
  }
];
