import { DASHBOARD, WELCOME } from 'constants/routes';
import useUserData from 'hooks/useUserData';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const GeneralRedirect = () => {
  const { rolesByName } = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('redirectUrl')) {
      const redirectUrl = localStorage.getItem('redirectUrl') || DASHBOARD;
      navigate(redirectUrl);
      setTimeout(() => {
        if (localStorage.getItem('redirectUrl') === redirectUrl) {
          localStorage.removeItem('redirectUrl');
        }
      }, 5000);
      return;
    }
    navigate(DASHBOARD);
  }, [rolesByName]);

  return null;
};

export default GeneralRedirect;
