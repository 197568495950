import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserUpdate } from 'adp-panel/hooks/api/useUsers';
import { UserRoles } from 'types';
import { isNil, omitBy } from 'lodash';
import { RoleEnum, UserUpdatePayload } from 'adp-panel/api/users/users.types';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import PatientForm from './PatientForm';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from 'adp-panel/utils/parsePhone';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import useUserData from 'hooks/useUserData';
import * as Sentry from '@sentry/react';
import {
  CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY,
  CHAT_ROOMS_QUERY_KEY
} from 'adp-panel/features/Chat/hooks/api/useChatRooms';
import { useQueryClient } from '@tanstack/react-query';
import { countryCodeMap, countryCodeReversedMap } from 'adp-panel/utils/definesLocal';
import { NotificationFactory } from 'lib/NotificationFactory';
import { PATIENTS } from 'constants/routes';
import { useState } from 'react';

const EditPatient = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { rolesByName } = useUserData();
  const { state }: any = useLocation();
  const { detail: user = undefined }: any = state;
  const { mutateAsync: editUser } = useUserUpdate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async ({
    name = undefined,
    email,
    phone = undefined,
    phone_code = undefined,
    location,
    clinician,
    mrn
  }: any) => {
    try {
      setIsLoading(true);
      await editUser(
        omitBy(
          {
            id: user.id,
            name,
            ...(mrn !== user.mrn && { mrn }),
            email: user.email === email ? undefined : email,
            ...(phone && { phone: `${countryCodeReversedMap.get(phone_code)}-${phone}` }),
            ...(location && { location: location?.id || null }),
            role: UserRoles.amputee,
            ...(clinician && { clinician: clinician.id })
          },
          isNil
        ) as UserUpdatePayload
      );
      await queryClient.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      await queryClient.invalidateQueries([CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY]);
      NotificationFactory.successNotification('User updated');
      navigate(PATIENTS);
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to update patient');
    } finally {
      setIsLoading(false);
    }
  };

  const transformInitialData = (userData: any) => {
    const phoneCode = parseCountryCodeFromAPI(userData?.phone);
    return {
      ...userData,
      phone_code: phoneCode ? countryCodeMap.get(phoneCode) : '',
      phone: parsePhoneFromAPI(userData?.phone)
    };
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Edit patient'} small>
        <PatientForm
          handleSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          existingData={transformInitialData(user)}
          isLoading={isLoading}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default EditPatient;
