import { WidgetContainer } from 'adp-panel/features/Chat/components/styled';
import React, { useEffect, useState } from 'react';
import ChatRoomList from 'adp-panel/features/Chat/components/ChatRoomList';
import { configureChatClient, unmountChatClient } from 'adp-panel/api/utils/ablyClient';
import ChatRoom from 'adp-panel/features/Chat/components/ChatRoom';
import { useModal } from 'adp-panel/hooks/api/useModal';
import useUserData from 'hooks/useUserData';
import { useChatRooms } from 'adp-panel/features/Chat/hooks/api/useChatRooms';
import { ChatRoomEntry, ChatRoomsExtendOptions } from 'adp-panel/features/Chat/api/chat.types';
import { useUnreadMessages } from 'adp-panel/features/Chat/hooks/api/useUnreadMessages';
import ChatRoomCreateModal from 'adp-panel/features/Chat/components/ChatRoomCreateModal/ChatRoomCreateModal';
import ChatRoomListHeader from 'adp-panel/features/Chat/components/ChatHeaders/ChatRoomListHeader';
import ChatRoomHeader from 'adp-panel/features/Chat/components/ChatHeaders/ChatRoomHeader';
import useChatRoomsWithUnreadMessages from 'adp-panel/features/Chat/hooks/useUnreadMessageForRooms';
import { ConfirmationModalProvider } from 'adp-panel/features/Chat/components/Modals/ConfirmModalUsingContext';
import { useLockBodyScroll } from 'adp-panel/features/Chat/hooks/useLockBodyScroll';
import { initializeRoomConfig } from 'adp-panel/features/Chat/config/RoomConfig';
import { authAblyClient } from 'adp-panel/api/authentication/authentication';

const ChatWidget = () => {
  const userData = useUserData();
  const { lock, unlock } = useLockBodyScroll();
  const {
    result: rooms,
    refetch: refetchRoomList,
    isLoading: isLoadingRoomList
  } = useChatRooms(true, {
    extend: [
      ChatRoomsExtendOptions.participants,
      ChatRoomsExtendOptions.owner,
      ChatRoomsExtendOptions.patient
    ]
  });
  const { result: unreadMessages } = useUnreadMessages({}, true);
  const roomsWithUnreadMessages = useChatRoomsWithUnreadMessages(rooms ?? []);

  const [currentRoom, setCurrentRoom] = useState<any>();
  const [roomView, setRoomView] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const {
    isOpen: isCreateModalOpen,
    handleOpen: handleCreateOpenModal,
    handleClose: handleCreateCloseModal
  } = useModal();

  useEffect(() => {
    configureChatClient({
      authCallback: async (tokenParams, callback) => {
        try {
          const tokenRequest = await authAblyClient();
          callback(null, tokenRequest);
        } catch (error: any) {
          callback(error, null);
        }
      }
    });

    return () => {
      unmountChatClient();
    };
  }, []);

  const roomSelected = (room: ChatRoomEntry) => {
    setCurrentRoom(room);
    initializeRoomConfig(
      room.name,
      room.name.replace('.messages.', '.recipients.'),
      room.encryption_key
    );
    setRoomView(true);
  };

  const backToRoomList = () => {
    refetchRoomList();
    setRoomView(false);
  };

  const toggleChatSize = () => {
    setIsMinimized((prev) => !prev);
  };

  const toggleFullScreenMode = () => {
    if (!isFullScreen) {
      document.body.style.overflow = 'hidden';
      setIsFullScreen(true);
      return;
    }
    document.body.style.overflow = '';
    setIsFullScreen(false);
  };

  useEffect(() => {
    isMinimized ? unlock() : lock();
  }, [isMinimized]);

  return (
    <>
      {!isMinimized && <div className='k-overlay'></div>}
      <ConfirmationModalProvider>
        <WidgetContainer fullscreen={isFullScreen} minimised={isMinimized}>
          <ChatRoomCreateModal
            isOpen={isCreateModalOpen}
            handleClose={handleCreateCloseModal}
            user={userData}
          />
          {!roomView && (
            <ChatRoomList
              header={
                <ChatRoomListHeader
                  toggleChatSize={toggleChatSize}
                  isMinimized={isMinimized}
                  isFullScreen={isFullScreen}
                  toggleFullScreenMode={toggleFullScreenMode}
                  unreadMessages={unreadMessages ?? []}
                />
              }
              isLoadingRoomList={isLoadingRoomList}
              rooms={roomsWithUnreadMessages ?? []}
              onNewRoomCreateAction={handleCreateOpenModal}
              onRoomSelected={roomSelected}
            />
          )}
          {roomView && (
            <ChatRoom
              header={
                <ChatRoomHeader
                  unreadMessages={unreadMessages}
                  currentRoom={currentRoom}
                  toggleChatSize={toggleChatSize}
                  isMinimized={isMinimized}
                  isFullScreen={isFullScreen}
                  toggleFullScreenMode={toggleFullScreenMode}
                  backToRoomList={backToRoomList}
                />
              }
              backToRoomList={backToRoomList}
              currentRoom={currentRoom}
            />
          )}
        </WidgetContainer>
      </ConfirmationModalProvider>
    </>
  );
};
export default ChatWidget;
