/* eslint-disable react/jsx-key */
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useProcedureReply from 'configurator/hooks/useProcedure';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { useUiStore } from 'configurator/reducers/uiStore';
import { genericSwitchingMap, genericSwitchingReversedMap } from 'configurator/utils/definesLocal';
import {
  CalibrationWrapper,
  DoubleLabel,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  Header2
} from 'configurator/components/molecules/CalibrationProcedure/styled';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';

const SoftGrip = ({
  addHistoryProsthesis,
  softGrip,
  fingerStrength,
  softGripPermission,
  replayIsEnabled,
  sessionEnabled,
  sessionApi,
  channel,
  setConfigProperty,
  handleProcedure,
  disableCalibration
}: any) => {
  const [softGripStatus] = softGrip;
  const procedure = useProcedureReply(ProcedureTypes.calibrateSoftGrip);
  const [fingerStrengthSliderLocal, setFingerStrengthSliderLocal] = useState<any>(
    // @ts-ignore
    initialState.config.fingerStrength?.[1]
  );
  const procedureState = useUiStore((state: any) => state.procedureState);
  const fingerStrengthMap: any = new Map([
    [0, 50],
    [1, 100],
    [2, 150],
    [3, 300],
    [4, 500],
    [5, 700]
  ]);

  const fingerStrengthReversedMap = new Map(Array.from(fingerStrengthMap, (a: any) => a.reverse()));

  const handleSoftGripStatus = (value: any) => {
    addHistoryProsthesis(setConfigProperty('softGrip', [value]));
  };

  const handleOnAfterChangeFinger = async (value: any) => {
    addHistoryProsthesis(setConfigProperty('fingerStrength', [1, fingerStrengthMap.get(value)]));
  };

  useEffect(() => {
    if (fingerStrength) {
      setFingerStrengthSliderLocal(fingerStrengthReversedMap.get(fingerStrength?.[1]));
    }
  }, [fingerStrength]);

  const Softgrip = useMemo(
    () => (
      <OptionsWithSlider
        header='Soft-grip'
        toggleOptions={['On', 'Off']}
        disabled={replayIsEnabled || !softGripPermission}
        disableSlider={Boolean(!softGripStatus)}
        toggleValue={genericSwitchingMap.get(softGripStatus)}
        sliderValue={fingerStrengthSliderLocal}
        handleSliderChange={(e: any, value: any) => setFingerStrengthSliderLocal(value)}
        sliderLimits={{ min: 0, max: 5 }}
        sliderLabel={
          <DoubleLabel>
            <span>Gentle</span>
            <span>Strong</span>
          </DoubleLabel>
        }
        step={1}
        handleToggleChange={(value: any) =>
          handleSoftGripStatus(genericSwitchingReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args: any) => handleOnAfterChangeFinger(args[1])}
        description='Used to limit the strength of the fingers'
      />
    ),
    [softGrip, fingerStrengthSliderLocal, softGripPermission, fingerStrength]
  );

  return (
    <>
      {Softgrip}
      <CalibrationWrapper>
        {procedure && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>Procedure results</Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td aria-label='Procedure information'>&nbsp;</td>
                  <td>Thumb</td>
                  <td>Index</td>
                  <td>Middle</td>
                  <td>Ring</td>
                  <td>Pinky</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Status</td>
                  {procedure.status!.map((status, index) => (
                    // @ts-ignore
                    <ProcedureStatusBox colSpan={1} status={procedure.status![index]}>
                      {status === 1 ? 'OK' : 'NOT OK'}
                    </ProcedureStatusBox>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Max current</td>
                  {procedure.maxCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip title={disableCalibration ? 'Device not connected' : null}>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration || !softGripPermission}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={() =>
                handleProcedure({
                  procedureNumber: ProcedureTypes.calibrateSoftGrip,
                  ...(sessionEnabled && {
                    liveSession: {
                      clinicianUUID: sessionApi?.clinician_uuid,
                      channelName: channel?.name
                    }
                  })
                })
              }>
              <span>Calibration procedure</span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};

export default SoftGrip;
