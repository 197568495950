import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { FakeInput, TextInput } from 'adp-panel/features/Chat/components/styled';

const MAX_INPUT = 12000;

const ChatInput = ({
  sendMessage,
  onFocus = () => {}
}: {
  sendMessage: Function;
  onFocus?: Function;
}) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (message: string) => {
    if (message.trim() === '') {
      return;
    }
    sendMessage(message.trim());
    setMessage('');
  };

  const handleChange = (message: string) => {
    if (message.length > MAX_INPUT) {
      setMessage(message.slice(0, MAX_INPUT));
      return;
    }
    setMessage(message);
  };

  return (
    <div>
      <FakeInput>
        <TextInput
          onFocus={(e) => onFocus(e)}
          onChange={(e) => handleChange((e.target as HTMLTextAreaElement).value)}
          onKeyUp={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.shiftKey) {
              return;
            }
            if (e.code == 'Enter') {
              handleSubmit((e.target as HTMLTextAreaElement).value);
            }
          }}
          value={message}
          placeholder={'Write here...'}
        />
        <Button
          onClick={() => handleSubmit(message)}
          themeColor={'primary'}
          disabled={message.trim() === ''}>
          Send
        </Button>
      </FakeInput>
    </div>
  );
};
export default ChatInput;
