import styled from 'styled-components';
import { adpTheme } from 'configurator/theme/adpTheme';

export const FooterAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  text-align: center;
  gap: 16px;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  margin-top: 32px;
`;

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  margin-top: 24px;
`;

export const AnimationWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
`;

export const Logo = styled.div`
  margin-bottom: 40px;
  justify-self: center;

  img {
    height: 48px;
    object-fit: contain;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const LogoLink = styled.a`
  display: flex;
  justify-content: center;
`;

export const HandImageWrapper = styled.img`
  width: 96px;
  height: 96px;
  transform: rotate(30deg);
`;

export const LoadingButtonAdpBlue = {
  backgroundColor: adpTheme.palette.primary,
  ':hover': {
    transform: 'scale(1.02, 1.02)',
    transition: 'transform 0.2s',
    backgroundColor: adpTheme.palette.primary
  }
};

export const AlertParagraph = styled.p`
  margin-top: 4px;
`;
