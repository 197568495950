/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled from 'styled-components';
import Divider from 'configurator/components/atoms/Divider/Divider';
import {
  aetherBatteryVoltageFunctions,
  allUserFeedbackTypeMap,
  configSettingsNamesMap,
  controlModeMap,
  freezeModeMap,
  genericSwitchingMap,
  gripsGroupsOptionsReversedMap,
  gripSwitchingMap,
  inputDeviceMap,
  inputSiteMap,
  singleElectrodeModeMap,
  speedControlMap,
  startOnStartupMap
} from 'configurator/utils/definesLocal';
import Dropdown from 'configurator/components/atoms/Dropdown/Dropdown';
import InnerCard from 'configurator/components/atoms/Card/InnerCard';
import { TypesConfig } from 'configurator/utils/types';
import ConfigItem from 'configurator/components/atoms/ConfigItem/ConfigItem';
import { DeviceConfigTemplate } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { Table } from 'configurator/components/atoms/Table/Table';
import Accordion from 'configurator/components/atoms/Accordion/Accordion';
import { InputSites } from 'bluetooth/Bluetooth/Control';
import { useConfigStore } from 'configurator/reducers/configStore';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { singleElectrodeSettingsAlternatingNamingsMap } from 'configurator/consts/deviceConfig/configPropertiesNamings';

export enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

interface ConfigComponentsProps {
  config?: DeviceConfigTemplate;
  transformedConfig?: any;
  currentToggle?: ToggleTypesChangesHistory;
}

const ConfigWrapper = styled.div`
  display: grid;
  gap: 24px;
`;

const DividerWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const ConfigList = styled.ul`
  display: grid;
  gap: 16px;
  list-style: none;
`;

export const ConfigDetailsMap = {
  gripsPositions: (config) => (transformedConfig, selectedGrip, setSelectedGrip, gripNumber) => (
    <Accordion header='Grip positions'>
      <DividerWrapper>
        <div style={{ width: '300px' }}>
          <Dropdown
            options={transformedConfig?.grips}
            label='Grip:'
            selected={selectedGrip}
            onChange={(e) => setSelectedGrip(e)}
          />
        </div>
        <InnerCard>
          <Table>
            <tr>
              <th style={{ width: '60%' }}>Finger</th>
              <th>Initial</th>
              <th>Limit</th>
            </tr>
            <tr>
              <td>Thumb</td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[0]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[0]}</td>
            </tr>
            <tr>
              <td>Index</td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[1]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[1]}</td>
            </tr>
            <tr>
              <td>Middle</td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[2]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[2]}</td>
            </tr>
            <tr>
              <td>Ring</td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[3]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[3]}</td>
            </tr>
            <tr>
              <td>Pinky</td>
              <td>{config?.gripsPositions?.[gripNumber]?.initial?.[4]}</td>
              <td>{config?.gripsPositions?.[gripNumber]?.limit?.[4]}</td>
            </tr>
          </Table>
        </InnerCard>
      </DividerWrapper>
    </Accordion>
  ),
  gripPairsConfig: (transformedConfig) => (
    <Accordion header='Pairs config'>
      <DividerWrapper>
        <InnerCard>
          <Table>
            <caption>Opposed</caption>
            <tr>
              <th colSpan={2} style={{ width: '50%' }}>
                Primary
              </th>
              <th colSpan={2} style={{ width: '50%' }}>
                Secondary
              </th>
            </tr>
            <tr>
              <td>{transformedConfig?.gripPairsConfig[0]}</td>
              <td>{transformedConfig?.gripPairsConfig[1]}</td>
              <td>{transformedConfig?.gripPairsConfig[2]}</td>
              <td>{transformedConfig?.gripPairsConfig[3]}</td>
            </tr>
          </Table>
          <Divider margin='20px' />
          <Table>
            <caption>Non-opposed</caption>
            <tr>
              <th colSpan={2}>Primary</th>
              <th colSpan={2}>Secondary</th>
            </tr>
            <tr>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[4]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[5]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[6]}</td>
              <td colSpan={1}>{transformedConfig?.gripPairsConfig[7]}</td>
            </tr>
          </Table>
        </InnerCard>
      </DividerWrapper>
    </Accordion>
  ),
  gripSequentialConfig: (transformedConfig) => (
    <Accordion header='Grips sequential config'>
      <DividerWrapper>
        <InnerCard>
          <Table>
            <caption>Opposed</caption>
            <tr>
              <td>{transformedConfig?.gripSequentialConfig[0]}</td>
              <td>{transformedConfig?.gripSequentialConfig[1]}</td>
              <td>{transformedConfig?.gripSequentialConfig[2]}</td>
              <td>{transformedConfig?.gripSequentialConfig[3]}</td>
              <td>{transformedConfig?.gripSequentialConfig[4]}</td>
            </tr>
          </Table>
          <Divider margin='20px' />
          <Table>
            <caption>Non-opposed</caption>
            <tr>
              <td>{transformedConfig?.gripSequentialConfig[6]}</td>
              <td>{transformedConfig?.gripSequentialConfig[7]}</td>
              <td>{transformedConfig?.gripSequentialConfig[8]}</td>
              <td>{transformedConfig?.gripSequentialConfig[9]}</td>
              <td>{transformedConfig?.gripSequentialConfig[10]}</td>
            </tr>
          </Table>
        </InnerCard>
      </DividerWrapper>
    </Accordion>
  ),
  inputSite: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('inputSite')}
        items={[
          {
            header: 'Value',
            content: `${inputSiteMap.get(config.inputSite[0])}`
          }
        ]}
      />
    </li>
  ),
  inputDevice: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('inputDevice')}
        items={[
          {
            header: 'Value',
            content: `${inputDeviceMap.get(config.inputDevice[0])}`
          }
        ]}
      />
    </li>
  ),
  speedControlStrategy: (config) => (
    <li>
      <ConfigItem
        header='Speed control strategy'
        items={[
          {
            header: 'Value',
            content: `${speedControlMap.get(config.speedControlStrategy[0])}`
          }
        ]}
      />
    </li>
  ),
  gripSwitchingMode: (config) => (
    <li>
      <ConfigItem
        header='Grip switching modes'
        items={[
          {
            header: 'Value',
            content: `${gripSwitchingMap.get(config.gripSwitchingMode[0])}`
          }
        ]}
      />
    </li>
  ),
  controlMode: (config) => (
    <li>
      <ConfigItem
        header='Control mode'
        items={[
          {
            header: 'Value',
            content: `${controlModeMap.get(config.controlMode[0])}`
          }
        ]}
      />
    </li>
  ),
  softGrip: (config) => (
    <li>
      <ConfigItem
        header='Soft-grip'
        items={[
          {
            header: 'Status',
            content: genericSwitchingMap.get(config.softGrip[0]),
            type: TypesConfig.bool,
            value: config?.softGrip?.[0]
          }
        ]}
      />
    </li>
  ),
  fingerStrength: (config) => (
    <li>
      <ConfigItem
        header='Finger strength'
        items={[{ header: 'Value', content: `${config?.fingerStrength?.[1]}` }]}
      />
    </li>
  ),
  autoGrasp: (config) => (
    <li>
      <ConfigItem
        header='Auto grasp'
        items={[
          {
            header: 'Status',
            content: `${genericSwitchingMap.get(config.autoGrasp[0])}`,
            type: TypesConfig.bool,
            value: config?.autoGrasp?.[0]
          },
          { header: 'Value', content: `${config.autoGrasp[1]}` }
        ]}
      />
    </li>
  ),
  holdOpen: (config) => (
    <li>
      <ConfigItem
        header='Hold open timings'
        items={[
          { header: 'Primary hold open time', content: `${config.holdOpen[0]} ms` },
          { header: 'Secondary hold open time', content: `${config.holdOpen[1]} ms` }
        ]}
      />
    </li>
  ),
  pulseTimings: (config) => (
    <li>
      <ConfigItem
        header='Pulse timings'
        items={[
          { header: 'Min. pulse time', content: `${config.pulseTimings[0]} ms` },
          { header: 'Max. pulse time', content: `${config.pulseTimings[1]} ms` },
          {
            header: 'Min. time between pulses',
            content: `${config.pulseTimings[2]} ms`
          },
          { header: 'Max. time between pulses', content: `${config.pulseTimings[3]} ms` }
        ]}
      />
    </li>
  ),
  coContractionTimings: (config) => (
    <li>
      <ConfigItem
        header='Co-contraction timings'
        items={[
          {
            header: 'Long co-contraction time',
            content: `${config?.coContractionTimings[0]} ms`
          },
          {
            header: 'Signal rise offset time',
            content: `${config?.coContractionTimings[1]} ms`
          }
        ]}
      />
    </li>
  ),
  batteryBeep: (config) => (
    <li>
      <ConfigItem
        header='Low battery beep'
        items={[
          {
            header: 'Alarm trigger level',
            content: `${aetherBatteryVoltageFunctions.voltageToPercent(config?.batteryBeep[0])} %`
          },
          {
            header: 'Status',
            content: `${genericSwitchingMap.get(config.batteryBeep[1])}`,
            type: TypesConfig.bool,
            value: config?.batteryBeep?.[1]
          }
        ]}
      />
    </li>
  ),
  freezeModeEmg: (config) => (
    <li>
      <ConfigItem
        header='Freeze mode EMG'
        items={[
          {
            header: 'Status',
            content: `${freezeModeMap.get(config?.freezeModeEmg[2])}`,
            type: TypesConfig.bool,
            value: config?.freezeModeEmg?.[2]
          },
          {
            header: 'Threshold closing',
            content: `${config?.freezeModeEmg[0]}`
          },
          {
            header: 'Threshold opening',
            content: `${config?.freezeModeEmg[1]}`
          }
        ]}
      />
    </li>
  ),
  generalHandSettings: (config) => (
    <li>
      <ConfigItem
        header='Wait for CS before startup'
        items={[
          {
            header: 'Status',
            content: `${startOnStartupMap.get(config?.generalHandSettings[2])}`,
            type: TypesConfig.bool,
            value: !config?.generalHandSettings?.[2]
          }
        ]}
      />
    </li>
  ),
  emgSpike: (config) => (
    <li>
      <ConfigItem
        header='Emg spike'
        items={[
          {
            header: 'Status',
            content: `${genericSwitchingMap.get(config.emgSpike[0])}`,
            type: TypesConfig.bool,
            value: config?.emgSpike?.[0]
          },
          { header: 'Value', content: `${config.emgSpike[1]} ms` }
        ]}
      />
    </li>
  ),
  emgGains: (config) => (
    <li>
      <ConfigItem
        header='EMG gains'
        items={[
          { header: 'Extension', content: `${config.emgGains[0]}%` },
          { header: 'Flexion', content: `${config.emgGains[1]}%` }
        ]}
      />
    </li>
  ),
  emgThresholds: (config) => (
    <li>
      <ConfigItem
        header='EMG thresholds'
        items={[
          {
            header: 'CS extension thresholds',
            content: `${config.emgThresholds[0]}`
          },
          {
            header: 'CS flexion thresholds',
            content: `${config.emgThresholds[1]}`
          },
          {
            header: 'Threshold control open value',
            content: `${config.emgThresholds[2]}`
          },
          {
            header: 'Proportional control open second value',
            content: `${config.emgThresholds[3]}`
          },
          {
            header: 'Proportional control open third value',
            content: `${config.emgThresholds[4]}`
          },
          {
            header: 'Threshold control close value',
            content: `${config.emgThresholds[5]}`
          },
          {
            header: 'Proportional control close second value',
            content: `${config.emgThresholds[6]}`
          },
          {
            header: 'Proportional control close third value',
            content: `${config.emgThresholds[7]}`
          },
          {
            header: 'Soft-grip open threshold second value',
            content: `${config.emgThresholds[8]}`
          },
          {
            header: 'Soft-grip close threshold second value',
            content: `${config.emgThresholds[9]}`
          }
        ]}
      />
    </li>
  ),
  buzzingVolumeSettings: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('buzzingVolumeSettings')}
        items={[
          {
            header: 'Volume',
            content: `${Math.round((config.buzzingVolumeSettings[0] / 255) * 100)} %`
          }
        ]}
      />
    </li>
  ),
  followingGrip: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('followingGrip')}
        items={[
          {
            header: 'Status',
            content: `${genericSwitchingMap.get(config.followingGrip[0])}`,
            type: TypesConfig.bool,
            value: config?.followingGrip?.[0]
          }
        ]}
      />
    </li>
  ),
  freezeModeEmgSettings: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('freezeModeEmgSettings')}
        items={[
          { header: 'Threshold opening', content: `${config.freezeModeEmgSettings[0]}` },
          { header: 'Threshold closing', content: `${config.freezeModeEmgSettings[1]}` },
          { header: 'Stage 1 time', content: `${config.freezeModeEmgSettings[2]} ms` },
          { header: 'Stage 2 time', content: `${config.freezeModeEmgSettings[3]} ms` }
        ]}
      />
    </li>
  ),
  singleElectrodeMode: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('singleElectrodeMode')}
        items={[
          {
            header: 'Mode',
            content: `${singleElectrodeModeMap.get(config.singleElectrodeMode[0])}`
          }
        ]}
      />
    </li>
  ),
  singleElectrodeModeSettings: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('singleElectrodeModeSettings')}
        items={[
          {
            header: 'Start point signal thresholds',
            content: `${config.singleElectrodeModeSettings[0]}`
          },
          {
            header: 'Window time',
            content: `${config.singleElectrodeModeSettings[1]} ms`
          },
          {
            header: 'Primary hold open time',
            content: `${config.singleElectrodeModeSettings[2]} ms`
          },
          {
            header: 'Secondary hold open time',
            content: `${config.singleElectrodeModeSettings[3]} ms`
          }
        ]}
      />
    </li>
  ),
  userFeedbackType: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('userFeedbackType')}
        items={[
          {
            header: 'Buzzer',
            content: `${allUserFeedbackTypeMap.get(config.userFeedbackType[0])}`
          }
        ]}
      />
    </li>
  ),
  emergencyBatterySettings: (config: DeviceConfigTemplate) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('emergencyBatterySettings')}
        items={[
          {
            header: 'Status',
            content: `${genericSwitchingMap.get(config.emergencyBatterySettings[0])}`,
            type: TypesConfig.bool,
            value: config?.emergencyBatterySettings?.[0]
          },
          {
            header: 'Mode trigger level',
            content: `${aetherBatteryVoltageFunctions.voltageToPercent(
              config.emergencyBatterySettings[2]
            )} %`
          }
        ]}
      />
    </li>
  ),
  oneSpeed: (config) => (
    <li>
      <ConfigItem
        header='One speed settings'
        items={[{ header: 'Fingers speed', content: `${config.oneSpeed[0]}%` }]}
      />
    </li>
  ),
  singleElectrodeSettingsAlternating: (config) => (
    <li>
      <ConfigItem
        header={configSettingsNamesMap.get('singleElectrodeSettingsAlternating')}
        items={[
          {
            header: singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
              ]
            } ms`
          },
          {
            header: singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.minimumPulseTime
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.minimumPulseTime
              ]
            } ms`
          },
          {
            header: singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.maximumPulseTime
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.maximumPulseTime
              ]
            } ms`
          },
          {
            header: singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
              ]
            } ms`
          },
          {
            header: singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
            ),
            content: `${
              config.singleElectrodeSettingsAlternating[
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              ]
            } ms`
          }
        ]}
      />
    </li>
  )
};

const prosthesisSettings: Array<keyof DeviceConfigTemplate> = [
  'inputSite',
  'inputDevice',
  'speedControlStrategy',
  'gripSwitchingMode',
  'controlMode',
  'singleElectrodeMode',
  'singleElectrodeModeSettings',
  'softGrip',
  'fingerStrength',
  'autoGrasp',
  'holdOpen',
  'pulseTimings',
  'coContractionTimings',
  'batteryBeep',
  'buzzingVolumeSettings',
  'followingGrip',
  'freezeModeEmg',
  'freezeModeEmgSettings',
  'generalHandSettings',
  'userFeedbackType',
  'emergencyBatterySettings',
  'oneSpeed',
  'singleElectrodeSettingsAlternating'
];
const emgSettings: Array<keyof DeviceConfigTemplate> = ['emgSpike', 'emgGains', 'emgThresholds'];

const ConfigComponent = ({
  config,
  transformedConfig,
  currentToggle = ToggleTypesChangesHistory.all
}: ConfigComponentsProps) => {
  const [selectedGrip, setSelectedGrip] = useState('Rest opp');
  const { commonKeys, modeKeys } = useConfigStore((state) => ({
    commonKeys: state.commonPropertiesAPI,
    modeKeys: state.modePropertiesAPI
  }));
  const configKeys = commonKeys && modeKeys ? [...commonKeys, ...modeKeys] : null;

  const showProsthesis =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.prosthesis;

  const showGrips =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.grips;

  const showEMG =
    currentToggle === ToggleTypesChangesHistory.all ||
    currentToggle === ToggleTypesChangesHistory.emg;

  if (!config) return <div>Config is not initialized</div>;

  const gripNumber: any = gripsGroupsOptionsReversedMap.get(selectedGrip);
  const filterKeys = (setting: keyof DeviceConfigTemplate) =>
    configKeys ? configKeys.includes(setting) : true;
  const showSetting = (setting: keyof DeviceConfigTemplate) =>
    Boolean(config?.[`${setting}`]) && ConfigDetailsMap[`${setting}`](config);

  return (
    <ConfigWrapper>
      {showGrips && (
        <>
          {config?.gripsPositions &&
            ConfigDetailsMap.gripsPositions(config)(
              transformedConfig,
              selectedGrip,
              setSelectedGrip,
              gripNumber
            )}
          {transformedConfig?.gripPairsConfig &&
            ConfigDetailsMap.gripPairsConfig(transformedConfig)}
          {transformedConfig?.gripSequentialConfig &&
            ConfigDetailsMap.gripSequentialConfig(transformedConfig)}
        </>
      )}
      <ConfigList>
        {showProsthesis && (
          <>
            {prosthesisSettings.filter(filterKeys).map((setting) => {
              if (setting === 'singleElectrodeMode') {
                return (
                  config?.singleElectrodeMode &&
                  config?.inputSite &&
                  config.inputSite[0] === InputSites.kSingleElectrode &&
                  ConfigDetailsMap.singleElectrodeMode(config)
                );
              }

              if (setting === 'singleElectrodeModeSettings') {
                return (
                  config?.singleElectrodeModeSettings &&
                  config.inputSite &&
                  config.inputSite[0] === InputSites.kSingleElectrode &&
                  ConfigDetailsMap.singleElectrodeModeSettings(config)
                );
              }

              return showSetting(setting);
            })}
          </>
        )}
        {showEMG && <>{emgSettings.filter(filterKeys).map(showSetting)}</>}
      </ConfigList>
    </ConfigWrapper>
  );
};

export default ConfigComponent;
