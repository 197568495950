import React, { useState, useMemo, useEffect } from 'react';
import { Alert } from '@mui/material';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { genericSwitchingMap, genericSwitchingReversedMap } from 'configurator/utils/definesLocal';
import { emgSpikeEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { OptionsWithSlider } from '../../../components/molecules/Sliders/Sliders';

const EmgSpike = ({
  addHistoryProsthesis,
  emgSpike,
  emgSpikesPermission,
  disabled,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  emgSpike: emgSpikeEntry;
  emgSpikesPermission: any;
  disabled: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [emgSpikeSliderLocal, setEmgSpikeSliderLocal] = useState<any>(
    // @ts-ignore
    initialState.config.emgSpike[1]
  );
  const [emgSpikeStatus, emgSpikeTime] = emgSpike;

  const handleEmgSlider = (value: any) => {
    addHistoryProsthesis(setConfigProperty('emgSpike', [emgSpikeStatus, value]));
  };

  const handleEmgStatus = (value: any) => {
    addHistoryProsthesis(setConfigProperty('emgSpike', [value, emgSpikeTime]));
  };

  useEffect(() => {
    if (emgSpike) {
      setEmgSpikeSliderLocal(emgSpike?.[1]);
    }
  }, [emgSpike]);

  const descriptionEmgSpikes = useMemo(() => {
    if (emgSpikeStatus === 0) {
      return 'Ignore short EMG spikes of the specified length.';
    }

    return (
      <>
        <p style={{ marginBottom: 10 }}>Ignore short EMG spikes of the specified length.</p>
        <Alert severity='error'> Enabling this function might influence other settings</Alert>
      </>
    );
  }, [emgSpikeStatus]);

  const EMG = useMemo(
    () => (
      <OptionsWithSlider
        header='Ignore EMG spikes'
        toggleOptions={['On', 'Off']}
        disabled={disabled}
        disableSlider={Boolean(!emgSpikeStatus)}
        toggleValue={genericSwitchingMap.get(emgSpikeStatus)}
        sliderValue={emgSpikeSliderLocal}
        handleSliderChange={(e: any, value: any) => setEmgSpikeSliderLocal(value)}
        sliderLimits={{ min: 10, max: 500 }}
        sliderLabel={`Time ignored`}
        valueToDisplay={`${parseNumber(emgSpikeSliderLocal)}s`}
        handleToggleChange={(value: any) => handleEmgStatus(genericSwitchingReversedMap.get(value))}
        handleSliderOnAfterChange={(...args: any) => handleEmgSlider(args[1])}
        description={descriptionEmgSpikes}
      />
    ),
    [emgSpike, emgSpikeSliderLocal, emgSpikesPermission, disabled]
  );

  return EMG;
};

export default EmgSpike;
