import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LabelStyled = styled(Label)`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.palette.headerTitle};
  line-height: 1.5;
`;

const InputStyled = styled(Input)`
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 4px;
  padding: 0 12px 0 0;
`;

const FormInput = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    readOnly,
    optional,
    max,
    value,
    ...others
  } = fieldRenderProps;
  const { t } = useTranslation();
  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper>
      {label && (
        <LabelStyled
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}>
          {label}
        </LabelStyled>
      )}
      <div className={'k-form-field-wrap'}>
        <InputStyled
          valid={valid}
          type={type}
          id={id}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          maxLength={max ?? null}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {max && max > 0 && (
          <Hint direction={'end'} style={{ position: 'absolute', right: 15 }}>
            {value?.length || 0} / {max}
          </Hint>
        )}
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{t(validationMessage || '')}</Error>}
      </div>
    </FieldWrapper>
  );
};

export default FormInput;
