import { useNavigate } from 'react-router-dom';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { FormButtonsWrapper } from '../styled';
import { Button } from '@progress/kendo-react-buttons';
import FormComboBox from '../../../components/FormInput/FormComboBox';
import { requiredValidator } from '../../../components/FormInput/validators';
import FormInput from '../../../components/FormInput/FormInput';
import FormUpload from '../../../components/FormInput/FormUpload';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  createFirmwareVersion,
  createPCBVersion,
  createSoftwareVersion
} from '../../../api/versions/verions';
import { mapErrorMessage } from '../../../utils/notifications';
import { useUploadFiles } from '../../../hooks/useUploadFiles';
import { RELEASES } from '../../../../constants/routes';
import { VersionType, VersionTypeOption } from '../../../api/versions/version.types';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { NotificationFactory } from 'lib/NotificationFactory';

const VersionAdd = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<{ type: VersionTypeOption }>({
    type: 'DeviceModel'
  });

  const onSuccess = () => {
    NotificationFactory.successNotification('New version has been created.');
  };
  const onError = (error: any) => {
    mapErrorMessage(error);
  };
  const { mutate: createSoftware } = useMutation(createSoftwareVersion, { onSuccess, onError });
  const { mutate: createFirmware } = useMutation(createFirmwareVersion, { onSuccess, onError });
  const { mutate: createPcb } = useMutation(createPCBVersion, { onSuccess, onError });

  const onSubmit = async (e: any) => {
    const { name, firmware, bootloader } = e;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const firmwareFile = useUploadFiles(firmware);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const bootloaderFile = useUploadFiles(bootloader);
    switch (selectedType.type) {
      case 'PCBVersion':
        await createPcb(name);
        break;
      case 'FirmwareVersion':
        createFirmware({
          name,
          ...(firmwareFile && { file_firmware: firmwareFile }),
          ...(bootloaderFile && { file_bootloader: bootloaderFile })
        });
        break;
      case 'SoftwareVersion':
        createSoftware(name);
        break;
    }
    navigate(RELEASES);
  };

  const handleTypeChange = ({ value }: { value: VersionType }) => {
    setSelectedType({ type: value.id });
  };

  const typeList: VersionType[] = [
    { id: 'SoftwareVersion', label: 'Software' },
    { id: 'FirmwareVersion', label: 'Firmware' },
    { id: 'PCBVersion', label: 'PCB' }
  ];

  const isLoading = false;
  return (
    <DefaultLayout>
      <FormContentWrapper title={'Add version'} small>
        <Form
          onSubmit={onSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <Field
                id={'type'}
                label={'Type'}
                name={'type'}
                component={FormComboBox}
                data={typeList}
                textField='label'
                textId='id'
                validator={requiredValidator}
                onChange={handleTypeChange}
              />
              <Field
                id={'name'}
                name={'name'}
                label={'Name'}
                component={FormInput}
                validator={requiredValidator}
              />
              {selectedType.type === 'FirmwareVersion' && (
                <>
                  <Field
                    id={'firmware'}
                    name={'firmware'}
                    label={'Firmware File'}
                    restrictions={{
                      allowedExtensions: ['.bin']
                    }}
                    component={FormUpload}
                    optional={true}
                  />
                  <Field
                    id={'bootloader'}
                    name={'bootloader'}
                    label={'Bootloader file'}
                    restrictions={{
                      allowedExtensions: ['.bin']
                    }}
                    component={FormUpload}
                    optional={true}
                  />
                </>
              )}
              <FormButtonsWrapper className='k-form-buttons'>
                <Button onClick={() => navigate(-1)} type='button'>
                  Cancel
                </Button>
                <Button
                  themeColor='primary'
                  type='submit'
                  disabled={!formRenderProps.allowSubmit || isLoading}>
                  Add version
                  {isLoading && <LoaderButton />}
                </Button>
              </FormButtonsWrapper>
            </FormElement>
          )}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default VersionAdd;
