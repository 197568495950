import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import { speedControlMap, speedControlReversedMap } from 'configurator/utils/definesLocal';

const SpeedControlStrategy = ({
  addHistoryProsthesis,
  speedControlStrategy,
  speedStrategyPermission,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const [speedControlStrategyValue] = speedControlStrategy;

  const handleSpeedControl = (type: any, value: any) => {
    addHistoryProsthesis(
      setConfigProperty('speedControlStrategy', [speedControlReversedMap.get(value)])
    );
  };

  const SpeedControlStrategy = useMemo(
    () => (
      <InputOptions
        header='Speed control strategy'
        data-tour='strategy'
        options={['One speed', 'Proportional']}
        id='strategy'
        onChange={handleSpeedControl}
        disable={replayIsEnabled || !speedStrategyPermission}
        // @ts-ignore
        value={speedControlMap.get(speedControlStrategyValue)}
        description='Mode in which you operate the prosthesis'
      />
    ),
    [speedControlStrategy, speedStrategyPermission]
  );

  return SpeedControlStrategy;
};

export default SpeedControlStrategy;
