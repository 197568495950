import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { SERVICING } from 'constants/routes';
import ModalBase from './ModalBase';

const ParagraphBold = styled.p`
  ${TextS};
  margin-top: 30px;
  margin-bottom: 40px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: any;
};

const SessionWasEndedServicing = ({ handleClose }: ModalProps) => {
  const navigate = useNavigate();
  const accepted = async () => {
    handleClose();
    navigate(SERVICING);
  };

  return (
    <ModalBase handleClick={handleClose} header='Device is disconnected' allowBackDropClose={false}>
      <DialogContent>
        <ParagraphBold>
          The local session is over, if there was a replacement of parts, go to the form.
        </ParagraphBold>
        <ButtonsWrapper>
          <Button onClick={handleClose}>No replacement was needed</Button>
          <Button onClick={accepted} variant='outlined'>
            Go to replacement form
          </Button>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default SessionWasEndedServicing;
