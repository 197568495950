/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */

import { Commands } from 'bluetooth/Bluetooth/Defines';
import TelemetryParser from 'configurator/utils/TelemetryParser';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { HandMovementStates } from 'configurator/utils/definesLocal';
import BluetoothWebController from 'configurator/bluetooth-handler/bluetoothWeb';

const bluetooth = new BluetoothWebController();
const telemetryParser = new TelemetryParser();

type FingerStateType = {
  encoderTicks: number;
  pwm: number;
  velocity: number;
  current: number;
};

const TelemetryController: {
  gripInTransition: boolean;
  telemetryListening: boolean;
  telemetryReplayListening: boolean;
  telemetryData: any;
  eventListenerController: AbortController;
  eventListenerReplayController: AbortController;
  fingerStates: FingerStateType[] | null;
  prosthesisGrip: number | null;
  prosthesisVoltage: number | null;
  newestTelemetryData: any;
  handMovementState: HandMovementStates | null;
  parseTelemetry: (telemetryData: any) => void;
  parseReplayTelemetry: (telemetryData: any) => void;
  abortReplay: () => void;
  enableReplay: () => void;
  checkVoltage: () => Promise<number | null>;
  initiateTelemetry: () => void;
  abortTelemetry: () => void;
} = {
  gripInTransition: false,
  telemetryListening: false,
  telemetryReplayListening: false,
  telemetryData: <any>[],
  prosthesisGrip: null,
  prosthesisVoltage: null,
  newestTelemetryData: null,
  fingerStates: null,
  handMovementState: null,
  eventListenerController: new AbortController(),
  eventListenerReplayController: new AbortController(),
  parseTelemetry(telemetryData: any) {
    const [{ payload: telemetry }] = telemetryData.detail;
    this.newestTelemetryData = telemetryData.detail;
    const newData = telemetryParser.parseTelemetry(telemetryData.detail, 500);
    const fingers: FingerStateType[] = [];
    for (let index = 0; index < 5; index += 1) {
      const encoderTicks = telemetry[4 * index + 0];
      const pwm = telemetry[4 * index + 1];
      const velocity = telemetry[4 * index + 2];
      const current = telemetry[4 * index + 3];

      fingers.push({ encoderTicks, pwm, velocity, current });
    }
    this.fingerStates = fingers;

    for (let index = 0; index < 5; index += 1) {
      const fingerVelocity = this.fingerStates[index]?.velocity;

      if (fingerVelocity > 0) {
        this.handMovementState = HandMovementStates.closing;
        break;
      }
      if (fingerVelocity < 0) {
        this.handMovementState = HandMovementStates.opening;
        break;
      }
      if (index === 4) {
        this.handMovementState = HandMovementStates.idle;
      }
    }
    const binaryStates = telemetry[22];
    const newGrip = telemetry[23];
    const voltage = telemetry[24];
    const newGripInTransition = ((binaryStates >> 3) & 1) === 1;
    if (newGrip !== this.prosthesisGrip) {
      this.prosthesisGrip = newGrip;
    }
    this.gripInTransition = newGripInTransition;
    // @ts-ignore
    this.telemetryData = newData;
    this.prosthesisVoltage = voltage;
  },
  parseReplayTelemetry(telemetryData: any) {
    const { reset, data } = telemetryData.detail;
    if (reset) telemetryParser.clear();
    const newData = telemetryParser.parseTelemetry(data, 500);
    this.telemetryData = newData;
  },
  abortReplay() {
    this.eventListenerReplayController.abort();
    this.telemetryReplayListening = false;
  },
  enableReplay() {
    this.eventListenerReplayController = new AbortController();
    if (!this.telemetryReplayListening) {
      window.addEventListener(`replay`, this.parseReplayTelemetry.bind(this), {
        signal: this.eventListenerReplayController.signal
      });
      this.telemetryReplayListening = true;
    }
  },
  async checkVoltage() {
    if (!bluetooth.telemetryEnabled) {
      await bluetooth.telemetryOn();
      await delay(400);
      await bluetooth.telemetryOff();
    }
    return this.prosthesisVoltage;
  },
  initiateTelemetry() {
    this.eventListenerController = new AbortController();
    if (!this.telemetryListening) {
      window.addEventListener(
        `received${Commands.kTelemetryData}`,
        this.parseTelemetry.bind(this),
        { signal: this.eventListenerController.signal }
      );
      this.telemetryListening = true;
    }
  },
  abortTelemetry() {
    this.eventListenerController.abort();
    this.telemetryListening = false;
  }
};

export default TelemetryController;
