import { useNavigate } from 'react-router-dom';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../../api/users/users.types';
import { useState } from 'react';
import { useUsersInfinite } from '../../../hooks/api/useUsers';
import { mapUsersRoles, userHasPermissions } from '../../../utils/permissionUtils';
import useUserData from '../../../../hooks/useUserData';
import { useVersions } from '../../../hooks/api/useVersions';
import CustomTextField from 'components/FormFields/CustomTextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import FormComboBox from 'components/FormFields/FormComboBox';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress } from '@mui/material';
import { FormWrapper } from 'components/FormFields/commonStyles';

export const deviceSchema = yup.object().shape({
  model: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object'),
  serial: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  bluetooth_id: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  clinician: yup
    .mixed()
    .test('is-object', 'Wrong format', (value) => value === null || typeof value === 'object'),
  amputee: yup
    .mixed()
    .test('is-object', 'Wrong format', (value) => value === null || typeof value === 'object'),
  firmware: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object'),
  pcb: yup
    .mixed()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('is-object', 'Wrong format', (value) => value !== null && typeof value === 'object')
});

interface DeviceFormProps {
  existingData?: any;
  handleSubmit: any;
  loading: boolean;
}

const DeviceForm = ({ existingData, handleSubmit, loading }: DeviceFormProps) => {
  const defaultValues = {
    model: null,
    serial: '',
    bluetooth_id: '',
    clinician: null,
    amputee: null,
    pcb: null,
    firmware: null
  };

  const isEdit = Boolean(existingData);

  const {
    control,
    handleSubmit: handleSubmitDevice,
    setValue,
    watch
  } = useForm<{
    model: any;
    serial: string;
    bluetooth_id: string;
    clinician: any;
    amputee: any;
    firmware: any;
    pcb: any;
  }>({
    defaultValues: isEdit ? existingData : defaultValues,
    // @ts-ignore
    resolver: yupResolver(deviceSchema)
  });

  const [clinician] = watch(['clinician']);

  const navigate = useNavigate();
  const { rolesByName } = useUserData();

  const [queryParamsClinician, setQueryParamsClinician] = useState<UsersQueryParams>({
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    extend: [UserExtendOptions.roles],
    perpage: 1000
  });
  const [queryParamsPatient, setQueryParamsPatient] = useState<UsersQueryParams>({
    roles: RoleEnum.amputee,
    perpage: 1000
  });

  const { PCBVersion, FirmwareVersion, DeviceModel } = useVersions(
    userHasPermissions([RoleEnum.superAdmin], rolesByName)
  );
  const { result: clinicians, isLoading: isLoadingClinicians } =
    useUsersInfinite(queryParamsClinician);
  const { result: patients, isLoading: isLoadingPatients } = useUsersInfinite(queryParamsPatient);

  const cliniciansWithRoles = clinicians && mapUsersRoles(clinicians);

  const initialFetchData = PCBVersion && FirmwareVersion && DeviceModel;
  const dynamicFetchData = cliniciansWithRoles && patients;

  if (!initialFetchData) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmitDevice(handleSubmit)}>
      <FormWrapper>
        <FormComboBox
          label='Model'
          id='model'
          control={control}
          options={DeviceModel}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <FormComboBox
          label='Firmware'
          id='firmware'
          control={control}
          options={FirmwareVersion}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => {
            console.log(option, value);
            return option.id === value.id;
          }}
        />
        <FormComboBox
          label='PCB'
          id='pcb'
          control={control}
          options={PCBVersion}
          optionLabel={'name'}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <CustomTextField label='Serial' id='serial' control={control} />
        )}
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <CustomTextField label='Bluetooth Id' id='bluetooth_id' control={control} />
        )}
        {dynamicFetchData ? (
          <>
            <FormComboBox
              label='Clinician'
              id='clinician'
              control={control}
              options={cliniciansWithRoles}
              optionLabel={'name'}
              disabled={isLoadingClinicians}
              onChange={(data) => {
                if (!data) setValue('amputee', null);
              }}
              optional
            />
            <FormComboBox
              label='Patient'
              id='amputee'
              control={control}
              options={patients}
              optionLabel={'name'}
              disabled={!clinician || isLoadingPatients}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              optional
            />
          </>
        ) : (
          <CircularProgress />
        )}
        <div>
          <Button data-testid='cancel-device-form' onClick={() => navigate(-1)} variant='outlined'>
            Cancel
          </Button>
          <LoadingButton data-testid='submit-device-form' type='submit' loading={loading}>
            <span>{isEdit ? 'Edit' : 'Create'}</span>
          </LoadingButton>
        </div>
      </FormWrapper>
    </form>
  );
};

export default DeviceForm;
