import { useQuery } from 'adp-panel/hooks/useQuery';
import { useEffect } from 'react';
import { useRedirectStore } from 'reducers/redirectReducer';

const TicketRedirect = () => {
  const query = useQuery();
  const ticketId = query.get('ticketId');
  const { setTicketRedirectId } = useRedirectStore((state) => ({
    setTicketRedirectId: state.setTicketRedirectId
  }));

  useEffect(() => {
    if (ticketId) {
      setTicketRedirectId({ ticketId: Number(ticketId) });
    }
  }, [ticketId]);

  return null;
};

export default TicketRedirect;
