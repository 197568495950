import React, { useEffect, useState } from 'react';
import { Types } from 'ably';
import toast from 'react-hot-toast';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import useUnsaved from 'configurator/hooks/useUnsaved';
import useSynchronizeBluetooth from 'configurator/hooks/bluetooth/useSynchronizeBluetooth';
import { ablyClient } from 'configurator/utils/LiveConfigurator/AblyClient';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import useSynchronizeConfigProperties from 'configurator/hooks/bluetooth/useSynchronizeConfigProperties';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import useTelemetry from 'configurator/hooks/bluetooth/useTelemetry';
import { useUiStore } from 'configurator/reducers/uiStore';
import useUserData from 'hooks/useUserData';

const BaseLogic = ({ children }: any) => {
  const { me } = useUserData();
  const { enabled: liveConfiguratorEnabled } = useLiveConfiguratorStore((state: any) => ({
    enabled: state.enabled,
    getLiveSessionApi: state.getLiveSessionApi
  }));
  const {
    config,
    configConflict,
    firstConnection,
    getInitialConfigAPI,
    clearConfigHistory,
    configCopy
  } = useConfigStore((state: any) => ({
    config: state.config,
    configConflict: state.configConflict,
    firstConnection: state.firstConnection,
    getInitialConfigAPI: state.getInitialConfigAPI,
    clearConfigHistory: state.clearConfigHistory,
    configCopy: state.configCopy
  }));
  const { amputeeId, deviceId, firmware, deviceConnected } = useDeviceInfoStore((state: any) => ({
    amputeeId: state.amputeeId,
    deviceId: state.deviceId,
    firmware: state.firmware,
    deviceConnected: state.connected
  }));
  const { mainViewLoaded, setItemUiStore } = useUiStore((state: any) => ({
    mainViewLoaded: state.mainViewLoaded,
    setItemUiStore: state.setItemUiStore
  }));
  const [notificationsChannel, setNotificationsChannel] =
    useState<Types.RealtimeChannelPromise | null>();
  const {
    isFirmwareUpdateNeeded,
    isPcbUpgradeNeeded,
    availableFirmwares,
    isFirmwareUpdateAvailable
  } = useCompatibilities();
  const { sendDifferencesDemo } = useUnsaved();
  const { sendConfig } = useRemoteSession();
  const { synchronizeConfig } = useSynchronizeBluetooth();
  useSynchronizeConfigProperties();
  useTelemetry(true);

  // Send changes during demo mode and session

  useEffect(() => {
    sendDifferencesDemo();
    if (liveConfiguratorEnabled) sendConfig();
  }, [JSON.stringify(config), JSON.stringify(configCopy), liveConfiguratorEnabled]);

  // Update config after receiving event from mobile

  useEffect(() => {
    if (amputeeId && me?.id && !notificationsChannel) {
      const channel = `notificationsConfig:${amputeeId}`;
      const notifications = ablyClient(`${me?.id}`).channels.get(channel);

      setNotificationsChannel(notifications);

      notifications?.subscribe('updateConfig', async () => {
        await getInitialConfigAPI();
        clearConfigHistory();
        toast.success('Configuration was changed by patient. Recent one was retrieved.', {
          duration: 10000
        });
      });
    }

    return () => {
      if (notificationsChannel) {
        notificationsChannel.unsubscribe();
      }
    };
  }, [amputeeId, me?.id, notificationsChannel]);

  // Show warning before leaving in session

  useEffect(() => {
    if (!liveConfiguratorEnabled) return;

    function handleLeave(event: any) {
      event.returnValue = 'Session is in progress. Are you sure you want to leave?';
    }

    window.addEventListener('beforeunload', handleLeave);

    return () => window.removeEventListener('beforeunload', handleLeave);
  }, [liveConfiguratorEnabled]);

  // Fetch device config from API at startup

  useEffect(() => {
    if (!liveConfiguratorEnabled && deviceId && !deviceConnected) {
      getInitialConfigAPI().catch(console.error);
    }
  }, [deviceId]);

  // Synchronize device config with config from API

  useEffect(() => {
    if (configConflict && deviceConnected) {
      console.log('GET INITIAL');
      synchronizeConfig(config);
    }
  }, [configConflict]);

  useEffect(() => {
    if (isPcbUpgradeNeeded) {
      toast(
        `This device is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please contact support to upgrade it`,
        { duration: 60000, icon: '⚠️', id: 'isPcbUpgradeNeeded' }
      );
    }
  }, [isPcbUpgradeNeeded]);

  useEffect(() => {
    if (isFirmwareUpdateNeeded) {
      toast(
        `Current device firmware ${firmware?.name} is not fully compatible with software version ${process.env.REACT_APP_SOFTWARE_VERSION}, please update to latest firmware ${availableFirmwares?.[0]?.name}`,
        { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateNeeded' }
      );
    }
  }, [isFirmwareUpdateNeeded]);

  useEffect(() => {
    if (isFirmwareUpdateAvailable) {
      toast(
        `There is a new firmware available, please update to latest version ${availableFirmwares?.[0]?.name}`,
        { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateAvailable' }
      );
    }
  }, [isFirmwareUpdateAvailable]);

  useEffect(() => {
    if (!mainViewLoaded) setItemUiStore('mainViewLoaded', true);
  }, []);

  if (firstConnection === null) {
    return null;
  }

  return children;
};

const ReplayLogic = ({ children }: any) => {
  const configUrl = useReplayStore((state: any) => state.configUrl);
  const getTicketConfigApi = useConfigStore((state: any) => state.geTicketConfigApi);

  useEffect(() => {
    const fetchTicket = async () => {
      await getTicketConfigApi();
    };
    if (configUrl) {
      fetchTicket().catch(console.error);
    }
  }, [configUrl]);

  return children;
};

const MainViews = ({ children }: any) => {
  const { enabled: isRecordReplay } = useReplayStore();
  return isRecordReplay ? <ReplayLogic>{children}</ReplayLogic> : <BaseLogic>{children}</BaseLogic>;
};

export default MainViews;
