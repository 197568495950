import React from 'react';

import { InnerWrapper, PageContentWrapper } from './styled';
import { CircularProgress } from '@mui/material';

type Props = {
  fullScreen?: boolean;
  inline?: boolean;
};

const ConfirmationLoader = ({ fullScreen = false, inline = false }: Props) => {
  return (
    <PageContentWrapper>
      <InnerWrapper fullScreen={fullScreen} inline={inline}>
        <CircularProgress size={50} />
      </InnerWrapper>
    </PageContentWrapper>
  );
};

export default ConfirmationLoader;
