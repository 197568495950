/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, MenuItem, TextField, DialogContent, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import Card from 'configurator/components/atoms/Card/Card';
import {
  TEMPLATE_NOTES_KEY,
  useCreateTemplateNote,
  useDeleteTemplateNote,
  useDeviceConfigTemplates,
  useTemplateNotes
} from 'configurator/hooks/api/useDevice';
import InnerCard from 'configurator/components/atoms/Card/InnerCard';
import { Table } from 'configurator/components/atoms/Table/Table';
import Pagination from 'configurator/components/atoms/Pagination/Pagination';
import Divider from 'configurator/components/atoms/Divider/Divider';
import {
  StyledEye,
  StyledUndo,
  StyledNotesIcon,
  StyledPlus
} from 'configurator/components/atoms/Icons/Icons';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import ImportTemplateModal from 'configurator/modals/ImportTemplateModal';
import { useModal } from 'configurator/hooks/useModal';
import { SortDirs } from 'configurator/utils/types';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import AddNote from 'configurator/modals/AddNoteModal';
import ModalBase from 'configurator/modals/ModalBase';
import { findDifferencesImport } from 'configurator/utils/Config/transformConfig';
import useImportButton from 'configurator/hooks/useImportButton';
import { useConfigStore } from 'configurator/reducers/configStore';
import {
  ConfigTemplatesExtendOptions,
  ConfigTemplatesScopeOptions,
  ConfigNotesSortOptions
} from '../../../api/device/device.types';
import { Header1, HeaderWrapper } from '../../DeviceConfig/styled';
import { CONFIG_TEMPLATES } from 'constants/routes';

export const TextStyled = styled.div`
  max-height: 80px;
  overflow: auto;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;

  button {
    width: auto;
    display: flex;
    align-items: center;
  }
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

const templateOwnerOptions = [
  { id: ConfigTemplatesScopeOptions.me, name: 'Me' },
  { id: ConfigTemplatesScopeOptions.company, name: 'Company' },
  { id: ConfigTemplatesScopeOptions.global, name: 'Aether' }
];

const ChangeItem = ({ changeEntry }: any) => {
  const navigate = useNavigate();
  const { importTemplateConfig, isLoadingDeviceManager } = useDeviceManager();
  const {
    handleClose: handleCloseImportModal,
    handleOpen: handleOpenImportModal,
    isOpen: isOpenImportModal
  } = useModal();
  const { mutateAsync: createTemplateNote, isLoading: isLoadingCreateNote } =
    useCreateTemplateNote();
  const { mutateAsync: deleteTemplateNote } = useDeleteTemplateNote();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    modes: state.config.modes,
    common: state.config.common
  }));
  const queryClient = useQueryClient();
  const importDifferences = findDifferencesImport(common, modes, JSON.parse(changeEntry.config));
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const handlePush = (templateId: number) => {
    navigate(`${CONFIG_TEMPLATES}/${templateId}`);
  };

  const { result: notes } = useTemplateNotes({
    templateId: Number(changeEntry.id),
    // @ts-ignore
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });

  const handleImport = async (modeId: number) => {
    await importTemplateConfig(modeId, Number(changeEntry.id));
    handleCloseImportModal();
  };

  const handleAddNote = async ({ note }: any) => {
    await createTemplateNote({ templateId: Number(changeEntry.id), data: { note } });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteTemplateNote({ templateId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries([TEMPLATE_NOTES_KEY]);
  };

  return (
    <>
      {isOpenImportModal && (
        <ImportTemplateModal
          header='Import template'
          handleAccept={handleImport}
          handleClose={handleCloseImportModal}
          entry={JSON.parse(changeEntry.config)}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <ModalBase handleClick={handleClose} header='Notes' fullWidth>
            <DialogContent>
              <NotesList notes={notes} handleDelete={handleDeleteNote} showTypes={false} />
            </DialogContent>
          </ModalBase>
        </ModalPortal>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote
            handleClose={handleCloseAddNote}
            handleSubmitNote={handleAddNote}
            isLoading={isLoadingCreateNote}
            showTypes={false}
          />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td>{changeEntry?.name}</td>
        <td>
          <TextStyled>{changeEntry?.description}</TextStyled>
        </td>
        <td>
          <TextStyled>{notes?.[0]?.note}</TextStyled>
        </td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Tooltip title={importTooltip()}>
              <span>
                <Button
                  onClick={() => handleOpenImportModal()}
                  size='small'
                  color='secondary'
                  disabled={disableImportButton()}>
                  <StyledUndo style={{ marginRight: '9px' }} />
                  Import
                </Button>
              </span>
            </Tooltip>
            <Button onClick={() => handlePush(changeEntry.id)} size='small' color='secondary'>
              <StyledEye style={{ marginRight: '9px' }} />
              See
            </Button>
            {notes?.length > 0 && (
              <Button onClick={handleOpen} size='small'>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                Show notes
              </Button>
            )}
            <Button onClick={handleOpenAddNote} size='small' color='secondary'>
              <StyledPlus style={{ marginRight: '9px' }} />
              Add note
            </Button>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const ConfigTemplatesComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [ownerSelected, setOwnerSelected] = useState<ConfigTemplatesScopeOptions>(
    ConfigTemplatesScopeOptions.me
  );
  const { result: configTemplates } = useDeviceConfigTemplates({
    params: {
      extend: ConfigTemplatesExtendOptions.author,
      scope: ownerSelected,
      perpage: 10,
      page: currentPage
    }
  });

  useEffect(() => {
    if (currentPage > configTemplates?.paginator?.last_page) {
      setCurrentPage(configTemplates?.paginator?.last_page);
    }
  }, [configTemplates?.paginator?.last_page]);

  return (
    <>
      <HeaderWrapper>
        <Header1>Config Templates</Header1>
        <HeaderInnerWrapper>
          <TextField
            sx={{ width: 150 }}
            label='Owner'
            id='selected-owner'
            select
            SelectProps={{
              onChange: (e: any) => setOwnerSelected(e.target.value),
              value: ownerSelected
            }}>
            {templateOwnerOptions.map((option) => (
              <MenuItem key={`selected-owner_${option.id}`} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </HeaderInnerWrapper>
      </HeaderWrapper>
      <Card>
        <InnerCard>
          <Table>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Note</th>
              <th>Created at</th>
              <th style={{ width: '28%' }}>Actions</th>
            </tr>
            {configTemplates?.items.map((deviceConfigHistoryEntry: any) => (
              <ChangeItem changeEntry={deviceConfigHistoryEntry} />
            ))}
          </Table>
        </InnerCard>
        <Divider margin='20px' />
        <PaginationWrapper>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            lastPage={configTemplates?.paginator?.last_page}
          />
        </PaginationWrapper>
      </Card>
    </>
  );
};

export default ConfigTemplatesComponent;
