import { CircularProgress, Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import FormComboBox, { clinicianOption } from 'components/FormFields/FormComboBox';
import { useForm } from 'react-hook-form';
import { myTheme } from 'theme/theme';
import FormButtonsWrapper from '../../../components/FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as PlusSvg } from 'assets/plus.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalStyle } from '../../../components/Modals/styles';
import { GroupedFormField } from 'adp-panel/pages/Patients/styled';
import CustomTextField from 'components/FormFields/CustomTextField';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { useState } from 'react';
import { emailSchema, isStrongPassword } from 'configurator/views/Register/utils';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { useUsers } from 'adp-panel/hooks/api/useUsers';
import { UsersQueryParams, UserEntry } from 'adp-panel/api/users/users.types';
import { useDeviceInfo, useDeviceUpdate } from 'adp-panel/hooks/api/useDevices';
import * as Sentry from '@sentry/react';
import LoaderWrapper from 'components/Loader/Loader';
import { ErrorComponent } from '../../../components/Error/ErrorComponent';
import { useAddPatient } from 'adp-panel/pages/Patients/Hooks/useAddPatient';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';

const deviceAssignSchema = {
  patient: yup.mixed().test('is-object', VALIDATOR_TEXT.REQUIRED, (value) => {
    return value !== null && typeof value === 'object';
  })
};

const patientCreateSchema = {
  firstName: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  lastName: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  email: emailSchema.required(VALIDATOR_TEXT.REQUIRED),
  password: yup.string().test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
    if (value === undefined || value === null || value === '') return true;

    return isStrongPassword(value);
  }),
  retypePassword: yup
    .string()
    .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
      const parentPassword = this.parent.password;
      if (parentPassword === undefined || parentPassword === null || parentPassword === '')
        return true;

      return parentPassword === value;
    })
};

const AssignDeviceModal = ({ isModalOpen, handleModalClose, deviceId }) => {
  const { result: deviceData, isLoading } = useDeviceInfo(deviceId, true);

  const defaultValues = {
    device: deviceData,
    patient: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    retypePassword: ''
  };
  const [showPatientForm, setShowPatientForm] = useState(false);

  const validationSchema = showPatientForm ? { ...patientCreateSchema } : { ...deviceAssignSchema };
  const {
    control,
    handleSubmit: handleSubmitDevice,
    watch,
    reset
  } = useForm<{
    device: { id: number; serial: string; bluetoothId: string };
    patient: any;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    retypePassword: string;
  }>({
    defaultValues: defaultValues,
    // @ts-ignore
    resolver: yupResolver(yup.object().shape(validationSchema)),
    mode: 'onChange',
    shouldUnregister: false
  });
  const [password] = watch(['password']);
  const queryParams: UsersQueryParams = {
    roles: 'Amputee'
  };
  const {
    result: patientsData,
    total: totalPatients,
    isLoading: isLoadingPatients
  } = useUsers(queryParams);

  const mapPatients = (apiData: UserEntry[]) =>
    apiData?.map((patient) => ({
      ...patient,
      nameFull: `${patient.name} (${patient.email})`
    }));
  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();
  const { handleSubmit: handleSubmitAddPatient, isLoading: isLoadingAddPatient } = useAddPatient();

  const handleSubmit = async (e: any) => {
    const { patient = undefined, email = undefined } = e;
    let newUser;

    if (email) newUser = await handleSubmitAddPatient({ ...e, cancelRedirect: true });

    const deviceData = {
      amputee_id: newUser ? newUser.id : patient.id
    };

    try {
      await updateDevice({ data: deviceData, deviceId });
      reset();
      handleModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const showForm = () => {
    if (isLoading)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    if (deviceData)
      return (
        <form onSubmit={handleSubmitDevice(handleSubmit)}>
          <FormWrapper>
            <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
              <FormComboBox
                label='Device'
                id={'device'}
                control={control}
                options={[deviceData]}
                value={deviceData}
                disabled
                optionLabel='serial'
              />
              {!showPatientForm && (
                <FormComboBox
                  label='Patient'
                  id={'patient'}
                  control={control}
                  options={mapPatients(patientsData)}
                  renderOption={(props, option) =>
                    clinicianOption(props, option, 'nameFull', 'name')
                  }
                  optionLabel={'nameFull'}
                  nameProperty='name'
                  placeholder='Search by name or e-mail'
                  loading={isLoadingPatients}
                />
              )}
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                alignItems: 'center'
              }}>
              <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
              <CustomButton
                onClick={() => setShowPatientForm((prev) => !prev)}
                type='button'
                variant='text'
                Icon={showPatientForm ? AddUserSvg : PlusSvg}
                sx={{ margin: '0 4px' }}>
                {showPatientForm ? 'Assign existing patient' : 'Create new patient'}
              </CustomButton>
              <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
            </div>
            {showPatientForm && (
              <FormWrapper>
                <GroupedFormField>
                  <CustomTextField label='First Name' id='firstName' control={control} />
                  <CustomTextField label='Last Name' id='lastName' control={control} />
                </GroupedFormField>
                <CustomTextField label='E-mail' id='email' control={control} />
                <GroupedFormField>
                  <CustomPasswordField label='Password' id='password' control={control} optional />
                  {password && (
                    <CustomPasswordField
                      label='Re-type Password'
                      id='retypePassword'
                      control={control}
                    />
                  )}
                </GroupedFormField>
              </FormWrapper>
            )}
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancel-medical-form'
                type='button'
                color='light'
                onClick={handleModalClose}>
                Cancel
              </CustomButton>
              <LoadingButton
                data-testid='submit-patient-form'
                type='submit'
                loading={isLoadingUpdate || isLoadingAddPatient}>
                <span>Assign device</span>
              </LoadingButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      );

    return <ErrorComponent message='Device not found' allowRedirect innerComponent />;
  };

  return (
    <Modal open={isModalOpen} sx={{ width: '720px', ...ModalStyle }}>
      <FormContentWrapper title={`Assign Device`}>{showForm()}</FormContentWrapper>
    </Modal>
  );
};

export default AssignDeviceModal;
