import React, { useMemo } from 'react';
import InputOptions from 'configurator/components/molecules/InputOptions/InputOptions';
import {
  configSettingsNamesMap,
  inputSiteMap,
  inputSiteReversedMap
} from 'configurator/utils/definesLocal';
import {
  inputSitesEntry,
  controlModeEntry,
  speedControlStrategyEntry,
  gripSwitchingModeEntry
} from 'configurator/consts/deviceConfig/deviceConfig.types';

const InputSites = ({
  addHistoryProsthesis,
  inputSite,
  controlMode,
  speedControlStrategy,
  gripSwitchingMode,
  disable,
  setControlConfig
}: {
  addHistoryProsthesis: any;
  inputSite: inputSitesEntry;
  controlMode: controlModeEntry;
  speedControlStrategy: speedControlStrategyEntry;
  gripSwitchingMode: gripSwitchingModeEntry;
  disable: boolean;
  setControlConfig: any;
}) => {
  const [inputSiteValue] = inputSite;
  const [controlModeValue] = controlMode;
  const [speedControlStrategyValue] = speedControlStrategy;
  const [gripSwitchingModeValue] = gripSwitchingMode;

  const handleInputSites = (type: any, value: any) => {
    const newControlConfig = [
      inputSiteReversedMap.get(value),
      controlModeValue,
      speedControlStrategyValue,
      gripSwitchingModeValue
    ];
    addHistoryProsthesis(setControlConfig(newControlConfig));
  };

  const InputSites = useMemo(
    () => (
      <InputOptions
        header={configSettingsNamesMap.get('inputSite')!}
        options={['Dual direct', 'Dual Inverted', 'Single']}
        id='sites'
        onChange={handleInputSites}
        // @ts-ignore
        value={inputSiteMap.get(inputSiteValue)}
        disable={disable}
        data-tour='inputSites'
      />
    ),
    [inputSite, controlMode, speedControlStrategy, gripSwitchingMode, disable]
  );

  return InputSites;
};

export default InputSites;
