import { UploadFileInfo } from '@progress/kendo-react-upload';

export const useUploadFiles = (files?: UploadFileInfo[], multiple = false) => {
  const processedFiles: any[] = [];

  if (!files) {
    return multiple ? processedFiles : undefined;
  }

  files.forEach((file) => {
    if (file && file.getRawFile && file.validationErrors?.length === 0)
      processedFiles.push(file.getRawFile());
  });

  if (processedFiles.length === 0) {
    return multiple ? processedFiles : undefined;
  }

  return multiple ? processedFiles : processedFiles[0];
};
