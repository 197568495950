/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import theme from './themeConfigurator';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lightBlue: true;
    light: true;
  }
}

declare module '@mui/material/Pagination' {
  interface PaginationPropsColorOverrides {
    lightPagination: true;
  }
}

declare module '@mui/material/PaginationItem' {
  interface PaginationItemPropsColorOverrides {
    lightPagination: true;
  }
}

declare module '@mui/material/Chip' {
  interface PaginationItemPropsColorOverrides {
    lightSuccess: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    lightBlue: Palette['primary'];
    light: Palette['primary'];
    lightPagination: Palette['primary'];
  }
  interface PaletteOptions {
    lightBlue?: PaletteOptions['primary'];
    light: Palette['primary'];
    lightPagination: Palette['primary'];
  }
}

export const themeMui = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#33499C',
      dark: '#002884',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#EEF4FF',
      dark: '#ba000d',
      contrastText: '#000'
    },
    lightBlue: {
      main: '#EEF4FF',
      light: '#EEF4FF',
      dark: '#d1e1ff',
      contrastText: '#253786'
    },
    light: {
      main: '#FFFFFF',
      light: '#D0D5DD',
      dark: '#f2f2f2',
      contrastText: '#344054'
    },
    lightPagination: {
      main: '#f2f2f2',
      light: '#D0D5DD',
      dark: '#f2f2f2',
      contrastText: '#344054'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif'
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input::placeholder': {
            opacity: 1
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#667085'
          },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#F9FAFB'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: theme.colorFill
        },
        icon: {
          fill: theme.colorPrimary
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: {
          color: theme.colorPrimary
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: 'capitalize',
          borderRadius: '8px'
        }
      }
    },
    // @ts-ignore
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: 'capitalize',
          borderRadius: '8px'
        }
      }
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.colorPrimary,
          fontSize: 12
        },
        arrow: {
          color: theme.colorPrimary
        }
      }
    }
  }
});
