import CustomTextField from 'components/FormFields/CustomTextField';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import FormComboBox, { clinicianOption } from 'components/FormFields/FormComboBox';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { emailSchema, isStrongPassword } from 'configurator/views/Register/utils';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { useDevicesListInfinite } from 'adp-panel/hooks/api/useDevices';
import { DevicesQueryParams } from 'adp-panel/api/devices/device.types';
import { GroupedFormField } from '../styled';
import { useEffect, useState } from 'react';
import { myTheme } from 'theme/theme';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as PlusSvg } from 'assets/plus.svg';
import { ReactComponent as CrossSvg } from 'assets/cross.svg';
import { FormWrapper, FormColumnWrapper } from 'components/FormFields/commonStyles';
import FormCheckbox from 'components/FormFields/FormCheckbox';
import useUserData from 'hooks/useUserData';
import LoaderWrapper from 'components/Loader/Loader';

const patientSchemas = [
  yup.object().shape({
    firstName: yup.string().required(VALIDATOR_TEXT.REQUIRED),
    lastName: yup.string().required(VALIDATOR_TEXT.REQUIRED),
    email: emailSchema.required(VALIDATOR_TEXT.REQUIRED),
    password: yup.string().test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
      if (value === undefined || value === null || value === '') return true;

      return isStrongPassword(value);
    }),
    retypePassword: yup
      .string()
      .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
        const parentPassword = this.parent.password;
        if (parentPassword === undefined || parentPassword === null || parentPassword === '')
          return true;

        return parentPassword === value;
      }),
    device: yup.mixed().test('is-object', 'Wrong format', (value) => {
      if (value === null || value === undefined) return true;

      return value !== null && typeof value === 'object';
    })
  }),
  yup.object().shape({ clinicians: yup.array() })
];

interface PatientFormProps {
  existingData?: any;
  handleSubmit: any;
  isLoading: boolean;
  isAdmin?: boolean;
}

const FORM_STEPS = [
  {
    id: 0,
    label: 'Patient Details',
    content: "Please provide patient's basic information"
  },
  {
    id: 1,
    label: 'Medical Professionals',
    content: "Invite other medical professionals (CPO's OT/PT, etc. to access patient)"
  }
];

const CLINICIANS = [
  {
    id: 2,
    nameFull: 'John Doe (hubert@aetherbiomedical.com)',
    name: 'John Doe',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 3,
    nameFull: 'Mike Furry (hello@aetherbiomedical.com)',
    name: 'Mike Furry',
    email: 'hubert@aetherbiomedical.com'
  },
  {
    id: 4,
    nameFull: 'Klap Tap (hubert@aetherbiomedical.com)',
    name: 'Klap Tap',
    email: 'hubert@aetherbiomedical.com'
  }
];

const ROLES = [
  { id: 0, name: 'Clinician' },
  { id: 1, name: 'Patient' }
];

const PatientForm = ({
  existingData,
  handleSubmit,
  isLoading,
  isAdmin = false
}: PatientFormProps) => {
  const [formStep, setFormStep] = useState(FORM_STEPS[0].id);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    retypePassword: '',
    device: null,
    clinicians: [],
    trainingConfirmation: false
  };

  const isEdit = Boolean(existingData);

  const {
    control,
    handleSubmit: handleSubmitDevice,
    setValue,
    watch,
    trigger
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    password: string | null;
    retypePassword: string | null;
    device: any;
    clinicians: any;
    trainingConfirmation: boolean;
  }>({
    defaultValues: isEdit ? existingData : defaultValues,
    // @ts-ignore
    resolver: yupResolver(patientSchemas[formStep]),
    mode: 'onChange',
    shouldUnregister: false
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clinicians'
  });
  const [password, clinicians] = watch(['password', 'clinicians']);
  const { data: userData } = useUserData();
  const ME = userData ? [{ ...userData, nameFull: `${userData.name} (${userData.email})` }] : [];

  const queryParamsDevice: DevicesQueryParams = {
    perpage: 1000
  };

  const { result: devices } = useDevicesListInfinite(queryParamsDevice, true);

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setFormStep((prevActiveStep) => prevActiveStep + 1);
  };

  const initialFetchData = devices;

  useEffect(() => {
    if (password === '' || password === null || password === undefined)
      setValue('retypePassword', '');
  }, [password]);

  const isLastFilled = () => {
    if (clinicians.length === 0) return true;

    return (
      clinicians?.[clinicians.length - 1]?.clinician && clinicians?.[clinicians.length - 1]?.role
    );
  };

  if (!initialFetchData)
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );

  return (
    <form onSubmit={handleSubmitDevice(handleSubmit)}>
      <Stepper alternativeLabel activeStep={formStep} sx={{ marginBottom: '36px' }}>
        {FORM_STEPS.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel sx={{ '& .MuiStepLabel-label': { fontSize: '16px' } }} {...labelProps}>
                {step.label}
              </StepLabel>
              <Typography
                sx={{ color: myTheme.palette.subtleText, textAlign: 'center', fontSize: '14px' }}>
                {step.content}
              </Typography>
            </Step>
          );
        })}
      </Stepper>
      <div style={{ display: formStep === FORM_STEPS[0].id ? 'block' : 'none' }}>
        <FormWrapper>
          <GroupedFormField>
            <CustomTextField label='First Name' id='firstName' control={control} />
            <CustomTextField label='Last Name' id='lastName' control={control} />
          </GroupedFormField>
          <CustomTextField label='E-mail' id='email' control={control} />
          <GroupedFormField>
            <CustomPasswordField label='Password' id='password' control={control} optional />
            {password && (
              <CustomPasswordField label='Re-type Password' id='retypePassword' control={control} />
            )}
          </GroupedFormField>
          <FormComboBox
            label='Device'
            id='device'
            control={control}
            options={devices}
            optionLabel={'serial'}
            optional
            key='device'
          />
          <FormButtonsWrapper>
            <Button data-testid='continue-patient-form' type='button' onClick={handleNext}>
              <span>Continue</span>
            </Button>
          </FormButtonsWrapper>
        </FormWrapper>
      </div>
      <div style={{ display: formStep === FORM_STEPS[1].id ? 'block' : 'none' }}>
        <FormWrapper>
          <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <FormColumnWrapper>
              <FormComboBox
                label='Role'
                id='roleMe'
                control={control}
                options={ROLES}
                value={ROLES[0]}
                disabled
                optionLabel='name'
              />
              <FormComboBox
                label='Me'
                id='me'
                control={control}
                options={ME}
                value={ME[0]}
                renderOption={(props, option) => clinicianOption(props, option, 'nameFull', 'name')}
                optionLabel={'nameFull'}
                nameProperty='name'
                disabled
              />
            </FormColumnWrapper>
            {fields.map((field, index) => {
              const isFirst = index === 0;

              return (
                <div
                  style={{
                    display: 'grid',
                    gap: '8px',
                    gridTemplateColumns: '170px auto 40px',
                    alignItems: 'end'
                  }}
                  key={field.id}>
                  <FormComboBox
                    label='Role'
                    id={`clinicians.${index}.role`}
                    control={control}
                    options={ROLES}
                    optionLabel='name'
                    showLabel={isFirst}
                    optional
                  />
                  <FormComboBox
                    label='Person'
                    id={`clinicians.${index}.clinician`}
                    control={control}
                    options={CLINICIANS}
                    renderOption={(props, option) =>
                      clinicianOption(props, option, 'nameFull', 'name')
                    }
                    optionLabel={'nameFull'}
                    nameProperty='name'
                    showLabel={isFirst}
                    optional
                    placeholder='Search by name or e-mail'
                  />
                  <CustomButton
                    onClick={() => remove(index)}
                    type='button'
                    variant='text'
                    sx={{ minWidth: '40px', height: '40px' }}
                    Icon={CrossSvg}>
                    <></>
                  </CustomButton>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto 1fr',
              alignItems: 'center'
            }}>
            <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
            <CustomButton
              onClick={() => append({ role: null, clinician: null })}
              type='button'
              variant='text'
              iconProps={{ stroke: myTheme.palette.primary }}
              Icon={PlusSvg}
              disabled={!isLastFilled()}
              sx={{ margin: '0 4px' }}>
              Add next person
            </CustomButton>
            <div style={{ height: '1px', border: `1px solid ${myTheme.palette.primary}` }} />
          </div>
          <FormCheckbox
            label='I confirm that the user I invited has been trained in the use of the application and is competent to do so.'
            id='trainingConfirmation'
            control={control}
          />
          <FormButtonsWrapper>
            <CustomButton
              data-testid='back-patient-form'
              type='button'
              color='light'
              onClick={() => setFormStep(FORM_STEPS[0].id)}>
              Back
            </CustomButton>
            <LoadingButton data-testid='submit-patient-form' type='submit' loading={isLoading}>
              <span>Send invitation</span>
            </LoadingButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </div>
    </form>
  );
};

export default PatientForm;
