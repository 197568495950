import React from 'react';
import { TextField, InputAdornment, Select, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as PLFlag } from 'assets/languages/pl.svg';
import { ReactComponent as GBFlag } from 'assets/languages/gb.svg';
import { ReactComponent as ValidCheck } from 'assets/check-circle.svg';
import { ReactComponent as InValidCheck } from 'assets/info-circle.svg';

const codeIconMap = new Map();
codeIconMap.set('English', <GBFlag />);
codeIconMap.set('Polish', <PLFlag />);

const countries = [
  {
    country: 'GB',
    code: '+44',
    icon: codeIconMap.get('English')
  },
  {
    country: 'PL',
    code: '+48',
    icon: codeIconMap.get('Polish')
  }
];

const StyledCountryCode = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: 20px;
  width: 20px;

  img,
  svg {
    border-radius: 50%;
  }
`;

const CountryItem = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneSelectInput = ({
  phoneNumber,
  selectedCountry,
  onCountryChange,
  onPhoneNumberChange,
  onBlur,
  isValidPhoneNumber,
  value
}) => {
  return (
    <TextField
      value={phoneNumber}
      onChange={(e) => onPhoneNumberChange(e.target.value)}
      onBlur={onBlur}
      size='small'
      variant='outlined'
      fullWidth
      type='tel'
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position='start'>
              <Select
                value={selectedCountry}
                onChange={(e) => onCountryChange(e.target.value)}
                disableUnderline
                variant='standard'
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiSelect-select': {
                    backgroundColor: 'transparent !important'
                  }
                }}>
                {countries.map((country) => (
                  <MenuItem key={country.country} value={country.code}>
                    <CountryItem>
                      <StyledCountryCode>{country.icon}</StyledCountryCode> {country.code}
                    </CountryItem>
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {isValidPhoneNumber ? <ValidCheck /> : <InValidCheck />}
            </InputAdornment>
          )
        }
      }}
    />
  );
};

export default PhoneSelectInput;
