import { useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import { PATIENT_ADD, PATIENT_EDIT, PATIENTS } from '../../../constants/routes';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useQueryClient } from '@tanstack/react-query';
import { USERS_QUERY_KEY, useUserDelete, useUsers } from '../../hooks/api/useUsers';
import { DEBOUNCE_SEARCH_TIME } from '../../constants/search';
import TableHeader from '../../components/Table/TableHeader';
import { Button } from '@progress/kendo-react-buttons';
import { userHasPermissions } from '../../utils/permissionUtils';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import useUserData from '../../../hooks/useUserData';
import { PATIENTS_PERMISSIONS } from '../../constants/rolesPermissions';
import CustomTable from 'adp-panel/components/Table/CustomTable';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as UserChangeSvg } from 'assets/user-change.svg';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import Card from 'adp-panel/components/Card/Card';
import { TableLink } from 'adp-panel/components/Links/Links';

const LocationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DevicesWrapper = styled.td`
  display: flex;
  flex-direction: column;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    white-space: pre;
  }
`;

export const SwitchInner = styled.div`
  margin-right: 12px;
`;

export const hasPermissionToDeletePatient = (
  clinicianId: any,
  rolesByName: any,
  userData: any
): boolean => {
  if (
    userHasPermissions([RoleEnum.clinician], rolesByName) &&
    userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName)
  ) {
    return clinicianId === userData.id;
  }

  return userHasPermissions(PATIENTS_PERMISSIONS.DELETE, rolesByName);
};

const sortMap: any = {
  name: 'user_name',
  clinician: 'clinician_name',
  mrn: 'user_mrn'
};
const mapPatientsRows = (apiData: UserEntry[]) =>
  apiData.map((patient) => ({
    id: patient?.id,
    name: patient?.name,
    clinician: patient?.clinician?.name,
    devices: patient?.devices
  }));

const PatientsList = () => {
  const navigate = useNavigate();
  const { data: userData, rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedPatient, setSelectedPatient] = useState<UserEntry>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const queryClient = useQueryClient();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.devices,
      UserExtendOptions.clinician,
      UserExtendOptions.permissions
    ],
    perpage: 10,
    page: paginationModel.page + 1,
    roles: 'Amputee',
    search: searchTerm
  };
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const [patients, setPatients] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const tableRef = useRef(null) as any;

  const {
    result: patientsData,
    total: totalPatients,
    isLoading: isLoadingPatients
  } = useUsers(queryParams);

  console.log(patientsData, 'PATIENTS');

  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const handleDeleteModal = (patient: UserEntry) => {
    setSelectedPatient(patient);
    handleModalOpen();
  };

  const handleDelete = async () => {
    if (selectedPatient) {
      await deleteUser(selectedPatient.id);
      handleModalClose();
      await queryClient.invalidateQueries([USERS_QUERY_KEY]);
    }
  };

  return (
    <DefaultLayout>
      <Card>
        <div>
          <ConfirmDeleteModal
            isModalOpen={isModalOpen}
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={selectedPatient?.name}
                id={selectedPatient?.id}
                text='Do you want to delete patient'
              />
            }
          />
          <CustomTable
            actions={
              userHasPermissions(PATIENTS_PERMISSIONS.CREATE, rolesByName) && (
                <Button
                  data-testid='button-add'
                  onClick={() => navigate(PATIENT_ADD)}
                  themeColor={'primary'}
                  icon={'plus'}>
                  Add new patient
                </Button>
              )
            }
            tableData={patientsData}
            tableHeader='Patients list'
            totalItems={totalPatients}
            isLoading={isLoadingPatients}
            columns={[
              {
                field: 'name',
                headerName: 'Id',
                flex: 1,
                renderCell: (params) => {
                  const { name, id } = params.row;
                  return (
                    <td>
                      <TableLink to={`${PATIENTS}/${id}`}>{name}</TableLink>
                    </td>
                  );
                }
              },
              {
                field: 'clinician',
                headerName: 'Clinician',
                flex: 1,
                sortable: false
              },
              {
                field: 'devices',
                headerName: 'Devices',
                flex: 1,
                renderCell: (params) => {
                  const { devices } = params.row;
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      {devices?.map((device) => {
                        return <span>{device.serial}</span>;
                      })}
                    </div>
                  );
                }
              },
              {
                field: 'actions',
                type: 'actions',
                flex: 1,
                headerName: 'Actions',
                getActions: (params) => {
                  const { id } = params.row;

                  const actionButtons = [
                    <CustomButton
                      Icon={UserChangeSvg}
                      color='light'
                      onClick={() => navigate(PATIENT_EDIT, { state: { detail: id } })}
                      data-testid='button-edit'
                    />,
                    <CustomButton
                      Icon={TrashSvg}
                      color='light'
                      data-testid='button-delete'
                      onClick={() => handleDeleteModal(id)}
                    />
                  ];

                  return actionButtons;
                }
              }
            ]}
            mapTableData={mapPatientsRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default PatientsList;
