/* eslint-disable react/jsx-key */
import React from 'react';
import { Alert, Link, Typography } from '@mui/material';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { errorApiNotificationParse } from 'configurator/utils/notifications';
import { ConnectionAnimation } from 'configurator/components/atoms/ConnectionAnimation/ConnectionAnimation';
import { CUSTOM_ERRORS } from '../utils';
import { AlertParagraph, AnimationWrapper } from '../styled';
import useLogout from 'hooks/useLogout';

const FooterWrapper = styled.div`
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const RegisterFooter = ({ token, loading }: any) => {
  const { logout } = useLogout();

  return (
    <FooterWrapper>
      {token && !loading && (
        <Link onClick={() => logout()} sx={{ cursor: 'pointer' }}>
          Logout
        </Link>
      )}
      <Typography variant='body2'>{dayjs().format('YYYY')} © Aether Digital Platform</Typography>
    </FooterWrapper>
  );
};

export const ConnectionErrors = ({
  showConnectionError,
  deviceExistsInAdp,
  connectionState,
  checkSerialError
}: any) => {
  if (
    (showConnectionError || deviceExistsInAdp === false || checkSerialError) &&
    connectionState !== FETCHING_STATES.loading
  ) {
    const handleMessage = () => {
      if (checkSerialError) return errorApiNotificationParse(checkSerialError).message;
      if (showConnectionError) return CUSTOM_ERRORS.CONNECTION_ERROR;
      if (deviceExistsInAdp === false) return CUSTOM_ERRORS.DEVICE_NOT_EXIST;

      return 'Something went wrong';
    };

    return <ErrorMessage message={handleMessage()} errors={null} />;
  }

  return null;
};

export const ErrorMessage = ({
  message,
  errors = []
}: {
  message: string | React.JSX.Element | null;
  errors?: string[] | null;
}) => (
  <Alert severity='error' sx={{ whiteSpace: 'pre-line' }}>
    <h3>
      <b>{message}</b>
    </h3>
    {errors?.map((error) => <AlertParagraph>{error}</AlertParagraph>)}
  </Alert>
);

export const ConnectionLoading = ({
  waitText = 'Please wait for the device to connect.',
  showConnectionText = true
}) => (
  <>
    <Typography variant='body2'>{waitText}</Typography>
    <AnimationWrapper>
      <ConnectionAnimation />
      {showConnectionText && <p>Connecting...</p>}
    </AnimationWrapper>
  </>
);
