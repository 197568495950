import api, { withCredentialsAndMultipartForm } from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import { PaginatedResponse } from '../../../types';
import {
  SupportTicketEntry,
  SupportTicketQueryParams,
  SupportTicketsQueryParams,
  TicketMessagePayload,
  TicketReOpenPayload
} from './tickets.types';
import { AxiosResponse } from 'axios';
import { convertToFormData } from '../utils/apiUtils';

export const GET_TICKETS = 'api/tickets';
export const GET_TICKET = 'api/ticket/{ticketId}';
export const CREATE_TICKET_MESSAGE = 'api/ticket/{ticketId}/messages';
export const CLOSE_TICKET = 'api/ticket/{ticketId}/close';
export const READ_TICKET = 'api/ticket/{ticketId}/read';
export const READ_TICKET_MESSAGE = 'api/ticket/{ticketId}/read/{messageId}';
export const REOPEN_TICKET = 'api/ticket/{ticketId}/reopen';

const withCredentials = {
  withCredentials: true
};

export const getSupportTickets = (params: SupportTicketsQueryParams): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get<PaginatedResponse<SupportTicketEntry>>(`${GET_TICKETS}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const getTicket = ({
  ticketId,
  params = {}
}: {
  ticketId: number;
  params: SupportTicketQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData(params);
  return api
    .get<SupportTicketEntry>(
      `${GET_TICKET.replace(`{ticketId}`, `${ticketId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const createTicketMessage = (message: TicketMessagePayload) => {
  return api
    .post(
      `${CREATE_TICKET_MESSAGE.replace('{ticketId}', String(message.ticketId))}`,
      convertToFormData(message),
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);
};

export const closeTicket = (ticketId: number) => {
  return api
    .post(`${CLOSE_TICKET.replace('{ticketId}', String(ticketId))}`, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const readTicket = (ticketId: number) => {
  return api
    .post(`${READ_TICKET.replace('{ticketId}', String(ticketId))}`, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const readTicketMessage = ({
  ticketId,
  messageId
}: {
  ticketId: number;
  messageId: number;
}) => {
  return api
    .post(
      `${READ_TICKET_MESSAGE.replace('{ticketId}', String(ticketId)).replace(
        '{messageId}',
        String(messageId)
      )}`,
      {},
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const reopenTicket = (payload: TicketReOpenPayload) => {
  const { reason } = payload;
  return api
    .post(
      `${REOPEN_TICKET.replace('{ticketId}', String(payload.ticketId))}`,
      { ...(reason && { reason }) },
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};
