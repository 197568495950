import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Caret } from 'assets/caret-up-solid.svg';
import { HeaderM } from '../Typography/Typography';

const AccordionWrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledCaret = styled(Caret)<{ open: boolean }>`
  width: 20px;
  cursor: pointer;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

const Header3 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.colorPrimary};
`;

type AccordionProps = {
  children: any;
  header: string;
  actions?: any;
  [x: string]: any;
};

const Accordion = ({ children, header, actions, ...props }: AccordionProps) => {
  const [open, setOpen] = useState(true);

  return (
    <div {...props}>
      <HeaderWrapper>
        <ActionsWrapper>
          <Header3>{header}</Header3>
          {actions}
        </ActionsWrapper>
        <StyledCaret open={open} onClick={() => setOpen((prev) => !prev)} />
      </HeaderWrapper>
      <AccordionWrapper open={open}>{children}</AccordionWrapper>
    </div>
  );
};

export default Accordion;
