import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import { Avatar, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { envelopIcon } from '@progress/kendo-svg-icons';
import {
  ALLIED_PERMISSIONS,
  CLINIC_ADMIN_PERMISSIONS,
  CLINICIANS_PERMISSIONS,
  COMPANIES_PERMISSIONS
} from 'adp-panel/constants/rolesPermissions';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';
import { ReactComponent as PhoneIcon } from 'assets/vaadin_phone.svg';
import {
  CLINICIAN_EDIT,
  COMPANY,
  DASHBOARD,
  DEVICE,
  LOCATION,
  MEDICAL_PROFESSIONALS,
  PATIENTS
} from 'constants/routes';
import { useTypedParams } from 'hooks/useTypedParams';
import useUserData from 'hooks/useUserData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserRoles } from 'types';
import {
  DeviceEntry,
  DeviceExtendOptions,
  DevicesQueryParams
} from '../../api/devices/device.types';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import Card from '../../components/Card/Card';
import { DetailsLink, TableLink } from '../../components/Links/Links';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import StaticTable from '../../components/Table/StaticTable';
import { HeaderComponent } from '../../components/Typography/Header';
import { useDevicesList } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import { useUser, useUserDelete, useUsersInfinite } from '../../hooks/api/useUsers';
import { rolesNamesMap } from '../../utils/definesLocal';
import { rolesByName, userCanVisit, userHasPermissions } from '../../utils/permissionUtils';
import { DevicesWrapper } from '../Patients/PatientsList';
import {
  AvatarWrapper,
  ButtonsWrapper,
  ClinicianName,
  CompanyDetails,
  ContactDetails,
  GridWrapper,
  IconWrapper,
  LocationDetails,
  RemoveButton,
  TabsWrapper
} from './styled';

const ClinicianDetails = () => {
  const { t } = useTranslation();
  const [updateKey, setUpdateKey] = useState(0);
  const [selected, setSelected] = useState(0);
  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();
  const navigate = useNavigate();
  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const { clinicianId } = useTypedParams(['clinicianId']);
  const { rolesByName: myRoles, me } = useUserData();
  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.roles, UserExtendOptions.permissions]
  };
  const queryParamsPatients: UsersQueryParams = {
    extend: [UserExtendOptions.devices],
    roles: UserRoles.amputee,
    clinician: parseInt(clinicianId),
    perpage: 100
  };
  const queryParamsDevices: DevicesQueryParams = {
    clinician: clinicianId,
    extend: [DeviceExtendOptions.model]
  };
  const { result: clinician, isLoading } = useUser(parseInt(clinicianId, 10), queryParams);
  const { result: patients } = useUsersInfinite(queryParamsPatients);
  const { result: devices } = useDevicesList(queryParamsDevices);

  const clinicianRole = clinician?.roles?.[0].name;

  const handleDelete = async () => {
    if (!clinicianId) {
      return;
    }
    await deleteUser(clinicianId);
    navigate(MEDICAL_PROFESSIONALS);
  };

  const handleEdit = () => {
    navigate(CLINICIAN_EDIT, {
      state: {
        item: {
          ...clinician,
          role: {
            ...clinician.roles?.[0],
            parsedName: rolesNamesMap.get(clinicianRole)
          }
        }
      }
    });
  };

  const hasPermission = () => {
    if (clinicianRole === RoleEnum.clinicAdmin)
      return userHasPermissions(CLINIC_ADMIN_PERMISSIONS.EDIT, myRoles);
    if (clinicianRole === RoleEnum.clinician)
      return userHasPermissions(CLINICIANS_PERMISSIONS.EDIT, myRoles);
    if (clinicianRole === RoleEnum.clinicianSupport)
      return userHasPermissions(ALLIED_PERMISSIONS.EDIT, myRoles);

    if (Number(clinicianId) === me.id) return false;
  };

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    setUpdateKey((prev) => prev + 1);
  }, [devices]);

  const roleName = rolesNamesMap.get(clinicianRole);

  const Details = (
    <>
      <HeaderComponent
        headerText={t('clinicians:component.clinician_details.header', {
          defaultValue: '{{role}} details',
          role: roleName
        })}
      />
      <ConfirmDeleteModal
        isModalOpen={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleAccept={handleDelete}
        isLoading={isLoadingDelete}
        message={
          <ModalMessageDelete
            name={clinician?.name}
            id={Number(clinicianId)}
            text={t('clinicians:component.clinician_details.delete_modal.message', {
              defaultValue: 'Do you want to delete {{role}}',
              role: roleName
            })}
          />
        }
      />
      <Card>
        <GridWrapper>
          <AvatarWrapper>
            <Avatar
              style={{ width: '200px', height: '200px', backgroundColor: 'transparent' }}
              type='image'>
              {clinician?.image ? (
                <img src={clinician.image} alt='Clinician Avatar' />
              ) : (
                <DefaultAvatar />
              )}
            </Avatar>
          </AvatarWrapper>
          <ClinicianName>{clinician?.name}</ClinicianName>
          {hasPermission() && (
            <ButtonsWrapper>
              <RemoveButton
                data-testid='delete-clinician'
                onClick={handleDeleteModalOpen}
                fillMode='outline'
                icon='trash'>
                {t('clinicians:component.clinician_details.button.delete', {
                  defaultValue: 'Delete {{role}}',
                  role: roleName
                })}
              </RemoveButton>
              <Button
                data-testid='edit-clinician'
                onClick={handleEdit}
                icon='pencil'
                themeColor={'primary'}>
                {t('clinicians:component.clinician_details.button.edit', {
                  defaultValue: 'Edit {{role}}',
                  role: roleName
                })}
              </Button>
            </ButtonsWrapper>
          )}
          <CompanyDetails
            headerText={t('clinicians:component.clinician_details.company.header', 'Company')}>
            <DetailsLink
              to={`${COMPANY}/${clinician?.location?.company?.id}`}
              disabled={!userCanVisit(COMPANIES_PERMISSIONS.VIEW, myRoles)}>
              {clinician?.location?.company?.name}
            </DetailsLink>
          </CompanyDetails>
          <ContactDetails
            headerText={t(
              'clinicians:component.clinician_details.contact.header',
              'Contact details'
            )}>
            <IconWrapper>
              <PhoneIcon style={{ marginRight: '12px' }} /> {clinician?.phone}
            </IconWrapper>
            <IconWrapper>
              <SvgIcon icon={envelopIcon} style={{ marginRight: '12px' }} />
              {clinician?.email}
            </IconWrapper>
          </ContactDetails>
        </GridWrapper>
        {userHasPermissions(
          [RoleEnum.clinician, RoleEnum.clinicAdmin],
          rolesByName(clinician?.roles)
        ) && (
          <TabsWrapper>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              style={{ height: 450 }}
              key={updateKey}>
              <TabStripTab
                title={t(
                  'clinicians:component.clinician_details.table_patients.header',
                  'Patients'
                )}>
                <StaticTable
                  fields={[
                    {
                      field: 'name',
                      title: t(
                        'clinicians:component.clinician_details.table_patients.field.name',
                        'Name'
                      ),
                      action: (userId: number) => navigate(`${PATIENTS}/${userId}`),
                      idField: 'id'
                    },
                    { field: 'location.company.country.name', title: 'Country' },
                    {
                      field: 'hand',
                      title: t(
                        'clinicians:component.clinician_details.table_patients.field.hand',
                        'Device'
                      ),
                      cell: (dataItem: any) => {
                        const rows = Array.from(dataItem.devices as DeviceEntry[]).map(
                          (item: DeviceEntry) => {
                            return (
                              <TableLink key={item.id} to={`${DEVICE}/${item.id}`}>
                                {item.serial}
                              </TableLink>
                            );
                          }
                        );
                        return <DevicesWrapper>{rows}</DevicesWrapper>;
                      }
                    }
                  ]}
                  data={patients ?? []}
                />
              </TabStripTab>
              <TabStripTab
                title={t('clinicians:component.clinician_details.table_devices.header', 'Devices')}>
                <StaticTable
                  fields={[
                    {
                      field: 'serial',
                      title: t(
                        'clinicians:component.clinician_details.table_devices.field.serial',
                        'Serial number'
                      ),
                      action: (deviceId: number) => navigate(`${DEVICE}/${deviceId}`),
                      idField: 'id'
                    },
                    { field: 'model.name', title: 'Model' }
                  ]}
                  data={devices ?? []}
                />
              </TabStripTab>
            </TabStrip>
          </TabsWrapper>
        )}
      </Card>
    </>
  );

  return <DefaultLayout>{isLoading ? <ConfirmationLoader /> : Details}</DefaultLayout>;
};

export default ClinicianDetails;
