/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Button, DialogContent, MenuItem, TextField, Tooltip, Pagination } from '@mui/material';
import Card from 'configurator/components/atoms/Card/Card';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDeviceConfigHistory,
  useDeviceConfigHistoryInfinite
} from 'configurator/hooks/api/useDevice';
import { MONTHS, months, YEARS } from 'configurator/consts/consts';
import Calendar from 'configurator/components/organisms/Calendar/Calendar';
import { groupConfigEntries } from 'configurator/utils/Calendar/CalendarUtils';
import InnerCard from 'configurator/components/atoms/Card/InnerCard';
import { useModal } from 'configurator/hooks/useModal';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import { AvatarSnack } from 'configurator/components/atoms/AvatarSnack/AvatarSnack';
import RestoreConfigHistoryModal from 'configurator/modals/RestoreConfigHistoryModal';
import {
  StyledEye,
  StyledNotesIcon,
  StyledUndo,
  StyledPlus
} from 'configurator/components/atoms/Icons/Icons';
import {
  ConfigNotesSortOptions,
  ConfigHistoryEntry,
  ConfigHistoryItemEntry
} from 'configurator/api/device/device.types';
import { SortDirs } from 'configurator/utils/types';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { Table } from 'configurator/components/atoms/Table/Table';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import CalendarPicker from 'configurator/components/molecules/CalendarPicker/CalendarPicker';
import {
  findDifferencesImport,
  transformConfigAPI
} from 'configurator/utils/Config/transformConfig';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import ConfirmationLoader from 'configurator/layouts/ConfirmationLoader/ConfirmationLoader';
import useImportButton from 'configurator/hooks/useImportButton';
import AddNote from 'configurator/modals/AddNoteModal';
import { TextStyled } from 'configurator/views/Templates/ConfigTemplates/ConfigTemplatesComponent';
import { Header1, HeaderWrapper } from '../styled';
import { CheckComponent, ToggleHistoryView } from '../components';
import { DEVICE_HISTORY } from 'constants/routes';
import ModalBase from 'configurator/modals/ModalBase';

const SelectorWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

enum sortByOptions {
  latest = 'desc',
  earliest = 'asc'
}

enum filterByOptions {
  noFilter = 'null',
  restorePoint = '1'
}

const sortOptions = [
  { id: sortByOptions.latest, name: 'Latest' },
  { id: sortByOptions.earliest, name: 'Earliest' }
];

const filterOptions = [
  { id: filterByOptions.noFilter, name: 'No filter' },
  { id: filterByOptions.restorePoint, name: 'Restore point' }
];

const ChangeItem = ({ changeEntry, deviceId }: any) => {
  const navigate = useNavigate();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    modes: state.config.modes,
    common: state.config.common
  }));
  const { restoreConfigHistory, isLoadingDeviceManager } = useDeviceManager();
  const { parsedConfig } = transformConfigAPI(changeEntry, modes);
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId: changeEntry.id,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateConfigNote } =
    useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();
  const importDifferences = findDifferencesImport(common, modes, parsedConfig);
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const handlePush = (configId: number) => {
    navigate(`${DEVICE_HISTORY}/${configId}`, { state: { detail: changeEntry } });
  };

  const handleRestore = async () => {
    await restoreConfigHistory(changeEntry.id);
    handleCloseRestoreConfigHistoryModal();
  };

  const handleAddNote = async ({ note, type }: any) => {
    await createConfigNote({ deviceId, configId: Number(changeEntry.id), data: { note, type } });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteConfigNote({ deviceId, configId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  return (
    <>
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
          entry={parsedConfig}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <ModalBase handleClick={handleClose} header='Notes' fullWidth>
            <DialogContent>
              <NotesList notes={notes} handleDelete={handleDeleteNote} />
            </DialogContent>
          </ModalBase>
        </ModalPortal>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote
            handleClose={handleCloseAddNote}
            handleSubmitNote={handleAddNote}
            isLoading={isLoadingCreateConfigNote}
          />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td aria-label='Author name'>
          <AvatarSnack img={changeEntry?.author?.img} name={changeEntry?.author?.name} />
        </td>
        <td>{changeEntry?.restore_point === 0 ? '-' : <CheckComponent />}</td>
        <td>
          <TextStyled>{notes?.[0]?.note}</TextStyled>
        </td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Tooltip title={importTooltip()}>
              <span>
                <Button
                  color='secondary'
                  size='small'
                  onClick={handleOpenRestoreConfigHistoryModal}
                  disabled={disableImportButton()}>
                  <StyledUndo style={{ marginRight: '9px' }} />
                  Load
                </Button>
              </span>
            </Tooltip>
            <Button onClick={() => handlePush(changeEntry.id)} color='secondary' size='small'>
              <StyledEye style={{ marginRight: '9px' }} />
              See
            </Button>
            {notes?.length > 0 && (
              <Button onClick={handleOpen} size='small'>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                Show notes
              </Button>
            )}
            <Button onClick={handleOpenAddNote} color='secondary' size='small'>
              <StyledPlus style={{ marginRight: '9px' }} />
              Add note
            </Button>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const itemsPerPage = 10;

const DeviceHistoryComponent = () => {
  const isCalendarHistory = useUiStore((state: any) => state.isCalendarHistory);
  const deviceId = useDeviceInfoStore((state: any) => Number(state.deviceId));
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const { result: devicesConfigHistory, isLoading: isLoadingDevicesHistory } =
    useDeviceConfigHistory(deviceId, {
      extend: 'author,entries',
      sortby: 'date',
      sortdir: sortByOptions.latest,
      perpage: 1000
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBySelected, setSortBySelected] = useState(sortByOptions.latest);
  const [filterBySelected, setFilterBySelected] = useState(filterByOptions.noFilter);
  const [dateSelected, setDateSelected] = useState<number>();
  const { result: devicesConfigHistoryLog, isLoading: isLoadingDevicesConfigHistoryLog } =
    useDeviceConfigHistoryInfinite(deviceId, {
      extend: 'author,entries',
      perpage: 100,
      sortby: 'date',
      sortdir: sortBySelected,
      restore_point: filterBySelected,
      ...(dateSelected && {
        date_from: dateSelected,
        date_to: dateSelected + 24 * 60 * 60
      })
    });
  const { result: devicesConfigHistoryCalendar } = useDeviceConfigHistory(deviceId, {
    extend: 'author,entries',
    perpage: 10000
  });
  const { setItemUiStore } = useUiStore();

  const currentPageFirsItemIndex = (currentPage - 1) * itemsPerPage;
  const currentPageLastItemIndex = (currentPage - 1) * itemsPerPage + itemsPerPage;

  const isAutomaticallyCreated = (changeEntries: ConfigHistoryEntry[]) =>
    !changeEntries.find((entry) => Boolean(JSON.parse(entry.old_value)) === true) ||
    changeEntries.length === 0;

  const deviceConfigHistoryFilter = (configHistoryItemEntries: ConfigHistoryItemEntry[]) =>
    configHistoryItemEntries.filter(
      (historyEntry) => historyEntry.index !== 1 && !isAutomaticallyCreated(historyEntry.entries)
    );

  const devicesConfigHistoryLogFiltered = devicesConfigHistoryLog
    ? deviceConfigHistoryFilter(devicesConfigHistoryLog).slice(
        currentPageFirsItemIndex,
        currentPageLastItemIndex
      )
    : [];

  const devicesConfigHistoryFiltered = {
    ...devicesConfigHistory,
    items: devicesConfigHistory?.items ? deviceConfigHistoryFilter(devicesConfigHistory?.items) : []
  };

  const devicesConfigHistoryCalendarFiltered = {
    ...devicesConfigHistoryCalendar,
    items: devicesConfigHistoryCalendar?.items
      ? deviceConfigHistoryFilter(devicesConfigHistoryCalendar?.items)
      : []
  };

  const lastPage = Math.ceil(devicesConfigHistoryFiltered.items?.length / itemsPerPage);

  const handleApply = (selectedDate: any) => {
    const dateFrom = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    setDateSelected(dateFrom.unix());
  };

  const handleCancel = () => {
    setDateSelected(undefined);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const isLoading = isLoadingDevicesConfigHistoryLog || isLoadingDevicesHistory;

  const CalendarHeader = (
    <>
      <Header1>Changes history</Header1>
      <HeaderInnerWrapper>
        <SelectorWrapper>
          <TextField
            sx={{ width: 150 }}
            select
            label='Month'
            id='select-month'
            SelectProps={{
              onChange: (e: any) => setSelectedMonth(e.target.value),
              value: selectedMonth
            }}>
            {MONTHS.map((month) => (
              <MenuItem key={`select-month_${month.id}`} value={month.id}>
                {month.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: 150 }}
            select
            label='Year'
            id='select-year'
            SelectProps={{
              onChange: (e: any) => setSelectedYear(e.target.value),
              value: selectedYear
            }}>
            {YEARS.map((year) => (
              <MenuItem key={`select-year_${year.id}`} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </TextField>
        </SelectorWrapper>
        <ToggleHistoryView
          status={isCalendarHistory}
          handler={() => setItemUiStore('isCalendarHistory', !isCalendarHistory)}
        />
      </HeaderInnerWrapper>
    </>
  );

  const LogsHeader = () => (
    <>
      <Header1>Changes history</Header1>
      <HeaderInnerWrapper>
        {devicesConfigHistoryCalendarFiltered && (
          <CalendarPicker
            style={{ width: '150px' }}
            selected={dateSelected}
            label='Date:'
            events={groupConfigEntries(devicesConfigHistoryCalendarFiltered)}
            handleApply={handleApply}
            handleCancel={handleCancel}
          />
        )}
        <TextField
          sx={{ width: 150 }}
          select
          label='Sort by'
          id='select-sort-by'
          SelectProps={{
            onChange: (e: any) => setSortBySelected(e.target.value),
            value: sortBySelected
          }}>
          {sortOptions.map((option) => (
            <MenuItem key={`select-sort-by_${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ width: 150 }}
          select
          label='Filter by'
          id='select-filter-by'
          SelectProps={{
            onChange: (e: any) => setFilterBySelected(e.target.value),
            value: filterBySelected
          }}>
          {filterOptions.map((option) => (
            <MenuItem key={`select-filter-by_${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <ToggleHistoryView
          status={isCalendarHistory}
          handler={() => setItemUiStore('isCalendarHistory', !isCalendarHistory)}
        />
      </HeaderInnerWrapper>
    </>
  );

  const CalendarView = (
    <>
      {devicesConfigHistoryFiltered?.items && (
        <Calendar
          month={dayjs().year(selectedYear).month(selectedMonth).date(1)}
          events={groupConfigEntries(devicesConfigHistoryFiltered)}
          months={months}
        />
      )}
    </>
  );

  const LogsView = devicesConfigHistoryLogFiltered && (
    <>
      <InnerCard>
        <Table>
          <tr>
            <th style={{ width: '20%' }}>Author name</th>
            <th style={{ width: '12%' }}>Restore point</th>
            <th>Note</th>
            <th style={{ width: '20%' }}>Created at</th>
            <th style={{ width: '28%' }}>Actions</th>
          </tr>
          {devicesConfigHistoryLogFiltered.map((deviceConfigHistoryEntry) => (
            <ChangeItem
              changeEntry={deviceConfigHistoryEntry}
              deviceId={deviceId}
              key={`entry_${deviceConfigHistoryEntry?.id}`}
            />
          ))}
        </Table>
      </InnerCard>
      <Divider margin='20px' />
      <PaginationWrapper>
        <Pagination
          count={lastPage}
          shape='rounded'
          page={currentPage}
          onChange={handleChangePage}
        />
      </PaginationWrapper>
    </>
  );

  if (isLoading) {
    return <ConfirmationLoader />;
  }

  return (
    <>
      <HeaderWrapper>{isCalendarHistory ? CalendarHeader : <LogsHeader />}</HeaderWrapper>
      <Card>{isCalendarHistory ? CalendarView : LogsView}</Card>
    </>
  );
};

export default DeviceHistoryComponent;
