import React from 'react';
import { emgColors } from 'configurator/theme/emgColors/emgColors';
import ChartJS from 'configurator/components/atoms/ChartJS/ChartJS';
import ChartProportionalGraph from 'configurator/components/atoms/ChartJS/ChartProportional';
import { ChartProportionalWrapper, ChartWrapper } from './styled';

export const ChartCombinedData = ({ graphContainer, ...props }) => (
  <ChartJS
    {...props}
    id='combined'
    emgData={[
      {
        label: 'EMG extension',
        backgroundColor: emgColors.emgExtension,
        borderColor: emgColors.emgExtension,
        borderWidth: 4,
        fill: false,
        pointRadius: 0
      },
      {
        label: 'EMG flexion',
        backgroundColor: emgColors.emgFlexion,
        borderColor: emgColors.emgFlexion,
        borderWidth: 4,
        fill: false,
        pointRadius: 0
      }
    ]}
    thresholdsData={[
      {
        label: 'CS Open',
        backgroundColor: emgColors.csOpen,
        borderColor: emgColors.csOpen,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0
      },
      {
        label: 'CS Close',
        backgroundColor: emgColors.csClose,
        borderColor: emgColors.csClose,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0
      },
      {
        label: 'SCS Open',
        backgroundColor: emgColors.activationOpen,
        borderColor: emgColors.activationOpen,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 2]
      },
      {
        label: 'SCS Close',
        backgroundColor: emgColors.activationClose,
        borderColor: emgColors.activationClose,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 2]
      }
    ]}
    ref={graphContainer}
  />
);

export const ChartCombined = ({ graphContainer, ...props }: any) => (
  <ChartWrapper {...props}>
    <ChartCombinedData graphContainer={graphContainer} />
  </ChartWrapper>
);

export const ChartProportionalData = ({ graphContainer }) => (
  <ChartProportionalGraph
    id='proportional'
    emgData={[
      {
        label: 'EMG extension',
        backgroundColor: emgColors.emgExtension,
        borderColor: emgColors.emgExtension,
        borderWidth: 4,
        fill: false,
        pointRadius: 0
      },
      {
        label: 'EMG flexion',
        backgroundColor: emgColors.emgFlexion,
        borderColor: emgColors.emgFlexion,
        borderWidth: 4,
        fill: false,
        pointRadius: 0
      }
    ]}
    thresholdsData={[
      {
        label: 'Power Closing',
        backgroundColor: emgColors.powerClose,
        borderColor: emgColors.powerClose,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0
      },
      {
        label: 'Speed 3 Opening',
        backgroundColor: emgColors.speed3Open,
        borderColor: emgColors.speed3Open,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 10]
      },
      {
        label: 'Speed 3 Closing',
        backgroundColor: emgColors.speed3Close,
        borderColor: emgColors.speed3Close,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 10]
      },
      {
        label: 'Speed 2 Opening',
        backgroundColor: emgColors.speed2Open,
        borderColor: emgColors.speed2Open,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 5]
      },
      {
        label: 'Speed 2 Closing',
        backgroundColor: emgColors.speed2Close,
        borderColor: emgColors.speed2Close,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 5]
      },
      {
        label: 'Speed 1 Opening',
        backgroundColor: emgColors.activationOpen,
        borderColor: emgColors.activationOpen,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 2]
      },
      {
        label: 'Speed 1 Closing',
        backgroundColor: emgColors.activationClose,
        borderColor: emgColors.activationClose,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0,
        borderDash: [5, 2]
      },
      {
        label: 'SCS Closing',
        backgroundColor: emgColors.csOpen,
        borderColor: emgColors.csOpen,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0
      },
      {
        label: 'SCS Opening',
        backgroundColor: emgColors.csClose,
        borderColor: emgColors.csClose,
        borderWidth: 4,
        fill: false,
        hidden: false,
        pointRadius: 0
      }
    ]}
    ref={graphContainer}
  />
);

export const ChartProportional = ({ graphContainer, ...props }: any) => (
  <ChartProportionalWrapper {...props}>
    <ChartProportionalData graphContainer={graphContainer} />
  </ChartProportionalWrapper>
);
