import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addDeviceWithCode,
  createNewActivationCode,
  getActiveDeviceCode
} from 'adp-panel/api/deviceCodes/deviceCodes';
import { DEVICES_QUERY_KEY } from './useDevices';
import ParseApiError from 'adp-panel/utils/parseApiError';
import { Link } from '@mui/material';
import { TEXTS } from 'constants/texts';
import { NotificationFactory } from 'lib/NotificationFactory';
import { mapErrorMessage } from 'utils/notifications';

export const ACTIVE_DEVICE_CODE_QUERY_KEY = 'activeDeviceCode';

export const useActiveDeviceCode = (deviceId: number) => {
  const { data, isLoading, error, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [ACTIVE_DEVICE_CODE_QUERY_KEY, deviceId],
    () => getActiveDeviceCode(deviceId),
    {
      retry: false
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    error,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateActivationCode = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createNewActivationCode, {
    onSuccess() {
      NotificationFactory.successNotification('Activation code created');
      return queryClient.invalidateQueries([ACTIVE_DEVICE_CODE_QUERY_KEY]);
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useAddDeviceWithCode = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError, isSuccess, error } = useMutation(
    addDeviceWithCode,
    {
      onSuccess() {
        NotificationFactory.successNotification('Device added');
        queryClient.invalidateQueries([DEVICES_QUERY_KEY]);
      },
      onError(err: any) {
        if (err?.request?.status === 304) {
          NotificationFactory.errorNotification(
            'Device is assigned to another clinician in your company'
          );
          return;
        }
        if (ParseApiError(err)?.message === 'Serial number is incorrect') {
          NotificationFactory.errorNotification(
            <p>
              We are unable to recognize the device you are trying to connect with, please contact
              us at <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}>{TEXTS.SUPPORT_EMAIL}</Link>.
            </p>
          );
          return;
        }
        if (ParseApiError(err)?.message === 'Device is assigned to another company') {
          NotificationFactory.errorNotification(
            <p>
              Activation code is no longer valid. Please contact Aether Biomedical at{' '}
              <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}>{TEXTS.SUPPORT_EMAIL}</Link>.
            </p>
          );
          return;
        }
        mapErrorMessage(err);
      }
    }
  );

  return {
    result: data,
    isSuccess,
    mutateAsync,
    isLoading,
    isError,
    error
  };
};
