import React, { Component, ReactNode } from 'react';
import { ErrorComponent } from 'adp-panel/components/Error/ErrorComponent';

interface Props {
  children: ReactNode;
  message?: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

export default ErrorBoundary;
