export const codes = [
  {
    code: '+1',
    country: 'US'
  },
  {
    code: '+1',
    country: 'CA'
  },
  {
    code: '+7',
    country: 'RU'
  },
  {
    code: '+27',
    country: 'ZA'
  },
  {
    code: '+30',
    country: 'GR'
  },
  {
    code: '+31',
    country: 'NL'
  },
  {
    code: '+32',
    country: 'BE'
  },
  {
    code: '+33',
    country: 'FR'
  },
  {
    code: '+34',
    country: 'ES'
  },
  {
    code: '+36',
    country: 'HU'
  },
  {
    code: '+39',
    country: 'IT'
  },
  {
    code: '+40',
    country: 'RO'
  },
  {
    code: '+41',
    country: 'CH'
  },
  {
    code: '+43',
    country: 'AT'
  },
  {
    code: '+44',
    country: 'GB'
  },
  {
    code: '+45',
    country: 'DK'
  },
  {
    code: '+46',
    country: 'SE'
  },
  {
    code: '+47',
    country: 'NO'
  },
  {
    code: '+48',
    country: 'PL'
  },
  {
    code: '+49',
    country: 'DE'
  },
  {
    code: '+52',
    country: 'MX'
  },
  {
    code: '+55',
    country: 'BR'
  },
  {
    code: '+61',
    country: 'AU'
  },
  {
    code: '+64',
    country: 'NZ'
  },
  {
    code: '+81',
    country: 'JP'
  },
  {
    code: '+82',
    country: 'KR'
  },
  {
    code: '+86',
    country: 'CN'
  },
  {
    code: '+90',
    country: 'TR'
  },
  {
    code: '+91',
    country: 'IN'
  },
  {
    code: '+351',
    country: 'PT'
  },
  {
    code: '+352',
    country: 'LU'
  },
  {
    code: '+358',
    country: 'FI'
  },
  {
    code: '+359',
    country: 'BG'
  },
  {
    code: '+370',
    country: 'LT'
  },
  {
    code: '+371',
    country: 'LV'
  },
  {
    code: '+372',
    country: 'EE'
  },
  {
    code: '+380',
    country: 'UA'
  },
  {
    code: '+381',
    country: 'RS'
  },
  {
    code: '+385',
    country: 'HR'
  },
  {
    code: '+420',
    country: 'CZ'
  },
  {
    code: '+421',
    country: 'SK'
  },
  {
    code: '+966',
    country: 'SA'
  },
  {
    code: '+971',
    country: 'AE'
  },
  {
    code: '+972',
    country: 'IL'
  }
];
