import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { HeaderBig } from '../../components/Typography/HeaderBig';
import Card from '../../components/Card/Card';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'buttons'
    'avatar'
    'details';
  grid-template-columns: repeat(1, 1fr);
  @media ${({ theme }) => theme.devices.large} {
    grid-template-areas:
      'avatar header buttons buttons'
      'avatar details details details';
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-areas:
      'avatar header header buttons buttons buttons buttons'
      'avatar details details details details details details';
    grid-template-columns: repeat(7, 1fr);
  }
`;

export const AvatarWrapper = styled.div`
  grid-area: avatar;
  width: 100%;
  height: auto;
  @media ${({ theme }) => theme.devices.large} {
    width: 200px;
    height: 200px;
    margin-right: 30px;
  }
`;

export const PatientNameWrapper = styled.div`
  grid-area: header;
`;

export const PatientName = styled.h2`
  ${HeaderBig};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.large} {
    margin: 0;
  }
`;

export const TabsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacing * 3}px;
`;

export const DetailsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LowerGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing * 3}px
    ${({ theme }) => theme.dimensions.spacing * 2}px;
  margin-top: ${({ theme }) => theme.dimensions.spacing * 3}px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const Details = styled(LowerGrid)`
  grid-area: details;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  grid-area: buttons;
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
  }
`;

export const StyledCard = styled(Card)`
  padding: 30px;
`;

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error2} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }
`;

export const StartSessionButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.orange};
  color: white;
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }

  @media ${({ theme }) => theme.devices.large} {
    margin-top: ${({ theme }) => theme.dimensions.spacing}px;
    margin-bottom: 0px;
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ChartsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-template-columns: 100%;
  gap: 40px;
`;

export const GroupedFormField = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 24px;
`;
