import React from 'react';
import styled from 'styled-components';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

type InputOptionsProps = {
  header: string;
  options: string[];
  id: string;
  onChange?: Function;
  value: string;
  description?: string | React.ReactElement;
  disable?: boolean;
  hideInput?: boolean;
  warning?: string | React.ReactElement | null;
};

const InputOptions = ({
  header,
  options,
  id,
  onChange = () => true,
  value,
  description = '',
  disable = false,
  hideInput = false,
  warning,
  ...props
}: InputOptionsProps) => {
  const updateSelectedOption = (option) => {
    onChange(id, option);
  };

  return (
    <div {...props}>
      <div>
        <Typography sx={{ color: '#101A5A', fontWeight: '600' }}>{header}</Typography>
        {description && (
          <Typography variant='body2' sx={{ color: '#344054', marginTop: '4px' }}>
            {description}
          </Typography>
        )}
      </div>
      {!hideInput && (
        <FormControl>
          <RadioGroup row name='position'>
            {options.map((option) => (
              <FormControlLabel
                disabled={disable}
                value={option}
                control={<Radio onChange={() => updateSelectedOption(option)} />}
                label={option}
                checked={value == option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {warning}
    </div>
  );
};

export default InputOptions;
