import { commonFormStyles, LabelStyle, LabelWrapper } from 'components/FormFields/commonStyles';
import React from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { myTheme } from 'theme/theme';

type FormRadioData = {
  label: string;
  value: string | number;
};

type FormRadioGroupProps = {
  id: any;
  label: string;
  control: any;
  data: FormRadioData[];
};

const FormRadioGroup = ({ id, label, control, data = [] }: FormRadioGroupProps) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <FormControl
        {...field}
        error={Boolean(fieldState.error)}
        component='fieldset'
        variant='standard'>
        <FormLabel htmlFor={id} sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle }}>{label}</Typography>
          </LabelWrapper>
        </FormLabel>
        <RadioGroup {...field} aria-labelledby={id} name='radio-buttons-group'>
          {data.map((radioItem) => {
            return (
              <FormControlLabel
                value={radioItem.value}
                sx={{ alignItems: 'flex-start', marginTop: '12px' }}
                control={<Radio />}
                label={
                  <>
                    {radioItem.label}{' '}
                    <Typography
                      sx={{
                        color: myTheme.palette.subtitle,
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}>
                      Hendrerit mauris eget et maecenas integer volutpat commodo. Risus ipsum sed id
                      consectetur tellus.
                    </Typography>
                  </>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    )}
  />
);

export default FormRadioGroup;
