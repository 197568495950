import React from 'react';
import { InputLabel, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

const CustomTextField = ({ id, label, control, disabled = false, optional = false }) => (
  <Controller
    control={control}
    name={id}
    render={({ field, fieldState }) => (
      <div>
        <InputLabel
          shrink={false}
          htmlFor={id}
          sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle }}>{label}</Typography>
            {optional && <Typography>(optional)</Typography>}
          </LabelWrapper>
        </InputLabel>
        <TextField
          {...field}
          id={id}
          size='small'
          variant='outlined'
          disabled={disabled}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error?.message}
        />
      </div>
    )}
  />
);

export default CustomTextField;
