import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as Check } from 'assets/check-solid.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HiddenRadioButton = styled.input.attrs({
  type: 'checkbox'
})`
  height: 15px;
  width: 15px;
  cursor: pointer;
  position: absolute;
  opacity: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    height: 25px;
    width: 25px;
  }
`;

const RadioButton = styled.span`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colorPrimary};
  pointer-events: none;
  background-color: ${({ theme, isChecked }) => (isChecked ? theme.colorPrimary : '#f2f2f2')};
  transition: background-color 0.3s;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 30px;
    height: 30px;
  }
`;

const PressedIcon = styled(Check)`
  position: absolute;
  left: 6px;
  top: 6px;
  width: 9px;
  color: #fff;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    left: 9px;
    top: 8px;
    width: 13px;
  }
  display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
`;

const RadioInput = ({ id, name, checked, ...props }) => (
  <Wrapper {...props}>
    <PressedIcon isChecked={checked ? 1 : 0} />
    <HiddenRadioButton id={id} name={name} />
    <RadioButton isChecked={checked ? 1 : 0} />
  </Wrapper>
);

RadioInput.defaultProps = {
  checked: false
};

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool
};

export default RadioInput;
