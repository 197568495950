import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Center, Description, Text, Title, Wrapper } from './styled';

const NotFoundScreenAuthentication = () => {
  const { t } = useTranslation();

  return (
    <Center>
      <Wrapper>
        <div>
          <Title>{t('common:component.not_found.title', '404')}</Title>
          <Description>{t('common:component.not_found.description', 'Page not found')}</Description>
        </div>
        <div>
          <Text>
            {t('common:component.not_found.footer', {
              defaultValue: '{{year}} © Aether Digital Platform',
              year: dayjs().format('YYYY')
            })}
          </Text>
        </div>
      </Wrapper>
    </Center>
  );
};

export default NotFoundScreenAuthentication;
