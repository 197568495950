/* eslint-disable react/jsx-key */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { FETCHING_STATES } from 'configurator/consts/consts';
import useProcedureReply from 'configurator/hooks/useProcedure';
import { useUiStore } from 'configurator/reducers/uiStore';
import { OptionsWithSlider } from 'configurator/components/molecules/Sliders/Sliders';
import {
  CalibrationWrapper,
  Header2,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureTable,
  ProcedureTableWrapper
} from 'configurator/components/molecules/CalibrationProcedure/styled';

const CheckMovementRangeFingerClosingCombinedProcedure = ({
  sessionEnabled,
  sessionApi,
  channel,
  handleProcedure,
  disableCalibration
}: any) => {
  const procedureData = useProcedureReply(ProcedureTypes.checkMovementRangeTestClosingCombined);
  const procedureState = useUiStore((state: any) => state.procedureState);

  return (
    <>
      <OptionsWithSlider
        header='Test fingers'
        toggleOptions={['On', 'Off']}
        disabled
        toggleValue={0}
        sliderValue={0}
        handleSliderChange={() => false}
        sliderLimits={{ min: 0, max: 0 }}
        sliderLabel={<></>}
        handleToggleChange={() => false}
        handleSliderOnAfterChange={() => false}
        description='The parameters of the fingers will be tested to asses if they are working correctly. Keep the hand in secure position - the fingers will move during the test procedure.'
        hideInput
      />
      <CalibrationWrapper>
        {procedureData && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>Procedure results</Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td aria-label='Procedure information'>&nbsp;</td>
                  <td>Thumb</td>
                  <td>Index</td>
                  <td>Middle</td>
                  <td>Ring</td>
                  <td>Pinky</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Average closing time (ms)</td>
                  {procedureData.closingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Minimum closing time (ms)</td>
                  {procedureData.minimumClosingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Maximum closing time (ms)</td>
                  {procedureData.maximumClosingTimes!.map((time) => (
                    <td colSpan={1}>{time}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Average current (mA)</td>
                  {procedureData.averageCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Minimum current (mA)</td>
                  {procedureData.minimumCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Maximum current (mA)</td>
                  {procedureData.maximumCurrents!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td aria-label='Procedure information'>&nbsp;</td>
                  <td>Thumb</td>
                  <td>Index</td>
                  <td>Middle</td>
                  <td>Ring</td>
                  <td>Pinky</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Upper position</td>
                  {procedureData.positionsUpper!.map((position) => (
                    <td colSpan={1}>{position}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Lower position</td>
                  {procedureData.positionsLower!.map((position) => (
                    <td colSpan={1}>{position}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Upper revolutions</td>
                  {procedureData.revolutionsUpper!.map((revolution) => (
                    <td colSpan={1}>{revolution / 1000}</td>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Lower revolutions</td>
                  {procedureData.revolutionsLower!.map((revolution) => (
                    <td colSpan={1}>{revolution / 1000}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip title={disableCalibration ? 'Device not connected' : null}>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={() =>
                handleProcedure({
                  procedureNumber: ProcedureTypes.checkMovementRangeTestClosingCombined,
                  ...(sessionEnabled && {
                    liveSession: {
                      clinicianUUID: sessionApi?.clinician_uuid,
                      channelName: channel?.name
                    }
                  })
                })
              }>
              <span>Calibration procedure</span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};
export default CheckMovementRangeFingerClosingCombinedProcedure;
