/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { JaaSMeeting } from '@jitsi/react-sdk';
import StandaloneChart from 'configurator/views/Graph/StandaloneChart';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import useMeeting from 'configurator/hooks/useMeeting';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import { GRAPH } from 'constants/routes';
import { Rnd } from 'react-rnd';
import Card from 'adp-panel/components/Card/Card';

const ChartWrapper = styled.div`
  flex: 1;
  margin: 10px;
`;

const OuterChartWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Meeting = () => {
  const location = useLocation();
  const { meetingStatus, viewMode, meetingJwt, roomId } = useMeetingStore();
  const { handleCloseMeeting } = useMeeting();

  const isInForbiddenChartLocation = location.pathname.includes(GRAPH);

  return meetingStatus ? (
    <Rnd
      default={{
        x: 0,
        y: 0,
        width: 320,
        height: 200
      }}>
      <Card>
        <OuterChartWrapper>
          {roomId && (
            <div style={{ flex: '1' }}>
              <JaaSMeeting
                appId={process.env.REACT_APP_JITSI_APP_ID!}
                roomName={roomId}
                getIFrameRef={(iframeRef) => {
                  iframeRef.style.width = '100%';
                  iframeRef.style.height = '100%';
                }}
                jwt={meetingJwt!}
                onReadyToClose={handleCloseMeeting}
              />
            </div>
          )}
          {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) &&
            !isInForbiddenChartLocation && (
              <ChartWrapper>
                <StandaloneChart style={{ height: '100%' }} />
              </ChartWrapper>
            )}
        </OuterChartWrapper>
      </Card>
      {!meetingStatus &&
        (viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
          <Card>
            <OuterChartWrapper>
              {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
                <ChartWrapper>
                  <StandaloneChart style={{ height: '100%' }} />
                </ChartWrapper>
              )}
            </OuterChartWrapper>
          </Card>
        )}
    </Rnd>
  ) : (
    !isInForbiddenChartLocation && (
      <Rnd
        default={{
          x: 0,
          y: 0,
          width: 500,
          height: 500
        }}
        bounds={'parent'}
        style={{ zIndex: '999' }}>
        {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
          <Card style={{ height: '100%', paddingBottom: '50px' }}>
            <StandaloneChart style={{ height: '100%' }} />
          </Card>
        )}
      </Rnd>
    )
  );
};

export default Meeting;
