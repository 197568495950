import React from 'react';
import styled from 'styled-components';
import CompanyImg from 'assets/company_img.jpg';

const AuthorImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
`;

export const AvatarSnack = ({ img, name }: any) => (
  <AuthorImgWrapper>
    <AuthorImg src={CompanyImg || img} alt='Author' />
    <span>{name}</span>
  </AuthorImgWrapper>
);
