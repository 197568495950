import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import Card from '../../components/Card/Card';
import { TabsWrapper } from './styled';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import { USERS_QUERY_KEY, useUserDelete } from '../../hooks/api/useUsers';
import UserTab from './Tabs/UserTab';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getUsers } from '../../api/users/users';
import { TabStripSelectEventArguments } from '@progress/kendo-react-layout/dist/npm/tabstrip/TabStrip';
import { userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../../hooks/useUserData';

const sortMap: any = {
  name: 'user_name',
  company: 'company_name',
  location: 'location_name'
};

const UserManageList = () => {
  const { rolesByName } = useUserData();
  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();
  const queryClient = useQueryClient();
  const [selectedUser, setSelectedUser] = useState<UserEntry>();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.roles, UserExtendOptions.clinician]
  };

  const queryParamsSystemUser = { ...queryParams, ...{ roles: RoleEnum.superAdmin } };
  const queryParamsClinicians = { ...queryParams, ...{ roles: RoleEnum.clinician } };
  const queryParamsClinicAdmin = { ...queryParams, ...{ roles: RoleEnum.clinicAdmin } };
  const queryParamsAlliedHealth = { ...queryParams, ...{ roles: RoleEnum.clinicianSupport } };
  const queryParamsPatients = { ...queryParams, ...{ roles: RoleEnum.amputee } };

  const options = [
    { id: 0, role: null, queryParams },
    { id: 1, role: RoleEnum.superAdmin, queryParams: queryParamsSystemUser },
    { id: 2, role: RoleEnum.clinicAdmin, queryParams: queryParamsClinicAdmin },
    { id: 3, role: RoleEnum.clinician, queryParams: queryParamsClinicians },
    { id: 4, role: RoleEnum.clinicianSupport, queryParams: queryParamsAlliedHealth },
    { id: 5, role: RoleEnum.amputee, queryParams: queryParamsPatients }
  ];
  const [selected, setSelected] = useState(options.find((option) => option.role === null));

  const [users, setUsers] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0
  });
  const tableRef = useRef(null) as any;

  const dataReceived = (users: any) => {
    setUsers(users);
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handleOption = (e: TabStripSelectEventArguments) => {
    setSelected(options.find((option) => option.id === e.selected));
  };

  const handleDelete = (user: UserEntry) => {
    setSelectedUser(user);
    handleModalOpen();
  };

  const deleteComp = async () => {
    if (!selectedUser) {
      return;
    }

    await deleteUser(selectedUser.id);
    handleModalClose();
    await queryClient.invalidateQueries([USERS_QUERY_KEY]);
  };

  const UsersSection = () => (
    <UserTab
      users={users}
      isPatientView={selected?.role === RoleEnum.amputee}
      isCliniciansView={
        (selected?.role &&
          [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicianSupport].includes(
            selected.role
          )) ||
        false
      }
      onDelete={handleDelete}
      dataState={dataState}
      onDataStateChange={dataStateChange}
      isSuperAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
    />
  );

  return (
    <DefaultLayout>
      <HeaderComponent headerText={'User management'} />
      <ConfirmDeleteModal
        isModalOpen={isModalOpen}
        handleClose={handleModalClose}
        handleAccept={deleteComp}
        isLoading={isLoadingDelete}
        message={
          <ModalMessageDelete
            name={selectedUser?.name || ''}
            id={selectedUser?.id || 0}
            text='Do you want to delete user'
          />
        }
      />
      <Card>
        <TabsWrapper>
          <TabStrip selected={selected?.id} onSelect={handleOption}>
            <TabStripTab title='All'>
              <UsersSection />
            </TabStripTab>
            <TabStripTab title='Systems user'>
              <UsersSection />
            </TabStripTab>
            <TabStripTab title='Clinic admin'>
              <UsersSection />
            </TabStripTab>
            <TabStripTab title='Clinicians'>
              <UsersSection />
            </TabStripTab>
            <TabStripTab title='Allied health'>
              <UsersSection />
            </TabStripTab>
            <TabStripTab title='Patients'>
              <UsersSection />
            </TabStripTab>
          </TabStrip>
          <TableDataLoader
            dataState={dataState}
            onDataReceived={dataReceived}
            setDataState={setDataState}
            sortMap={sortMap}
            additionalQueryParams={selected?.queryParams}
            getFetch={getUsers}
            errorMessage={'Failed to fetch users'}
            ref={tableRef}
            queryKey={USERS_QUERY_KEY}
          />
        </TabsWrapper>
      </Card>
    </DefaultLayout>
  );
};

export default UserManageList;
