import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createMessage,
  getMessages,
  markMessageArchive,
  markMessageRead
} from '../../api/messages/messages';
import { mapErrorMessage } from '../../utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';

export const MESSAGES_QUERY_KEY = 'messages';

export const useMessages = (queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [MESSAGES_QUERY_KEY, queryParams],
    () => getMessages(queryParams),
    {
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useMessagesInfinite = (queryParams?: any, dependency: any = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [MESSAGES_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getMessages({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useMessageCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createMessage, {
    onSuccess() {
      NotificationFactory.successNotification('Message sent successfully');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMessageMarkRead = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(markMessageRead, {
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMessageMarkArchive = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(markMessageArchive, {
    onSuccess() {
      NotificationFactory.successNotification('Message archived');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
