/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { singleElectrodeSettingsAlternatingEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { singleElectrodeSettingsAlternatingNamingsMap } from 'configurator/consts/deviceConfig/configPropertiesNamings';
import { singleElectrodeSettingsAlternatingPositions } from 'configurator/consts/deviceConfig/configPropertiesPositions';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';
import styled from 'styled-components';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;
const SingleElectrodeSettingsAlternating = ({
  addHistoryProsthesis,
  singleElectrodeSettingsAlternating,
  disabled,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  singleElectrodeSettingsAlternating: singleElectrodeSettingsAlternatingEntry;
  disabled: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [singleElectrodeSettingsAlternatingLocal, setSingleElectrodeModeSettingsAlternatingLocal] =
    useState<any>([
      // @ts-ignore
      initialState.config.singleElectrodeSettingsAlternating?.[0],
      [
        // @ts-ignore
        initialState.config.singleElectrodeSettingsAlternating?.[3],
        // @ts-ignore
        initialState.config.singleElectrodeSettingsAlternating?.[4]
      ],
      [
        // @ts-ignore
        initialState.config.singleElectrodeSettingsAlternating?.[2],
        // @ts-ignore
        initialState.config.singleElectrodeSettingsAlternating?.[1]
      ]
    ]);

  const handleAlternatingSliders = (value: any, sliderNumber: any) => {
    let type:
      | 'stateSwitchDelay'
      | 'minimumTimeBetweenPulses'
      | 'maximumTimeBetweenPulses'
      | 'minimumPulseTime'
      | 'maximumPulseTime';
    let payload;
    switch (sliderNumber) {
      case 0:
        type = 'stateSwitchDelay';
        payload = [
          value,
          singleElectrodeSettingsAlternating[1],
          singleElectrodeSettingsAlternating[2],
          singleElectrodeSettingsAlternating[3],
          singleElectrodeSettingsAlternating[4],
          singleElectrodeSettingsAlternating[5]
        ];
        break;
      case 1: {
        type = 'minimumPulseTime' || 'maximumPulseTime';

        const minimumPulseTime = value[0];
        const maximumPulseTime = value[1];

        payload = [
          singleElectrodeSettingsAlternating[0],
          singleElectrodeSettingsAlternating[1],
          singleElectrodeSettingsAlternating[2],
          minimumPulseTime,
          maximumPulseTime,
          singleElectrodeSettingsAlternating[5]
        ];
        break;
      }
      case 2: {
        type = 'minimumTimeBetweenPulses' || 'maximumTimeBetweenPulses';

        const minimalTimeBetweenPulses = value[0];
        const maximalTimeBetweenPulses = value[1];

        payload = [
          singleElectrodeSettingsAlternating[0],
          maximalTimeBetweenPulses,
          minimalTimeBetweenPulses,
          singleElectrodeSettingsAlternating[3],
          singleElectrodeSettingsAlternating[4],
          singleElectrodeSettingsAlternating[5]
        ];
        break;
      }
      default:
        payload = [
          singleElectrodeSettingsAlternating[0],
          singleElectrodeSettingsAlternating[1],
          singleElectrodeSettingsAlternating[2],
          singleElectrodeSettingsAlternating[3],
          singleElectrodeSettingsAlternating[4],
          singleElectrodeSettingsAlternating[5]
        ];
    }
    // @ts-ignore
    addHistoryProsthesis(setConfigProperty('singleElectrodeSettingsAlternating', payload));
  };

  useEffect(() => {
    if (singleElectrodeSettingsAlternating) {
      const stateSwitchDelay =
        singleElectrodeSettingsAlternating[
          singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
        ];
      const minimumPulseTime =
        singleElectrodeSettingsAlternating[
          singleElectrodeSettingsAlternatingPositions.minimumPulseTime
        ];
      const maximumPulseTime =
        singleElectrodeSettingsAlternating[
          singleElectrodeSettingsAlternatingPositions.maximumPulseTime
        ];
      const minimumTimeBetweenPulses =
        singleElectrodeSettingsAlternating[
          singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
        ];
      const maximumTimeBetweenPulses =
        singleElectrodeSettingsAlternating[
          singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
        ];
      setSingleElectrodeModeSettingsAlternatingLocal([
        stateSwitchDelay,
        [minimumPulseTime, maximumPulseTime],
        [minimumTimeBetweenPulses, maximumTimeBetweenPulses]
      ]);
    }
  }, [singleElectrodeSettingsAlternating]);

  const SingleElectrodeSettingsAlternating = useMemo(
    () => (
      <MultiSlider
        header='Alternating options'
        sliderValues={singleElectrodeSettingsAlternatingLocal}
        disabled={disabled}
        limits={[
          { min: 500, max: 1500 },
          { min: 1, max: 100 },
          { min: 500, max: 1500 }
        ]}
        handleChange={(...args: any) =>
          setSingleElectrodeModeSettingsAlternatingLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>
            {singleElectrodeSettingsAlternatingNamingsMap.get(
              singleElectrodeSettingsAlternatingPositions.stateSwitchDelay
            )}
            : {parseNumber(singleElectrodeSettingsAlternatingLocal?.[0])} s
          </p>,
          <LabelWrapper>
            <p>
              {singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.minimumPulseTime
              )}
              : {parseNumber(singleElectrodeSettingsAlternatingLocal?.[1][0])} s
            </p>
            <p>
              {singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.maximumPulseTime
              )}
              : {parseNumber(singleElectrodeSettingsAlternatingLocal?.[1][1])} s
            </p>
          </LabelWrapper>,
          <LabelWrapper>
            <p>
              {singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses
              )}
              : {parseNumber(singleElectrodeSettingsAlternatingLocal?.[2][0])} s
            </p>
            <p>
              {singleElectrodeSettingsAlternatingNamingsMap.get(
                singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses
              )}
              : {parseNumber(singleElectrodeSettingsAlternatingLocal?.[2][1])} s
            </p>
          </LabelWrapper>
        ]}
        handleOnAfterChange={(...args: any) => handleAlternatingSliders(args[1], args[2])}
        description='Sets alternating settings'
      />
    ),
    [singleElectrodeSettingsAlternating, singleElectrodeSettingsAlternatingLocal, disabled]
  );

  return SingleElectrodeSettingsAlternating;
};

export default SingleElectrodeSettingsAlternating;
