import { useEffect, useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import FormInput from '../../components/FormInput/FormInput';
import { requiredValidator } from '../../components/FormInput/validators';
import FormTextArea from '../../components/FormInput/FormTextArea';
import FormMultiSelect from '../../components/FormInput/FormMultiSelect';
import { useNavigate } from 'react-router-dom';
import { HeaderComponent } from '../../components/Typography/Header';
import { useLocationsInfinite } from '../../hooks/api/useLocations';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { useMessageCreate } from '../../hooks/api/useMessages';
import { DASHBOARD } from '../../../constants/routes';
import { FormRadioGroup } from '../../components/FormInput/FormRadioGroup';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1040px;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const targets = ['SuperAdmin', 'Clinician', 'Amputee'];

const AddMessage = () => {
  const [formUpdateKey, setFormUpdateKey] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const { mutateAsync: createMessage, isLoading } = useMessageCreate();
  const { result: companies } = useCompaniesInfinite({ perpage: 100 });
  const { result: locations, refetch: refetchLocations } = useLocationsInfinite(
    { company: selectedCompany?.toString(), perpage: 100 },
    !!selectedCompany
  );
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    const { title, content = undefined, company = undefined, location = undefined } = e;
    let roles: any = undefined;
    if (e?.roles?.length > 0) roles = e.roles;

    const messageData = {
      title,
      ...(content && { content }),
      ...(company && { company: company.id }),
      ...(location && { location: location.id }),
      ...(roles && { roles: roles.join() })
    };

    try {
      await createMessage(messageData);
      navigate(DASHBOARD);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    refetchLocations().then(() => {
      setFormUpdateKey((prev) => prev + 1);
    });
  }, [selectedCompany]);

  const onCompanyChange = (e: any) => {
    const company = e.value;
    setSelectedCompany(company.id);
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={'New push message'} />
        <StyledCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <FieldsWrapper>
                  <Fieldset
                    className={'k-form-fieldset'}
                    style={{ margin: 0, justifySelf: 'start' }}>
                    <Field
                      id={'title'}
                      name={'title'}
                      label={'Title'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <Field
                      id={'content'}
                      name={'content'}
                      label={'Message'}
                      component={FormTextArea}
                      validator={requiredValidator}
                      rows={8}
                    />
                    <Field
                      id={'targets_option'}
                      name={'targets_option'}
                      label={'Send to'}
                      component={FormRadioGroup}
                      validator={requiredValidator}
                      onChange={(e) => {
                        onCompanyChange(e);
                        formRenderProps.onChange('company', { value: null });
                        formRenderProps.onChange('location', { value: null });
                        formRenderProps.onChange('roles', { value: null });
                      }}
                      data={[
                        { label: 'All', value: 'all' },
                        { label: 'Selected targets', value: 'targets' }
                      ]}
                    />
                    {formRenderProps.valueGetter('targets_option') === 'targets' && (
                      <>
                        <Field
                          id={'roles'}
                          name={'roles'}
                          label={'Targets'}
                          component={FormMultiSelect}
                          validator={requiredValidator}
                          data={targets}
                        />
                        {companies && (
                          <Field
                            id={'company'}
                            name={'company'}
                            label={'Company'}
                            component={FormComboBox}
                            optional={true}
                            data={companies}
                            filterable={true}
                            textField='name'
                            onChange={(e) => {
                              onCompanyChange(e);
                              formRenderProps.onChange('location', { value: null });
                            }}
                          />
                        )}
                        {selectedCompany && locations?.length > 0 && (
                          <Field
                            id={'location'}
                            name={'location'}
                            label={'Location'}
                            component={FormComboBox}
                            data={locations}
                            optional={true}
                            filterable={true}
                            textField='name'
                            key={formUpdateKey}
                          />
                        )}
                      </>
                    )}
                  </Fieldset>
                </FieldsWrapper>
                <ButtonsWrapper className='k-form-buttons'>
                  <Button onClick={() => navigate(-1)} type='button'>
                    Cancel
                  </Button>
                  <Button
                    themeColor={'primary'}
                    type='submit'
                    disabled={!formRenderProps.allowSubmit || isLoading}>
                    Send push
                    {isLoading && <LoaderButton />}
                  </Button>
                </ButtonsWrapper>
              </FormElement>
            )}
          />
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddMessage;
