import React, { useEffect } from 'react';
import CustomTextField from 'components/FormFields/CustomTextField';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { LoadingButton } from '@mui/lab';
import { IconBackgroundWrapper } from 'configurator/components/atoms/Icons/Icons';
import FormCheckbox from 'components/FormFields/FormCheckbox';
import { ReactComponent as HomeRegister } from 'assets/home-register.svg';
import CustomVerificationCode from 'configurator/components/molecules/CustomVerificationCode/CustomVerificationCode';
import PasswordStrength from 'configurator/components/atoms/PasswordStrength/PasswordStrength';
import { errorApiNotificationParse } from 'configurator/utils/notifications';
import { ConnectionAnimation } from 'configurator/components/atoms/ConnectionAnimation/ConnectionAnimation';
import { useDevicesListInfinite } from 'configurator/hooks/api/useDevice';
import { Alert, Button, Link, Tooltip, Typography } from '@mui/material';
import HandImage from 'assets/precision-open-minimal.png';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import EmailIcon from '@mui/icons-material/Email';
import { API_ERRORS } from 'configurator/api/errors';
import InfoIcon from '@mui/icons-material/Info';
import {
  AlertParagraph,
  AnimationWrapper,
  CodeWrapper,
  FooterAction,
  HandImageWrapper,
  LoadingButtonAdpBlue
} from '../styled';
import { ConnectionErrors, ConnectionLoading, ErrorMessage } from '../Components/Components';
import { MESSAGES } from '../utils';
import useUserData from 'hooks/useUserData';
import { FormWrapper } from 'components/FormFields/commonStyles';

export const FORM_STEPS = {
  welcome: 0,
  connection: 1,
  register: 2,
  login: 3,
  loginSuccess: 4,
  mfaVerify: 5,
  verifyCode: 6,
  notAllowed: 7,
  forgottenPassword: 8
};

export const DeviceConnection = ({ serial, handleDisconnect }: any) => (
  <Alert
    severity='success'
    sx={{
      '& .MuiAlert-message': {
        width: '100%'
      }
    }}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
      <div>
        <h3>
          <b>Device connected</b>
        </h3>
        <AlertParagraph>{`Serial number: ${serial}`}</AlertParagraph>
      </div>
      <Tooltip title='Disconnect' className='dark-tooltip'>
        <button
          type='button'
          onClick={handleDisconnect}
          style={{
            cursor: 'pointer',
            border: 'none',
            width: '33px',
            height: '33px',
            backgroundColor: '#FFFFFF',
            borderRadius: '4px'
          }}>
          <BluetoothDisabledIcon sx={{ width: '20px', height: '20px' }} />
        </button>
      </Tooltip>
    </div>
  </Alert>
);

export const StepWelcome = ({ startConfiguration }: any) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
      <Typography variant='h5'>Prepare to activate Zeus</Typography>
      <Alert severity='info'>
        <h3>
          <b>Before start</b>
        </h3>
        <AlertParagraph>
          Connect the Zeus hand to the prosthesis with the prosthesis battery turned on. Have the
          activation code that was delivered with your Zeus hand ready.
        </AlertParagraph>
      </Alert>
    </div>
    <Button sx={{ marginTop: '40px' }} onClick={startConfiguration} className='adpBlue'>
      Click to Pair Device
    </Button>
  </div>
);

export const StepConnection = ({
  connectionState,
  showConnectionError,
  deviceConnected,
  serial,
  setFormStep,
  onChangeCode,
  valueCode,
  startConfiguration,
  deviceExistsInAdp,
  verificationCodeValid,
  handleDisconnect,
  isLoading,
  checkSerialError
}: any) => {
  const successfulConnection = deviceConnected && deviceExistsInAdp;
  const loadingData = connectionState === FETCHING_STATES.loading || isLoading;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', width: '100%' }}>
        <Typography variant='h5'>{successfulConnection ? 'Activation code' : 'Device'}</Typography>
        {loadingData && <ConnectionLoading />}
        {!loadingData && (
          <ConnectionErrors
            deviceExistsInAdp={deviceExistsInAdp}
            showConnectionError={showConnectionError}
            connectionState={connectionState}
            checkSerialError={checkSerialError}
          />
        )}
        {successfulConnection && (
          <>
            <Typography variant='body2'>The device was successfully connected.</Typography>
            <DeviceConnection serial={serial} handleDisconnect={handleDisconnect} />
            <CodeWrapper>
              <Typography>{MESSAGES.PLEASE_ENTER_CODE}</Typography>
              <CustomVerificationCode onChange={onChangeCode} value={valueCode} />
              <Button
                disabled={!verificationCodeValid}
                onClick={() => setFormStep(FORM_STEPS.register)}
                className='adpBlue'>
                Activate device
              </Button>
            </CodeWrapper>
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
                marginTop: '32px'
              }}>
              <div>
                <Typography>
                  {`Don't have a code? Contact us by writing to the email address below`}
                </Typography>
                <Link
                  href='mailto:support@aetherbiomedical.com'
                  sx={{
                    marginTop: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '6px'
                  }}>
                  <EmailIcon /> support@aetherbiomedical.com
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      {(showConnectionError || deviceExistsInAdp === false || checkSerialError) && !loadingData && (
        <>
          <LoadingButton
            sx={{ marginTop: '40px', ...LoadingButtonAdpBlue }}
            onClick={startConfiguration}
            loading={connectionState === FETCHING_STATES.loading}>
            <span>Click to Pair Device</span>
          </LoadingButton>
        </>
      )}
    </div>
  );
};

export const StepLogin = ({
  handleSubmit,
  onSubmit,
  serial,
  control,
  loadingLogin,
  setFormStep,
  handleDisconnect,
  errorLogin,
  handleForgottenPassword
}: any) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <FormWrapper style={{ display: 'grid', gap: '16px' }}>
      <Typography variant='h5'>Log in!</Typography>
      <Typography>
        Already registered with us? Sign in with your email to connect to the configurator or
        explore the Aether Digital Platform (ADP) for additional functionalities.
      </Typography>
      <DeviceConnection serial={serial} handleDisconnect={handleDisconnect} />
      <CustomTextField label='Email' id='email' control={control} />
      <CustomPasswordField label='Password' id='password' control={control} />
      <Link
        onClick={handleForgottenPassword}
        style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
        Forgotten password
      </Link>
      {errorLogin && (
        <ErrorMessage
          message={errorApiNotificationParse(errorLogin).message}
          errors={errorApiNotificationParse(errorLogin).errors}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingButton type='submit' loading={loadingLogin} sx={{ ...LoadingButtonAdpBlue }}>
          <span>Log in</span>
        </LoadingButton>
      </div>
      <div style={{ display: 'flex', gap: '8px', marginTop: '40px', justifySelf: 'center' }}>
        <Typography>Is this your first device from Aether?</Typography>
        <Link onClick={() => setFormStep(FORM_STEPS.register)} sx={{ cursor: 'pointer' }}>
          Register here
        </Link>
      </div>
    </FormWrapper>
  </form>
);

const RegisterError = ({ errorRegister, onChangeCode, valueCode }: any) => {
  if (!errorRegister) return null;

  const wrongCodeError =
    errorApiNotificationParse(errorRegister).code === API_ERRORS.AUTH.REGISTER.CODE_INCORRECT.code;

  return (
    <>
      <ErrorMessage
        message={errorApiNotificationParse(errorRegister).message}
        errors={errorApiNotificationParse(errorRegister).errors}
      />
      {wrongCodeError && (
        <CodeWrapper style={{ margin: '0 0 16px 0' }}>
          <Typography>{MESSAGES.PLEASE_ENTER_CODE}</Typography>
          <CustomVerificationCode
            onChange={onChangeCode}
            value={valueCode}
            error={wrongCodeError}
          />
        </CodeWrapper>
      )}
    </>
  );
};

export const StepRegister = ({
  handleSubmit,
  onSubmit,
  serial,
  control,
  loadingLogin,
  setFormStep,
  password,
  onChangeCode,
  valueCode,
  verificationCodeValid,
  handleDisconnect,
  errorRegister
}: any) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <FormWrapper style={{ display: 'grid', gap: '16px' }}>
      <Typography variant='h5'>Create a New Account</Typography>
      <Typography>
        To register new account, you need to enter your email address and password.
      </Typography>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Typography>
          If you already have an account, please log in{' '}
          <Link onClick={() => setFormStep(FORM_STEPS.login)} sx={{ cursor: 'pointer' }}>
            here.
          </Link>
        </Typography>
      </div>
      <DeviceConnection serial={serial} handleDisconnect={handleDisconnect} />
      <CustomTextField label='Email' id='email' control={control} />
      <CustomPasswordField label='Password' id='password' control={control} />
      <PasswordStrength password={password} minLength={8} numbers specialChars upperCase />
      <CustomPasswordField label='Re-type password' id='retypePassword' control={control} />
      <CustomTextField label='Full Name' id='name' control={control} />
      <CustomTextField
        label={
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '5px' }}>Company name</div>
            <Tooltip
              className='dark-tooltip'
              placement='top-end'
              title='Enter the official name of your company as it should appear in the Aether Digital Platform (ADP). This will be used for your profile identification and all official records.'>
              <InfoIcon sx={{ width: '16px', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        }
        id='companyName'
        control={control}
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            I have read and on behalf of the Partner I accept the{' '}
            <b>
              <Link
                target='_blank'
                href='https://aether-prod-bucket.s3.amazonaws.com/documents/POL-8_Privacy_Policy_of_Aether_Digital_Platform_v_2.pdf'
                rel='noreferrer'>
                Privacy policy of Aether Digital Platform
              </Link>
            </b>{' '}
            and{' '}
            <b>
              <Link
                target='_blank'
                href='https://aether-prod-bucket.s3.amazonaws.com/documents/POL-9_Terms_of_Service_of_Aether_Digital_Platform_v_2.pdf'
                rel='noreferrer'>
                Terms of Service of Aether Digital Platform.
              </Link>
            </b>
          </span>
        }
        id='privacy-policy'
        control={control}
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            By creating Partner’s Account, on behalf of the Partner I hereby enter into a{' '}
            <b>
              <Link
                target='_blank'
                href='https://aether-prod-bucket.s3.amazonaws.com/documents/POL-10_Data_Processing_Agreement_v_1.pdf'
                rel='noreferrer'>
                {' '}
                Data Processing Agreement.
              </Link>
            </b>
          </span>
        }
        id='data-processing-policy'
        control={control}
      />
      <RegisterError
        errorRegister={errorRegister}
        onChangeCode={onChangeCode}
        valueCode={valueCode}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LoadingButton
          type='submit'
          loading={loadingLogin}
          disabled={!verificationCodeValid}
          sx={{ ...LoadingButtonAdpBlue }}>
          <span>Create</span>
        </LoadingButton>
      </div>
    </FormWrapper>
  </form>
);

export const StepLoginSuccess = ({ redirectConfigurator, isRegister, redirectAdp }: any) => (
  <div style={{ display: 'grid', gap: '16px' }}>
    <Typography variant='h5'>
      {isRegister ? 'Congratulations! Your Account Is Ready!' : 'Log in successful!'}
    </Typography>
    <Typography variant='body2'>
      The device has been successfully assigned to your account.
    </Typography>
    <FooterAction>
      <HandImageWrapper src={HandImage} alt='Zeus hand' />
      <Typography>
        {isRegister
          ? 'Everything is set up, and you can proceed to the Zeus Configurator. Remember, advanced features, such as remote configuration are not available without creating a patient account.'
          : 'If you want to set the Zeus hand up and make settings changes.'}
      </Typography>
      <Button onClick={redirectConfigurator} className='adpBlue'>
        Start Zeus Configurator
      </Button>
    </FooterAction>
    {!isRegister && (
      <FooterAction>
        <IconBackgroundWrapper icon={<HomeRegister />} primary={false} />
        <Typography>
          If you want to go to ADP. This is where you can access advanced features such as remote
          configuration, device usage monitoring, data analytics and more.
        </Typography>
        <Button onClick={redirectAdp} color='info'>
          Go to the ADP
        </Button>
      </FooterAction>
    )}
  </div>
);

export const StepVerifyCode = ({
  errorAddDevice,
  onChangeCode,
  valueCode,
  handleAddDevice,
  isLoading,
  verificationCodeValid,
  serialNumber
}: any) => {
  const { me, isLoading: isLoadingMe } = useUserData();
  const {
    result: devicesApi,
    isLoading: isLoadingDevices,
    refetch: refetchDevices,
    isSuccess: isSuccessDevices
  } = useDevicesListInfinite({ perPage: '200' }, Boolean(me?.id));

  useEffect(() => {
    if (valueCode && !errorAddDevice && devicesApi) {
      const deviceAccess = devicesApi.find((deviceApi: any) => deviceApi.serial === serialNumber);
      handleAddDevice(deviceAccess);
    }
  }, [JSON.stringify(devicesApi)]);

  return (
    <form>
      <FormWrapper style={{ display: 'grid', gap: '16px' }}>
        {(isLoadingDevices || isLoadingMe || isLoading) && (
          <AnimationWrapper>
            <ConnectionAnimation />
            <Typography>Your device is being added...</Typography>
          </AnimationWrapper>
        )}
        {errorAddDevice && (
          <>
            <ErrorMessage
              message={errorApiNotificationParse(errorAddDevice).message}
              errors={errorApiNotificationParse(errorAddDevice).errors}
            />
            {errorApiNotificationParse(errorAddDevice).code ===
              API_ERRORS.DEVICE.ADD_WITH_CODE.INCORRECT_CODE.code && (
              <CodeWrapper style={{ margin: '0 0 16px 0' }}>
                <Typography>{MESSAGES.PLEASE_ENTER_CODE}</Typography>
                <CustomVerificationCode
                  onChange={onChangeCode}
                  value={valueCode}
                  error={errorAddDevice}
                />
                <Button
                  onClick={() => handleAddDevice(false)}
                  disabled={!verificationCodeValid}
                  className='adpBlue'>
                  Activate device
                </Button>
              </CodeWrapper>
            )}
          </>
        )}
      </FormWrapper>
    </form>
  );
};

export const StepNotAllowed = ({ redirectAdp }: any) => (
  <div style={{ display: 'grid', gap: '16px' }}>
    <Typography variant='h5'>Not allowed</Typography>
    <Typography>You are not allowed to register device using this form.</Typography>
    <FooterAction>
      <IconBackgroundWrapper icon={<HomeRegister />} primary={false} />
      <Typography>If you want to go to Aether Digital Platform</Typography>
      <Button onClick={redirectAdp}>Go to the ADP</Button>
    </FooterAction>
  </div>
);
