import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createCompatibility,
  deleteCompatibility,
  getCompatibilities,
  getFirmwareVersion,
  getPCBVersion,
  getSoftwareVersion,
  updateCompatibility
} from '../../api/versions/verions';
import { getDevicesModels } from '../../api/devices/devices';
import { CompatibilitiesQueryParams, VersionEntry } from '../../api/versions/version.types';
import { mapErrorMessage } from '../../utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';

export const SOFTWARE_VERSION_QUERY_KEY = 'version/software';
export const PCB_VERSION_QUERY_KEY = 'version/pcb';
export const FIRMWARE_VERSION_QUERY_KEY = 'version/firmware';
export const DEVICES_VERSION_QUERY_KEY = 'version/devices';
export const COMPATIBILITIES_QUERY_KEY = 'compatibilities';

export const useVersions = (
  enabled: boolean = true
): {
  DeviceModel: VersionEntry[];
  SoftwareVersion: VersionEntry[];
  FirmwareVersion: VersionEntry[];
  PCBVersion: VersionEntry[];
  isLoading: boolean;
} => {
  const { data: softwareVersion, isLoading: isLoadingSoftware } = useQuery(
    [SOFTWARE_VERSION_QUERY_KEY],
    () => getSoftwareVersion(),
    { enabled }
  );
  const { data: firmwareVersion, isLoading: isLoadingModel } = useQuery(
    [FIRMWARE_VERSION_QUERY_KEY],
    () => getFirmwareVersion(),
    { enabled }
  );
  const { data: pcbVersion, isLoading: isLoadingPcb } = useQuery(
    [PCB_VERSION_QUERY_KEY],
    () => getPCBVersion(),
    {
      enabled
    }
  );
  const { data: devicesVersion, isLoading: isLoadingVersions } = useQuery(
    [DEVICES_VERSION_QUERY_KEY],
    () => getDevicesModels(),
    {
      enabled
    }
  );

  return {
    DeviceModel: devicesVersion,
    SoftwareVersion: softwareVersion,
    FirmwareVersion: firmwareVersion,
    PCBVersion: pcbVersion,
    isLoading: isLoadingModel || isLoadingSoftware || isLoadingPcb || isLoadingVersions
  };
};

export const useVersionCompatibility = (
  queryParams: CompatibilitiesQueryParams,
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [COMPATIBILITIES_QUERY_KEY, JSON.stringify(queryParams)],
    () => getCompatibilities({ params: queryParams }),
    {
      // cacheTime: Infinity,
      // enabled: enabled,
      // keepPreviousData: true,
      // staleTime: Infinity,
      // retry: 0
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useVersionCompatibilityCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createCompatibility, {
    onSuccess() {
      NotificationFactory.successNotification('Compatibility created');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useVersionCompatibilityUpdate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateCompatibility, {
    onSuccess() {
      NotificationFactory.successNotification('Compatibility updated');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeleteCompatibility = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteCompatibility, {
    onSuccess() {
      NotificationFactory.successNotification('Compatibility deleted');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
