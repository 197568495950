import React, { useEffect, useMemo, useState } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import {
  aetherBatteryVoltageFunctions,
  genericSwitchingMap,
  genericSwitchingReversedMap
} from 'configurator/utils/definesLocal';
import { OptionsWithSlider } from '../../../components/molecules/Sliders/Sliders';

const sliderLimits = {
  min: aetherBatteryVoltageFunctions.percentToVoltage(5),
  max: aetherBatteryVoltageFunctions.percentToVoltage(30)
};

const BatteryBeep = ({
  addHistoryProsthesis,
  batteryBeep,
  batteryBeepPermission,
  replayIsEnabled,
  setConfigProperty
}: any) => {
  const [batteryBeepSliderLocal, setBatteryBeepSliderLocal] = useState<any>(
    // @ts-ignore
    initialState.config.batteryBeep![0]
  );

  const handleBatteryBeepSlider = (value: any) => {
    addHistoryProsthesis(setConfigProperty('batteryBeep', [value, batteryBeep[1]]));
  };

  const handleBatteryBeepStatus = (value: any) => {
    addHistoryProsthesis(setConfigProperty('batteryBeep', [batteryBeep[0], value]));
  };

  useEffect(() => {
    if (batteryBeep) {
      setBatteryBeepSliderLocal(batteryBeep?.[0]);
    }
  }, [batteryBeep]);

  const BatteryBeep = useMemo(
    () => (
      <OptionsWithSlider
        header='Low battery beep'
        toggleOptions={['On', 'Off']}
        disabled={replayIsEnabled || !batteryBeepPermission}
        disableSlider={Boolean(!batteryBeep[1])}
        toggleValue={genericSwitchingMap.get(batteryBeep[1])}
        sliderValue={batteryBeepSliderLocal}
        handleSliderChange={(e: any, value: any) => setBatteryBeepSliderLocal(value)}
        sliderLimits={sliderLimits}
        sliderLabel={`Level: ${aetherBatteryVoltageFunctions.voltageToPercent(
          batteryBeepSliderLocal
        )} %`}
        handleToggleChange={(value: any) =>
          handleBatteryBeepStatus(genericSwitchingReversedMap.get(value))
        }
        handleSliderOnAfterChange={(...args: any) => handleBatteryBeepSlider(args[1])}
        description='Sets low battery checker alarm voltage level and state of activation.'
      />
    ),
    [batteryBeep, batteryBeepSliderLocal, batteryBeepPermission]
  );

  return BatteryBeep;
};

export default BatteryBeep;
