import { UploadFileInfo } from '@progress/kendo-react-upload';

type MultipleTrue = true;
type MultipleFalse = false | undefined;

export function getValidFiles(files: UploadFileInfo[] | undefined, multiple?: MultipleTrue): File[];
export function getValidFiles(
  files: UploadFileInfo[] | undefined,
  multiple?: MultipleFalse
): File | undefined;

export function getValidFiles(
  files?: UploadFileInfo[],
  multiple: MultipleFalse | MultipleTrue = false
): File | File[] | undefined {
  if (!files) {
    return multiple ? [] : undefined;
  }

  const uploadedFiles: File[] = files
    .filter(
      (file) =>
        (!file?.validationErrors || file.validationErrors.length === 0) &&
        file.getRawFile !== undefined
    )
    .map((file) => file.getRawFile!());

  if (uploadedFiles.length === 0) {
    return multiple ? [] : undefined;
  }

  return multiple ? uploadedFiles : uploadedFiles[0];
}

export const getFileName = (image?: string) => {
  return image?.split('/').slice(-1)[0];
};
