import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { ErrorEvent, EventHint } from '@sentry/types/types/event';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SENTRY_DSN, SENTRY_TRACES_SAMPLE_DATE } from 'adp-panel/constants/config';
import AppProviders from 'AppProviders';
import ToastsRemover from 'configurator/views/ToastRemover';
import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { myTheme } from 'theme/theme';
import { themeMui } from 'theme/themeMui';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './theme/globalStyle';
// import 'configurator/utils/i18n/i18n';
import 'i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.reportingObserverIntegration({ types: ['crash'] }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({ maskAllText: false })
  ],
  beforeSend(event: ErrorEvent, hint: EventHint) {
    if (
      // @ts-ignore
      hint?.originalException?.isAxiosError &&
      // @ts-ignore
      hint?.originalException?.response &&
      // @ts-ignore
      hint.originalException.response?.status < 500
    ) {
      return null;
    }
    return event;
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_DATE,
  enabled: process.env.NODE_ENV !== 'development'
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <ThemeProvider theme={myTheme}>
        <ThemeProviderMui theme={themeMui}>
          <BrowserRouter>
            <AppProviders>
              <StrictMode>
                <ToastsRemover>
                  <App />
                </ToastsRemover>
              </StrictMode>
            </AppProviders>
          </BrowserRouter>
        </ThemeProviderMui>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
