/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { isEmpty } from 'lodash';
import {
  postAppReceivedProcedure,
  postFingerLimits,
  postInitialGripPositions,
  runProcedure
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import { arraysEqual } from 'configurator/utils/funcs';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { Commands } from 'bluetooth/Bluetooth/Defines';

export const compareGripsPositions = (apiGripsPositions, localGripsPositions) => {
  let positionsToSentApi = {};
  const localConfigCopy = { ...localGripsPositions };
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localGripsPositions) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      if (
        !arraysEqual(element?.initial, apiGripsPositions[key]?.initial) ||
        (!arraysEqual(element?.limit, apiGripsPositions[key]?.limit) && element)
      ) {
        positionsToSentApi = { ...positionsToSentApi, [key]: element };
      }
      delete localConfigCopy[key];
    }
  }
  positionsToSentApi = { ...positionsToSentApi, ...localConfigCopy };
  return positionsToSentApi;
};

export const compareConfigs = (apiConfig, localConfig) => {
  let configToSentApi = {};
  const localConfigCopy = { ...localConfig };
  delete localConfigCopy.gripsConfiguration;
  const localConfigCopy2 = { ...localConfigCopy };
  for (const key in localConfig) {
    if (Object.prototype.hasOwnProperty.call(localConfigCopy2, key)) {
      const element = localConfigCopy2[key];
      const elementAPI = apiConfig?.[key];

      if (element && elementAPI) {
        if (!arraysEqual(element, elementAPI)) {
          configToSentApi = { ...configToSentApi, [key]: element };
        }
      }
      delete localConfigCopy[key];
    }
  }
  configToSentApi = { ...configToSentApi, ...localConfigCopy };
  if (apiConfig?.gripsPositions && localConfig?.gripsPositions) {
    const gripsCompared = compareGripsPositions(
      apiConfig.gripsPositions,
      localConfig.gripsPositions
    );
    if (!isEmpty(gripsCompared)) {
      return { ...configToSentApi, gripsPositions: gripsCompared };
    }
  }
  return { ...configToSentApi };
};

export const sendFingersConfigHelper = async (grip, valuesInitial, valuesLimit) => {
  await postInitialGripPositions(grip, valuesInitial);
  await postFingerLimits(grip, valuesLimit);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (gripsPositions) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(grip, gripPositions.initial, gripPositions.limit);
    }
  }
};

export const testClosingProcedure = async (abortEvent: string) => {
  const procedureTries: number[][] = [];
  const numberOfTries = 10;
  for (let index = 1; index <= numberOfTries; index += 1) {
    const procedureTry = await runProcedure(
      ProcedureTypes.testClosing,
      [1, ...Array(119).fill(0)],
      abortEvent
    );
    procedureTries.push([
      procedureTry[2],
      procedureTry[3],
      procedureTry[4],
      procedureTry[5],
      procedureTry[6],
      procedureTry[7],
      procedureTry[8],
      procedureTry[9],
      procedureTry[10],
      procedureTry[11]
    ]);
    await postAppReceivedProcedure(Commands.kFrameTypeProcedureReply);
  }

  const closingTimeFingers: any = [];
  const averageCurrentFingers: any = [];
  for (let i = 0; i < 5; i++) {
    const fingerClosingTimes: any = [];
    const fingerAverageCurrents: any = [];
    for (let index = 0; index < numberOfTries; index++) {
      const iteration = procedureTries[index];
      fingerClosingTimes.push(iteration[i]);
      fingerAverageCurrents.push(iteration[i + 5]);
    }
    closingTimeFingers.push(fingerClosingTimes);
    averageCurrentFingers.push(fingerAverageCurrents);
  }

  const sumProcedures = procedureTries.reduce(
    (prev, cur) =>
      cur.map((value, index) => {
        if (prev.length) {
          return value + prev[index];
        }
        return value;
      }),
    []
  );

  return [
    sumProcedures.map((value) => Math.round(value / numberOfTries)),
    closingTimeFingers.map((finger) => ({ min: Math.min(...finger), max: Math.max(...finger) })),
    averageCurrentFingers.map((finger) => ({ min: Math.min(...finger), max: Math.max(...finger) }))
  ];
};
