import styled from 'styled-components';

export const ActionButtonsWrapper = styled.td`
  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

export const FormGridWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin-bottom: 60px;

  & > div {
    width: 49%;
  }
`;

export const ResetPasswordButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const ResetPasswordInput = styled.div`
  flex: 2;
  margin-right: 10px;
`;

export const ResetPasswordButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  column-gap: 20px;
`;

export const TabsWrapper = styled.div`
  padding: 30px;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;
