import { VALIDATOR_TEXT } from 'constants/validatorText';
import * as yup from 'yup';

export const emailSchema = yup
  .string()
  .email('Must be a valid email')
  .matches(/@[^.]*\./, 'Must be a valid email');

export const isStrongPassword = (value) => {
  const hasUpperCase = /[A-Z]/.test(value);
  const hasLowerCase = /[a-z]/.test(value);
  const hasNumber = /[0-9]/.test(value);
  // eslint-disable-next-line no-useless-escape
  const hasSymbole = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
  const isProperLength = value.length >= 8;
  let validConditions = 0;
  const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole, isProperLength];
  // eslint-disable-next-line no-return-assign
  conditions.forEach((condition) => (condition ? (validConditions += 1) : null));
  if (validConditions >= conditions.length) {
    return true;
  }
  return false;
};

export const strongPasswordSchema = yup
  .string()
  .test('isValidPass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
    if (value === undefined) return false;

    return isStrongPassword(value);
  });

export const retypePasswordSchema = yup
  .string()
  .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
    return this.parent.password === value;
  });

export const registerSchema = yup.object().shape({
  email: emailSchema,
  password: strongPasswordSchema,
  retypePassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
    .required('This field is required'),
  'privacy-policy': yup
    .boolean()
    .oneOf(
      [true],
      'Please confirm that you have read, on behalf of the Partner, and accept the Privacy Policy of Aether Digital Platform and the Terms of Service of Aether Digital Platform.'
    ),
  'data-processing-policy': yup
    .boolean()
    .oneOf(
      [true],
      'By creating the Partner’s Account, you must confirm, on behalf of the Partner, that you agree to enter into a Data Processing Agreement.'
    ),
  name: yup.string().required('This field is required'),
  companyName: yup.string().required('This field is required')
});

export const loginSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('This field is required'),
  password: yup.string().required('This field is required')
});

export const codeSchema = yup.object().shape({
  code: yup.string().required('This field is required')
});

export const ADD_DEVICE_ERRORS = {
  DEVICE_ASSIGNED_ANOTHER_CLINICIAN: 'Device is assigned to another clinician in your company',
  DEVICE_ASSIGNED_ANOTHER_COMPANY: 'Device is assigned to another company',
  ACTIVATION_CODE_INCORRECT: 'Activation code is incorrect'
};

export const CUSTOM_ERRORS = {
  DEVICE_NOT_EXIST:
    'Device you have connected with, does not exist in ADP. \n Please contact support@aetherbiomedical.com',
  CONNECTION_ERROR: 'There was an error while connecting to the device. Please try again.'
};

export const MESSAGES = {
  PLEASE_ENTER_CODE: 'Please enter the activation code included with the hand.'
};
