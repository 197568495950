import { API_ERRORS, STATUS_ERROR_MESSAGES, StatusCode } from 'adp-panel/api/errors';
import * as Sentry from '@sentry/react';
import Notification, { NotificationVariant } from 'adp-panel/components/Notifications/Notification';
import React from 'react';
import { Toast, toast, ToastOptions } from 'react-hot-toast';

export class NotificationFactory {
  public static handleStatusErrors(error: any) {
    const statusCode = error?.request?.status;
    let message = STATUS_ERROR_MESSAGES.default;
    if (statusCode >= 500 && statusCode <= 599) {
      Sentry.captureException(error);
      message = STATUS_ERROR_MESSAGES[statusCode as StatusCode] || message;
    }

    if (statusCode === 413) {
      message = STATUS_ERROR_MESSAGES[statusCode as StatusCode] || message;
    }

    return message;
  }

  public static handleApiErrorCode(code: string) {
    const [group, action, errorType] = code.split(':');
    return API_ERRORS?.[group]?.[action]?.[errorType];
  }

  static createNotification(
    variant: NotificationVariant,
    title: string | React.JSX.Element,
    description: string,
    options: ToastOptions,
    children: React.ReactNode
  ) {
    return toast.custom(
      (t: Toast) => (
        <Notification toast={t} title={title} description={description} variant={variant}>
          {children}
        </Notification>
      ),
      options
    );
  }

  static errorNotification(
    title: string | React.JSX.Element = '',
    description = '',
    options: ToastOptions = {},
    children: React.ReactNode = null
  ) {
    return NotificationFactory.createNotification('error', title, description, options, children);
  }

  static successNotification(
    title = '',
    description = '',
    options: ToastOptions = {},
    children: React.ReactNode = null
  ) {
    return NotificationFactory.createNotification('success', title, description, options, children);
  }

  static warningNotification(
    title = '',
    description = '',
    options: ToastOptions = {},
    children: React.ReactNode = null
  ) {
    return NotificationFactory.createNotification('warning', title, description, options, children);
  }
}
