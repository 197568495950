import styled from 'styled-components';
import Card from 'configurator/components/atoms/Card/Card';
import { HeaderM } from 'configurator/components/atoms/Typography/Typography';
import { ReactComponent as Times } from 'assets/times-solid.svg';

export const CalendarDay = styled.div<{ active: any }>`
  position: absolute;
  top: 8px;
  right: 8px;
  color: ${({ active }) => (active ? 'auto' : '#adb2b6')};
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  h2 {
    ${HeaderM};
  }
`;

export const CalendarTable = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;

  tr {
    th {
      font-size: 14px;
      font-weight: bold;
      background-color: ${({ theme }) => theme.colorPrimary};
      color: white;
      height: 40px;
      padding-left: 8px;
      border: 1px solid ${({ theme }) => theme.colorFill};
      text-align: left;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  th,
  td {
    height: 120px;
    border: 1px solid ${({ theme }) => theme.colorFill};

    &:hover {
      border-color: ${({ theme }) => theme.colorSecondary};

      ${CalendarDay} {
        color: ${({ theme }) => theme.colorSecondary};
      }
    }
  }
`;

export const CalendarDayWrapper = styled.td`
  position: relative;
  padding: 8px;
`;

export const EventWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: 8px;
  height: 60px;
  background-color: rgba(55, 136, 179, 0.1);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: inset 3px 0 0 0 ${({ theme }) => theme.colorPrimary};
  border-radius: 3px;
  padding: 8px 8px 8px 12px;
  color: ${({ theme }) => theme.colorPrimary};
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClinicianImg = styled.img`
  width: 12px;
  height: 12px;
  object-fit: cover;
  border-radius: 50%;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);

  ${Card} {
    position: absolute;
    width: 275px;
  }
`;

export const ModalInnerWrapper = styled.div`
  text-align: left;
  display: grid;
  gap: 16px;
`;

export const ModalDetail = styled.div`
  h5 {
    font-size: 14px;
    color: ${({ theme }) => theme.typography.colors.subtitle};
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 15px;
  position: relative;
`;

export const StyledTimes = styled(Times)`
  width: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 10000;

  &&:hover {
    transform: scale(1.1, 1.1);
    transition: transform 0.2s;
  }
`;
