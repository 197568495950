import parseApiError from 'adp-panel/utils/parseApiError';
import { NotificationFactory } from 'lib/NotificationFactory';
import ApiErrorNotification from 'adp-panel/components/Notifications/ApiErrorNotification';

export const mapErrorMessage = (error: any, overrideDefaultMessage?: string) => {
  if (overrideDefaultMessage) {
    NotificationFactory.errorNotification(overrideDefaultMessage);
    return;
  }

  const code = parseApiError(error)?.code;

  if (code) {
    const customMessage = NotificationFactory.handleApiErrorCode(code);

    if (customMessage) {
      NotificationFactory.errorNotification(customMessage);
      return;
    }
  }

  errorApiNotification(error, overrideDefaultMessage);
};

export function errorApiNotification(error: any, fallbackErrorMessage: string | null = null) {
  if (!error.isAxiosError && fallbackErrorMessage) {
    NotificationFactory.errorNotification(fallbackErrorMessage);
  }

  const parsedError = parseApiError(error);
  if (parsedError.accumulatedErrors) {
    NotificationFactory.errorNotification(
      parsedError.message,
      '',
      { id: parsedError.message },
      ApiErrorNotification({ errors: parsedError.accumulatedErrors })
    );
  } else {
    return NotificationFactory.errorNotification(parsedError.message, '', {
      id: parsedError.message
    });
  }
}
