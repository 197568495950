import EditModal, { ActionButtons } from 'adp-panel/components/Modals/EditModal';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormMultiSelect from 'adp-panel/components/FormInput/FormMultiSelect';
import { requiredValidator } from 'adp-panel/components/FormInput/validators';
import React, { useEffect, useState } from 'react';
import { UserEntry } from 'adp-panel/api/users/users.types';
import { CHAT_ROOMS_QUERY_KEY } from 'adp-panel/features/Chat/hooks/api/useChatRooms';
import { useChatRoomCreate } from 'adp-panel/features/Chat/hooks/api/useChatRoomCreate';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAvailablePatients,
  useChatParticipantsByPatientId
} from 'adp-panel/features/Chat/hooks/useChatParticipants';
import { rolesNamesMap } from 'adp-panel/utils/definesLocal';
import { UserDataType } from 'hooks/useUserData';
import FormComboBox from 'adp-panel/components/FormInput/FormComboBox';
import { uniq } from 'lodash';

const ChatRoomCreateModal = ({
  user,
  isOpen,
  handleClose
}: {
  user: UserDataType;
  isOpen: boolean;
  handleClose: Function;
}) => {
  const queryClient = useQueryClient();
  const [statesKey, setStatesKey] = useState(1);
  const [patientId, setPatientId] = useState<number | undefined>(undefined);
  const { mutateAsync: createChatRoom, isLoading: chatRoomCreateLoading } = useChatRoomCreate();
  const users = useChatParticipantsByPatientId(user, patientId);
  const { users: patients } = useAvailablePatients();

  const handleCreateNewRoom = async (data: any) => {
    try {
      await createChatRoom({
        name: `${user.data.name}, ${data.participants
          .map((item: UserEntry) => item.name)
          .join(', ')}`.trim(),
        participants: uniq([...data.participants.map((item: UserEntry) => item.id), user.data.id]),
        patient_id: Number(data.amputee.id)
      });
      await queryClient.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      handleClose();
    } catch (error: any) {
      console.log('err', error);
    }
  };

  const onPatientChange = (e: any) => {
    setPatientId(e.value?.id);
  };

  useEffect(() => {
    setStatesKey((prev) => prev + 1);
  }, [patientId, JSON.stringify(patients)]);

  const itemRender = (li: any, itemProps: any) => {
    const itemChildren = (
      <span>
        {itemProps.dataItem.name} - {rolesNamesMap.get(itemProps.dataItem?.roles?.[0]?.name)}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <EditModal handleClose={handleClose} title={'Create new chat room'} slim>
      <Form
        onSubmit={handleCreateNewRoom}
        initialValues={{
          participants: users.filter((item: UserEntry) => item.id === user.data.id) || []
        }}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement key={statesKey}>
            <Field
              id={'amputee'}
              name={'amputee'}
              label={'Select patient'}
              component={FormComboBox}
              validator={requiredValidator}
              textField='name'
              onChange={onPatientChange}
              data={patients ?? []}
            />
            <Field
              id={'participants'}
              name={'participants'}
              label={'Select which users attached to channel'}
              component={FormMultiSelect}
              validator={requiredValidator}
              itemRender={itemRender}
              textField='name'
              data={users ?? []}
            />
            <ActionButtons
              handleAccept={handleCreateNewRoom}
              handleCancel={handleClose}
              action={'Create'}
              disabled={!formRenderProps.allowSubmit || chatRoomCreateLoading}
            />
          </FormElement>
        )}
      />
    </EditModal>
  );
};

export default ChatRoomCreateModal;
