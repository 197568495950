import React from 'react';
import backgroundImage1 from 'assets/background-register-1.jpg';
import { Image, WelcomeContainer } from './styled';

const RegisterLayout = ({ children }) => (
  <WelcomeContainer>
    <div
      style={{
        padding: '32px',
        display: 'grid',
        flexDirection: 'column',
        gap: '10px',
        gridTemplateRows: 'auto auto 1fr'
      }}>
      {children}
    </div>
    <Image src={backgroundImage1} alt='Background' />
  </WelcomeContainer>
);

export default RegisterLayout;
