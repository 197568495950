import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import * as Sentry from '@sentry/react';
import { FormPasswordTextBox } from 'adp-panel/components/FormInput/FormPasswordTextBox';
import {
  useChangePasswordWithToken,
  useVerifyPasswordChangeToken
} from 'adp-panel/hooks/api/useUsers';
import { useQuery } from 'adp-panel/hooks/useQuery';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { FORGOTTEN_PASSWORD, LOGIN } from 'constants/routes';
import { useEffect, useState } from 'react';
import React from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';
import { AuthTitle, AuthWrapperBox } from '../styled';
import { ButtonWrapper, Link } from './styled';
import { passwordValidator } from './validators';

const SetUpPass = () => {
  const query = useQuery();
  const { t } = useTranslation('auth');
  const token = query.get('token');
  const email = query.get('email');
  const navigate = useNavigate();
  const [isTokeValid, setIsTokenValid] = useState(true);
  const { mutateAsync: changePassword, isLoading: isLoadingChangePassword } =
    useChangePasswordWithToken();
  const { mutate: verifyToken, isLoading } = useVerifyPasswordChangeToken();
  const handleChange = async (e: any) => {
    try {
      if (!token || !email) {
        return;
      }
      await changePassword({
        token,
        email,
        password: e.password
      });

      navigate(LOGIN);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (!token || !email) {
        return;
      }
      verifyToken(
        { token, email },
        {
          onError: (error: any) => {
            if (error.response.status === 400) {
              setIsTokenValid(false);
            }
          },
          onSuccess: () => {
            setIsTokenValid(true);
          }
        }
      );
    };
    verify();
  }, [token, email]);

  if (isLoading) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <AuthLayout>
      <Card>
        {!isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component:setup_pass.title', 'Set up password')}</AuthTitle>
            <p>
              {t('auth:component:setup_pass.text1', 'Your token has been used or has expired.')}
            </p>
            <br />
            <p>
              {t('auth:component:setup_pass.text2', {
                defaultValue: 'If you would like to generate a new token, please go {{link}}',
                link: (
                  <Link onClick={() => navigate(FORGOTTEN_PASSWORD)}>
                    {t('auth:component:setup_pass.link', 'here')}
                  </Link>
                )
              })}
            </p>
          </AuthWrapperBox>
        )}
        {isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component:setup_pass.title', 'Set up password')}</AuthTitle>
            <Form
              validator={passwordValidator}
              onSubmit={handleChange}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form-fieldset'}>
                    <Field
                      data-testid='password'
                      id={'password'}
                      name={'password'}
                      label={t('auth:component:setup_pass.form.password', 'Password')}
                      component={FormPasswordTextBox}
                    />
                    <Field
                      data-testid='confirm-password'
                      id={'confirmPassword'}
                      name={'confirmPassword'}
                      label={t(
                        'auth:component:setup_pass.form.retype_password',
                        'Re-type password'
                      )}
                      component={FormPasswordTextBox}
                    />
                    <div className='k-form-buttons'>
                      <ButtonWrapper>
                        <Button
                          data-testid='cancel-save-password'
                          themeColor={'secondary'}
                          onClick={() => navigate(LOGIN)}
                          type={'button'}>
                          {t('auth:component:setup_pass.button.cancel', 'Back to login')}
                        </Button>
                        <Button
                          data-testid='save-password'
                          themeColor={'primary'}
                          type={'submit'}
                          disabled={!formRenderProps.allowSubmit || isLoadingChangePassword}>
                          {t('auth:component:setup_pass.button.cancel', 'Save password')}
                          {isLoadingChangePassword && <LoaderButton />}
                        </Button>
                      </ButtonWrapper>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </AuthWrapperBox>
        )}
      </Card>
    </AuthLayout>
  );
};

export default SetUpPass;
