import React, { createContext, useState } from 'react';
import AddDeviceModal from 'adp-panel/components/Modals/AddDeviceModal';

export const AddDeviceModalContext = createContext({
  showAddDeviceModal: () => null,
  hideAddDeviceModal: () => null
} as any);

export const AddDeviceModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState(false);

  const showAddDeviceModal = () => setModal(true);
  const hideAddDeviceModal = () => setModal(false);

  return (
    <AddDeviceModalContext.Provider value={{ showAddDeviceModal, hideAddDeviceModal }}>
      {children}
      {modal && <AddDeviceModal />}
    </AddDeviceModalContext.Provider>
  );
};
