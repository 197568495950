/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import useMeeting from 'configurator/hooks/useMeeting';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';
import HandStatus from '../HandStatus/HandStatus';
import { useConfigStore } from 'configurator/reducers/configStore';
import useModes from 'configurator/hooks/useModes';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as HandDeviceSvg } from 'assets/hand-device.svg';
import { ReactComponent as DisconnectBluetoothSvg } from 'assets/bt.svg';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useSidebar from 'configurator/hooks/useSidebar';
import CustomChip from 'components/CustomChip/CustomChip';
import DoneIcon from '@mui/icons-material/Done';

const Wrapper = styled.div`
  height: 85px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
  background-color: #fff;
`;

const ViewModesOptions = [
  { id: ViewModes.standard, name: 'Standard' },
  { id: ViewModes.minimal, name: 'Minimal' },
  { id: ViewModes.minimalEMG, name: 'Minimal + EMG' }
];

const ViewModesOptionsMeeting = [
  { id: ViewModes.minimal, name: 'Minimal' },
  { id: ViewModes.minimalEMG, name: 'Minimal + EMG' }
];

const TopBar = ({ ...props }: any) => {
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore();
  const { amputeeId, connected, serial } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    amputeeId: state.amputeeId,
    serial: state.serial
  }));
  const {
    slotSelected,
    config: { modes }
  } = useConfigStore((state) => state);
  const { viewMode, meetingStatus, setItemMeeting } = useMeetingStore();
  const { handleCloseMeeting, handleOpenMeeting } = useMeeting();
  const { handleChangeMode } = useModes();
  const videoSessionState = useUiStore((state: any) => state.videoSessionState);
  const { handleRedirectAdp, handleDisconnect } = useLeaveConfigurator();
  const { bluetoothConnect } = useBluetooth();
  const connectionState = useUiStore((state: any) => state.connectionState);
  const { allowConnectButton } = useSidebar();

  const toggleMeeting = async () => {
    if (!meetingStatus) {
      handleOpenMeeting(Number(amputeeId));
    } else {
      handleCloseMeeting();
    }
  };

  const switchViewMode = (viewModeId: ViewModes) => {
    setItemMeeting('viewMode', viewModeId);
  };

  return (
    <Wrapper {...props}>
      <div style={{ display: 'flex', gap: '16px' }}>
        {/* {(remoteSessionEnabled || connected) && <HandStatus />} */}
        <CustomButton onClick={handleRedirectAdp} color='light'>
          Go to ADP
        </CustomButton>
        {(remoteSessionEnabled || meetingStatus) && (
          <LoadingButton
            onClick={toggleMeeting}
            loading={videoSessionState === FETCHING_STATES.loading}>
            <span>{meetingStatus ? 'Stop Meeting' : 'Start meeting'}</span>
          </LoadingButton>
        )}
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id='selected-mode-select-label'>Choose view</InputLabel>
          <Select
            value={viewMode}
            labelId='selected-mode-select-label'
            id='selected-mode-select'
            size='small'
            label='Choose view'
            onChange={(e) => switchViewMode(e.target.value as ViewModes)}>
            {(meetingStatus ? ViewModesOptionsMeeting : ViewModesOptions).map((option) => (
              <MenuItem key={`selected-mode-select_${option.id}`} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Boolean(slotSelected !== null && modes?.length > 0) && (
          <FormControl sx={{ width: 150 }}>
            <InputLabel id='selected-mode-select-label'>Selected mode</InputLabel>
            <Select
              value={slotSelected}
              labelId='selected-mode-select-label'
              id='selected-mode-select'
              label='Selected mode'
              size='small'
              onChange={(e) => handleChangeMode(e.target.value)}>
              {modes.map((mode) => (
                <MenuItem
                  key={`selected-mode-select_${mode.name}`}
                  value={mode.slot}
                  disabled={mode.active === 0}>
                  {mode.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div style={{ display: 'flex', gap: '16px' }}>
        {connected && (
          <CustomChip
            color='success'
            label={`Connected (SN: ${serial})`}
            sx={{ borderRadius: '6px', height: 'auto' }}
            icon={<DoneIcon />}
          />
        )}
        {allowConnectButton && (
          <CustomButton
            color={connected ? 'light' : 'primary'}
            Icon={connected ? DisconnectBluetoothSvg : HandDeviceSvg}
            loading={connectionState === FETCHING_STATES.loading}
            onClick={connected ? () => handleDisconnect() : () => bluetoothConnect()}>
            {connected ? `Disconnect` : 'Connect device'}
          </CustomButton>
        )}
      </div>
    </Wrapper>
  );
};

export default TopBar;
