/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { Alert } from '@mui/material';
import { GripSwitchingModes } from 'bluetooth/Bluetooth/Control';
import { configSettingsNamesMap, gripSwitchingMap } from 'configurator/utils/definesLocal';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const FreezeModeEMGSettings = ({
  addHistoryProsthesis,
  freezeModeEmgSettings,
  replayIsEnabled,
  setConfigProperty,
  gripSwitchingMode
}: any) => {
  const [freezeModeEmgSettingsLocal, setFreezeModeEmgSettingsLocal] = useState<any>([
    // @ts-ignore
    defaultConfig.freezeModeEmgSettings[0],
    // @ts-ignore
    defaultConfig.freezeModeEmgSettings[1],
    // @ts-ignore
    defaultConfig.freezeModeEmgSettings[2],
    // @ts-ignore
    defaultConfig.freezeModeEmgSettings[3]
  ]);

  const handleFreezeEmgSettingsSlider = (value: any, sliderNumber: any) => {
    let payload;
    switch (sliderNumber) {
      case 0:
        // thresholdOpening
        payload = [
          value,
          freezeModeEmgSettings[1],
          freezeModeEmgSettings[2],
          freezeModeEmgSettings[3]
        ];
        break;
      case 1:
        // thresholdClosing
        payload = [
          freezeModeEmgSettings[0],
          value,
          freezeModeEmgSettings[2],
          freezeModeEmgSettings[3]
        ];
        break;
      case 2:
        // stage1HoldOpenTime
        payload = [
          freezeModeEmgSettings[0],
          freezeModeEmgSettings[1],
          value,
          freezeModeEmgSettings[3]
        ];
        break;
      case 3:
        // stage2HoldOpenTime
        payload = [
          freezeModeEmgSettings[0],
          freezeModeEmgSettings[1],
          freezeModeEmgSettings[2],
          value
        ];
        break;
      default:
        payload = [
          freezeModeEmgSettings[0],
          freezeModeEmgSettings[1],
          freezeModeEmgSettings[2],
          freezeModeEmgSettings[3]
        ];
    }
    addHistoryProsthesis(setConfigProperty('freezeModeEmgSettings', payload));
  };

  useEffect(() => {
    if (freezeModeEmgSettings) {
      setFreezeModeEmgSettingsLocal(freezeModeEmgSettings);
    }
  }, [freezeModeEmgSettings]);

  const descriptionFreezeModeEmgSettings = useMemo(() => {
    const description =
      'Allows customization of EMG freeze mode thresholds, and time needed to activate it';

    if (gripSwitchingMode[0] !== GripSwitchingModes.kSingleGripSwitching) {
      return description;
    }

    return (
      <>
        <p style={{ marginBottom: 10 }}>{description}</p>
        <Alert severity='warning'>
          When {configSettingsNamesMap.get('gripSwitchingMode')}:{' '}
          {gripSwitchingMap.get(GripSwitchingModes.kSingleGripSwitching)} is selected, `Opening`
          threshold value will be used.
        </Alert>
      </>
    );
  }, [gripSwitchingMode]);

  const FreezeModeEmgSettings = useMemo(
    () => (
      <MultiSlider
        header='EMG freeze mode settings'
        sliderValues={freezeModeEmgSettingsLocal}
        disabled={replayIsEnabled}
        limits={[
          { min: 1, max: 100 },
          { min: 1, max: 100 },
          { min: 250, max: 5000 },
          { min: 500, max: 5000 }
        ]}
        handleChange={(...args: any) =>
          setFreezeModeEmgSettingsLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>Threshold opening: {freezeModeEmgSettingsLocal?.[0]}</p>,
          <p>Threshold closing: {freezeModeEmgSettingsLocal?.[1]}</p>,
          <p>Stage 1 hold time: {parseNumber(freezeModeEmgSettingsLocal?.[2])} s</p>,
          <p>Stage 2 hold time: {parseNumber(freezeModeEmgSettingsLocal?.[3])} s</p>
        ]}
        handleOnAfterChange={(...args: any) => handleFreezeEmgSettingsSlider(args[1], args[2])}
        description={descriptionFreezeModeEmgSettings}
      />
    ),
    [freezeModeEmgSettings, freezeModeEmgSettingsLocal, gripSwitchingMode]
  );

  return FreezeModeEmgSettings;
};

export default FreezeModeEMGSettings;
