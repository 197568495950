import api, { withCredentials } from '../utils/apiClient';
import { PaginatedResponse } from '../../../types';
import {
  ReleaseNoteCreatePayload,
  ReleaseNoteMappingEntry,
  ReleasesQueryParams
} from './releaseNote.types';
import qs from 'qs';
import { VersionEntry } from '../versions/version.types';
import { AxiosResponse } from 'axios';

export const GET_RELEASES_LIST = 'api/releases';
export const CREATE_RELEASE = 'api/releases';
export const UPDATE_RELEASE = 'api/releases/{id}';

export const getReleases = (params: ReleasesQueryParams): Promise<any> => {
  return api
    .get<PaginatedResponse<ReleaseNoteMappingEntry>>(
      `${GET_RELEASES_LIST}?${qs.stringify(params)}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createRelease = (payload: ReleaseNoteCreatePayload): Promise<any> => {
  return api
    .post<VersionEntry>(CREATE_RELEASE, payload, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const updateRelease = (payload: any): Promise<any> => {
  return api
    .post<VersionEntry>(UPDATE_RELEASE.replace('{id}', payload.id), payload, withCredentials)
    .then((res: AxiosResponse) => res.data);
};
