import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCompatibilities } from 'configurator/api/versions/versions';
import {
  CompatibilitiesQueryParams,
  CompatibilitiesExtendOptions,
  CompatibilityEntry,
  FeaturesEntry
} from 'configurator/api/versions/versions.types';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { parseBluetoothFirmware } from 'configurator/utils/funcs';

const COMPATIBILITIES_QUERY_KEY = 'compatibilities';
const COMPATIBILITIES_FIRMWARE_QUERY_KEY = 'compatibilities/firmware';
const COMPATIBILITIES_PCB_QUERY_KEY = 'compatibilities/pcb';

const SORT_DIRS = {
  ascending: -1,
  descending: 1
};

const useCompatibilities = () => {
  let isCompatible = true;
  let isPcbUpgradeNeeded = false;
  // Update needed for some things to work
  let isFirmwareUpdateNeeded = false;
  // Update to newer version available but not required
  let isFirmwareUpdateAvailable = false;
  let deviceFirmware;
  let deviceFirmwareUnknown: any = false;
  const { firmware, pcb, modelId, deviceId } = useDeviceInfoStore((state) => ({
    firmware: state.firmware,
    pcb: state.pcb,
    modelId: state.modelId,
    deviceId: state.deviceId
  }));
  const versions = useDeviceInfoStore((state) => state.versions);

  if (firmware?.name) {
    deviceFirmware = firmware.name;
  }

  const compatibilitiesParams: CompatibilitiesQueryParams = {
    model: modelId || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceFirmware,
    pcb: pcb?.name,
    extend: [CompatibilitiesExtendOptions.features]
  };

  const compatibilitiesParamsFirmware: CompatibilitiesQueryParams = {
    model: modelId || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    pcb: pcb?.name,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.firmwareVersion]
  };

  const compatibilitiesParamsPCB: CompatibilitiesQueryParams = {
    model: modelId || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceFirmware,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.pcbVersion]
  };

  const {
    data: compatibilityInfo,
    isLoading,
    isError
  } = useQuery(
    [COMPATIBILITIES_QUERY_KEY, deviceId, firmware, modelId, pcb?.name],
    () => getCompatibilities({ params: compatibilitiesParams }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceId && deviceFirmware),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  const { data: compatibilityListFirmware } = useQuery(
    [COMPATIBILITIES_FIRMWARE_QUERY_KEY, deviceId, firmware, pcb?.name, modelId],
    () => getCompatibilities({ params: compatibilitiesParamsFirmware }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceId && deviceFirmware),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  const { data: compatibilityListPCB } = useQuery(
    [COMPATIBILITIES_PCB_QUERY_KEY, deviceId, firmware, modelId],
    () => getCompatibilities({ params: compatibilitiesParamsPCB }),
    {
      cacheTime: Infinity,
      enabled: Boolean(deviceId && pcb?.name),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  isCompatible = useMemo(() => {
    const currentCompatibility: CompatibilityEntry = compatibilityInfo?.items?.[0];
    return currentCompatibility
      ? Boolean(
          currentCompatibility?.is_fully_compatible ||
            currentCompatibility?.features?.find((feature) => Boolean(feature.is_compatible))
        )
      : true;
  }, [compatibilityInfo]);

  const supportedFeatures = useMemo(
    () =>
      compatibilityInfo?.items?.[0]?.features.map((feature: FeaturesEntry) => ({
        is_compatible: feature.is_compatible,
        ...feature.feature
      })) || [],
    [compatibilityInfo]
  );

  const availableFirmwares = useMemo(
    () =>
      compatibilityListFirmware?.items
        .filter(
          (item: CompatibilityEntry) =>
            item.is_fully_compatible === 1 ||
            item.features?.find((feature) => Boolean(feature.is_compatible))
        )
        .sort((a, b) => {
          const parseFirmware = (firmwareName) => Number(firmwareName.replaceAll('.', ''));
          const parsedFirmwareA = parseFirmware(a.firmware_version.name);
          const parsedFirmwareB = parseFirmware(b.firmware_version.name);

          if (parsedFirmwareA > parsedFirmwareB) return SORT_DIRS.ascending;
          return SORT_DIRS.descending;
        })
        .map((item) => item.firmware_version),
    [compatibilityListFirmware]
  );

  deviceFirmwareUnknown = useMemo(
    () =>
      versions?.current &&
      !compatibilityListFirmware?.items.find(
        (item) => item.firmware_version.name === parseBluetoothFirmware(versions.current)
      ),
    [compatibilityListFirmware, versions]
  );

  isPcbUpgradeNeeded = useMemo(() => {
    if (compatibilityListPCB && !isCompatible) {
      return (
        compatibilityListPCB?.items.find(
          (item: CompatibilityEntry) =>
            item.is_fully_compatible === 1 ||
            item.features?.find((feature) => Boolean(feature.is_compatible))
        )?.length > 0
      );
    }

    return false;
  }, [compatibilityListPCB, isCompatible]);

  isFirmwareUpdateAvailable = useMemo(() => {
    if (
      compatibilityListFirmware &&
      availableFirmwares.length > 0 &&
      isCompatible &&
      deviceFirmware !== availableFirmwares?.[0].name
    ) {
      return true;
    }
    return false;
  }, [compatibilityListFirmware, availableFirmwares, isCompatible, deviceFirmware]);

  isFirmwareUpdateNeeded = useMemo(() => {
    if (compatibilityListFirmware && availableFirmwares.length > 0 && !isCompatible) {
      return true;
    }
    return false;
  }, [availableFirmwares, compatibilityListFirmware, isCompatible]);

  return {
    compatibilityInfo: compatibilityInfo?.items,
    compatibilityListFirmware: compatibilityListFirmware?.items,
    supportedFeatures,
    isLoading,
    isError,
    isCompatible,
    availableFirmwares,
    isPcbUpgradeNeeded,
    isFirmwareUpdateNeeded,
    isFirmwareUpdateAvailable,
    deviceFirmwareUnknown,
    isFullyCompatible: Boolean(compatibilityInfo?.items?.[0]?.is_fully_compatible)
  };
};

export default useCompatibilities;
