import { last } from 'lodash';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { SessionStatus } from 'configurator/api/liveSession/liveSession.types';
import { useConfigStore } from 'configurator/reducers/configStore';
import { REMOTE_SESSION_DEBUG } from 'configurator/consts/consts';
import { ablyClient } from '../utils/LiveConfigurator/AblyClient';
import { useLiveSession } from './api/useLiveSession';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';

interface ModeType {
  id: number;
  slot: number;
  name: string;
}

const useRemoteSession = () => {
  const {
    channel,
    sessionApi,
    sessionRestorePoints,
    setItemLiveConfigurator,
    setLiveSessionClosed,
    getLiveSessionApi,
    enabled
  } = useLiveConfiguratorStore((state) => ({
    channel: state.channel,
    sessionApi: state.sessionApi,
    sessionRestorePoints: state.sessionRestorePoints,
    setItemLiveConfigurator: state.setItemLiveConfigurator,
    setLiveSessionClosed: state.setLiveSessionClosed,
    getLiveSessionApi: state.getLiveSessionApi,
    enabled: state.enabled
  }));
  const { config, setConfigCopy, getInitialConfigAPI, clearConfigHistory, importConfig } =
    useConfigStore((state) => ({
      config: state.config,
      setConfigCopy: state.setConfigCopy,
      getInitialConfigAPI: state.getInitialConfigAPI,
      clearConfigHistory: state.clearConfigHistory,
      importConfig: state.importConfig
    }));
  const { setItemUiStore } = useUiStore((state) => ({ setItemUiStore: state.setItemUiStore }));
  const client = ablyClient(sessionApi?.clinician_uuid);
  const channelsAbly = client.channels.get(channel.name);
  const { updateSession } = useLiveSession();
  const { amputeeId } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId
  }));
  const channelMeeting = client.channels.get(`meeting:${amputeeId}`);
  const sessionReady = channel?.name && sessionApi?.clinician_uuid && enabled;

  const sendConfig = async () => {
    const modesPayload = config.modes?.map((mode) => ({
      id: mode.id,
      config: mode.config,
      slot: mode.slot
    }));
    const configSession = {
      common: config.common.config,
      modes: modesPayload
    };
    setConfigCopy();
    channelsAbly.publish('config', JSON.stringify(configSession));
    console.log(configSession, 'SESSION CONFIG');
  };

  const sendModeChange = async (mode: ModeType) => {
    if (REMOTE_SESSION_DEBUG) console.log('CHANGE MODE SESSION', mode);
    channelsAbly.publish('current_mode', JSON.stringify(mode));
  };

  const sendTempEmg = async (message) => {
    channelsAbly.publish('emg', message);
  };

  const sendMeeting = async (message) => {
    channelMeeting.publish('meeting', message);
  };

  const sendCloseMeeting = async () => {
    channelMeeting.publish('close_meeting_mobile', '');
  };

  const sendReady = async () => {
    channelsAbly.publish('ready', '');
  };

  const sendMeetingSummary = async (message) => {
    channelsAbly.publish('summary', message);
  };

  const consumeAllSessionChanges = (refreshConfig = true) => {
    clearConfigHistory();

    if (refreshConfig) {
      getInitialConfigAPI();
    }
  };

  const rollbackToLastRestorePoint = () => {
    importConfig(last(sessionRestorePoints));
    consumeAllSessionChanges();
    sendConfig();
  };

  const enableLiveSession = async (
    token: string,
    sessionId: number,
    amputeeId: number,
    clinicianId: number
  ) => {
    setItemLiveConfigurator('enabled', true);
    setItemLiveConfigurator('channel', { name: token, connected: false, id: sessionId });
    await getLiveSessionApi({ amputeeId, clinicianId });
    consumeAllSessionChanges();
  };

  const disconnectRemoteSession = async (notifyMobile: boolean, refreshConfig = true) => {
    try {
      channelsAbly.presence.unsubscribe();
      channelsAbly.unsubscribe();
      setItemUiStore('sessionDisconnecting', true);
      setLiveSessionClosed();
      if (channel.id && notifyMobile) {
        await updateSession({ id: channel.id, status: SessionStatus.closed });
      }
      if (notifyMobile) channelsAbly.publish('close_session', 'close');
      channelsAbly.presence.leave();
      consumeAllSessionChanges(refreshConfig);
      setLiveSessionClosed();
    } finally {
      setItemUiStore('sessionDisconnecting', false);
    }
  };

  return {
    rollbackToLastRestorePoint,
    sendConfig,
    disconnectRemoteSession,
    sendTempEmg,
    enableLiveSession,
    consumeAllSessionChanges,
    sendMeeting,
    sendMeetingSummary,
    sendModeChange,
    sendCloseMeeting,
    sendReady,
    sessionReady
  };
};

export default useRemoteSession;
