import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormCheckbox from 'adp-panel/components/FormInput/FormCheckbox';
import FormInput from 'adp-panel/components/FormInput/FormInput';
import { FormPasswordTextBox } from 'adp-panel/components/FormInput/FormPasswordTextBox';
import LoaderButton from 'adp-panel/components/LoaderButton/LoaderButton';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { FORGOTTEN_PASSWORD, REGISTER } from 'constants/routes';
import { useAuthentication } from 'hooks/useAuthentication';
import React from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonWrapper } from '../SetUpPass/styled';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import MfaScreen from './MfaScreen';
import {
  FooterWrapper,
  ForgottenPasswordLink,
  RegisterBackgroundWrapper,
  RegisterHeader,
  RegisterParagraph,
  RegisterWrapper
} from './styled';

const Login = () => {
  const { t } = useTranslation();
  const { isLoading, login, is2FaPage, mfaData } = useAuthentication();
  const { state: locationState = {} } = useLocation();
  const { mfa = false }: any = locationState || {};
  const onSubmit = (data: any) => {
    login(data);
  };
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate(REGISTER);
  };

  const isMfaPage = is2FaPage || mfa || mfaData.required;

  return (
    <>
      {!isMfaPage && (
        <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
          <div>
            <AuthLayout lightVariant showUdi>
              <AuthWrapperBox>
                <AuthTitle>
                  {t('auth:component.login.title', 'Sign In With Your Existing Account')}
                </AuthTitle>
                <AuthParagraph variant='body2'>
                  {t(
                    'auth:component.login.paragraph',
                    'Already registered with us? Sign in with your email to connect to the configurator or explore the Aether Digital Platform (ADP) for additional functionalities.'
                  )}
                </AuthParagraph>
                <Form
                  onSubmit={onSubmit}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <fieldset className={'k-form-fieldset'}>
                        <Field
                          id={'emailAddress'}
                          name={'email'}
                          type={'email'}
                          label={t('auth:component.login.form.password', 'Email address')}
                          component={FormInput}
                        />
                        <Field
                          id={'password'}
                          name={'password'}
                          label={t('auth:component.login.form.password', 'Password')}
                          component={FormPasswordTextBox}
                        />
                        <FooterWrapper style={{ marginTop: '12px' }}>
                          <Field
                            id={'rememberMe'}
                            name={'rememberMe'}
                            label={t('auth:component.login.form.remember_me', 'Remember me')}
                            component={FormCheckbox}
                          />
                          <ForgottenPasswordLink onClick={() => navigate(FORGOTTEN_PASSWORD)}>
                            {t(
                              'auth:component.login.link.forgotten_password',
                              'Forgotten password'
                            )}
                          </ForgottenPasswordLink>
                        </FooterWrapper>
                        <div className='k-form-buttons'>
                          <ButtonWrapper>
                            <Button
                              themeColor={'primary'}
                              onClick={formRenderProps.onSubmit}
                              disabled={!formRenderProps.allowSubmit || isLoading}>
                              {t('auth:component.login.button.login', 'Log in')}
                              {isLoading && <LoaderButton />}
                            </Button>
                          </ButtonWrapper>
                        </div>
                      </fieldset>
                    </FormElement>
                  )}
                />
              </AuthWrapperBox>
            </AuthLayout>
          </div>
          <RegisterWrapper>
            <RegisterBackgroundWrapper>
              <div style={{ maxWidth: '1100px', padding: '0 60px 0 60px' }}>
                <RegisterHeader>
                  {t(
                    'auth:component.login.add_device.title',
                    'Do you need to create an account or add a new device to your account?'
                  )}
                </RegisterHeader>
                <RegisterParagraph style={{ marginTop: '16px' }}>
                  {t(
                    'auth:component.login.add_device.text',
                    'Welcome to the Aether Digital Platform (ADP)! To ensure a smooth setup for your first device or to add a new device to your account, please have your activation code and the device ready. This process requires both for verification.'
                  )}
                </RegisterParagraph>
                <Button
                  data-testid='register'
                  onClick={handleRegister}
                  style={{ marginTop: '32px' }}
                  themeColor={'primary'}>
                  {t('auth:component.login.button.add_device', 'Add new device')}
                </Button>
              </div>
            </RegisterBackgroundWrapper>
          </RegisterWrapper>
        </div>
      )}
      {isMfaPage && <MfaScreen />}
    </>
  );
};

export default Login;
