import styled, { css } from 'styled-components';
import Card from '../../components/Card/Card';

interface Props {
  small?: boolean;
}

export const InnerWrapper = styled.div<Props>`
  margin: 0 auto;
  max-width: 720px;

  ${({ small }) =>
    !small &&
    css`
      @media ${({ theme }) => theme.devices.xlarge} {
        width: 100%;
        max-width: 1640px;
      }
    `};
`;

export const StyledCard = styled(Card)`
  padding: 30px;
`;
