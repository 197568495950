import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import Checkbox from 'configurator/components/atoms/Checkbox/Checkbox';
import { Grips } from '../../../../bluetooth/Bluetooth/Grips';
import Card from 'adp-panel/components/Card/Card';

const DropdownsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-around;

  @media (max-width: 1630px) {
    padding: 0 40px;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  position: relative;
`;

const StyledDropdownImg = styled(DropdownImg)`
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
`;

const GripWrapper = styled.div`
  width: 200px;
`;

const HeaderSecondary = styled.h4<{ active?: any; show?: any }>`
  display: flex;
  align-items: center;
  ${TextS};
  text-align: center;
  margin-right: ${({ show }) => (show ? '10px' : '0')};
  opacity: ${({ active }) => (active ? '1' : '0.5')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 30px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  display: ${({ show }) => (show ? 'auto' : 'none')};
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  opened: number | null;
  updateOpened?: Function;
  indexes: number[];
  gripsImgs: any;
  disabled?: boolean;
};

const GripsChooser2 = ({
  grips,
  values,
  nonOpposed = false,
  onChange,
  updateOpened = () => false,
  opened = null,
  indexes = [0],
  gripsImgs,
  disabled,
  ...props
}: GraphChooserTreeProps) => {
  const { t } = useTranslation();
  const [enabledColumns, setEnabledColumns] = useState([true, false, false, false, false]);
  const [visibleCheckboxes, setVisibleCheckboxes] = useState([true, false, false, false, false]);

  const updateGrips = (option, gripOrder, isNonOpposed) => {
    onChange(gripsGroupsOptionsReversedMap.get(option), gripOrder, isNonOpposed);
  };

  const updateEnabledColumns = (index) => {
    const newEnabledColumns = enabledColumns.map((isColumnEnabled, localIndex) => {
      if (index === localIndex) {
        isColumnEnabled = !isColumnEnabled;
        if (isColumnEnabled === false) {
          updateGrips('Disabled', index, nonOpposed);
        } else {
          updateGrips(gripsGroupsOptionsMap.get(grips[0]), index, nonOpposed);
        }
      }
      return isColumnEnabled;
    });
    setEnabledColumns(newEnabledColumns);
  };

  useEffect(() => {
    const gripsSequence = values;
    const updatedEnabledColumns = gripsSequence.map((grip) => {
      if (grip === Grips.kGripTypeUnknown) {
        return false;
      }
      return true;
    });
    setEnabledColumns(updatedEnabledColumns);
  }, [values]);

  useEffect(() => {
    const newVisibleCheckboxes = Array(5).fill(false);
    const lastIndex = values.findIndex(
      (value, index) =>
        value === Grips.kGripTypeUnknown && value[index - 1] !== Grips.kGripTypeUnknown
    );
    if (lastIndex !== 1) {
      newVisibleCheckboxes[lastIndex] = true;
      newVisibleCheckboxes[lastIndex - 1] = true;
    } else {
      newVisibleCheckboxes[1] = true;
    }
    setVisibleCheckboxes(newVisibleCheckboxes);
  }, [enabledColumns]);

  return (
    <Card {...props}>
      <DropdownsWrapper>
        {indexes.map((chooseGripsIndex, localIndex) => (
          <GripWrapper>
            <CheckboxWrapper>
              <HeaderSecondary
                active={enabledColumns[localIndex]}
                show={visibleCheckboxes[localIndex]}>
                {t('prosthesis_settings.grip')} {localIndex + 1}
              </HeaderSecondary>
              <StyledCheckbox
                onClick={() => updateEnabledColumns(localIndex)}
                checked={enabledColumns[localIndex]}
                show={visibleCheckboxes[localIndex]}
                id={`checkbox${localIndex}`}
                name={`checkbox${localIndex}`}
              />
            </CheckboxWrapper>
            <DropdownWrapper>
              <StyledDropdownImg
                disabled={disabled}
                active={enabledColumns[localIndex]}
                activeProp={enabledColumns[localIndex]}
                options={grips.map((grip) => ({
                  value: gripsGroupsOptionsMap.get(grip),
                  img: gripsImgs.get(grip)
                }))}
                selected={{
                  value: gripsGroupsOptionsMap.get(values[localIndex]),
                  img: gripsImgs.get(values[localIndex])
                }}
                responsive
                onChange={(option) => updateGrips(option, localIndex, nonOpposed)}
                directControl
                setOpenParent={() => updateOpened(chooseGripsIndex)}
                isOpen={opened === chooseGripsIndex}
              />
            </DropdownWrapper>
          </GripWrapper>
        ))}
      </DropdownsWrapper>
    </Card>
  );
};

export default GripsChooser2;
