import { Link, Typography } from '@mui/material';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import CustomVerificationCodeForm from 'adp-panel/components/CustomVerificationCode/CustomVerificationCodeForm';
import FormCheckbox from 'adp-panel/components/FormInput/FormCheckbox';
import LoaderButton from 'adp-panel/components/LoaderButton/LoaderButton';
import useCountDown from 'adp-panel/hooks/useCountDown';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { Spacer } from 'adp-panel/layouts/AuthLayout/styled';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { DeviceUUID } from 'device-uuid';
import { useAuthentication } from 'hooks/useAuthentication';
import useLogout from 'hooks/useLogout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MfaMethods } from 'types';
import { ButtonWrapper } from '../SetUpPass/styled';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import {
  CountDownTimer,
  FooterWrapper,
  RegisterBackgroundWrapper,
  RegisterWrapper
} from './styled';

const MfaScreen = ({ blockRedirect = false }) => {
  const [isLoadingMfaCode, setIsLoadingMfaCode] = useState<boolean>(false);
  const { t } = useTranslation();
  const { logout } = useLogout();
  const { state: locationState = {} } = useLocation();
  const { method = undefined, code = '' }: any = locationState || {};
  const { isLoading, verifyCode, reSendCode, mfaData, isErrorVerify } = useAuthentication();
  const deviceUUID = new DeviceUUID();
  const verificationMethod = method ?? mfaData.channel;
  const {
    time: resendRemainingTime,
    start: startCountDown,
    reset: resetCountDown
  } = useCountDown({ initialTime: 30, autoStart: true });

  const onSubmit2Fa = async (data: any) => {
    if (data.code) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code: data.code,
        remember_mfa_session: data.rememberDevice,
        machine_key: deviceUUID.get(),
        blockRedirect
      });
    }
  };

  const resendCodeAction = (channel: any = undefined) => {
    reSendCode(channel);
    resetCountDown();
    startCountDown();
  };

  useEffect(() => {
    if (isErrorVerify) {
      setIsLoadingMfaCode(false);
    }
  }, [isErrorVerify]);

  useEffect(() => {
    if (code && code.length === 6) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code,
        remember_mfa_session: false,
        machine_key: deviceUUID.get(),
        blockRedirect
      });
    }
  }, [code]);

  return (
    <>
      {isLoadingMfaCode && <ConfirmationLoader fullScreen />}
      <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
        <div>
          <AuthLayout lightVariant showUdi>
            <AuthWrapperBox>
              <AuthTitle>{t('auth:component.login_mfa.title', 'Verify log in')}</AuthTitle>
              <AuthParagraph variant='body2'>
                {t('auth:component.login_mfa.message', {
                  defaultValue:
                    'We have sent you {{method}} with verification code. Please, check your {{destination}} and type code below to verify your first log in.',
                  method:
                    verificationMethod === MfaMethods.email
                      ? t('auth:component.login_mfa.email', 'an email')
                      : t('auth:component.login_mfa.sms', 'sms'),
                  destination:
                    verificationMethod === MfaMethods.email
                      ? t('auth:component.login_mfa.inbox', 'inbox')
                      : t('auth:component.login_mfa.phone', 'phone')
                })}
              </AuthParagraph>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Form
                  onSubmit={onSubmit2Fa}
                  initialValues={{ code }}
                  key={code}
                  render={(formRenderProps: FormRenderProps) => {
                    return (
                      <FormElement>
                        <fieldset className={'k-form-fieldset'}>
                          <Typography
                            variant='subtitle2'
                            align='center'
                            style={{ margin: '10px 0' }}>
                            {t('auth:component.login_mfa.code_title', 'Verification code')}
                          </Typography>
                          <Field
                            data-testid='code'
                            id={'code'}
                            name={'code'}
                            type={'number'}
                            label={t(
                              'auth:component.login_mfa.form.verification_code',
                              'Verification code'
                            )}
                            autoFocus
                            component={CustomVerificationCodeForm}
                          />
                          <FooterWrapper style={{ marginTop: '5px' }}>
                            <Field
                              data-testid='remember-device'
                              id={'rememberDevice'}
                              name={'rememberDevice'}
                              label={t(
                                'auth:component.login_mfa.form.remember_me',
                                'Turn off MFA for this device for 30 days'
                              )}
                              component={FormCheckbox}
                            />
                          </FooterWrapper>
                          <div className='k-form-buttons'>
                            <ButtonWrapper>
                              <Button
                                data-testid='submit-code'
                                themeColor={'primary'}
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit || isLoading}>
                                {t('auth:component.login_mfa.button.verify', 'Verify')}
                                {isLoading && <LoaderButton />}
                              </Button>
                            </ButtonWrapper>
                          </div>
                        </fieldset>
                      </FormElement>
                    );
                  }}
                />
              </div>
              <Typography variant='subtitle2' align='center' mt={3}>
                {t('auth:component.login_mfa.text.resend_code', 'Did you not receive the code? \n')}
                {resendRemainingTime > 0 ? (
                  <CountDownTimer>
                    {t('auth:component.login_mfa.text.resend_code_timer', {
                      defaultValue: 'Resend Code in {{seconds}}s',
                      seconds: resendRemainingTime
                    })}
                  </CountDownTimer>
                ) : (
                  <Link onClick={() => resendCodeAction(mfaData.channel)} href='#'>
                    {t('auth:component.login_mfa.link.resend_code', 'Resend Code')}
                  </Link>
                )}
              </Typography>
            </AuthWrapperBox>
            <div>
              <Spacer style={{ margin: '24px auto', width: '95%' }} />
            </div>
            <div>
              <Typography variant='subtitle2' align='center'>
                {t(
                  'auth:component.login_mfa.text.other_account',
                  'Do you want to use other account?'
                )}
              </Typography>
              <Typography variant='subtitle2' align='center'>
                <Link onClick={() => logout()} sx={{ cursor: 'pointer' }}>
                  {t('auth:auth:component.login_mfa.button.logout', 'Log out')}
                </Link>
              </Typography>
            </div>
          </AuthLayout>
        </div>
        <RegisterWrapper>
          <RegisterBackgroundWrapper>
            <div style={{ maxWidth: '1100px', padding: '0 60px 0 60px' }}></div>
          </RegisterBackgroundWrapper>
        </RegisterWrapper>
      </div>
    </>
  );
};

export default MfaScreen;
