const workerCode = () => {
  let lastTime = new Date().getTime();
  const checkInterval = 1000;

  setInterval(() => {
    const currentTime = new Date().getTime();

    if (currentTime > lastTime + checkInterval * 2) {
      // ignore small delays
      postMessage('wakeup');
    }

    lastTime = currentTime;
  }, checkInterval);
};

let code = workerCode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));
const blob = new Blob([code], { type: 'application/javascript' });

if (typeof URL.createObjectURL === 'function') {
  const wakeup_script = URL.createObjectURL(blob);
  module.exports = wakeup_script;
}
