import { useMutation } from '@tanstack/react-query';
import { createChatRoom } from 'adp-panel/features/Chat/api/chat';
import { NotificationFactory } from 'lib/NotificationFactory';
import { mapErrorMessage } from 'utils/notifications';

export const useChatRoomCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createChatRoom, {
    onSuccess() {
      NotificationFactory.successNotification('Room created');
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
