/* eslint-disable no-unused-vars */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import { P2PSession } from 'configurator/api/liveSession/liveSession.types';
import { getP2PSession } from 'configurator/api/liveSession/liveSession';

type LiveConfiguratorState = {
  sessionPatientId: number | null;
  enabled: boolean;
  device: number | null;
  channel: {
    id: number | null;
    name: string;
    connected: boolean;
  };
  sessionApi: P2PSession | null;
  sessionRestorePoints: any[];
  setItemLiveConfigurator: <T extends keyof LiveConfiguratorState>(
    property: T,
    value: LiveConfiguratorState[T]
  ) => void;
  addSessionRestorePoint: (payload: {
    common: any;
    modes: { config: any; slot: number }[];
  }) => void;
  getLiveSessionApi: ({
    amputeeId,
    clinicianId
  }: {
    amputeeId: number;
    clinicianId: number;
  }) => Promise<any>;
  setLiveSessionClosed: () => void;
  setLiveSessionOpen: (id: number) => void;
  patientConnected: boolean | null;
};

const initialState = {
  sessionPatientId: null,
  enabled: false,
  device: null,
  channel: {
    id: null,
    name: '',
    connected: false
  },
  sessionApi: null,
  sessionRestorePoints: [],
  patientConnected: null
};

const store = (set): LiveConfiguratorState => ({
  ...initialState,
  setItemLiveConfigurator: <T extends keyof LiveConfiguratorState>(
    property: T,
    value: LiveConfiguratorState[T]
  ) => set({ [`${property}`]: value }),
  addSessionRestorePoint: (payload: { common: any; modes: { config: any; slot: number }[] }) =>
    set((state) => ({
      sessionRestorePoints: [
        ...state.sessionRestorePoints,
        { id: state.sessionRestorePoints?.length ?? 0, ...payload }
      ]
    })),
  getLiveSessionApi: async ({
    amputeeId,
    clinicianId
  }: {
    amputeeId: number;
    clinicianId: number;
  }) => {
    try {
      const payload = await getP2PSession(Number(amputeeId), clinicianId);
      set({ sessionApi: payload });
    } catch (err: any) {
      console.log(err);
      return false;
    }
  },
  setLiveSessionClosed: () =>
    set(
      produce((state: LiveConfiguratorState) => {
        state.enabled = initialState.enabled;
        state.device = initialState.device;
        state.channel = initialState.channel;
        state.sessionApi = initialState.sessionApi;
        state.sessionRestorePoints = initialState.sessionRestorePoints;
        state.patientConnected = initialState.patientConnected;
      })
    ),
  setLiveSessionOpen: (id: number) =>
    set(
      produce((state: LiveConfiguratorState) => {
        state.sessionPatientId = id;
      })
    )
});

export const useLiveConfiguratorStore = create<LiveConfiguratorState>()(
  // @ts-ignore
  devtools(store, { name: 'Live configurator' })
);
