import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  SessionText,
  SessionWrapper,
  StyledWarningIcon
} from '../RemoteSessionBar/RemoteSessionBar.styled';

const EmgPlaybackBar = ({ ...props }) => {
  const { t } = useTranslation();
  const replayIsEnabled = useReplayStore((state) => state.replayData);
  const navigate = useNavigate();

  if (!replayIsEnabled) {
    return <></>;
  }

  return (
    <SessionWrapper {...props}>
      <SessionText>
        <StyledWarningIcon />
        <span>{t('replay.in_progress')}</span>
      </SessionText>
      <div>
        <Button onClick={() => navigate('graph')}>{t('replay.playback')}</Button>
      </div>
    </SessionWrapper>
  );
};

export default EmgPlaybackBar;
