import { useState } from 'react';
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, process, State } from '@progress/kendo-data-query';

const createDataState = (dataState: State, data: any) => {
  return {
    result: process(data.slice(0), { ...dataState, take: 0, skip: 0 }),
    dataState: dataState
  };
};

export const useTable = (initialData: any, filters?: any, itemsPerPage: number = 12) => {
  const initialState = createDataState(
    {
      take: itemsPerPage,
      skip: 0
    },
    initialData
  );

  const [result, setResult] = useState<DataResult>(initialState.result);
  const [dataState, setDataState] = useState<State>(initialState.dataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    const updatedState = createDataState(event.dataState, initialData);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const updateTable = (initialData: any, filters?: any, itemsPerPage: number = 12) => {
    const updatedState = createDataState(
      {
        take: itemsPerPage,
        skip: 0
      },
      initialData
    );
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  return { result, dataState, dataStateChange, updateTable };
};
