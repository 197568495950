import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';
import { useNavigate } from 'react-router-dom';
import { HeaderComponent } from '../../../components/Typography/Header';
import { useDeviceCreate } from '../../../hooks/api/useDevices';
import { DEVICE } from '../../../../constants/routes';
import * as Sentry from '@sentry/react';
import DeviceForm from './DeviceForm';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
`;

const AddDevice = () => {
  const { mutateAsync: createDevice, isLoading: isLoadingCreate } = useDeviceCreate();

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    const {
      model = undefined,
      pcb_version: pcb = undefined,
      firmware_version: firmware = undefined,
      amputee = undefined,
      clinician = undefined,
      serial = undefined,
      bluetooth_id = undefined,
      company = undefined
    } = e;

    const deviceData = {
      ...(model && { model_id: model.id }),
      ...(pcb && { pcb_version_id: pcb.id }),
      ...(firmware && { firmware_version_id: firmware.id }),
      ...(bluetooth_id && { bluetooth_id: bluetooth_id }),
      ...(serial && { serial }),
      ...(amputee ? { amputee_id: amputee.id } : { amputee_id: null }),
      ...(clinician ? { clinician_id: clinician.id } : { clinician_id: null }),
      ...(company ? { company_id: company.id } : { company_id: null })
    };

    try {
      await createDevice(deviceData);
      navigate(DEVICE);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={'Add device'} />
        <StyledCard>
          <DeviceForm handleSubmit={handleSubmit} loading={isLoadingCreate} />
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddDevice;
