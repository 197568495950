import { useCallback, useEffect, useRef, useState } from 'react';

interface UseSecondCounterOptions {
  initialTime?: number;
  autoStart?: boolean;
}

const useCountDown = ({ initialTime = 0, autoStart = false }: UseSecondCounterOptions) => {
  const [time, setTime] = useState(initialTime);
  const intervalRef = useRef<number | null>(null);

  const startTimer = useCallback(() => {
    intervalRef.current = window.setInterval(decrementTime, 1000);
  }, []);

  const decrementTime = useCallback(() => {
    setTime((prevTime) => {
      if (prevTime > 0) {
        return prevTime - 1;
      } else {
        clearTimer();
        return 0;
      }
    });
  }, []);

  const clearTimer = useCallback(() => {
    if (intervalRef.current !== null) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const start = useCallback(() => {
    if (intervalRef.current === null) {
      startTimer();
    }
  }, [startTimer]);

  const pause = useCallback(() => {
    clearTimer();
  }, [clearTimer]);

  const reset = useCallback(() => {
    clearTimer();
    setTime(initialTime);
  }, [initialTime, clearTimer]);

  useEffect(() => {
    if (autoStart) {
      start();
    }
    return () => {
      clearTimer();
    };
  }, [autoStart, start, clearTimer]);

  return { time, start, pause, reset };
};
export default useCountDown;
