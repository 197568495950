import { Avatar } from '@mui/material';
import { stringAvatar, stringToColor } from 'adp-panel/components/PatientCard/PatientCard';
import React from 'react';

const UserListAvatar = ({ name, children }: { name: string; children?: React.ReactNode }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar
      {...stringAvatar(name)}
      sx={{
        bgcolor: stringToColor(name),
        fontSize: 12,
        width: 28,
        height: 28
      }}
    />
    <div style={{ marginLeft: '8px' }}>{children ? children : name}</div>
  </div>
);

export default UserListAvatar;
