/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { isStrongPassword } from 'configurator/views/Register/utils';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useChangePassword } from '../../hooks/api/useUsers';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { NotificationFactory } from 'lib/NotificationFactory';

const changePasswordSchema = yup.object({
  currentPassword: yup.string().required(VALIDATOR_TEXT.REQUIRED),
  newPassword: yup
    .string()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('valid-pass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
      if (value === undefined || value === null || value === '') return true;

      return isStrongPassword(value);
    }),
  retypeNewPassword: yup
    .string()
    .required(VALIDATOR_TEXT.REQUIRED)
    .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
      const parentPassword = this.parent.newPassword;
      if (parentPassword === undefined || parentPassword === null || parentPassword === '')
        return true;

      return parentPassword === value;
    })
});

type Inputs = {
  currentPassword: string;
  newPassword: string;
  retypeNewPassword: string;
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync: changePassword, isLoading: isLoadingChangePassword } = useChangePassword();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { newPassword, currentPassword } = data;

    try {
      await changePassword({
        new_password: newPassword,
        old_password: currentPassword
      });
      navigate(-1);
    } catch (err: any) {
      Sentry.captureException(err);
      NotificationFactory.errorNotification(err?.response?.data?.errors?.old_password[0]);
    }
  };

  const initialValue = {
    currentPassword: '',
    newPassword: '',
    retypeNewPassword: ''
  };

  const {
    control,
    handleSubmit: handleSubmitChangePassword,
    formState
  } = useForm<Inputs>({
    defaultValues: initialValue,
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
    shouldUnregister: false
  });

  return (
    <DefaultLayout>
      <FormContentWrapper
        title={t('account:component.account_change_password.form.title', 'Change password')}
        small>
        <form onSubmit={handleSubmitChangePassword(onSubmit)}>
          <FormWrapper>
            <CustomPasswordField
              label={t('account:component.account_change_password.form.old_password')}
              id='currentPassword'
              control={control}
            />
            <CustomPasswordField
              label={t(
                'account:component.account_change_password.form.new_password',
                'New password'
              )}
              id='newPassword'
              control={control}
            />
            <CustomPasswordField
              label={t(
                'account:component.account_change_password.form.retype_password',
                'Re-type new password'
              )}
              id='retypeNewPassword'
              control={control}
            />
            <FormButtonsWrapper>
              <CustomButton
                data-testid='cancelChangePassword'
                type='button'
                color='light'
                onClick={() => navigate(-1)}>
                {t('account:component.account_change_password.button.cancel', 'Cancel')}
              </CustomButton>
              <LoadingButton
                data-testid='submitChangePasswordForm'
                type='submit'
                disabled={!formState.isValid}
                loading={isLoadingChangePassword}>
                <span>
                  {t(
                    'account:component.account_change_password.button.save_password',
                    'Save new password'
                  )}
                </span>
              </LoadingButton>
            </FormButtonsWrapper>
          </FormWrapper>
        </form>
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ChangePassword;
