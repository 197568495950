/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useConfigStore } from 'configurator/reducers/configStore';
import { pulseTimingsEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;
const PulseTimings = ({
  addHistoryProsthesis,
  pulseTimings,
  disable
}: {
  addHistoryProsthesis: any;
  pulseTimings: pulseTimingsEntry;
  disable: boolean;
}) => {
  const [pulseTimingsLocal, setPulseTimingsLocal] = useState<any>(initialState.config.pulseTimings);
  const [minimumPulse, maximumPulse, minimumBetweenPulses, maximumBetweenPulses] = pulseTimings;
  const { setConfigProperty } = useConfigStore();

  const handlePulseSlider = (values: any, sliderNumber: any) => {
    let newMinimumPulse = minimumPulse;
    let newMaximumPulse = maximumPulse;
    let newMinimumBetweenPulses = minimumBetweenPulses;
    let newMaximumBetweenPulses = maximumBetweenPulses;
    if (sliderNumber === 1) {
      [newMinimumBetweenPulses, newMaximumBetweenPulses] = values;
    } else {
      [newMinimumPulse, newMaximumPulse] = values;
    }
    addHistoryProsthesis(
      setConfigProperty('pulseTimings', [
        newMinimumPulse,
        newMaximumPulse,
        newMinimumBetweenPulses,
        newMaximumBetweenPulses
      ])
    );
  };

  useEffect(() => {
    if (pulseTimings) {
      setPulseTimingsLocal(pulseTimings);
    }
  }, [pulseTimings]);

  const PulseTimings = useMemo(
    () => (
      <MultiSlider
        header='Pulse timings'
        disabled={disable}
        sliderValues={[
          [pulseTimingsLocal?.[0], pulseTimingsLocal?.[1]],
          [pulseTimingsLocal?.[2], pulseTimingsLocal?.[3]]
        ]}
        limits={[
          { min: 10, max: 750 },
          { min: 50, max: 2000 }
        ]}
        handleChange={(...args: any) =>
          setPulseTimingsLocal((prev: any) => {
            const localCopy = [...prev];
            if (args[3] === 0) {
              localCopy[0] = args[1][0];
              localCopy[1] = args[1][1];
            } else {
              localCopy[2] = args[1][0];
              localCopy[3] = args[1][1];
            }
            return localCopy;
          })
        }
        labelSliders={[
          <LabelWrapper>
            <p>Min pulse time: {parseNumber(pulseTimingsLocal?.[0])} s</p>
            <p>Max pulse time: {parseNumber(pulseTimingsLocal?.[1])} s</p>
          </LabelWrapper>,
          <LabelWrapper>
            <p>Min time between pulses: {parseNumber(pulseTimingsLocal?.[2])} s</p>
            <p>Max time between pulses: {parseNumber(pulseTimingsLocal?.[3])} s</p>
          </LabelWrapper>
        ]}
        handleOnAfterChange={(...args: any) => handlePulseSlider(args[1], args[2])}
        description='Sets time for performing open-open or single alternating signal'
        key='Pulse timings slider'
      />
    ),
    [pulseTimings, pulseTimingsLocal, disable]
  );

  return PulseTimings;
};

export default PulseTimings;
