import React, { useEffect, useRef, useState } from 'react';
import { ChatListItem } from 'adp-panel/features/Chat/components/styled';
import { ArchiveMessage, ChatMessage } from 'adp-panel/features/Chat/types';
import ProcessMessage from 'adp-panel/features/Chat/components/Extensions/ProcessMessage';
import { MessageContext } from 'adp-panel/features/Chat/components/Extensions/MessageContext';
import { useMessageActions } from 'adp-panel/features/Chat/components/Extensions/MessageProcessor';
import { useIntersectionObserver } from 'adp-panel/features/Chat/hooks/useIntersectionObserver';
import { throttle } from 'lodash';

const ChatMessageSection = ({
  children,
  handleMessageVisibleAction
}: {
  children: React.ReactNode;
  handleMessageVisibleAction: () => void;
}) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const entry = useIntersectionObserver(ref, { threshold: [0, 0.5, 1] });
  const isVisible = !!entry?.isIntersecting;

  if (isVisible) {
    handleMessageVisibleAction();
  }

  return <ChatListItem ref={ref}>{children}</ChatListItem>;
};

const ChatMessages = ({
  history,
  markMessageAsRead,
  usedMessages = []
}: {
  history: ChatMessage[];
  markMessageAsRead: Function;
  usedMessages: ChatMessage[];
}) => {
  const [messageElements, setMessageElements] = useState<ChatMessage[]>([]);
  const throttledActions = useRef<Record<string, Function>>({});

  const dedupedHistory = history.reduce((prev: any, message: any) => {
    if (
      !prev.some((m: ChatMessage) => m.id === message.id) &&
      !usedMessages.some((usedMessage: ChatMessage) => usedMessage.msgId === message.msgId)
    ) {
      prev.push(message);
    }
    return prev;
  }, []);

  const handleVisibleAction = (messageEntry: ArchiveMessage) => {
    if (messageEntry.msgId && document.hasFocus() && !messageEntry.seen) {
      if (!throttledActions.current[messageEntry.msgId]) {
        throttledActions.current[messageEntry.msgId] = throttle(
          (msg) => markMessageAsRead(msg.msgId),
          100
        );
      }
      throttledActions.current[messageEntry.msgId](messageEntry);
    }
  };

  useEffect(() => {
    const renderedMessages = dedupedHistory.map((message: ArchiveMessage) => {
      return (
        <ChatMessageSection
          handleMessageVisibleAction={() => handleVisibleAction(message)}
          key={message.id}>
          <ProcessMessage message={message} />
        </ChatMessageSection>
      );
    });
    setMessageElements(renderedMessages);
  }, [history]);

  return (
    <MessageContext.Provider value={{ ...useMessageActions() }}>
      {messageElements as any}
    </MessageContext.Provider>
  );
};
export default ChatMessages;
