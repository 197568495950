import workerScript from 'adp-panel/workers/worker-script';
import { useEffect, useState } from 'react';

const timerWorker = new Worker(workerScript);
export const useTimerWorker = () => {
  const [running, setRunning] = useState(false);
  const [autoLogoutRemainingTime, setAutoLogoutRemainingTime] = useState(0);

  const startTimer = () => {
    setRunning(true);
  };

  const stopTimer = () => {
    setRunning(false);
  };

  const reset = () => {
    setAutoLogoutRemainingTime(0);
    timerWorker.postMessage({ action: 'reset' });
  };

  timerWorker.onmessage = ({ data: { time, action } }) => {
    if (action === 'timer') {
      setAutoLogoutRemainingTime(time);
      return;
    }

    if (action === 'fakeActivity') {
      sessionStorage.setItem('lastActivity', new Date().toISOString());
      timerWorker.postMessage({ action: 'reset' });
      return;
    }
  };

  useEffect(() => {
    if (running) {
      timerWorker.postMessage({ action: 'on' });
    } else {
      timerWorker.postMessage({ action: 'off' });
    }

    return () => {
      timerWorker.postMessage({ action: 'off' });
    };
  }, [running]);

  return { startTimer, resetTimer: reset, stopTimer, autoLogoutRemainingTime, timerWorker };
};
