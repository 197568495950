import { encodeQueryData } from 'configurator/api/utils/encodeQuery';
import {
  DeviceEntry,
  ConfigNoteEntry,
  ConfigNoteQueryParams,
  ConfigNotesQueryParams,
  ConfigNoteBodyParams,
  ConfigTemplatesQueryParams,
  ConfigTemplateBodyParams,
  ConfigTemplateUpdateBodyParams,
  DeviceUpdatePayload,
  TemplateNoteBodyParams,
  TemplateNotesQueryParams,
  TemplateNoteQueryParams,
  DeviceConfigBodyParams,
  TestConfigBodyParams,
  UpdateConfigDemoBodyParams,
  ConfigPayload,
  DeviceSerialStatusEntry,
  FirmwarePayloadQueryParams,
  AddDeviceCodePayload,
  DevicesQueryParams
} from 'configurator/api/device/device.types';
import { PaginatedResponse } from 'configurator/utils/types';
import { AxiosResponse } from 'axios';
import api from '../utils/apiClient';

export const GET_USER_DEVICES = 'api/user/{deviceId}/devices';
export const GET_DEVICE_INFO = 'api/device/{id}';
export const GET_DEVICE_CONFIG = 'api/device/{id}/config';
export const GET_DEVICE_CONFIG_HISTORY = 'api/device/{id}/config/history';
export const GET_DEVICE_CONFIG_HISTORY_ENTRY = 'api/device/{deviceId}/config/{configId}';
export const UPDATE_DEVICE_CONFIG = 'api/device/{deviceId}/config';
export const SEND_TEST_CONFIG = 'api/device/{deviceId}/config/send';
export const UPDATE_CONFIG_DEMO = 'api/device/{deviceId}/config/demos/{demoId}';
export const UPDATE_DEVICE_CONFIG_ENTRY = 'api/device/{deviceId}/config/history/{configId}';
export const GET_CONFIG_ENTRY_NOTES = 'api/device/{deviceId}/config/{configId}/notes';
export const GET_CONFIG_ENTRY_NOTE = 'api/device/{deviceId}/config/{configId}/notes/{noteId}';
export const CREATE_CONFIG_ENTRY_NOTE = 'api/device/{deviceId}/config/{configId}/notes';
export const DELETE_CONFIG_ENTRY_NOTE = 'api/device/{deviceId}/config/{configId}/notes/{noteId}';
export const GET_CONFIG_TEMPLATES = 'api/config/templates';
export const GET_CONFIG_TEMPLATE = 'api/config/templates/{templateId}';
export const CREATE_CONFIG_TEMPLATE = 'api/config/templates';
export const UPDATE_CONFIG_TEMPLATE = 'api/config/templates/{templateId}';
export const DELETE_CONFIG_TEMPLATE = 'api/config/templates/{templateId}';
export const UPDATE_DEVICE = 'api/device/{id}';
export const GET_TEMPLATE_NOTES = 'api/config/templates/{templateId}/notes';
export const GET_TEMPLATE_NOTE = 'api/config/templates/{templateId}/notes/{noteId}';
export const CREATE_TEMPLATE_NOTE = 'api/config/templates/{templateId}/notes';
export const DELETE_TEMPLATE_NOTE = 'api/config/templates/{templateId}/notes/{noteId}';
export const RESTORE_CONFIG_HISTORY = 'api/device/{deviceId}/config/restore/{configId}';
export const GET_FIRMWARE_KEYS = 'api/versions/firmware/{firmwareId}/schema';
export const CHECK_DEVICE_SERIAL = 'api/device/check/{serial}';
export const ADD_DEVICE_WITH_CODE = 'api/device/add/{serial}';
export const GET_DEVICES = 'api/devices';

const withCredentials = {
  withCredentials: true
};

export const getDevices = (params: DevicesQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<DeviceEntry>>(`${GET_DEVICES}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getDevice = (deviceId: number, queryParams: any = {}) => {
  const queryString = encodeQueryData(queryParams);
  return api
    .get<DeviceEntry>(
      GET_DEVICE_INFO.replace('{id}', `${deviceId}?${queryString}`),
      withCredentials
    )
    .then((res) => res.data);
};

export const checkDeviceSerial = (serial: string) =>
  api
    .get<DeviceSerialStatusEntry>(
      CHECK_DEVICE_SERIAL.replace('{serial}', `${serial}`),
      withCredentials
    )
    .then((res) => res.data);

export const updateDevice = ({
  deviceId,
  data
}: {
  deviceId: number;
  data: DeviceUpdatePayload;
}): Promise<any> =>
  api
    .put(UPDATE_DEVICE.replace('{id}', `${deviceId}`), data, withCredentials)
    .then((res: any) => res.data);

export const getDeviceConfig = (deviceId: number): Promise<ConfigPayload> =>
  api
    .get(GET_DEVICE_CONFIG.replace('{id}', `${deviceId}`), withCredentials)
    .then((res) => res.data);

export const getDeviceConfigTemplate = ({
  templateId,
  params = {}
}: {
  templateId: number;
  params: ConfigTemplatesQueryParams;
}) => {
  const queryString = encodeQueryData(params);
  return api
    .get(
      `${GET_CONFIG_TEMPLATE.replace('{templateId}', `${templateId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getDeviceConfigTemplates = ({
  params = {}
}: {
  params: ConfigTemplatesQueryParams;
}) => {
  const queryString = encodeQueryData(params);
  return api.get(`${GET_CONFIG_TEMPLATES}?${queryString}`, withCredentials).then((res) => res.data);
};

export const createConfigTemplate = ({ data }: { data?: ConfigTemplateBodyParams }): Promise<any> =>
  api.post(CREATE_CONFIG_TEMPLATE, data, withCredentials).then((res) => res.data);

export const addDeviceWithCode = (data: AddDeviceCodePayload): Promise<any> =>
  api
    .post(ADD_DEVICE_WITH_CODE.replace('{serial}', `${data.serial}`), data, withCredentials)
    .then((res) => res.data);

export const updateConfigTemplate = ({
  templateId,
  data
}: {
  templateId: number;
  data?: ConfigTemplateUpdateBodyParams;
}): Promise<any> =>
  api
    .put(UPDATE_CONFIG_TEMPLATE.replace(`{templateId}`, `${templateId}`), data, withCredentials)
    .then((res) => res.data);

export const deleteConfigTemplate = ({ templateId }: { templateId: number }): Promise<any> =>
  api
    .delete(DELETE_CONFIG_TEMPLATE.replace(`{templateId}`, `${templateId}`), withCredentials)
    .then((res) => res.data);

export const getDeviceConfigHistory = (deviceId: number, queryParams: any = {}) => {
  const queryString = encodeQueryData(queryParams);
  return api
    .get(
      `${GET_DEVICE_CONFIG_HISTORY.replace('{id}', `${deviceId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getDeviceConfigHistoryEntry = (
  deviceId: number,
  configId: number,
  queryParams: any = {}
) => {
  const queryString = encodeQueryData(queryParams);
  return api
    .get(
      `${GET_DEVICE_CONFIG_HISTORY_ENTRY.replace('{deviceId}', `${deviceId}`).replace(
        '{configId}',
        `${configId}`
      )}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const updateDeviceConfig = ({
  deviceId,
  data
}: {
  deviceId: number;
  data: DeviceConfigBodyParams;
}): Promise<ConfigPayload> =>
  api
    .post(UPDATE_DEVICE_CONFIG.replace('{deviceId}', `${deviceId}`), data, withCredentials)
    .then((res) => res.data);

export const sendTestConfig = ({
  deviceId,
  data
}: {
  deviceId: number;
  data: TestConfigBodyParams;
}): Promise<any> =>
  api
    .post(SEND_TEST_CONFIG.replace('{deviceId}', `${deviceId}`), data, withCredentials)
    .then((res) => res.data);

export const updateConfigDemo = ({
  deviceId,
  demoId,
  data
}: {
  deviceId: number;
  demoId: number;
  data?: UpdateConfigDemoBodyParams;
}): Promise<any> =>
  api
    .put(
      UPDATE_CONFIG_DEMO.replace('{deviceId}', `${deviceId}`).replace('{demoId}', `${demoId}`),
      data,
      withCredentials
    )
    .then((res) => res.data);

export const getUserDevices = (companyId: number, queryParams: any = {}): Promise<any> => {
  const queryString = encodeQueryData(queryParams);
  return api
    .get(
      `${GET_USER_DEVICES.replace('{deviceId}', `${companyId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const updateDeviceConfigEntry = ({ deviceId, configId, data }): Promise<any> =>
  api
    .post(
      UPDATE_DEVICE_CONFIG_ENTRY.replace('{deviceId}', `${deviceId}`).replace(
        '{configId}',
        `${configId}`
      ),
      data,
      withCredentials
    )
    .then((res) => res.data);

export const getConfigEntryNotes = ({
  deviceId,
  configId,
  params = {}
}: {
  deviceId: number;
  configId: number;
  params: ConfigNotesQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<ConfigNoteEntry>>(
      `${GET_CONFIG_ENTRY_NOTES.replace('{deviceId}', `${deviceId}`).replace(
        '{configId}',
        `${configId}`
      )}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getConfigEntryNote = ({
  deviceId,
  configId,
  noteId,
  params = {}
}: {
  deviceId: number;
  configId: number;
  noteId: number;
  params: ConfigNoteQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<ConfigNoteEntry>(
      `${GET_CONFIG_ENTRY_NOTES.replace(`{deviceId}`, `${deviceId}`)
        .replace(`{configId}`, `${configId}`)
        .replace(`{noteId}`, `${noteId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const createConfigNote = ({
  deviceId,
  configId,
  data
}: {
  deviceId: number;
  configId: number;
  data?: ConfigNoteBodyParams;
}): Promise<any> =>
  api
    .post(
      CREATE_CONFIG_ENTRY_NOTE.replace(`{deviceId}`, `${deviceId}`).replace(
        `{configId}`,
        `${configId}`
      ),
      data,
      withCredentials
    )
    .then((res) => res.data);

export const deleteConfigNote = ({
  deviceId,
  configId,
  noteId
}: {
  deviceId: number;
  configId: number;
  noteId: number;
}): Promise<any> =>
  api
    .delete(
      DELETE_CONFIG_ENTRY_NOTE.replace(`{deviceId}`, `${deviceId}`)
        .replace(`{configId}`, `${configId}`)
        .replace(`{noteId}`, `${noteId}`),
      withCredentials
    )
    .then((res) => res.data);

export const getTemplateNotes = ({
  templateId,
  params = {}
}: {
  templateId: number;
  params: TemplateNotesQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<ConfigNoteEntry>>(
      `${GET_TEMPLATE_NOTES.replace('{templateId}', `${templateId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const getTemplateNote = ({
  templateId,
  noteId,
  params = {}
}: {
  templateId: number;
  noteId: number;
  params: TemplateNoteQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<ConfigNoteEntry>(
      `${GET_TEMPLATE_NOTES.replace(`{templateId}`, `${templateId}`).replace(
        `{noteId}`,
        `${noteId}`
      )}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};

export const createTemplateNote = ({
  templateId,
  data
}: {
  templateId: number;
  data?: TemplateNoteBodyParams;
}): Promise<any> =>
  api
    .post(CREATE_TEMPLATE_NOTE.replace(`{templateId}`, `${templateId}`), data, withCredentials)
    .then((res) => res.data);

export const deleteTemplateNote = ({
  templateId,
  noteId
}: {
  templateId: number;
  noteId: number;
}): Promise<any> =>
  api
    .delete(
      DELETE_TEMPLATE_NOTE.replace(`{templateId}`, `${templateId}`).replace(
        `{noteId}`,
        `${noteId}`
      ),
      withCredentials
    )
    .then((res) => res.data);

export const restoreConfig = ({
  deviceId,
  configId
}: {
  deviceId: number;
  configId: number;
}): Promise<any> =>
  api
    .post(
      `${RESTORE_CONFIG_HISTORY.replace('{deviceId}', `${deviceId}`).replace(
        '{configId}',
        `${configId}`
      )}`,
      null,
      withCredentials
    )
    .then((res) => res.data);

export const getFirmwareKeys = ({
  firmwareId,
  params = {}
}: {
  firmwareId: number;
  params?: FirmwarePayloadQueryParams;
}): Promise<any> => {
  const queryString = encodeQueryData(params);
  return api
    .get(
      `${GET_FIRMWARE_KEYS.replace('{firmwareId}', `${firmwareId}`)}?${queryString}`,
      withCredentials
    )
    .then((res) => res.data);
};
