import React from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import useCompatibilities from 'configurator/hooks/useCompatibilities';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { StyledCheck, StyledTimes } from '../FirmwareModal';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PcbReplacementFinish = () => {
  const { versions } = useDeviceInfoStore((state: any) => ({
    versions: state.versions,
    deviceId: state.deviceId
  }));
  const { availableFirmwares, isFirmwareUpdateAvailable, isFirmwareUpdateNeeded } =
    useCompatibilities();
  const firmwareVersionCurrentParsed = `${versions?.current?.[1]}.${versions?.current?.[4]}.${versions?.current?.[7]}`;
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state: any) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { openModal, closeModal } = useUiStore((state: any) => ({
    openModal: state.openModal,
    closeModal: state.closeModal
  }));

  const finish = async () => {
    closeModal(MODALS.repair);
    await disconnectDevice();
    await getInitialConfigAPI();
  };

  const handleFirmware = () => {
    openModal(MODALS.firmware);
    closeModal(MODALS.repair);
  };

  return (
    <DialogContent>
      <Wrapper>
        <InfoWrapper>
          <div>Device version: {firmwareVersionCurrentParsed}</div>
          <div>Newest version: {availableFirmwares?.[0]?.name}</div>
          {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
            <>
              (Outdated) <StyledTimes />
            </>
          ) : (
            <>
              <StyledCheck />
            </>
          )}
        </InfoWrapper>
        <ButtonsWrapper>
          {isFirmwareUpdateAvailable || isFirmwareUpdateNeeded ? (
            <Button type='button' onClick={handleFirmware}>
              Update firmware
            </Button>
          ) : (
            <Button type='button' onClick={finish}>
              Close
            </Button>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </DialogContent>
  );
};

export default PcbReplacementFinish;
