const theme = {
  colorPrimary: 'hsl(201, 53%, 46%)',
  colorDanger: 'hsl(0, 71%, 53%)',
  colorPrimaryShades: [
    'hsl(229, 53%, 32%)',
    'hsl(229, 55%, 40%)',
    'hsl(229, 57%, 34%)',
    'hsl(229, 59%, 42%)',
    'hsl(229, 61%, 50%)',
    'hsl(229, 63%, 58%)',
    'hsl(229, 65%, 66%)'
  ],
  colorSecondary: 'hsl(201, 100%, 65%)',
  colorSupporting: '#FFFFFF',
  colorFill: '#F2F2F2',
  fontFamilyPrimary: "'Open Sans', sans-serif",
  colorError: 'hsl(0, 71%, 53%)',
  colorErrorShades: ['hsl(0, 71%, 45%%)', 'hsl(0, 71%, 61%)'],
  breakpoints: {
    hdWidth: '1450px',
    hdWidthNum: 1450,
    large: '1450px',
    largeNum: 1450,
    hdWidthSmall: '1124px',
    hdWidthSmallNum: 1124,
    hdHeight: '800px',
    hdHeightNum: 800,
    medium: '1124px',
    mediumNum: 1124,
    small: '924px',
    smallNum: 924,
    xsmall: '724px',
    xsmallNum: 724,
    mediumHeight: '730px',
    mediumHeightNum: 730,
    smallHeight: '520px',
    smallHeightNum: 520
  },
  typography: {
    fonts: {
      OpenSans: "'Open Sans', sans-serif"
    },
    fontSizes: {
      xxs: '10px',
      xs: '14px',
      s: '18px',
      m: '24px',
      l: '28px',
      xl: '36px'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700
    },
    colors: {
      primary: '#FFFFFF',
      secondary: '#393939',
      tetiary: '#4FC1FF',
      green: '#378805',
      red: 'hsl(1, 100%, 50%)',
      subtitle: 'hsl(0, 0%, 50%)'
    }
  }
};

export default theme;
