import {
  BackToListSection,
  ChatHeader,
  ChatHeaderIcons,
  CloseChatIcon,
  Icon,
  MinimizedChatNav,
  UnreadChip
} from 'adp-panel/features/Chat/components/styled';
import React from 'react';
import { ReactComponent as ChatIcon } from 'assets/chat.svg';
import * as svgIcons from '@progress/kendo-svg-icons';
import { ChatRoomEntry } from 'adp-panel/features/Chat/api/chat.types';
import { trimToLastWord } from 'adp-panel/features/Chat/hooks/useChatUtils';

interface ChatRoomHeaderProps {
  isMinimized: boolean;
  isFullScreen: boolean;
  toggleFullScreenMode: () => void;
  toggleChatSize: () => void;
  backToRoomList: () => void;
  unreadMessages: any[];
  currentRoom: ChatRoomEntry;
}
const ChatRoomHeader = ({
  isMinimized,
  isFullScreen,
  toggleFullScreenMode,
  toggleChatSize,
  unreadMessages,
  backToRoomList,
  currentRoom
}: ChatRoomHeaderProps) => {
  const chatTitle = currentRoom.patient?.name || '';
  const headerAction = (
    <ChatHeaderIcons>
      <Icon onClick={toggleFullScreenMode} icon={svgIcons['borderRadiusIcon']} />
      {isMinimized ? (
        <Icon onClick={toggleChatSize} icon={svgIcons['chevronUpIcon']} />
      ) : (
        <Icon onClick={toggleChatSize} icon={svgIcons['chevronDownIcon']} />
      )}
    </ChatHeaderIcons>
  );

  if (isMinimized) {
    return (
      <ChatHeader minimised={isMinimized} onClick={toggleChatSize}>
        {unreadMessages && unreadMessages.length > 0 && (
          <UnreadChip>{unreadMessages.length}</UnreadChip>
        )}
        <MinimizedChatNav>
          <ChatIcon />
          <h5>Chat</h5>
        </MinimizedChatNav>
      </ChatHeader>
    );
  }

  return (
    <ChatHeader minimised={isMinimized}>
      <div onClick={backToRoomList}>
        <BackToListSection>
          <Icon icon={svgIcons['chevronLeftIcon']} />
          <h5>Chat with {trimToLastWord(chatTitle, isFullScreen ? 200 : 55)}</h5>
        </BackToListSection>
      </div>
      {isFullScreen ? (
        <CloseChatIcon onClick={toggleFullScreenMode}>X</CloseChatIcon>
      ) : (
        headerAction
      )}
    </ChatHeader>
  );
};

export default ChatRoomHeader;
