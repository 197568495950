import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, DialogContent, TextField } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import ModalBase from './ModalBase';
import { CHOOSE_GRIPS } from 'constants/routes';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
};

type Inputs = {
  note: string;
};

const CloseSessionModal = ({ handleClose }: ModalProps) => {
  const { sendConfigOnlyToApi } = useDeviceManager();
  const { disconnectRemoteSession, sendMeetingSummary } = useRemoteSession();
  const navigate = useNavigate();
  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));
  const additionalArgs = additionalModalArgs(MODALS.closeSession)?.args;

  const accepted = async (data) => {
    const sendTestConfigResponse = await sendConfigOnlyToApi(data.note);
    await sendMeetingSummary(sendTestConfigResponse);
    await disconnectRemoteSession(true);
    navigate(CHOOSE_GRIPS);

    if (additionalArgs?.action) {
      additionalArgs.action();
    }
    handleClose();
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();

  const onSubmit = (data) => {
    accepted(data);
  };

  return (
    <ModalBase handleClick={handleClose} header='Stop session' allowBackDropClose={false}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ParagraphBold>
            Are you sure want to stop session? Every unsent configuration will be lost.
          </ParagraphBold>
          <TextField
            fullWidth
            id='note'
            label='Description'
            multiline
            required
            placeholder='Describe the change'
            {...register('note', { required: 'This field is required' })}
            error={Boolean(errors?.note)}
            helperText={errors?.note?.message}
            rows={6}
          />
          <ButtonsWrapper>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button type='submit' color='error'>
              Close session
            </Button>
          </ButtonsWrapper>
        </form>
      </DialogContent>
    </ModalBase>
  );
};

export default CloseSessionModal;
