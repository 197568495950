import React from 'react';
import styled from 'styled-components';
import { ReactComponent as OpposedTreeGraph } from 'assets/OpposedCropped.svg';
import { ReactComponent as NonOpposedTreeGraph } from 'assets/Non-OpposedCropped.svg';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { Grips } from '../../../../bluetooth/Bluetooth/Grips';
import Card from 'adp-panel/components/Card/Card';

const OpposedTreeGraphStyled = styled(OpposedTreeGraph)`
  padding: 0 10%;
  width: 100%;
`;

const NonOpposedTreeGraphStyled = styled(NonOpposedTreeGraph)`
  padding: 0 10% 5px 10%;
  width: 100%;
`;

const DropdownsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0% 1fr 1fr;
  grid-gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 1fr 6% 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10px;
  }
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  opened: number | null;
  updateOpened?: Function;
  indexes: number[];
  gripsImgs: any;
  disabled: boolean;
};

const GraphChooserTree = ({
  grips,
  values,
  nonOpposed = false,
  onChange,
  updateOpened = () => false,
  opened = null,
  indexes = [0],
  gripsImgs,
  disabled,
  ...props
}: GraphChooserTreeProps) => {
  const updateGrips = (option, gripOrder, isNonOpposed) => {
    onChange(gripsGroupsOptionsReversedMap.get(option), gripOrder, isNonOpposed);
  };

  return (
    <Card {...props}>
      {nonOpposed ? <NonOpposedTreeGraphStyled /> : <OpposedTreeGraphStyled />}
      <DropdownsWrapper>
        {indexes.map((chooseGripsIndex, localIndex) => (
          <React.Fragment key={chooseGripsIndex}>
            {localIndex === 2 ? <div>&nbsp;</div> : null}
            <DropdownImg
              disabled={disabled}
              options={grips.map((grip) => ({
                value: gripsGroupsOptionsMap.get(grip),
                img: gripsImgs.get(grip)
              }))}
              selected={{
                value: gripsGroupsOptionsMap.get(values[localIndex]),
                img: gripsImgs.get(values[localIndex])
              }}
              responsive
              onChange={(option) => updateGrips(option, localIndex, nonOpposed)}
              directControl
              setOpenParent={() => updateOpened(chooseGripsIndex)}
              isOpen={opened === chooseGripsIndex}
            />
          </React.Fragment>
        ))}
      </DropdownsWrapper>
    </Card>
  );
};

export default GraphChooserTree;
