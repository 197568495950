export const emgColors = {
  emgExtension: 'rgba(0,255,0,1)',
  emgFlexion: 'rgba(255,0,0,1)',
  csOpen: 'rgba(0,255,0)',
  csClose: 'rgba(255,0,0)',
  activationOpen: 'rgba(0,199,0)',
  activationClose: 'rgba(199,0,0)',
  speed2Open: 'rgba(0,145,0)',
  speed2Close: 'rgba(145,0,0)',
  speed3Open: 'rgba(0,95,0)',
  speed3Close: 'rgba(95,0,0)',
  powerClose: 'rgba(255,187,13)'
};
