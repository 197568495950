import styled, { css } from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { myTheme } from '../../../theme/theme';

const SvgStyle = css`
  width: 14px;
  height: 14px;
`;

const ButtonStyle = css`
  width: 30px;
  height: 30px;
  padding: 0;
`;

const EditButton = styled(Button)`
  ${ButtonStyle};
  background-color: #fff;

  svg {
    ${SvgStyle};
  }
`;

const EditButtonPrimary = styled(Button)`
  ${ButtonStyle};
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.primary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary};
    svg {
      fill: #fff;
    }
  }

  svg {
    ${SvgStyle};
    fill: ${({ theme }) => theme.palette.primary};
  }
`;

const EditButtonError = styled(Button)`
  ${ButtonStyle};
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.error};

  &:hover {
    background-color: ${({ theme }) => theme.palette.error};
    svg {
      fill: #fff;
    }
  }

  svg {
    ${SvgStyle};
    fill: ${({ theme }) => theme.palette.error};
  }
`;

const EditButtonSuccess = styled(Button)`
  ${ButtonStyle};
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.palette.success};

  &:hover {
    background-color: ${({ theme }) => theme.palette.success};
    svg {
      fill: #fff;
    }
  }

  svg {
    ${SvgStyle};
    fill: ${({ theme }) => theme.palette.success};
  }
`;

const variants = {
  primary: myTheme.palette.primary,
  success: myTheme.palette.success,
  error: myTheme.palette.error
};

interface Props {
  variant: 'primary' | 'success' | 'error';
}

const EditButtonGeneral = styled(Button)<Props>`
  ${ButtonStyle};
  background-color: #fff;
  border: 1px solid ${({ variant }) => variants[`${variant}`]};

  &:hover {
    background-color: ${({ variant }) => variants[`${variant}`]};
    svg {
      fill: #fff;
    }
  }

  svg {
    ${SvgStyle};
    fill: ${({ variant }) => variants[`${variant}`]};
  }
`;

export const RemoveButton = styled(Button)`
  color: white !important;
  background-color: ${({ theme }) => theme.palette.error2} !important;
`;

export const SuccessButton = styled(Button)`
  color: white !important;
  background-color: ${({ theme }) => theme.palette.success2} !important;
`;

export const ActionButton = ({ children, ...props }: any) => (
  <EditButton {...props}>{children}</EditButton>
);
export const ActionButtonPrimary = ({ children, ...props }: any) => (
  <EditButtonPrimary {...props}>{children}</EditButtonPrimary>
);
export const ActionButtonError = ({ children, ...props }: any) => (
  <EditButtonError {...props}>{children}</EditButtonError>
);
export const ActionButtonSuccess = ({ children, ...props }: any) => (
  <EditButtonSuccess {...props}>{children}</EditButtonSuccess>
);
export const ActionButtonGeneral = ({ children, ...props }: any) => (
  <EditButtonGeneral {...props}>{children}</EditButtonGeneral>
);
