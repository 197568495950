import React from 'react';
import toast, { useToaster } from 'react-hot-toast';

const ToastsRemover = ({ children }: any) => {
  const { toasts } = useToaster();

  React.useEffect(() => {
    toasts.forEach((item) => {
      if (!item.visible) {
        toast.remove(item.id);
      }
    });
  }, [toasts]);

  return <>{children}</>;
};

export default ToastsRemover;
