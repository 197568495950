import React from 'react';
import { Button, DialogContent } from '@mui/material';
import ModalBase from './ModalBase';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { EVENTS } from 'configurator/consts/events';
import { abortTestProcedure } from 'configurator/bluetooth-handler/bluetoothFunctions';
import { useConfigStore } from 'configurator/reducers/configStore';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';

const PreventInputModal = () => {
  const procedureState = useUiStore((state: any) => state.procedureState);
  const { currentlyRunningProcedure } = useConfigStore((state) => ({
    currentlyRunningProcedure: state.currentlyRunningProcedure
  }));

  const abortProcedure = () => {
    const event = new Event(EVENTS.stopProcedure);
    window.dispatchEvent(event);
    abortTestProcedure();
  };

  const isRunningCheckMovementRangeCombined =
    procedureState === FETCHING_STATES.loading &&
    currentlyRunningProcedure === ProcedureTypes.checkMovementRangeTestClosingCombined;

  return (
    <ModalBase handleClick={() => null} header='' basicModal allowBackDropClose={false}>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
          <p>Please wait...</p>
          {isRunningCheckMovementRangeCombined && (
            <div style={{ marginTop: '16px' }}>
              <Button onClick={abortProcedure}>Abort</Button>
            </div>
          )}
        </div>
      </DialogContent>
    </ModalBase>
  );
};

export default PreventInputModal;
