/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import { LoadingButton } from '@mui/lab';
import ModalBase from './ModalBase';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 20px;
`;

const InnerWrapper = styled.div`
  width: 550px;
`;

const ParagraphBold = styled.p`
  ${TextS};
  margin-bottom: 20px;
`;

type SessionReconnectionModalProps = {
  stopSessionAction: () => void;
  remainingTimeInSeconds: number;
  isLoading: boolean;
  isUnsaved: boolean;
};

let remainingTimeInterval: ReturnType<typeof setInterval> | null = null;

const SessionReconnectionModal = ({
  stopSessionAction,
  isLoading = false,
  remainingTimeInSeconds = 600,
  isUnsaved
}: SessionReconnectionModalProps) => {
  const [autoSessionCloseRemainingTime, setAutoSessionCloseRemainingTime] =
    useState(remainingTimeInSeconds);
  useEffect(() => {
    if (autoSessionCloseRemainingTime <= 0) {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
      setAutoSessionCloseRemainingTime(0);
      stopSessionAction();
    }
  }, [autoSessionCloseRemainingTime]);

  useEffect(() => {
    remainingTimeInterval = setInterval(() => {
      setAutoSessionCloseRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
    };
  }, []);

  const minutes = Math.floor(autoSessionCloseRemainingTime / 60);
  const seconds = autoSessionCloseRemainingTime - minutes * 60;

  return (
    <ModalBase header='Session reconnection' allowBackDropClose={false} hideCloseButton>
      <DialogContent>
        <InnerWrapper>
          <ParagraphBold>
            {`Patient has disconnected from session, they have ${minutes} minutes ${seconds} seconds to reconnect, after that time session will stop automatically.`}
            {isUnsaved && 'You still will be able to save current changes.'}
          </ParagraphBold>
          <ButtonsWrapper>
            <LoadingButton color='error' onClick={stopSessionAction} loading={isLoading}>
              <span>Stop session now</span>
            </LoadingButton>
          </ButtonsWrapper>
        </InnerWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default SessionReconnectionModal;
