/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { coContractionTimingsEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'configurator/reducers/configStore';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const CoContractionTimings = ({
  addHistoryProsthesis,
  coContractionTimings,
  disable,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  coContractionTimings: coContractionTimingsEntry;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [coContractionLocal, setCoContracionLocal] = useState<any>(
    initialState.config.coContractionTimings
  );
  const [longCoContraction, coContractionOffset] = coContractionTimings;

  const handleCoContractionSliders = (value: any, sliderNumber: any) => {
    const offset = sliderNumber === 1;
    let newLongCoContraction = longCoContraction;
    let newOffset = coContractionOffset;
    if (offset && value > longCoContraction) {
      newLongCoContraction = value;
    }
    if (!offset && value < coContractionOffset) {
      newOffset = value;
    }
    if (offset) {
      addHistoryProsthesis(
        setConfigProperty('coContractionTimings', [newLongCoContraction, value])
      );
    } else {
      addHistoryProsthesis(setConfigProperty('coContractionTimings', [value, newOffset]));
    }
  };

  useEffect(() => {
    if (coContractionTimings) {
      setCoContracionLocal(coContractionTimings);
    }
  }, [coContractionTimings]);

  const CoContractionTimings = useMemo(
    () => (
      <MultiSlider
        header='Co-contraction timings'
        sliderValues={[coContractionLocal?.[0], coContractionLocal?.[1]]}
        disabled={disable}
        limits={[
          { min: 200, max: 2000 },
          { min: 20, max: 500 }
        ]}
        handleChange={(...args: any) =>
          setCoContracionLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <div>Long co-contraction time: {parseNumber(coContractionLocal?.[0])} s</div>,
          <div>Signal rise offset time: {parseNumber(coContractionLocal?.[1])} s</div>
        ]}
        handleOnAfterChange={(...args: any) => handleCoContractionSliders(args[1], args[2])}
        description='Sets length of co-contraction signal'
      />
    ),
    [coContractionTimings, coContractionLocal, disable]
  );

  return CoContractionTimings;
};

export default CoContractionTimings;
