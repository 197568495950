import { useQuery } from '@tanstack/react-query';
import { getAvailablePatient, getChatRooms } from 'adp-panel/features/Chat/api/chat';
import {
  ChatAvailableUserQueryParams,
  ChatRoomsQueryParams
} from 'adp-panel/features/Chat/api/chat.types';

export const CHAT_ROOMS_QUERY_KEY = 'chat-rooms';
export const CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY = 'chat-available-patients';
export const useChatRooms = (enabled: boolean, queryParams: ChatRoomsQueryParams = {}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CHAT_ROOMS_QUERY_KEY],
    () => getChatRooms({ ...queryParams, perpage: 1000 }),
    {
      enabled,
      staleTime: 1000 * 30
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useChatAvailablePatients = (
  queryParams: ChatAvailableUserQueryParams = {},
  enabled: boolean
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY],
    () => getAvailablePatient(queryParams),
    {
      enabled
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
