/* eslint-disable no-unused-vars */
import { LoadingButton } from '@mui/lab';
import { TextField, MenuItem, Button, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import { NoteTypes } from 'configurator/utils/types';
import ModalBase from './ModalBase';

const StyledForm = styled.form`
  display: grid;
  gap: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

type AboutProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleSubmitNote: Function;
  showTypes?: boolean;
  isLoading: boolean;
};

type Inputs = {
  note: string;
};

export const NoteLabels = [
  { id: NoteTypes.private, name: 'Private' },
  { id: NoteTypes.public, name: 'Visible for patient' }
];

const AddNote = ({
  handleClose,
  handleSubmitNote = () => true,
  showTypes = true,
  isLoading
}: AboutProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const [selectedType, setSelectedType] = useState(NoteTypes.private);
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    handleSubmitNote({ note: data.note, type: selectedType });
    handleClose();
  };

  return (
    <ModalBase handleClick={handleClose} header='Add note' fullWidth>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id='note'
            label='Describe the change'
            multiline
            required
            placeholder='Write something...'
            {...register('note', { required: 'This field is required' })}
            error={Boolean(errors?.note)}
            helperText={errors?.note?.message}
            rows={6}
          />
          {showTypes && (
            <TextField
              label='Type'
              id='selected-type'
              select
              SelectProps={{
                value: selectedType,
                onChange: (e: any) => setSelectedType(e.target.value)
              }}>
              {NoteLabels.map((noteLabel) => (
                <MenuItem key={`selected-type_${noteLabel.id}`} value={noteLabel.id}>
                  {noteLabel.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <ButtonsWrapper>
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <LoadingButton type='submit' loading={isLoading}>
              <span>Add note</span>
            </LoadingButton>
          </ButtonsWrapper>
        </StyledForm>
      </DialogContent>
    </ModalBase>
  );
};

export default AddNote;
