import * as React from 'react';
import { useState } from 'react';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { InputSuffix, TextBox, TextBoxProps } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import styled from 'styled-components';
import { ReactComponent as PreviewOffIcon } from 'assets/preview-off.svg';

const LabelStyled = styled(Label)`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.palette.headerTitle};
  line-height: 1.5;
`;

const InputStyled = styled(TextBox)`
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 4px;
  padding: 0 12px 0 0;
`;

const SuffixedInput = styled(InputSuffix)`
  padding: 10px 5px;
  cursor: pointer;
  transition: transform ease-out 0.1s;
  z-index: 99999;
  &:hover {
    transform: scale(1.1);
    color: ${({ theme }) => theme.palette.infoText};
  }
`;
const EyeIcon = styled(SvgIcon)`
  transition: transform ease-out 0.1s;
`;
const EyeSlashIcon = styled(PreviewOffIcon)`
  transition: transform ease-out 0.1s;
`;

export const FormPasswordTextBox = (props: TextBoxProps & FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    placeholder,
    value = '',
    onBlur: onBlurProp,
    ...other
  } = props;
  const [showPassword, setShowPassword] = useState(true);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      {label && (
        <LabelStyled
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}>
          {label}
        </LabelStyled>
      )}
      <div className={'k-form-field-wrap'}>
        <InputStyled
          valid={valid}
          type={showPassword ? 'password' : 'text'}
          placeholder={placeholder}
          suffix={() => (
            <SuffixedInput onClick={() => setShowPassword((prevState) => !prevState)}>
              {showPassword ? (
                <EyeIcon icon={svgIcons['eyeIcon']} name='eyeIcon' />
              ) : (
                <EyeSlashIcon style={{ cursor: 'pointer' }} />
              )}
            </SuffixedInput>
          )}
          id={id}
          value={value}
          disabled={disabled}
          aria-describedby={`${hintId} ${errorId}`}
          {...other}
        />
      </div>

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
