import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompany,
  updateCompany
} from 'adp-panel/api/companies/companies';
import { useEffect } from 'react';
import { mapErrorMessage } from 'utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';

export const COMPANIES_QUERY_KEY = 'companies';
const COMPANY_QUERY_KEY = 'company';

export const useCompanies = (queryParams?: any, enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [COMPANIES_QUERY_KEY, queryParams],
    () => getCompanies(queryParams),
    {
      enabled
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCompaniesInfinite = (queryParams?: any, enabled: boolean = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [COMPANIES_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getCompanies({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCompany = (companyId: number, queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [COMPANY_QUERY_KEY],
    () => getCompany(companyId, queryParams),
    {
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCompanyCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createCompany, {
    onSuccess() {
      NotificationFactory.successNotification('Company created');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useCompanyUpdate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateCompany, {
    onSuccess() {
      NotificationFactory.successNotification('Company edited');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useCompanyDelete = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteCompany, {
    onSuccess() {
      NotificationFactory.successNotification('Company deleted');
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
