import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';

export type TicketInfoPayload = {
  ticketId: number | null;
};

export interface RedirectState {
  ticketId: number | null;
  setTicketRedirectId: (payload: TicketInfoPayload) => void;
}

const store: StateCreator<RedirectState> = (set) => ({
  ticketId: null,
  setTicketRedirectId: (payload: TicketInfoPayload) => set({ ticketId: payload.ticketId })
});

export const useRedirectStore = create<RedirectState>()(devtools(store, { name: 'Redirect' }));
