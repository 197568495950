import { allowedRedirectUrls } from 'adp-panel/constants/redirectUrls';

export const filteredRedirectUrls = (pathname: string) =>
  allowedRedirectUrls.filter((allowedUrl) => {
    const pattern = new RegExp('^' + allowedUrl.replace(/:\w+/g, '\\w*') + '$');
    return pattern.test(pathname);
  });

export const canBeShownBasedOnNotAllowedRoutes = (pathname: string, notAllowedRoutes: string[]) =>
  !notAllowedRoutes.find((notAllowedUrl) => {
    const pattern = new RegExp('^' + notAllowedUrl.replace(/:\w+/g, '\\w*') + '$');
    return pattern.test(pathname);
  });
