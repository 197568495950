import { useEffect, useState } from 'react';
import { useConfigStore } from 'configurator/reducers/configStore';
import { ProcedureTypes } from '../../bluetooth/Bluetooth/Procedures';

type ProcedureCalibrationSoftGrip = {
  status?: number[];
  maxCurrents?: number[];
};

type ProcedureCalibrationAutoGrasp = {
  status?: number[];
  minimumClosing?: number[];
};

type ProcedureFingersSpeedProcedure = {
  status?: number[];
  speeds?: number[];
};

type ProcedureCheckMovementRange = {
  positions?: number[];
  revolutions?: number[];
};

type ProcedureI2cCommunicationCheck = {
  rtcInitialization?: number;
  externalMemory1?: number;
  externalMemory2?: number;
  coapt?: number;
};

type ProcedureFingerClosingTest = {
  closingTimes?: number[];
  averageCurrents?: number[];
};

type ProcedureFingerClosingCheckMovementRangeCombinedTest = {
  closingTimes?: number[];
  averageCurrents?: number[];
  minimumClosingTimes?: number[];
  maximumClosingTimes?: number[];
  minimumCurrents?: number[];
  maximumCurrents?: number[];
  positionsUpper?: number[];
  positionsLower?: number[];
  revolutionsUpper?: number[];
  revolutionsLower?: number[];
};

type Procedure =
  | ProcedureCalibrationAutoGrasp
  | ProcedureCalibrationSoftGrip
  | ProcedureFingersSpeedProcedure
  | ProcedureCheckMovementRange
  | ProcedureI2cCommunicationCheck
  | ProcedureFingerClosingTest
  | ProcedureFingerClosingCheckMovementRangeCombinedTest;
type ProcedureLocalTypes =
  | ProcedureTypes.calibrateAutoGrasp
  | ProcedureTypes.calibrateSoftGrip
  | ProcedureTypes.calibrateFingersSpeed
  | ProcedureTypes.checkMovementRange
  | ProcedureTypes.i2cCommunicationCheck
  | ProcedureTypes.testClosing
  | ProcedureTypes.checkMovementRangeTestClosingCombined;

type ObjectType<T> = T extends ProcedureTypes.calibrateAutoGrasp
  ? ProcedureCalibrationAutoGrasp
  : T extends ProcedureTypes.calibrateSoftGrip
    ? ProcedureCalibrationSoftGrip
    : T extends ProcedureTypes.calibrateFingersSpeed
      ? ProcedureFingersSpeedProcedure
      : T extends ProcedureTypes.checkMovementRange
        ? ProcedureCheckMovementRange
        : T extends ProcedureTypes.i2cCommunicationCheck
          ? ProcedureI2cCommunicationCheck
          : T extends ProcedureTypes.testClosing
            ? ProcedureFingerClosingTest
            : T extends ProcedureTypes.checkMovementRangeTestClosingCombined
              ? ProcedureFingerClosingCheckMovementRangeCombinedTest
              : never;

const handleSoftGripProcedure = (procedureReply) => {
  let procedureObject: ProcedureCalibrationSoftGrip | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.maxCurrents = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};
const handleAutoGraspProcedure = (procedureReply) => {
  let procedureObject: ProcedureCalibrationAutoGrasp | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.minimumClosing = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};
const handleFingersSpeedProcedure = (procedureReply) => {
  let procedureObject: ProcedureFingersSpeedProcedure | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.status = [
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4],
      procedureReply[5]
    ];
    procedureObject.speeds = [
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9],
      procedureReply[10]
    ];
  }
  return procedureObject;
};

const handleMovementRangeCheck = (procedureReply) => {
  let procedureObject: ProcedureCheckMovementRange | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.positions = [procedureReply[1], procedureReply[2]];
    procedureObject.revolutions = [procedureReply[3], procedureReply[4]];
  }
  return procedureObject;
};

const handleI2cCommunicationCheck = (procedureReply) => {
  let procedureObject: ProcedureI2cCommunicationCheck | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.rtcInitialization = procedureReply[1];
    procedureObject.externalMemory1 = procedureReply[2];
    procedureObject.externalMemory1 = procedureReply[3];
    procedureObject.coapt = procedureReply[4];
  }
  return procedureObject;
};

const handleFingerClosingTest = (procedureReply) => {
  let procedureObject: ProcedureFingerClosingTest | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.closingTimes = [
      procedureReply[0],
      procedureReply[1],
      procedureReply[2],
      procedureReply[3],
      procedureReply[4]
    ];
    procedureObject.averageCurrents = [
      procedureReply[5],
      procedureReply[6],
      procedureReply[7],
      procedureReply[8],
      procedureReply[9]
    ];
  }
  return procedureObject;
};

const handleFingerClosingCheckMovementRangeCombinedTest = (procedureReply) => {
  let procedureObject: ProcedureFingerClosingCheckMovementRangeCombinedTest | null = null;
  if (procedureReply) {
    procedureObject = {};
    procedureObject.closingTimes = [
      procedureReply[0][0],
      procedureReply[0][1],
      procedureReply[0][2],
      procedureReply[0][3],
      procedureReply[0][4]
    ];
    procedureObject.averageCurrents = [
      procedureReply[0][5],
      procedureReply[0][6],
      procedureReply[0][7],
      procedureReply[0][8],
      procedureReply[0][9]
    ];
    procedureObject.minimumClosingTimes = [
      procedureReply[1][0].min,
      procedureReply[1][1].min,
      procedureReply[1][2].min,
      procedureReply[1][3].min,
      procedureReply[1][4].min
    ];
    procedureObject.maximumClosingTimes = [
      procedureReply[1][0].max,
      procedureReply[1][1].max,
      procedureReply[1][2].max,
      procedureReply[1][3].max,
      procedureReply[1][4].max
    ];
    procedureObject.minimumCurrents = [
      procedureReply[2][0].min,
      procedureReply[2][1].min,
      procedureReply[2][2].min,
      procedureReply[2][3].min,
      procedureReply[2][4].min
    ];
    procedureObject.maximumCurrents = [
      procedureReply[2][0].max,
      procedureReply[2][1].max,
      procedureReply[2][2].max,
      procedureReply[2][3].max,
      procedureReply[2][4].max
    ];
    procedureObject.positionsUpper = [
      procedureReply[3][1],
      procedureReply[3][5],
      procedureReply[3][9],
      procedureReply[3][13],
      procedureReply[3][17]
    ];
    procedureObject.positionsLower = [
      procedureReply[3][2],
      procedureReply[3][6],
      procedureReply[3][10],
      procedureReply[3][14],
      procedureReply[3][18]
    ];
    procedureObject.revolutionsUpper = [
      procedureReply[3][3],
      procedureReply[3][7],
      procedureReply[3][11],
      procedureReply[3][15],
      procedureReply[3][19]
    ];
    procedureObject.revolutionsLower = [
      procedureReply[3][4],
      procedureReply[3][8],
      procedureReply[3][12],
      procedureReply[3][16],
      procedureReply[3][20]
    ];
  }
  return procedureObject;
};

const useProcedureReply = <T extends ProcedureLocalTypes>(type: T): ObjectType<T> => {
  const { procedureReply, procedureUsedType } = useConfigStore((state) => ({
    procedureReply: state.procedureReply,
    procedureUsedType: state.procedureUsedType
  }));
  const [procedure, setProcedure] = useState<Procedure | null>(null);

  useEffect(() => {
    if (procedureUsedType !== type) {
      return;
    }
    // eslint-disable-next-line default-case
    switch (procedureUsedType) {
      case ProcedureTypes.calibrateAutoGrasp:
        setProcedure(handleAutoGraspProcedure(procedureReply));
        break;
      case ProcedureTypes.calibrateSoftGrip:
        setProcedure(handleSoftGripProcedure(procedureReply));
        break;
      case ProcedureTypes.calibrateFingersSpeed:
        setProcedure(handleFingersSpeedProcedure(procedureReply));
        break;
      case ProcedureTypes.checkMovementRange:
        setProcedure(handleMovementRangeCheck(procedureReply));
        break;
      case ProcedureTypes.i2cCommunicationCheck:
        setProcedure(handleI2cCommunicationCheck(procedureReply));
        break;
      case ProcedureTypes.testClosing:
        setProcedure(handleFingerClosingTest(procedureReply));
        break;
      case ProcedureTypes.checkMovementRangeTestClosingCombined:
        setProcedure(handleFingerClosingCheckMovementRangeCombinedTest(procedureReply));
        break;
    }
  }, [procedureReply]);

  return procedure as ObjectType<T>;
};

export default useProcedureReply;
