import api from '../utils/apiClient';
import { AxiosResponse } from 'axios';
import { encodeQueryData } from '../utils/encodeQuery';
import {
  LocationEntry,
  LocationOpeningHours,
  LocationQueryParams,
  LocationsQueryParams
} from './locations.types';
import { PaginatedResponse } from '../../../types';

export const GET_LOCATIONS = 'api/locations';
export const GET_LOCATION = 'api/location/{id}';
export const GET_LOCATION_OPENING_HOURS = 'api/location/{id}/opening-hours';
export const CREATE_LOCATION = 'api/location';
export const CREATE_LOCATION_OPENING_HOURS = 'api/location/{id}/opening-hours';
export const DELETE_LOCATION = 'api/location/{id}';
export const DELETE_LOCATION_OPENING_HOURS = 'api/location/{id}/opening-hours/{entryId}';
export const UPDATE_LOCATION = 'api/location/{id}';

const withCredentials = {
  withCredentials: true
};

export const getLocations = (params: LocationsQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<LocationEntry>>(`${GET_LOCATIONS}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getLocation = (locationId: number, params: LocationQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<LocationEntry>(
      `${GET_LOCATION.replace('{id}', `${locationId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const getLocationOpeningHours = (
  locationId: number | null,
  format: string = 'collection'
): Promise<any> => {
  return api
    .get<LocationOpeningHours>(
      `${GET_LOCATION_OPENING_HOURS.replace('{id}', String(locationId))}`,
      { ...withCredentials, params: { format } }
    )
    .then((res: AxiosResponse) => res.data);
};

export const deleteLocation = (locationId: number): Promise<any> =>
  api
    .delete(DELETE_LOCATION.replace('{id}', `${locationId}`), withCredentials)
    .then((res: AxiosResponse) => res.data);

export const createLocation = (data: any): Promise<any> =>
  api
    .post<LocationEntry>(CREATE_LOCATION, data, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const createLocationOpeningHours = (data: any): Promise<any> =>
  api
    .post<LocationOpeningHours>(
      CREATE_LOCATION_OPENING_HOURS.replace('{id}', String(data.location_id)),
      data,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const deleteLocationOpeningHours = ({
  locationId,
  hoursEntryId
}: {
  locationId: number;
  hoursEntryId: number;
}): Promise<any> =>
  api
    .delete(
      DELETE_LOCATION_OPENING_HOURS.replace('{id}', `${locationId}`).replace(
        '{entryId}',
        `${hoursEntryId}`
      ),
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const updateLocation = ({ data, locationId }: any): Promise<any> =>
  api
    .put(UPDATE_LOCATION.replace('{id}', `${locationId}`), data, withCredentials)
    .then((res: AxiosResponse) => res.data);
