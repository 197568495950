import React from 'react';
import { Link } from '@mui/material';
import { TEXTS } from 'constants/texts';

interface ApiErrorsInterface {
  [key: string]: {
    [key: string]: {
      [key: string]: string | React.JSX.Element;
    };
  };
}

export type StatusCode = 503 | 413 | 'default';

export const STATUS_ERROR_MESSAGES: Record<StatusCode, string> = {
  503: "Connection cannot be established. This may be due to a firewall block on your computer. Please contact your organization's IT department.",
  413: 'File is to large. Please upload an image with a smaller size.',
  default: 'Something went wrong. Please refresh the page or try again later.'
};

export const API_ERRORS: ApiErrorsInterface = {
  DEVICE: {
    UPDATE: {
      ACTIVE_CODE_EXISTS:
        'You can’t assign this device to any Company because it has an Activation code. To assign to Company, please contact ADP Team.'
    },
    ADD_WITH_CODE: {
      NO_ACTIVE_CODES: (
        <p>
          The code you provided is no longer active, please contact us at
          <Link href={`mailto:${TEXTS.SUPPORT_EMAIL}`}> {TEXTS.SUPPORT_EMAIL}</Link> to resolve the
          issue.
        </p>
      )
    }
  }
};
