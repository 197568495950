import React from 'react';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { Divider, Modal } from '@mui/material';
import { ModalStyle } from './styles';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { PATIENTS } from 'constants/routes';
import { ReactComponent as UserSvg } from 'assets/user.svg';
import { TableLink } from '../Links/Links';
import { useNavigate } from 'react-router-dom';

type PatientsModalProps = {
  handleClose: any;
  isModalOpen: boolean;
  patients: any;
};

const PatientsModal = ({ handleClose, isModalOpen, patients }: PatientsModalProps) => {
  const navigate = useNavigate();

  const redirectToPatient = (patientId) => {
    navigate(`${PATIENTS}/${patientId}`);
  };

  return (
    <Modal open={isModalOpen} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper title='Patients'>
        <ul>
          {patients.map((patient) => (
            <li
              style={{
                listStyle: 'none'
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '16px 0'
                }}>
                <TableLink to={`${PATIENTS}/${patient?.id}`}>{patient.name}</TableLink>
                <CustomButton
                  Icon={UserSvg}
                  color='lightBlue'
                  onClick={() => redirectToPatient(patient?.id)}
                />
              </div>
              <Divider />
            </li>
          ))}
        </ul>
        <FormButtonsWrapper>
          <CustomButton color='light' onClick={handleClose}>
            Cancel
          </CustomButton>
        </FormButtonsWrapper>
      </FormContentWrapper>
    </Modal>
  );
};

export default PatientsModal;
