type RoomConfigNotInitialized = {
  messageChannel: string | undefined;
  receiptChannel: string | undefined;
  encryptionKey: string | undefined;
};

type RoomConfig = {
  messageChannel: string;
  receiptChannel: string;
  encryptionKey: string;
};

const config: RoomConfigNotInitialized = {
  messageChannel: undefined,
  receiptChannel: undefined,
  encryptionKey: undefined
};

let isInitialized = false;

const validateNotInitialized = () => {
  if (!isInitialized) {
    throw new Error('RoomConfig is not initialized');
  }
};

export function initializeRoomConfig(
  messageChannel: string,
  receiptChannel: string,
  encryptionKey: string
) {
  isInitialized = true;
  config.messageChannel = messageChannel;
  config.receiptChannel = receiptChannel;
  config.encryptionKey = encryptionKey;
}

export function getRoomConfig(): RoomConfig {
  validateNotInitialized();

  return config as RoomConfig;
}
