import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';
import { useNavigate, useLocation } from 'react-router-dom';
import { HeaderComponent } from '../../../components/Typography/Header';
import { useDeviceInfo, useDeviceUpdate } from '../../../hooks/api/useDevices';
import { DEVICE } from '../../../../constants/routes';
import * as Sentry from '@sentry/react';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { DeviceExtendOptions, DevicesQueryParams } from 'adp-panel/api/devices/device.types';
import DeviceForm from './DeviceForm';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
`;

const EditDevice = () => {
  const queryParamsDevice: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion,
      DeviceExtendOptions.activeCode
    ]
  };

  const { state } = useLocation();
  const deviceId = state?.detail ?? false;
  const { result: device } = useDeviceInfo(deviceId, true, queryParamsDevice);
  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    const {
      model = undefined,
      pcb = undefined,
      firmware = undefined,
      amputee = undefined,
      clinician = undefined,
      serial = undefined,
      bluetooth_id = undefined,
      company = undefined
    } = e;

    const deviceData = {
      ...(model && { model_id: model.id }),
      ...(pcb && { pcb_version_id: pcb.id }),
      ...(firmware && { firmware_version_id: firmware.id }),
      ...(bluetooth_id && device?.bluetooth_id !== bluetooth_id && { bluetooth_id: bluetooth_id }),
      ...(serial && device?.serial !== serial && { serial }),
      ...(amputee ? { amputee_id: amputee.id } : { amputee_id: null }),
      ...(clinician ? { clinician_id: clinician.id } : { clinician_id: null }),
      ...(company ? { company_id: company.id } : { company_id: null })
    };

    try {
      await updateDevice({ data: deviceData, deviceId: device?.id });
      navigate(DEVICE);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  if (!device) {
    return (
      <DefaultLayout>
        <ConfirmationLoader />
      </DefaultLayout>
    );
  }

  const existingData = {
    model: device.model,
    serial: device.serial,
    bluetooth_id: device.bluetooth_id,
    clinician: device.clinician,
    amputee: device.amputee,
    pcb: device.pcb_version,
    firmware: device.firmware_version
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={'Edit device'} />
        <StyledCard>
          <DeviceForm
            existingData={existingData}
            loading={isLoadingUpdate}
            handleSubmit={handleSubmit}
          />
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default EditDevice;
